import React, { useContext, useMemo } from 'react';
import ICONS from '../../constants/icons';
import CoachProfilePersonal from './CoachProfilePersonal';

import qs from 'qs';
import {
  ProfileContainer,
  ProfileNavigation,
  ProfileNavHeading,
  ProfileNavLink,
  TermsAndPrivacy,
  Pusher,
  StyledLink,
} from './components';
import CoachProfilePricing from './CoachProfilePricing';
import CoachProfileSettings from './CoachProfileSettings';
import { useHistory } from 'react-router-dom';
import { MobileContext } from '../../services/MobileContext';

const CoachProfile = () => {
  const history = useHistory();
  const [active, subActive] = useMemo(() => {
    const destructed = qs.parse(history?.location?.search, { ignoreQueryPrefix: true });
    return [destructed?.tab, destructed?.sub_tab];
  }, [history.location]);

  const { isMobile } = useContext(MobileContext);

  return (
    <ProfileContainer>
      {!isMobile && (
        <ProfileNavigation>
          <ProfileNavHeading>YOUR PROFILE</ProfileNavHeading>
          <ProfileNavLink
            icon={ICONS.ProfilePersonal}
            activeIcon={ICONS.ProfilePersonalActive}
            active={!active || active === '0'}
            onClick={() => history?.push('profile?tab=0')}
          >
            Personal & Professional info
          </ProfileNavLink>
          <ProfileNavLink
            icon={ICONS.ProfilePricing}
            activeIcon={ICONS.ProfilePricingActive}
            active={active === '1'}
            onClick={() => history?.push('profile?tab=1')}
          >
            Pricing & Coupons
          </ProfileNavLink>
          {/* <ProfileNavLink
            icon={ICONS.ProfileBilling}
            activeIcon={ICONS.ProfileBillingActive}
            active={active === '2'}
            onClick={() => history?.push('profile?tab=2')}
          >
            Billing Options
          </ProfileNavLink>
          <ProfileNavLink
            icon={ICONS.ProfileSettings}
            activeIcon={ICONS.ProfileSettingsActive}
            active={active === '3'}
            onClick={() => history?.push('profile?tab=3')}
          >
            Preferences & Settings
          </ProfileNavLink> */}
          <Pusher />
          <TermsAndPrivacy>
            <StyledLink href="https://www.witnesstofitness.com/terms-of-use" target="_blank">
              Terms & Conditions
            </StyledLink>
            <StyledLink href="https://www.witnesstofitness.com/privacy-policy" target="_blank">
              Privacy Policy
            </StyledLink>
          </TermsAndPrivacy>
        </ProfileNavigation>
      )}
      {!active || active === '0' ? (
        <CoachProfilePersonal />
      ) : active === '1' ? (
        <CoachProfilePricing
          activeTab={subActive}
          setActiveTab={(i) => history?.push(`profile?tab=1&sub_tab=${i}`)}
        />
      ) : active === '3' ? (
        <CoachProfileSettings />
      ) : (
        <></>
      )}
    </ProfileContainer>
  );
};
export default CoachProfile;
