import React from 'react';
import {
  TemplateContainer,
  TemplateGridSquare,
  TemplateImage,
  TemplatePrimaryText,
  TemplateSecondaryText,
} from './components';
import CustomButton from '../button/CustomButton';

const EmptyTemplate = ({
  primaryText,
  secondarytext,
  buttonText,
  onButtonClick,
  image,
  imageSize,
  hideImageGrid,
}) => {
  return (
    <TemplateContainer>
      <TemplateImage url={image} size={imageSize}>
        {!hideImageGrid && (
          <>
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
            <TemplateGridSquare />
          </>
        )}
      </TemplateImage>
      <TemplatePrimaryText>{primaryText}</TemplatePrimaryText>
      {secondarytext && <TemplateSecondaryText>{secondarytext}</TemplateSecondaryText>}
      {buttonText && (
        <CustomButton
          onClick={onButtonClick}
          outlined
          hoverBackground="#00ff91"
          backgroundColor="#fff"
          style={{
            width: 'fit-content',
            height: 'fit-content',
            padding: '1.6rem 2.4rem',
            margin: '4.8rem 0 0',
          }}
          fontFamily="Roboto-r"
        >
          {buttonText}
        </CustomButton>
      )}
    </TemplateContainer>
  );
};

export default EmptyTemplate;
