import React, { useMemo } from 'react';
import moment from 'moment';
import {
  ConversationItemContainer,
  ConversationItemColumn,
  ConversationItemName,
  ConversationItemLastMessage,
  ConversationItemInfo,
  ConversationItemTime,
  ConversationItemUnreadIndicator,
  BorderIndicator,
  Avatar,
} from './components';

const ConversationItem = ({ item, isActive, onClick, isNew, user, isMobile }) => {
  const initials = useMemo(() => {
    const [first, last] = item?.user?.name?.split(' ') || ['', ''];
    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`;
  }, [item]);

  const time = useMemo(() => {
    const updatedAt = (item.lastMessage?.sentAt || item.createdAt) * 1000;
    return moment(updatedAt).fromNow();
  }, [item.lastMessage, item.createdAt]);

  return (
    <ConversationItemContainer onClick={onClick} isActive={isActive} isMobile={isMobile}>
      <ConversationItemColumn>
        <Avatar src={item.user.avatar} size="3.6rem" style={{ margin: '1.2rem' }}>
          {initials}
        </Avatar>
      </ConversationItemColumn>

      <ConversationItemColumn>
        <ConversationItemName>{item.user.name}</ConversationItemName>
        <ConversationItemLastMessage>
          {!item.lastMessage
            ? 'New conversation'
            : item.lastMessage.type === 'text'
            ? item.lastMessage?.text
            : item.lastMessage?.text === 'Click here to use an extension coupon'
            ? 'Extension coupon sent'
            : 'Custom message sent'}
        </ConversationItemLastMessage>
      </ConversationItemColumn>

      <ConversationItemInfo>
        <ConversationItemTime>{time}</ConversationItemTime>
        {item.unreadMessageCount > 0 && <ConversationItemUnreadIndicator />}
      </ConversationItemInfo>

      {isActive && <BorderIndicator />}
    </ConversationItemContainer>
  );
};

export default ConversationItem;
