import React from 'react';
import { RadioWrapper, RadioIcon, RadioText } from './components';

const RadioButton = ({ text, selected, onClick, buttonStyle, textStyle }) => (
  <RadioWrapper onClick={onClick}>
    <RadioIcon selected={selected} style={buttonStyle} />
    <RadioText style={textStyle}>{text}</RadioText>
  </RadioWrapper>
);

export default RadioButton;
