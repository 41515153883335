import React from 'react';
import {LeftIconImg, RightIconImg} from "./components";

const PaginatedNumbers = ({curPage, setCurrentNumber, total}) => {
    const maxPage = Math.max(Math.ceil(total /10), 1);
    const list = () => {
        let res = [];
        for (let i = Math.max(curPage -4, 0); i < Math.min(Math.ceil(total /10), curPage + 3); i++) {
            res.push(<div onClick={() => setCurrentNumber(i+1)}  style={{
                borderRadius: '50%',
                width: '36px',
                height: "36px",
                padding: '8px',
                background: curPage === i + 1 ? '#00ff91' : '#F7F6F6',
                textAlign: 'center',
                cursor: 'pointer', color: curPage === i + 1  ? 'black' : 'black'}}>{i+1 }
            </div>)
        }

        return res;
    }

    return (
        <div style={{display: 'flex',  justifyContent: 'center', width: "100%"}}>
            <div style={{cursor: 'pointer'}} onClick={() => setCurrentNumber(Math.max(curPage-1, 1))}>
                <LeftIconImg/>
            </div>
            {list()}
            <div style={{cursor: 'pointer'}} onClick={() => setCurrentNumber(Math.min(curPage+1, maxPage))}>
                <RightIconImg/>
            </div>
        </div>
    );
};

export default PaginatedNumbers;
