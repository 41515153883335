import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000012')};
  line-height: 2.4rem;
  font-family: Space-r;
  padding: 2rem 3.6rem;
  letter-spacing: 0.08rem;
  justify-content: space-between;
`;
export const CloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;
export const ModalMain = styled.div`
  /* padding: ${({ padding }) => (padding ? padding : '3.6rem')}; */
`;

export const ModalColumn = styled.div`
  width: Calc(50% - 12px);
`;

export const ModalInput = styled.div`
  width: ${({ small }) => (small ? 'Calc(50% - 8px)' : '100%')};
  height: ${({ height }) => (height ? height : '7.5rem')};
  flex-grow: ${({ grow }) => (grow ? '1' : 'none')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Roboto-m;
  font-size: 1.4rem;
  color: #37333399;
  margin-bottom: 2.4rem;
`;

export const ModalTextArea = styled.div`
  max-height: 100%;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  width: 22.6rem;
  margin: 0 0.8rem;
  height: 4.8rem;
`;

export const FieldWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`;

export const DaysWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CheckBox = styled.div`
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.color.primary : '#05000030')};
  background: ${({ theme, checked }) => (checked ? theme.color.primary : '#ffffff30')};
  background-image: ${({ checked }) => (checked ? `url(${ICONS.TermsCheckMark})` : 'url()')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 86% auto;
  border-radius: 4px;
  margin: 1.4rem;
`;
export const CoachRow = styled.div`
  width: 100%;
  padding: 0.4rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const CoachRowCheck = styled.div`
  background: #ffffff;
  width: fit-content;
  border-radius: 6px 0 0 6px;
  margin-right: 2px;
`;
export const CoachRowName = styled.div`
  background: #ffffff;
  flex-grow: 1;
  border-radius: 0 6px 6px 0;
  font-size: 1.4rem;
  color: #696666;
  display: flex;
  align-items: center;
  line-height: 2.8rem;
  padding: 0.8rem;
`;
export const CoachRowImage = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin: 0 1.6rem 0 0.8rem;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
`;
export const CoachList = styled.div`
  flex-grow: 1;
  background: #f1f0f0;
  max-height: Calc(100% - 5rem);
  overflow: auto;
  padding: 0.8rem 0;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f0f0;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #05000012;
    border-radius: 2px;
  }
`;
