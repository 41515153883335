import { useQuery } from '@apollo/react-hooks';
import { getCoachTraineesQuery, getOrganisationTraineeQuery } from '@witness/graphql';
import { useContext, useState } from 'react';
import { OrganizationContext } from '../services/organizationContext';
import { useDebounce } from '../services/utils';

const useTrainees = ({ initialFilter } = {}) => {
  const { isOrganization } = useContext(OrganizationContext);
  const [traineeName, setTraineeName] = useState('');
  const delayedName = useDebounce(traineeName, 500);

  const { data: coachTrainees, loading: coachTraineesLoading } = useQuery(getCoachTraineesQuery, {
    variables: {
      record: {
        searchName: delayedName,
      },
    },
    skip: isOrganization,
  });
  const { data: organisationTrainees, loading: organisationTraineesLoading } = useQuery(
    getOrganisationTraineeQuery,
    {
      variables: {
        record: {
          searchName: delayedName,
        },
      },
      skip: !isOrganization,
    },
  );
  let traineeObject = {
    traineeName,
    setTraineeName,
  };
  if (isOrganization) {
    traineeObject = {
      ...traineeObject,
      trainees: organisationTrainees?.getOrganisationTrainees,
      traineesLoading: organisationTraineesLoading,
    };
  }
  if (!isOrganization) {
    traineeObject = {
      ...traineeObject,
      trainees: coachTrainees?.getCoachTrainees,
      traineesLoading: coachTraineesLoading,
    };
  }
  return traineeObject;
};

export default useTrainees;
