import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 7.5%;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-r')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 1.8rem;
  padding: 4px 28px;
  min-height: 28px;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const ArrowIcon = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #d2d1d1;
  background-image: url('');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 3.5rem auto 2rem;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const ModalMain = styled.div`
  width: 100%;
  display: flex;
  padding: 2.2rem 3.2rem 3.2rem;
`;

export const ModalColumn = styled.div`
  width: ${({ left }) => (left ? '30%' : '70%')};
  padding-left: ${({ left }) => (left ? '0' : '3.2rem')};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FieldTitle = styled.div`
  font-family: Roboto-m;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #37333399;
  margin-bottom: 1.6rem;
  padding: 1rem 0.4rem 0;
`;

export const ReplaceVideo = styled.div`
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #373333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.6rem;
`;

export const UploadVideoIcon = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  background-image: url(${ICONS.Upload});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 0.4rem 0 0.8rem;
  cursor: pointer;
  position: relative;
`;

export const ChoosePartWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #05000033;
  padding: 1rem 0.2rem;
  ${({ disabled }) => (disabled ? `
  opacity: 0.6;
  pointer-events: none;
  ` : '')};
`;

export const ChoosePartWrapperScrollable = styled.div`
  width: 100%;
  padding: 1rem 1.8rem;
  max-height: 12.4rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const ChoosePartItem = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto-r;
  font-size: 1.4rem;
  color: #05000099;
  margin-bottom: 1.6rem;
  &:last-child {
    margin: 0;
  }
`;

export const ChoosePartRadio = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border: ${({ active, theme }) =>
    active ? `4px solid ${theme.color.primary}` : '1px solid #0500003d'};
  margin-right: 1.6rem;
  border-radius: 50%;
  cursor: pointer;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px;
  background: ${({ black }) => (black ? 'black' : 'white')};
  overflow: hidden;
`;

export const VideoSign = styled.div`
  background-image: url(${ICONS.VideoSign});
  background-size: auto 25%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 11;
  &:hover {
    transform: scale(1.1);
  }
`;

export const AddVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.color.primary};
  border-radius: 8px;
  padding: 0.6rem;
  position: relative;
  background: white;
`;

export const AddVideoWrapperDashed = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dashed #d2d1d1;
  border-radius: 8px;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 2rem;
`;

export const DragFileIcon = styled.div`
  width: 4.6rem;
  height: 3.2rem;
  background-image: url(${ICONS.ImportIconGreen});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const DragFileText = styled.div`
  font-family: Roboto-r;
  color: #4a4a4a;
  font-size: 1.4rem;
  padding-top: 1.4rem;
`;

export const BoldSpan = styled.span`
  font-family: Roboto-m;
  font-size: 1.4rem;
  color: #373333;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3.2rem 3.2rem;
`;

export const ButtonWrapper = styled.div`
  min-width: 14.4rem;
  height: 4.4rem;
`;
