import React from 'react';
import FinanceTableRow from '../../components/finance/FinanceTableRow';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import {
  FinanceMainGrid,
  FinanceMinimalPreview,
  FinanceChart,
  FinanceChartContent,
  FinanceTable,
  FinanceTableHeader,
  FinanceTableHeaderText,
  DollarSign,
  NotDollarSign,
} from './components';
import { useEarnings, useUtils } from '../../hooks';

const SimpleLineChart = ({ data }) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
        <XAxis dataKey="name" color="green" displayName="hiii" axisLine="none" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        {/* <Legend /> */}
        <Area type="monotone" dataKey="income" stroke="#00FF8D" fill="#53FF8A1A" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const Home = () => {
  const { earnings, today, currentMonthIncome, chartData } = useEarnings();
  const { formatNumber } = useUtils();
  return (
    <FinanceMainGrid>
      <FinanceMinimalPreview>
        <img style={{ objectFit: 'cover' }} src="https://i.ibb.co/wgCQ25z/download.png" alt="" />
        <div>
          <span>How Much will you Earn this Month?</span>
          <div>
            {/* <DollarSign /> */}
            <NotDollarSign>₪</NotDollarSign>
            <span>{currentMonthIncome}</span>
          </div>
          <span>{today}</span>
        </div>
      </FinanceMinimalPreview>
      <FinanceChart>
        <FinanceChartContent>
          <SimpleLineChart data={chartData} />
        </FinanceChartContent>
      </FinanceChart>
      <FinanceTable>
        <div
          style={{
            height: '100%',
            overflowY: 'auto',
            background: '#FAFAFA',
            padding: '1% 2%',
            paddingTop: 0,
            borderRadius: 8,
          }}
        >
          <FinanceTableHeader>
            <FinanceTableHeaderText>Dates</FinanceTableHeaderText>
            <FinanceTableHeaderText>Monthly Income</FinanceTableHeaderText>
            <FinanceTableHeaderText>#Number of Trainees</FinanceTableHeaderText>
            <FinanceTableHeaderText></FinanceTableHeaderText>
            <FinanceTableHeaderText></FinanceTableHeaderText>
            <FinanceTableHeaderText style={{ marginLeft: 'auto', width: '120px' }}>
              Actions
            </FinanceTableHeaderText>
          </FinanceTableHeader>

          {Object.keys(earnings)
            .reverse()
            .map((year) =>
              Object.keys(earnings[year])
                ?.reverse()
                .map((month) => (
                  <FinanceTableRow
                    data={earnings[year][month]?.programs}
                    date={`${month}.${year}`}
                    monthlyIncome={formatNumber(earnings[year][month]?.monthlyIncome)}
                    perHour="10.000"
                    numOfTrainees={earnings[year][month]?.programs?.length}
                  />
                )),
            )}
        </div>
      </FinanceTable>
    </FinanceMainGrid>
  );
};
export default Home;
