const LENGTH_OPTIONS = [
    { label: '1 month', value: 30 },
    { label: '2 months', value: 60 },
    { label: '3 months', value: 90 },
    { label: '4 months', value: 120 },
    { label: '5 months', value: 150 },
    { label: '6 months', value: 180 },
    { label: '7 months', value: 210 },
    { label: '8 months', value: 240 },
    { label: '9 months', value: 270 },
    { label: '10 months', value: 300 },
    { label: '11 months', value: 330 },
    { label: '12 months', value: 360 },
]

export default LENGTH_OPTIONS;