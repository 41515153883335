import React from 'react';
import { useMemo } from 'react';

import {
  ProgressTrack,
  WholeWrapper,
  ProgressBar,
  ChartLegend,
  ChartLegendProgress,
} from './components';

const ProgressLine = ({ total, progress }) => {
  const percentage = useMemo(() => {
    const part = Math.round((progress / total) * 100);
    if (part < 0) return 0;
    if (part > 100) return 100;
    return part;
  }, [progress, total]);
  return (
    <WholeWrapper>
      <ProgressTrack>
        <ProgressBar percentage={percentage}></ProgressBar>
      </ProgressTrack>
      <ChartLegend>
        <div className="end_points">0%</div>
        <ChartLegendProgress percentage={percentage}>
          {percentage !== 0 && percentage !== 100 && (
            <>
              {percentage}%<div>of Trainees</div>
            </>
          )}
        </ChartLegendProgress>
        <div className="end_points">100%</div>
      </ChartLegend>
    </WholeWrapper>
  );
};

export default ProgressLine;
