import * as Yup from 'yup';

const title = Yup.string().trim().required('Required');
const validUntil = Yup.date().min(new Date());
const description = Yup.string().required();
const available = Yup.number().required();
const amount = Yup.number().required();
const discountType = Yup.mixed().required();
const price = Yup.number().required();
const programLength = Yup.number().required();
const speciality = Yup.mixed().required();
const trainingTypes = Yup.array().min(1).required();
const daysOfTraining = Yup.array().of(Yup.string()).required();
const coaches = Yup.object()
.when('isOrganization', {
  is: true,
  then: (schema) =>
    schema.required().test('keys', 'at least one coach', (value) => {
      const coachesObj = { ...value };
      return Object.keys(coachesObj)?.find((x) => coachesObj[x])?.length;
    }),
});
const coachFee = Yup.number().when('isOrganization', {
  is: true,
  then: (schema) => schema.required(),
});

export const DiscountCouponSchema = Yup.object().shape({
  title,
  validUntil,
  description,
  available,
  amount: Yup.number().test({
    name: 'max',
    exclusive: false,
    params: {},
    message: 'Invalid Amount Value',
    test: function (value) {
      const { discountType } = this.parent;
      if (discountType.value === 'PERCENTAGE') {
        return value >= 1 && value <= 100;
      }
      return typeof value === 'number' && value === value;
    },
  }),
  discountType,
  coaches,
  coachFee,
});

export const ProgramCouponSchema = Yup.object().shape({
  title,
  validUntil,
  available,
  price,
  programLength,
  speciality,
  trainingTypes,
  daysOfTraining,
  coaches,
  coachFee,
});

export const ExtensionCouponSchema = Yup.object().shape({
  monthlySum: price,
  programLength,
});
