import React from 'react';
import {
  FlexWrapper,
  TemplateHeader,
  TemplateInputWrapper,
  TemplateLengthItem,
} from './components';
import { Field } from 'formik';
import CustomInput from '../../components/input/CustomInput';
import SelectStatus from '../../components/programs-header/SelectStatus';
import ReactSelectWitness from '../../components/react-select-witness/ReactSelectWitness';
import { useQuery } from '@apollo/react-hooks';
import { getTrainingTypesQuery } from '@witness/graphql';
import LENGTH_OPTIONS from '../../constants/programLengthOptions'
import { useUtils } from '../../hooks';


const TemplateFilter = ({ setFieldValue, modalLayout, values, specList, isAdmin, readOnly }) => {
  const { data: trainingTypes } = useQuery(getTrainingTypesQuery);
  const { programLengthValueToOption } = useUtils();

  return (
    <>
      <TemplateHeader reverse={modalLayout}>
        <TemplateInputWrapper>
          <>
            {!modalLayout && <span> Template Name</span>}
            <Field id="templateName" name="templateName">
              {({ field }) => (
                <CustomInput
                  outlined
                  borderRadius="8px"
                  height="4.4rem"
                  style={{ width: '26rem', margin: 0 }}
                  placeholder="Type Here"
                  onChange={(v) => setFieldValue(field.name, v)}
                  value={values?.[field?.name]}
                  disabled={readOnly}
                />
              )}
            </Field>
          </>
        </TemplateInputWrapper>
        {modalLayout && (
          <TemplateInputWrapper>
            <span>Search By</span>
            <Field id="searchBy" name="searchBy">
              {({ field }) => (
                <SelectStatus
                  options={[
                    { label: 'Program Template', value: 'templates' },
                    { label: 'Customer Programs', value: 'programs' },
                  ]}
                  value={values?.[field?.name]}
                  onChange={(v) => setFieldValue(field.name, v)}
                  disabled={readOnly}
                />
              )}
            </Field>
          </TemplateInputWrapper>
        )}
        <FlexWrapper>
          {!modalLayout && (
            <TemplateInputWrapper style={{ marginRight: '2rem' }}>
              <span>Training Type</span>
              <Field id="speciality" name="speciality">
                {({ field }) => (
                  <ReactSelectWitness
                    // error={touched[field.name] && !!errors[field.name]}
                    options={specList}
                    value={field?.value}
                    placeholder="- Choose speciality -"
                    onChange={(v) => {
                      if (v?.value !== field?.value?.value) {
                        setFieldValue('trainingTypes', []);
                      }
                      setFieldValue(field?.name, v);
                    }}
                    backspaceRemovesValue={false}
                    style={{ maxWidth: '20rem' }}
                    disabled={readOnly}
                  />
                )}
              </Field>
            </TemplateInputWrapper>
          )}
          {!modalLayout && (
            <TemplateInputWrapper style={{ marginRight: '2rem', position: 'relative' }}>
              <span>Subtype</span>
              <Field id="trainingTypes" name="trainingTypes">
                {({ field }) => (
                  <ReactSelectWitness
                    // error={touched[field.name] && !!errors[field.name]}
                    options={values?.speciality?.trainingTypes?.map((a) => ({
                      value: a?.id,
                      label: a?.name,
                    }))}
                    placeholder="- Choose training types -"
                    isMulti={values?.speciality?.label === 'Strength'}
                    checkBoxes={values?.speciality?.label === 'Strength'}
                    value={field?.value}
                    onChange={(v) => {
                      if (values?.speciality?.label !== 'Strength') {
                        setFieldValue(field?.name, [v]);
                      } else if (!v || v?.length <= 3) {
                        setFieldValue(field?.name, v);
                      }
                    }}
                    onDeleteOne={(v) => {
                      setFieldValue(
                        field.name,
                        field?.value?.filter((x) => x?.value !== v),
                      );
                    }}
                    backspaceRemovesValue={false}
                    style={{ maxWidth: '26rem' }}
                    disabled={readOnly}
                  />
                )}
              </Field>
            </TemplateInputWrapper>
          )}
          {modalLayout && (
            <TemplateInputWrapper style={{ marginRight: '2rem' }}>
              <span>Training Type</span>
              <Field id="trainingType" name="trainingType">
                {({ field }) => (
                  <ReactSelectWitness
                    // error={touched[field.name] && !!errors[field.name]}
                    options={trainingTypes?.getTrainingTypes?.map((x) => ({
                      value: x?.id,
                      label: x?.name,
                    }))}
                    placeholder="- Choose training type -"
                    value={field?.value}
                    onChange={(v) => {
                      setFieldValue(field?.name, v);
                    }}
                    style={{ maxWidth: '26rem' }}
                  />
                )}
              </Field>
            </TemplateInputWrapper>
          )}
          <TemplateInputWrapper>
            <span>Length</span>
            <Field id="templateLength" name="templateLength">
              {({ field }) => (
                <TemplateInputWrapper>
                  <ReactSelectWitness
                    options={LENGTH_OPTIONS}
                    value={
                      programLengthValueToOption(field?.value)
                    }
                    placeholder="- Choose Length -"
                    onChange={(v) => { !readOnly && setFieldValue(field.name, v?.value) }}
                    style={{ maxWidth: '26rem' }}
                  />
                </TemplateInputWrapper>
              )}
            </Field>
          </TemplateInputWrapper>

          {isAdmin && (
            <TemplateInputWrapper style={{ marginLeft: '2rem' }}>
              <span>Visible</span>
              <Field id="isGlobal" name="isGlobal">
                {({ field }) => (
                  <TemplateInputWrapper>
                    <input
                      type="checkbox"
                      checked={values[field?.name]}
                      onClick={() => setFieldValue(field.name, !values[field?.name])}
                    />
                  </TemplateInputWrapper>
                )}
              </Field>
            </TemplateInputWrapper>
          )}
        </FlexWrapper>
      </TemplateHeader>
    </>
  );
};

export default TemplateFilter;
