import React from 'react';
import TimePicker from 'react-time-picker';

import {
  InputWrapperClock,
  NumberInputArrowsContainer,
  NumberInputArrow,
  InputUpperLabel,
} from './components';

const CustomInputClock = ({
  onChange,
  value,
  disabled,
  noArrows,
  height,
  color,
  fontSize,
  borderRadius,
  error,
  upperlabel,
  hours,
  maxTime,
  small,
  borderColor,
  ...rest
}) => {
  const onArrowChange = (currVAl, coeff) => {
    if (!value) {
      onChange('00:00:01');
    } else {
      const [, currMins, currSecs] = currVAl?.split(':');
      const valueInSecs = Number(currMins) * 60 + Number(currSecs || 0);
      const updatedValueInSecs = valueInSecs + coeff;
      if (updatedValueInSecs <= (hours ? 1439 : 3599) && updatedValueInSecs >= 0) {
        onChange(
          `00:${('0' + Math.floor(updatedValueInSecs / 60)).slice(-2)}:${(
            '0' +
            (updatedValueInSecs % 60)
          ).slice(-2)}`,
        );
      }
    }
  };
  return (
    <>
      <InputWrapperClock
        height={height}
        fontSize={fontSize}
        borderRadius={borderRadius}
        error={error}
        disabled={disabled}
        small={small}
        borderColor={borderColor}
        {...rest}
      >
        {upperlabel && <InputUpperLabel>{upperlabel}</InputUpperLabel>}
        {!noArrows && (
          <NumberInputArrowsContainer style={{ margin: '0.3rem 0.4rem 0 0.8rem' }}>
            <NumberInputArrow
              small={small}
              onClick={() => onArrowChange(value, 60)}
              plus
              nonPositive={Number(value?.split(':')?.[1]) === (hours ? 23 : 59)}
            />
            <NumberInputArrow
              small={small}
              onClick={() => onArrowChange(value, -60)}
              nonPositive={Number(value?.split(':')?.[1]) === 0}
            />
          </NumberInputArrowsContainer>
        )}
        <TimePicker
          minutePlaceholder={hours ? 'hh' : 'mm'}
          secondPlaceholder={hours ? 'mm' : 'ss'}
          onChange={onChange}
          value={value}
          format="mm:ss"
          clearIcon={false}
          clockIcon={false}
          maxDetail="second"
          maxTime={maxTime}
        />
        {!noArrows && (
          <NumberInputArrowsContainer style={{ margin: '0.3rem 0.8rem 0 0.4rem' }}>
            <NumberInputArrow
              small={small}
              onClick={() => onArrowChange(value, 1)}
              plus
              nonPositive={/0{1,2}:0{1,2}:0{1,2}/.test(value)}
              right
            />
            <NumberInputArrow
              small={small}
              onClick={() => onArrowChange(value, -1)}
              nonPositive={/[0-9]{1,2}:59:59/.test(value)}
              right
            />
          </NumberInputArrowsContainer>
        )}
      </InputWrapperClock>
    </>
  );
};

export default CustomInputClock;
