import { CoachProgramStatus } from '../../constants/enums';
import ICONS from '../../constants/icons';

const { default: styled } = require('styled-components');

export const TraineesFilters = styled.div`
  padding: 3.2rem 2.8rem;
  display: flex;
  border-bottom: 1px solid #e9eaed;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const FiltersTitle = styled.span`
  color: #4a4a4a;
  font-family: 'Gotham-r';
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 1.9rem;
  & > span {
    padding: 0 0.8rem;
    font-size: 2rem;
    font-family: Space-r;
    color: #4a4a4a;
    letter-spacing: 0.1rem;
  }
`;

export const LayoutOption = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid ${({ checked, theme }) => (checked ? theme.color.primary : '#f1f0f0')};
  border-radius: 8px;
  background-image: url(${({ grid }) => (grid ? ICONS.GridLayout : ICONS.ListLayout)});
  background-size: 55%;
  background-position: center;
  background-repeat: no-repeat;
  &:last-child {
    margin-left: 1rem;
  }
  margin-left: 2rem;
  cursor: pointer;
`;

export const FiltersForm = styled.div`
  display: flex;
  align-items: center;
`;

export const FiltersFormTitlte = styled.span`
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.1rem;
  text-align: right;
  white-space: nowrap;
`;

export const OptionName = styled.div`
  color: #373333;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  margin-left: 1.6rem;
`;

const circleBg = ({ status }) => {
  switch (status) {
    case CoachProgramStatus.APPROVED:
      return '#FFCC00';
    case CoachProgramStatus.DECLINED:
      return '#F50A4F';
    case CoachProgramStatus.ONGOING:
      return '#00FF91';
    default:
      return 'transparent';
  }
};

const circleBorder = ({ status }) =>
  status === CoachProgramStatus.ENDED ? '#00FF91' : 'transparent';

export const OptionCircle = styled.div`
  border: 2px solid ${circleBorder};
  background-color: ${circleBg};
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  margin-left: 1.6rem;
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
