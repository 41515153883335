import React, { useState } from 'react';

import { InputWrapper, InputIcon, StyledInput, InputError, InputErrorIcon, Suffix } from './components';

const CustomInput = ({
  height,
  backgroundColor,
  focusBackgroundColor,
  borderRadius,
  outlined,
  green,
  fontSize,
  placeholder,
  icon,
  activeIcon,
  enableShowPasswordToggle,
  onToggleShowPassword,
  margin,
  value,
  onChange,
  error,
  type,
  fill,
  inputRef,
  color,
  borderColor,
  placeholderFontSize,
  onBlur = () => {},
  prefix = '',
  errorIcon = true,
  iconStyles,
  onKeyDown,
  endAdornment,
  suffix,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <InputWrapper
      height={height}
      outlined={outlined}
      isActive={isActive}
      backgroundColor={backgroundColor}
      focusBackgroundColor={focusBackgroundColor}
      borderColor={borderColor}
      green={green}
      borderRadius={borderRadius}
      fontSize={fontSize}
      margin={margin}
      error={error}
      fill={fill}
      {...rest}
    >
      {icon ? (
        <InputIcon
          icon={isActive && activeIcon ? activeIcon : icon}
          outlined={outlined}
          style={iconStyles}
        />
      ) : (
        <></>
      )}
      <StyledInput
        ref={inputRef}
        placeholder={placeholder}
        value={value ? prefix + value : ''}
        onChange={(e) => onChange(e.target.value.replace(prefix, ''))}
        onFocus={() => setIsActive(true)}
        onBlur={() => {
          setIsActive(false);
          onBlur();
        }}
        type={type || 'text'}
        novalidate
        color={color}
        onKeyDown={onKeyDown}
        {...rest}
      />

      { endAdornment }

      {error ? (
        <InputError>
          {error}
          {errorIcon && <InputErrorIcon />}
        </InputError>
      ) : (
        <></>
      )}

      { suffix && <Suffix>{suffix}</Suffix> }
    </InputWrapper>
  );
};

export default CustomInput;
