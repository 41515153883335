const URLS = {
  sign_up_coach: '/signup/coach',
  sign_up_coach_personal: '/signup/coach/personal-info',
  sign_up_coach_professional: '/signup/coach/professional-info',
  sign_up_coach_result: '/signup/coach/result',
  sign_in: '/signin',
  set_password: '/set-password',
  //sidebar
  home: '/home',
  export: '/export',
  stats: '/stats',
  templates: '/templates',
  media: '/media',
  calendar: '/calendar',
  trainees: '/trainees',
  all_trainees: '/all_trainees',
  groups: '/groups',
  program: '/program/:id',
  template: '/template/:id',
  groupProgram: '/program/:id?group=true',
  messages: '/messages',
  settings: '/settings',
  // header
  profile: '/profile',
  notifications: '/notifications',
  logout: '/logout',
  terms: '/terms-of-service',
  videoCall: '/video-call/:id',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  finance: '/finance',
  exercises: '/exercises',
};

export default URLS;
