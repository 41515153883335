import React, { useEffect, useRef, useState } from 'react';

import CustomButton from '../button/CustomButton';
import CustomTooltip from '../tooltip/CustomTooltip';
import CustomTextArea from '../input/CustomTextArea';

import {
  WeekBlockAddComment,
  WeekBlockAddCommentIcon,
  WeekBlockAddCommentText,
  WeekBlockAddCommentTitle,
  WeekBlockAddCommentButtons,
  WeekBlockAddCommentPreview,
  AttributeLabel,
  AttrCheckBox,
  CommentsOuterWrapper,
} from './components';

const AddBlockCommentTooltip = ({ onSubmit, initialValue, preview, beforeAfter, isMobile }) => {
  const [addCommentOpen, setAddCommentOpen] = useState(false);
  const [blockComment, setBlockComment] = useState(initialValue);

  const addCommentRef = useRef(null);
  const handleClickOutside = (event) => {
    if (!isMobile && addCommentRef.current && !addCommentRef.current.contains(event.target)) {
      setAddCommentOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <WeekBlockAddComment ref={addCommentRef} preview={preview} beforeAfter={beforeAfter}>
      <CustomTooltip
        style={{
          left: 0,
          top: beforeAfter ? 'Calc(100% + 0.8rem)' : 'auto',
          padding: '1.6rem 0.8rem 1rem',
          display: addCommentOpen && !isMobile ? 'block' : 'none',
          pointerEvents: 'all',
        }}
        triangleLeft={beforeAfter ? '' : '1rem'}
        below={beforeAfter}
      >
        {preview ? (
          <WeekBlockAddCommentPreview>{initialValue}</WeekBlockAddCommentPreview>
        ) : (
          <>
            {beforeAfter ? (
              <>
                <AttributeLabel flexGrow week>
                  <AttrCheckBox
                    style={{ width: '1.4rem', height: '1.4rem', margin: '0 0.6rem' }}
                    active={blockComment?.beforeMessageChecked}
                    onClick={() =>
                      setBlockComment((curr) => ({
                        ...curr,
                        beforeMessageChecked: !blockComment?.beforeMessageChecked,
                      }))
                    }
                  />
                  Message before workout
                </AttributeLabel>
                {blockComment?.beforeMessageChecked && (
                  <CustomTextArea
                    rowCount={null}
                    style={{
                      height: '16rem',
                      padding: '4px',
                      maxHeight: '16rem',
                      fontSize: '1.2rem',
                    }}
                    wrapperStyle={{
                      background: '#F8F7F7',
                      borderColor: '#05000012',
                      margin: '0.8rem 0',
                    }}
                    onChange={(v) => setBlockComment((curr) => ({ ...curr, beforeMessage: v }))}
                    value={blockComment?.beforeMessage}
                  />
                )}
                <AttributeLabel flexGrow week>
                  <AttrCheckBox
                    style={{ width: '1.4rem', height: '1.4rem', margin: '0 0.6rem' }}
                    active={blockComment?.afterMessageChecked}
                    onClick={() =>
                      setBlockComment((curr) => ({
                        ...curr,
                        afterMessageChecked: !blockComment?.afterMessageChecked,
                      }))
                    }
                  />
                  Message after workout
                </AttributeLabel>
                {blockComment?.afterMessageChecked && (
                  <CustomTextArea
                    rowCount={null}
                    style={{
                      height: '16rem',
                      padding: '4px',
                      maxHeight: '16rem',
                      fontSize: '1.2rem',
                    }}
                    wrapperStyle={{
                      background: '#F8F7F7',
                      borderColor: '#05000012',
                      margin: '0.8rem 0',
                    }}
                    onChange={(v) => setBlockComment((curr) => ({ ...curr, afterMessage: v }))}
                    value={blockComment?.afterMessage}
                  />
                )}
              </>
            ) : (
              <>
                <WeekBlockAddCommentTitle>Create comment for this block</WeekBlockAddCommentTitle>

                <CustomTextArea
                  rowCount={null}
                  style={{
                    height: '16rem',
                    padding: '4px',
                    maxHeight: '16rem',
                    fontSize: '1.2rem',
                  }}
                  wrapperStyle={{
                    background: '#F8F7F7',
                    borderColor: '#05000012',
                    margin: '0.8rem 0',
                  }}
                  onChange={(v) => setBlockComment(v)}
                  value={blockComment}
                />
              </>
            )}
            <WeekBlockAddCommentButtons style={beforeAfter ? { marginTop: '1.2rem' } : {}}>
              <CustomButton
                fontSize="1rem"
                type="button"
                style={{
                  maxHeight: '2.4rem',
                  minHeight: '2.4rem',
                  padding: '0 1.2rem',
                  width: 'fit-content',
                  borderRadius: '0.6rem',
                }}
                onClick={() => {
                  setBlockComment('');
                  setAddCommentOpen(false);
                }}
              >
                Discard
              </CustomButton>
              <CustomButton
                green
                fontSize="1rem"
                type="button"
                style={{
                  maxHeight: '2.4rem',
                  minHeight: '2.4rem',
                  padding: '0 1.2rem',
                  width: 'fit-content',
                  borderRadius: '0.6rem',
                  marginLeft: '0.8rem',
                }}
                onClick={() => {
                  onSubmit(blockComment);
                  setAddCommentOpen(false);
                }}
              >
                Save
              </CustomButton>
            </WeekBlockAddCommentButtons>
          </>
        )}
      </CustomTooltip>
      {!addCommentOpen || !isMobile ? (
        <CommentsOuterWrapper
          onClick={() => setAddCommentOpen((curr) => !curr)}
          noBorder={!beforeAfter}
          op={
            beforeAfter &&
            !initialValue?.beforeMessage &&
            !initialValue?.afterMessage &&
            !addCommentOpen
          }
        >
          <WeekBlockAddCommentIcon />
          {(!beforeAfter || !addCommentOpen) && (
            <WeekBlockAddCommentText>
              {beforeAfter
                ? initialValue?.beforeMessage ||
                  initialValue?.afterMessage ||
                  'Message before/after workout'
                : initialValue || 'Add Comments & Indications'}
            </WeekBlockAddCommentText>
          )}
        </CommentsOuterWrapper>
      ) : (
        <CustomTextArea
          rowCount={null}
          style={{
            height: '14rem',
            padding: '4px',
            maxHeight: '14rem',
            fontSize: '1.2rem',
          }}
          wrapperStyle={{
            background: '#F8F7F7',
            borderColor: '#05000012',
            margin: '0.8rem 0',
          }}
          onChange={(v) => onSubmit(v)}
          value={initialValue}
        />
      )}
    </WeekBlockAddComment>
  );
};

export default AddBlockCommentTooltip;
