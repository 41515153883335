import React, { useMemo } from 'react';
import { MobileAvatarContainer, MobileAvatarCredentials, MobileAvatarImage } from './components';

const MobileAvatar = ({ src, size, name }) => {
  const credentials = useMemo(() => {
    if (typeof name !== 'string') {
      return '';
    }

    const [first, last] = name.split(' ');

    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`.toUpperCase();
  }, [name]);

  return (
    <MobileAvatarContainer size={size}>
      <MobileAvatarImage src={src}>
        <MobileAvatarCredentials>{src ? '' : credentials}</MobileAvatarCredentials>
      </MobileAvatarImage>
    </MobileAvatarContainer>
  );
};

export default MobileAvatar;
