import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useCoachPersonalInfo } from '../hooks';

export const OrganizationContext = createContext(null);

export const OrganizationProvider = ({ children }) => {
  const [isOrganization, setIsOrganization] = useState(false);

  const { currentOrganization } = useCoachPersonalInfo({});

  useEffect(() => {
    if (currentOrganization?.uid) {
      setIsOrganization(true);
    }
  }, [currentOrganization, setIsOrganization]);

  const contextValue = useMemo(() => ({ isOrganization }), [isOrganization]);

  return (
    <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>
  );
};
