import React, { useContext, useMemo, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useQuery } from '@apollo/react-hooks';
import { getProgramDetailsCouponsQuery } from '@witness/graphql';
import { useHistory } from 'react-router-dom';

import AdvancedPriceModal from '../../components/advanced-price-modal/AdvancedPriceModal';
import ProgramCodeModal from '../../components/program-code-modal/ProgramCodeModal';
import CouponTable from '../../components/coupon-table/CouponTable';
import CustomTooltip from '../../components/tooltip/CustomTooltip';
import CustomInput from '../../components/input/CustomInput';
import CustomButton from '../../components/button/CustomButton'

import IMAGES from '../../constants/images';
import { useCoachPersonalInfo, usePricing, useOrganisation } from '../../hooks';
import {
  PricingWrapper,
  PricingHeader,
  PricingHeaderItem,
  ProfileCode,
  ProfileCodeValue,
  ProfileIdInfo,
  FlexWrapperSpaceBet,
  AddCouponButton,
  AddCouponButtonPlus,
  PricingListHeading,
  PricingListWrapper,
  ChangesSaved,
  ChangesSavedIcon,
  ProgramsWrapper,
  SingleProgramWrapper,
  ProgramImage,
  ProgramName,
  PriceHeading,
  PriceInputWrapper,
  AdvencedOptions,
  ProgramImageLines,
  ProgramImageFade,
  PseudoSingleProgram,
  LoadingDot,
  AddCouponButtonMobileText,
  CoachFeeWrapper,
  CoachFeeInput
} from './components';
import DetailCouponTable from '../../components/coupon-table/DetailCouponTable';
import { MobileContext } from '../../services/MobileContext';
import Fixedheader from '../../components/header/FixedHeader';
import URLS from '../../constants/urls';
import { OrganizationContext } from '../../services/organizationContext';
import { CoachFeeSchema } from '../../schemas/program/coachProgram'

const CoachProfilePricing = ({ activeTab, setActiveTab }) => {
  const [activeCode, setActiveCode] = useState(null);
  const [activeProgram, setActiveProgram] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);
  const [organisationLoading, setOrganisationLoading] = useState(false);

  const { programPrices, addProgramPricing, defaultPrices, deleteCoupon } = usePricing({
    setLoading: setPriceLoading,
  });

  const { setCoachFee } = useOrganisation({
    setLoading: setOrganisationLoading
  });

  const { currentCoach, currentOrganization } = useCoachPersonalInfo({});
  const { isOrganization } = useContext(OrganizationContext);

  const currentUser = useMemo(() => (isOrganization ? currentOrganization : currentCoach), [
    currentOrganization,
    currentCoach,
    isOrganization,
  ]);

  const loading = useMemo(() => priceLoading || organisationLoading, [
    priceLoading,
    organisationLoading
  ]);

  const { data, loading: detailsCouponsLoading } = useQuery(getProgramDetailsCouponsQuery);

  const { isMobile } = useContext(MobileContext);

  const history = useHistory();

  const PricingList = useMemo(
    () => (
      <>
        <ProfileCode isMobile={isMobile}>
          My Profile Code:
          <ProfileCodeValue isMobile={isMobile}>
            #
            {(currentUser?.code?.digits &&
              `${('000' + Math.floor(currentUser?.code?.digits / 1000)).slice(-3)}-${(
                '000' +
                (currentUser?.code?.digits % 1000)
              ).slice(-3)}`) ||
              '--- ---'}
          </ProfileCodeValue>
          <ProfileIdInfo marginBottom="1.3rem">
            i
            <CustomTooltip below style={{ width: '26rem' }}>
              This is Your Profile Code. You can send it to Your potential Trainers to help them
              to find you in the system
            </CustomTooltip>
          </ProfileIdInfo>
        </ProfileCode>
        <PricingListWrapper>
          <AdvancedPriceModal
            content={activeProgram}
            closeModal={() => setActiveProgram(null)}
            submitModal={addProgramPricing}
          />
          <FlexWrapperSpaceBet>
            <PricingListHeading>Here you can assign Prices of all your Programs</PricingListHeading>
            <ChangesSaved autoSize>
              <ChangesSavedIcon />
              {loading ? (
                <>
                  <LoadingDot i={2} />
                  <LoadingDot i={1} />
                  <LoadingDot i={0} />
                </>
              ) : (
                'Data Saved'
              )}
            </ChangesSaved>
          </FlexWrapperSpaceBet>
        </PricingListWrapper>
        { isOrganization && <Formik
          enableReinitialize
          initialValues={{
            coachFee: currentOrganization.coachFee
          }}
          validationSchema={CoachFeeSchema}
          onSubmit={({ coachFee }) => setCoachFee(currentOrganization.uid, coachFee) }
        >
          {({ setFieldValue, errors, touched, submitForm }) => (
            <Form>
              <Field id="coachFee" name="coachFee">
                {({ field }) => (
                  <CoachFeeWrapper>
                    <CoachFeeInput>
                      Coach fee
                      { console.log(field.value) }
                      <CustomInput
                        color="#373333"
                        outlined
                        suffix='%'
                        borderRadius="8px"
                        height="40px"
                        fontSize="1.4rem"
                        value={field.value}
                        error={touched[field.name] && !!errors[field.name]}
                        placeholder="Fee"
                        errorIcon={false}
                        style={{
                          width: '100px'
                        }}
                        margin='1rem 0 0 0'
                        disabled={loading}
                        onChange={(v) => setFieldValue(field.name, v && (!isNaN(Number(v)) && Number(v) ? Math.min(Number(v), 100) : field.value)) }                        
                      />
                    </CoachFeeInput>
                    <CustomButton
                      fontSize="1.4rem"
                      green
                      type="submit"
                      disabled={loading}
                      style={{
                        width: '80px',
                        height: '40px',
                        marginTop: 'auto',
                        marginLeft: '25px'
                      }}
                      onClick={submitForm}
                    >
                      Save
                    </CustomButton>
                  </CoachFeeWrapper>
                )}
              </Field>
            </Form>
          )}
        </Formik> }
        <ProgramsWrapper>
          {programPrices?.map((program) => {
            return (
              <SingleProgramWrapper>
                <ProgramImage image={IMAGES.TempProgram1}>
                  <ProgramImageFade>
                    <ProgramImageLines />
                  </ProgramImageFade>
                  <ProgramName>{program?.speciality?.name}</ProgramName>
                </ProgramImage>

                <PriceHeading>Program Price Per Month</PriceHeading>
                <Formik
                  enableReinitialize
                  initialValues={{
                    defaultPrice: defaultPrices[program.uid],
                    speciality: program?.speciality?.id,
                  }}
                  // validationSchema={EditAboutMeSchema}
                  onSubmit={addProgramPricing}
                >
                  {({ setFieldValue, errors, touched, isSubmitting, values, submitForm }) => (
                    <Form style={{ width: '100%' }}>
                      <Field id="defaultPrice" name="defaultPrice">
                        {({ field }) => (
                          <PriceInputWrapper>
                            <CustomInput
                              outlined
                              disabled={defaultPrices[program.uid] === 'Custom'}
                              prefix={defaultPrices[program.uid] === 'Custom' ? '' : '₪ '}
                              height="100%"
                              margin="0"
                              placeholder={
                                defaultPrices[program.uid] === 'Custom' ? 'Custom' : ' Type Price'
                              }
                              placeholderFontSize="2.4rem"
                              backgroundColor="#ffffff"
                              focusBorderColor="#15DF68"
                              style={{
                                fontFamily: 'Space-b',
                                textAlign: 'center',
                                fontSize: '3.2rem',
                                padding: '1.6rem',
                              }}
                              onChange={(v) => setFieldValue(field.name, v ? Number(v) : null)}
                              value={field.value}
                              onBlur={submitForm}
                            />
                          </PriceInputWrapper>
                        )}
                      </Field>
                    </Form>
                  )}
                </Formik>
                <AdvencedOptions onClick={() => setActiveProgram(program)}>
                  Advanced Options
                </AdvencedOptions>
              </SingleProgramWrapper>
            );
          })}
          <PseudoSingleProgram />
          <PseudoSingleProgram />
        </ProgramsWrapper>
      </>
    ),
    [activeProgram, addProgramPricing, defaultPrices, loading, programPrices],
  );

  const DiscoutCodes = useMemo(
    () => (
      <>
        <FlexWrapperSpaceBet isMobile={isMobile}>
          <ProfileCode isMobile={isMobile}>
            My Profile Code:
            <ProfileCodeValue isMobile={isMobile}>
              #
              {(currentUser?.code?.digits &&
                `${('000' + Math.floor(currentUser?.code?.digits / 1000)).slice(-3)}-${(
                  '000' +
                  (currentUser?.code?.digits % 1000)
                ).slice(-3)}`) ||
                '--- ---'}
            </ProfileCodeValue>
            <ProfileIdInfo marginBottom="1.3rem">
              i
              <CustomTooltip below style={{ width: '26rem' }}>
                This is Your Profile Code. You can send it to Your potential Trainers to help them
                to find you in the system
              </CustomTooltip>
            </ProfileIdInfo>
          </ProfileCode>
          {!isMobile && (
            <AddCouponButton
              onClick={() => setActiveCode({ type: 'discountCode' })}
              isMobile={isMobile}
            >
              {isMobile ? (
                <AddCouponButtonMobileText>Add Coupon</AddCouponButtonMobileText>
              ) : (
                <>
                  <AddCouponButtonPlus /> Add Coupon
                </>
              )}
            </AddCouponButton>
          )}
        </FlexWrapperSpaceBet>
        <CouponTable
          setEdditingCode={setActiveCode}
          coupons={currentUser?.coupons}
          deleteCoupon={deleteCoupon}
        />
      </>
    ),
    [deleteCoupon, isMobile, currentUser],
  );

  const ProgramCodes = useMemo(
    () => (
      <>
        <FlexWrapperSpaceBet isMobile={isMobile}>
          <ProfileCode isMobile={isMobile}>
            My Profile Code:
            <ProfileCodeValue isMobile={isMobile}>
              #
              {(currentUser?.code?.digits &&
                `${('000' + Math.floor(currentUser?.code?.digits / 1000)).slice(-3)}-${(
                  '000' +
                  (currentUser?.code?.digits % 1000)
                ).slice(-3)}`) ||
                '--- ---'}
            </ProfileCodeValue>
            <ProfileIdInfo marginBottom="1.3rem">
              i
              <CustomTooltip below style={{ width: '26rem' }}>
                This is Your Profile Code. You can send it to Your potential Trainers to help them
                to find you in the system
              </CustomTooltip>
            </ProfileIdInfo>
          </ProfileCode>
          {!isMobile && (
            <AddCouponButton
              onClick={() => setActiveCode({ type: 'programCode' })}
              isMobile={isMobile}
            >
              {isMobile ? (
                <AddCouponButtonMobileText>Add Coupon</AddCouponButtonMobileText>
              ) : (
                <>
                  <AddCouponButtonPlus /> Add Coupon
                </>
              )}
            </AddCouponButton>
          )}
        </FlexWrapperSpaceBet>
        <DetailCouponTable
          setEdditingCode={setActiveCode}
          coupons={data?.getProgramDetailsCoupons}
          loading={detailsCouponsLoading}
        />
      </>
    ),
    [currentUser, data, detailsCouponsLoading, isMobile],
  );

  return (
    <PricingWrapper>
      {isMobile && (
        <Fixedheader title="PRICING & COUPONS" handleBack={() => history.push(URLS.home)} />
      )}
      <ProgramCodeModal content={activeCode} closeModal={() => setActiveCode(null)} />
      <PricingHeader>
        {!isMobile && (
          <PricingHeaderItem
            active={activeTab === '0' || (!activeTab && !isMobile)}
            onClick={() => setActiveTab(0)}
          >
            Pricing List
          </PricingHeaderItem>
        )}
        <PricingHeaderItem
          active={activeTab === '1' || (!activeTab && isMobile)}
          onClick={() => setActiveTab(1)}
          isMobile={isMobile}
        >
          Discount Codes
        </PricingHeaderItem>
        <PricingHeaderItem
          active={activeTab === '2'}
          onClick={() => setActiveTab(2)}
          isMobile={isMobile}
        >
          Program Codes
        </PricingHeaderItem>
      </PricingHeader>
      {(activeTab === '0' || (!activeTab && !isMobile)) && PricingList}
      {(activeTab === '1' || (!activeTab && isMobile)) && DiscoutCodes}
      {activeTab === '2' && ProgramCodes}
    </PricingWrapper>
  );
};

export default CoachProfilePricing;
