import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useState } from 'react';
import Modal from 'react-modal';

import CustomInputFile from '../input/CustomInputFile';
import {ExerciseCategory, ExerciseType} from '../../constants/enums';
import MediaModal from '../media-modal/MediaModal';
import CustomButton from '../button/CustomButton';
import CustomInput from '../input/CustomInput';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  ModalFooter,
  ButtonWrapper,
  ModalCloseX,
  ModalColumn,
  FieldTitle,
  ChoosePartWrapper,
  ChoosePartItem,
  ChoosePartRadio,
  VideoWrapper,
  AddVideoWrapper,
  AddVideoWrapperDashed,
  VideoSign,
  ReplaceVideo,
  UploadVideoIcon,
  FlexWrapper,
  BoldSpan,
  DragFileText,
  DragFileIcon,
  ChoosePartWrapperScrollable,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '11111111',
  },
  content: {
    zIndex: '111111111',
    width: '44%',
    maxWidth: '772px',
    minWidth: '320px',
    height: 'fit-content',
    maxHeight: 'Calc(100% - 40px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const getVideoPreview = (inputFile, setValue) => {
  let reader = new FileReader();
  reader.onloadend = function () {
    setValue(reader.result);
    return;
  };
  reader.readAsDataURL(inputFile);
};

const AddExerciseModal = ({
  modalOpen,
  closeModal,
  submitModal,
  initialValues,
  loading,
  readOnly,
}) => {
  const [videoOpen, setVideoOpen] = useState(false);

  // console.log('init', initialValues);

  function convertYoutubeLinkToEmbedded(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const initialType = initialValues?.type ? initialValues?.type : ExerciseType.CORE;

  return (
    <Modal
      onRequestClose={() => closeModal(initialValues?.searchText || '')}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader style={{ padding: '2rem 3.2rem', fontSize: '1.6rem' }}>
          {readOnly ? 'VIEW EXERCISE' : initialValues?.id ? 'EDIT EXERCISE' : 'NEW EXERCISE'}
          <ModalCloseX onClick={() => closeModal(initialValues?.searchText || '')}>X</ModalCloseX>
        </ModalHeader>
        <Formik
          initialValues={{type: initialType, category: initialValues?.category ? initialValues?.category : 'CORE', ...initialValues, }}
          onSubmit={async (v) => {
            const { changeCallback, ...values } = v;
            if (changeCallback) {
              changeCallback(values);
              submitModal(
                values,
                true,
                !initialValues?.editedExercise && !(initialValues?.coach || !initialValues?.id),
              );
            } else {
              submitModal(values);
            }
            closeModal('');
          }}
        >
          {({ setFieldValue, errors, touched, isSubmitting, values }) => (
            <Form style={{ width: '100%' }}>
              <ModalMain>
                <MediaModal
                  type="video"
                  url={videoOpen && (values.preview || values?.video?.url)}
                  onClose={() => setVideoOpen(false)}
                />
                <ModalColumn left>
                  <FieldTitle>Exercise name</FieldTitle>
                  <Field id="title" name="title">
                    {({ field }) => (
                      <CustomInput
                        disabled={
                          initialValues?.editedExercise ||
                          !(initialValues?.coach || !initialValues?.id)
                        }
                        style={{
                          opacity:
                            initialValues?.editedExercise ||
                            !(initialValues?.coach || !initialValues?.id)
                              ? 0.6
                              : 1,
                        }}
                        placeholder="Type Name"
                        outlined
                        height="4rem"
                        borderRadius="8px"
                        margin="0 0 2.4rem"
                        value={field.value}
                        onChange={(val) => {
                          setFieldValue(field.name, val);
                        }}
                        error={errors[field.name]}
                      />
                    )}
                  </Field>
                  {initialType === 'PILATESR' &&
                  <>
                      <FieldTitle>Category</FieldTitle>
                    <Field id="category" name="category">
                      {({ field }) => (
                          <ChoosePartWrapper
                              disabled={
                                  initialValues?.editedExercise ||
                                  !(initialValues?.coach || !initialValues?.id)
                              }
                          >
                            <ChoosePartWrapperScrollable>
                              {ExerciseCategory.map((category) => {
                                return <ChoosePartItem>
                                  <ChoosePartRadio
                                      active={field.value === category.id}
                                      onClick={() => setFieldValue(field.name, category.id)}
                                  />
                                  {category.name}
                                </ChoosePartItem>
                              })}
                            </ChoosePartWrapperScrollable>
                          </ChoosePartWrapper>
                      )}
                    </Field>
                  <FieldTitle>Setting</FieldTitle>
                  <Field id="setting" name="setting">
                    {({ field }) => (
                        <CustomInput
                            disabled={
                                initialValues?.editedExercise ||
                                !(initialValues?.coach || !initialValues?.id)
                            }
                            style={{
                              opacity:
                                  initialValues?.editedExercise ||
                                  !(initialValues?.coach || !initialValues?.id)
                                      ? 0.6
                                      : 1,
                            }}
                            placeholder="Type Setting"
                            outlined
                            height="4rem"
                            borderRadius="8px"
                            margin="0 0 2.4rem"
                            value={field.value}
                            onChange={(val) => {
                              setFieldValue(field.name, val);
                            }}
                            error={errors[field.name]}
                        />
                    )}
                  </Field>
                  </>}
                  <FieldTitle>Choose Body Part</FieldTitle>
                  <Field id="type" name="type">
                    {({ field }) => (
                      <ChoosePartWrapper
                        disabled={
                          initialValues?.editedExercise || initialValues?.type ||
                          !(initialValues?.coach || !initialValues?.id)
                        }
                      >
                        <ChoosePartWrapperScrollable>
                          <ChoosePartItem>
                            <ChoosePartRadio
                              active={field.value === ExerciseType.LOWERBODY}
                              onClick={() => setFieldValue(field.name, ExerciseType.LOWERBODY)}
                            />
                            Lower Body
                          </ChoosePartItem>
                          <ChoosePartItem>
                            <ChoosePartRadio
                              active={field.value === ExerciseType.UPPERBODY}
                              onClick={() => setFieldValue(field.name, ExerciseType.UPPERBODY)}
                            />
                            Upper Body
                          </ChoosePartItem>
                          <ChoosePartItem>
                            <ChoosePartRadio
                              active={field.value === ExerciseType.CORE}
                              onClick={() => setFieldValue(field.name, ExerciseType.CORE)}
                            />
                            Core
                          </ChoosePartItem>
                          <ChoosePartItem>
                            <ChoosePartRadio
                              active={field.value === ExerciseType.STRETCH}
                              onClick={() => setFieldValue(field.name, ExerciseType.STRETCH)}
                            />
                            Stretch
                          </ChoosePartItem>
                          <ChoosePartItem>
                            <ChoosePartRadio
                              active={field.value === ExerciseType.MULTI}
                              onClick={() => setFieldValue(field.name, ExerciseType.MULTI)}
                            />
                            Multi
                          </ChoosePartItem>
                          <ChoosePartItem>
                            <ChoosePartRadio
                              active={field.value === ExerciseType.PILATESR}
                              onClick={() => setFieldValue(field.name, ExerciseType.PILATESR)}
                            />
                            PILATESR
                          </ChoosePartItem>
                        </ChoosePartWrapperScrollable>
                      </ChoosePartWrapper>
                    )}
                  </Field>
                </ModalColumn>
                <ModalColumn>
                  {values?.video || values?.youtubeVideoId ? (
                    <FlexWrapper>
                      <FieldTitle>Video</FieldTitle>
                      <ReplaceVideo>
                        Replace with your Video{' '}
                        <UploadVideoIcon>
                          <CustomInputFile
                            noHover
                            onChange={(val) => {
                              setFieldValue('preview', null);
                              getVideoPreview(val.target.files?.[0], (v) =>
                                setFieldValue('preview', v),
                              );
                              setFieldValue('youtubeVideoId', null);
                              setFieldValue('video', val.target.files?.[0]);
                            }}
                          />
                        </UploadVideoIcon>
                      </ReplaceVideo>
                    </FlexWrapper>
                  ) : (
                    <FieldTitle>Exercise Doesn’t have Video Yet</FieldTitle>
                  )}
                  <VideoWrapper black={!!values.preview || !!values?.video?.url}>
                    {values?.youtubeVideoId ? (
                      <iframe
                        title={values?.preview}
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${values.youtubeVideoId}`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : values.preview || values?.video?.mimetype?.split('/')[0] === 'video' ? (
                      <>
                        <video
                          controls={false}
                          style={{ width: '100%', height: '100%', position: 'absolute' }}
                        >
                          <source src={values.preview || values?.video?.url} />
                        </video>
                        <VideoSign onClick={() => setVideoOpen(true)} />
                      </>
                    ) : (
                      <AddVideoWrapper>
                        <AddVideoWrapperDashed>
                          <DragFileIcon />
                          <DragFileText>
                            Drag Your Video Here, or <BoldSpan>Browse</BoldSpan>{' '}
                          </DragFileText>
                          <CustomInputFile
                            noHover
                            onChange={(val) => {
                              getVideoPreview(val?.[0] || val.target.files?.[0], (v) =>
                                setFieldValue('preview', v),
                              );
                              setFieldValue('video', val?.[0] || val.target.files?.[0]);
                              setFieldValue('youtubeVideoId', null);
                            }}
                            disabled={readOnly}
                          />
                        </AddVideoWrapperDashed>
                      </AddVideoWrapper>
                    )}
                  </VideoWrapper>
                  <Field id="youtubeVideoId" name="youtubeVideoId">
                    {({ field }) => (
                      <CustomInput
                        placeholder="Or you can paste a youtube link here..."
                        outlined
                        height="4rem"
                        borderRadius="8px"
                        margin="1rem 0 0 0"
                        value={
                          field.value ? `https://www.youtube.com/embed/${field.value}` : field.value
                        }
                        disabled={readOnly}
                        onChange={(val) => {
                          const embeded = convertYoutubeLinkToEmbedded(val);
                          if (embeded) {
                            setFieldValue('video', null);
                            setFieldValue('preview', null);
                            setFieldValue(field.name, embeded);
                          } else {
                            setFieldValue(field.name, null);
                          }
                        }}
                        error={errors[field.name]}
                      />
                    )}
                  </Field>
                </ModalColumn>
              </ModalMain>
              <ModalFooter>
                {!readOnly && (
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" green type="submit" disabled={loading}>
                      Save & Use
                    </CustomButton>
                  </ButtonWrapper>
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </WholeWrapper>
    </Modal>
  );
};

export default AddExerciseModal;
