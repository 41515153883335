import styled from 'styled-components';
import IMAGES from '../../constants/images';

export const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 160px);
`;
export const DashboardSummaryBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DashboardMainBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DashboardSummaryItem = styled.div`
  width: Calc((100% - 40px) / 3);
  height: 120px;
  background: #ffffff;
`;

export const DashboardMainItem = styled.div`
  width: ${({ grow }) =>
    grow === 1 ? 'Calc((100% - 40px) / 3)' : 'Calc((100% - 40px) / 3 * 2 + 20px)'};
  background: #ffffff;
`;

export const DashboardBanner = styled.div`
  width: 100%;
  height: 15.8rem;
  background: white;
  background-image: url(${IMAGES.HomeBanner});
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 101%;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(74, 74, 74, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const DashboardBannerContent = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 6.2rem 3rem 3.8rem;
`;

export const BannerAvatar = styled.div`
  width: 10rem;
  height: 10rem;
  background-image: url(${({ pic }) => pic});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: ${({ theme }) => `4px solid ${theme.color.primary}`};
  margin-right: 3.2rem;
  border-radius: 50%;

  @media (max-width: 600px) {
    width: 88px;
    height: 88px;
    padding: 8px;
    margin-right: 0;
    border: ${({ theme }) => `1px solid ${theme.color.primary}`};
  }
`;

export const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const BannerText = styled.div`
  flex-grow: 1;
`;

export const BannerPrimaryText = styled.div`
  line-height: 4.8rem;
  font-family: Gotham-r;
  font-size: 2rem;
  color: #373333;
  letter-spacing: 0.2rem;

  @media (max-width: 600px) {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 1px;
    text-align: left;
    color: #4a4a4a;
  }
`;

export const BannerSecondaryText = styled.div`
  line-height: 2.4rem;
  font-family: Roboto-m;
  font-size: 1.6rem;
  color: #373333;
  opacity: 0.6;
`;

export const DashboardBannerBackLine = styled.div`
  flex-grow: 1;
  height: 100%;
  border-right: 1px solid #05000012;
  &:last-child {
    border: none;
  }
`;

// mobile
export const RespContainer = styled.div`
  padding: 0 16px;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0 3px;
  margin-bottom: 40px;
`;

export const BannerWelcomeText = styled.div`
  font-family: Roboto-r;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
  margin: 21px 0 4px;
`;

export const RespCard = styled.div`
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  background-color: #ffffff;
  margin-top: ${({ mt }) => (mt ? mt : 0)}px;
  margin-bottom: ${({ mb }) => (mb ? mb : 0)}px;
`;

export const TitleContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RespCardTitle = styled.div`
  font-family: Roboto-r;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 1px;
  text-align: left;
  color: #373333;
  text-transform: uppercase;
  margin-right: auto;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SeeAllReqText = styled.div`
  padding: 12px 0 14px;
  display: flex;
  justify-content: center;
  border-top: 0.5px solid rgba(0, 0, 0, 0.15);
  font-family: Roboto-r;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #050000;
`;

export const ProgramReqContainer = styled.div`
  padding: 20px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.15);
  border-left: 3px solid ${({ theme }) => theme?.color?.primary};
  display: flex;
  justify-content: space-between;
`;

export const ArrowIcon = styled.img`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const ProgramReqTitle = styled.div`
  font-family: Roboto-r;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #373333;
  margin-bottom: 5px;
`;

export const ProgramReqDesc = styled.div`
  opacity: 0.5;
  font-family: Roboto-r;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: #050000;
`;

export const ProgramReqArrowIcon = styled.img`
  opacity: 0.5;
  transform: rotate(90deg);
`;

export const BadgeContainer = styled.div`
  padding: 2px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.color?.primary};
  background-color: #ffffff;
  margin-right: 11px;
`;

export const BadgeWrapper = styled.div`
  padding: 0 10px 0 9px;
  border-radius: 6.5px;
  background-color: #00ff91;
  font-family: Roboto-r;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 2px;
  text-align: left;
  color: #373333;
`;
