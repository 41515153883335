import { useState } from 'react';
import { useEffect, useRef } from 'react';

const useAddressAutoComplete = () => {
  let autoComplete = { addListener: () => {} };

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    // console.log('loading');

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
        // console.log('ready');
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const handleScriptLoad = (autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
    autoComplete.setFields(['address_components', 'formatted_address']);
  };
  const [refToListen, setRefToListen] = useState(false);

  let inputRef = useRef(null);
  const autoCompleteRef = (node) => {
    inputRef.current = node;
    setRefToListen(node);
  };

  const createRef = () => {
    // console.log(inputRef);
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`,
      () => handleScriptLoad(inputRef),
    );
  };

  const setHandleAdressChoose = (handler) => {
    autoComplete.addListener('place_changed', () => {
      // console.log('PLACE CHANGED');
      handler(autoComplete.getPlace().formatted_address);
    });
  };

  useEffect(() => {
    // console.log(refToListen);
    if (refToListen) {
      createRef();
    }
  }, [refToListen, createRef]);

  return { autoCompleteRef, setHandleAdressChoose };
};

export default useAddressAutoComplete;
