import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import {
  CalendarDayDate,
  CalendarDayNum,
  CalendarDayTitle,
  DayBody,
  DayContainer,
  DayFooter,
  DayFooterText,
  DayHeader,
  DayWorkout,
  DropdownContainer,
  MessageIcon,
  DropdownContainerSmall,
  EmptyActions,
  CardSecondaryContent,
  CardSecondaryContentButton,
  CardSecondaryContentButtonIcon,
  Ellipsis,
} from './components';
import DayDropdown from './DayDropdownV2';
import ICONS from '../../constants/icons';
import HoverActions from './HoverActions';
import { WorkoutStatus } from '../../constants/enums';
import CalendarDragContext from '../../services/CalendarDragContext';
import SecondaryContentTooltip from './SecondaryContentTooltip';

const ProgramCalendarDay = ({
  day,
  program,
  firstRow,
  openModal,
  addRestDay,
  openMessage,
  copied,
  handleCopy,
  pasteWorkout,
  pasteLoading,
  setSelectedProgram,
  setSelectedMessage,
  isToday,
  openMeetingModal,
  openOnlineMeetingModal,
  readOnly,
  hideDate,
}) => {
  const isPast = useMemo(() => {
    const thatday = moment(day.date, 'DD:MM:YYYY');
    const today = moment();

    return today.isAfter(thatday, 'date');
  }, [day]);

  const { WORKOUT, MESSAGE, REST, MEETING } = program || {};

  const copyWorkout = useCallback(() => {
    handleCopy(day?.index);
  }, [handleCopy, day]);

  const [statusForColor, statusToText] = useMemo(() => {
    const colorStatus = WORKOUT?.workout?.status?.find((x) => x?.type === 'DONE')
      ? 'DONE'
      : WORKOUT?.workout?.status?.[0]?.type;
    if (WORKOUT?.workout?.status?.length > 1) {
      const doneStatuses = WORKOUT?.workout?.status?.filter(
        (x) => !!x.trainee && x.type === 'DONE',
      );
      return [
        colorStatus,
        `${doneStatuses?.length}/${
          WORKOUT?.workout?.status?.filter((x) => !!x.trainee)?.length
        } completed`,
      ];
    }
    switch (colorStatus) {
      case WorkoutStatus.DONE:
        return [colorStatus, 'Completed'];
      case WorkoutStatus.UNDONE:
        return [colorStatus, 'Not completed'];
      case WorkoutStatus.NEW:
        return [colorStatus, 'Planned'];
      default:
        return [colorStatus, ''];
    }
  }, [WORKOUT]);

  const [isDropOpen, setIsDropOpen] = useState(false);
  const closeDrop = useCallback(() => setIsDropOpen(false), []);

  useEffect(() => {
    if (isDropOpen) {
      document.addEventListener('click', closeDrop);
    } else {
      document.removeEventListener('click', closeDrop);
    }

    return () => document.removeEventListener('click', closeDrop);
  }, [closeDrop, isDropOpen]);

  const openMessageEdit = useCallback(() => {
    setSelectedMessage(MESSAGE);
    openMessage({ messageDay: day?.index });
  }, [MESSAGE, day, openMessage, setSelectedMessage]);

  const openMessageCreate = useCallback(() => {
    setSelectedMessage(null);
    openMessage({ messageDay: day?.index });
  }, [day, openMessage, setSelectedMessage]);

  const openWorkoutEdit = useCallback(() => {
    openModal({ programDay: day?.index, uid: program?.WORKOUT?.uid });
  }, [day, openModal, program]);

  const openWorkoutCreate = useCallback(() => {
    openModal({ programDay: day?.index, uid: program?.WORKOUT?.uid });
  }, [day, openModal, program]);

  const dropdownItems = useMemo(() => {
    const hideTop = Boolean(WORKOUT || REST);
    const hideMid = Boolean(MESSAGE);

    return readOnly
      ? []
      : [
          {
            title: 'Workout',
            hidden: isPast || hideTop,
            buttonProps: {
              // style: { backgroundColor: '#00ff91', borderColor: '#00ff91' },
              onClick: openWorkoutCreate,
            },
            icon: ICONS.Dumbell,
            hoverIcon: ICONS.DumbellActive,
          },
          {
            title: 'Rest Day',
            hidden: isPast || hideTop,
            buttonProps: {
              onClick: () => addRestDay(day?.index, closeDrop),
            },
            icon: ICONS.RestDayCup,
            hoverIcon: ICONS.RestDayCupActive,
          },
          {
            title: 'Message',
            hidden: isPast || hideMid,
            buttonProps: {
              onClick: openMessageCreate,
            },
            icon: ICONS.MessageDay,
            hoverIcon: ICONS.MessageDayActive,
          },
          {
            title: 'Video Call',
            hidden: isPast || !!MEETING,
            buttonProps: {
              onClick: openOnlineMeetingModal,
            },
            icon: ICONS.VideoCamera,
            hoverIcon: ICONS.VideoCameraActive,
          },
          {
            title: 'Meeting',
            hidden: isPast || !!MEETING,
            buttonProps: {
              onClick: openMeetingModal,
            },
            icon: ICONS.MeetingLocation,
          },
          {
            title: 'Copy Workout',
            hidden: !(WORKOUT || REST || MEETING || MESSAGE),
            buttonProps: {
              onClick: copyWorkout,
              className: 'copy_paste',
            },
            icon: ICONS.CopyDay,
            iconStyles: { backgroundSize: 'auto 80%' },
            styles: { background: '#F1F0F0', borderColor: '#F1F0F0' },
          },
          {
            title: 'Paste Workout',
            hidden: isPast || !copied,
            buttonProps: {
              onClick: () => pasteWorkout(day?.index),
              className: 'copy_paste',
            },
            icon: ICONS.PasteDay,
          },
        ];
  }, [
    day,
    openWorkoutCreate,
    addRestDay,
    closeDrop,
    MESSAGE,
    REST,
    WORKOUT,
    MEETING,
    copied,
    openMessageCreate,
    copyWorkout,
    pasteWorkout,
    openMeetingModal,
    openOnlineMeetingModal,
    isPast,
    readOnly,
  ]);

  const allHidden = useMemo(() => (
    dropdownItems.filter(item => !item.hidden).length === 0
  ), [dropdownItems]) 

  const openDrop = useCallback((e) => {
    e.stopPropagation();
    if (!allHidden) {
      setIsDropOpen(true);
    }
  }, []);

  const { isDragging } = useContext(CalendarDragContext);

  const messageIcon = MESSAGE?.message
    ? MESSAGE?.message.icon?.file?.url
    : MESSAGE?.icon?.file?.url;

  const openEditMeeting = useCallback(() => {
    if (MEETING?.onlineMeeting) {
      openOnlineMeetingModal(MEETING);
    }

    if (MEETING?.faceMeeting) {
      openMeetingModal(MEETING);
    }
  }, [MEETING, openOnlineMeetingModal, openMeetingModal]);

  return (
    <DayContainer status={statusForColor}>
      <DayHeader className="day-header">
        <CalendarDayNum isToday={isToday}>{`DAY ${day?.index}`}</CalendarDayNum>
        {!hideDate && <CalendarDayDate isToday={isToday}>{day?.date}</CalendarDayDate>}
      </DayHeader>

      {program ? (
        <>
          <DayBody>
            <DayWorkout>
              {!WORKOUT && !REST && isDragging && (
                <EmptyActions>
                  <HoverActions droppable index={day?.index} />
                </EmptyActions>
              )}

              {!REST && !WORKOUT ? (
                <DropdownContainerSmall onClick={openDrop}>
                  {/* <DayDropdown
                    isOepn={isDropOpen}
                    onClick={() => setIsDropOpen((s) => !s)}
                    items={dropdownItems}
                    isFirstRow={firstRow}
                    buttonSize="3.2rem"
                  /> */}
                </DropdownContainerSmall>
              ) : (
                <HoverActions
                  showEdit={!readOnly && !!WORKOUT}
                  showDelete={!readOnly}
                  onDelete={() => setSelectedProgram(WORKOUT ? { WORKOUT } : { REST })}
                  onEdit={WORKOUT ? openWorkoutEdit : undefined}
                  draggable={!readOnly && !!WORKOUT}
                  droppable={!!REST}
                  index={day?.index}
                  workoutId={WORKOUT?.uid}
                  onClick={readOnly && WORKOUT ? openWorkoutEdit : undefined}
                >
                  <CalendarDayTitle>
                    <Ellipsis>
                      {!!REST && 'Rest Day'}
                      {!!WORKOUT && WORKOUT?.workout?.name}
                    </Ellipsis>
                  </CalendarDayTitle>
                </HoverActions>
              )}
            </DayWorkout>
            <CardSecondaryContent>
              {!!MESSAGE ? (
                <CardSecondaryContentButton>
                  <CardSecondaryContentButtonIcon icon={ICONS.MessageBtn} />
                  <SecondaryContentTooltip
                    onDeleteClick={() => setSelectedProgram({ MESSAGE })}
                    onEditClick={openMessageEdit}
                    secondaryContent={
                      <>
                        {!!messageIcon && <MessageIcon src={messageIcon} alt="" />}
                        {MESSAGE?.message?.text || MESSAGE?.customMessage}
                      </>
                    }
                    hideActions={readOnly}
                  />
                </CardSecondaryContentButton>
              ) : (
                <></>
              )}
              {(!MESSAGE || !MEETING) &&
                (pasteLoading ? null : (
                  <CardSecondaryContentButton plus>
                    <DropdownContainerSmall onClick={openDrop}>
                      <DayDropdown
                        isOepn={isDropOpen}
                        onClick={() => setIsDropOpen((s) => !s)}
                        items={dropdownItems}
                        isFirstRow={firstRow}
                        buttonSize="3.2rem"
                      />
                    </DropdownContainerSmall>
                  </CardSecondaryContentButton>
                ))}
              {!!MEETING ? (
                <CardSecondaryContentButton>
                  <CardSecondaryContentButtonIcon
                    icon={MEETING.onlineMeeting ? ICONS.VideoCamera : ICONS.MeetingLocation}
                  />
                  <SecondaryContentTooltip
                    onDeleteClick={() => setSelectedProgram({ MEETING })}
                    onEditClick={openEditMeeting}
                    hideActions={readOnly || isPast}
                    secondaryContent={
                      MEETING.onlineMeeting
                        ? MEETING.onlineMeeting.date
                        : MEETING.faceMeeting.location
                    }
                  />
                </CardSecondaryContentButton>
              ) : (
                <></>
              )}
            </CardSecondaryContent>
          </DayBody>

          <DayFooter>
            <DayFooterText>{statusToText}</DayFooterText>
          </DayFooter>
        </>
      ) : (
        <DropdownContainer onClick={openDrop}>
          {isDragging && (
            <EmptyActions>
              <HoverActions droppable index={day?.index} />
            </EmptyActions>
          )}

          {pasteLoading ? null : (
            <DayDropdown
              isOepn={isDropOpen}
              onClick={() => setIsDropOpen((s) => !s)}
              items={dropdownItems}
              isFirstRow={firstRow}
              buttonSize="4.8rem"
            />
          )}
        </DropdownContainer>
      )}
    </DayContainer>
  );
};

export default ProgramCalendarDay;
