import styled from 'styled-components';

export const WholeWrapper = styled.div`
  width: 100%;
  padding: 2rem 10rem;
`;

export const OrgWholeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
export const OrgLineWrapper = styled.div`
  padding-top: 2.8rem;
  flex-grow: 1;

  & * .input-range__track {
    background: #fafafa;
    box-shadow: 0 0 1px 1px #f1f0f0 inset;
    border-radius: 4px;
    height: 8px;
    position: relative;
    display: flex;
  }
  & * .input-range__track--active {
    background: #00ff91;
    border-radius: 4px;
    box-shadow: none;
    border: none;
  }
  & * .input-range__label-container {
    display: none;
  }
  & * .input-range__slider {
    background: #00ff8d;
    position: absolute;
    right: -8px;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: 7px solid #ffffff;
    box-shadow: 0 0 10px 0 #00ff8dbb;
    margin-top: -10px;
    cursor: pointer;
  }
`;
export const OrgValuesWrapper = styled.div`
  border: 1px solid #05000012;
  background: #ffffff;
  padding: 1rem 1.6rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 2rem;
  &::after {
    content: '';
    position: absolute;
    left: -6px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6px 5px 0;
    border-color: transparent #ffffff transparent transparent;
  }
  &::before {
    content: '';
    position: absolute;
    left: -7px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 7px 6px 0;
    border-color: transparent #05000012 transparent transparent;
  }
`;

export const OrgValuesPercentage = styled.div`
  color: #11e470;
  font-size: 1.4rem;
  font-family: Roboto-m;
`;
export const OrgValuesNumeric = styled.div`
  color: #373333;
  font-size: 1.4rem;
  font-family: Roboto-b;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2.5rem;
  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 2rem;
    width: 0.5px;
    background: #05000012;
    left: 1.2rem;
  }
`;

export const ProgressTrack = styled.div`
  width: 100%;
  height: 0.8rem;
  background: #fafafa;
  /* border: 1px solid #f1f0f0; */
  box-shadow: 0 0 1px 1px #f1f0f0 inset;
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    background: #fafafa;
    position: absolute;
    right: -0.6rem;
    border-radius: 50%;
    padding: 0.5rem;
    border: 0.1rem solid #f1f0f0;
    z-index: 10;
    display: ${({ noEndpoints }) => (noEndpoints ? 'none' : 'block')};
  }
`;

export const ProgressBar = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 100%;
  border-radius: 0.4rem;
  background: #00ff91;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    background: #00ff8d;
    position: absolute;
    right: -0.8rem;
    border-radius: 50%;
    padding: 0.4rem;
    border: 0.7rem solid #ffffff;
    box-shadow: 0 0 10px 0 #00ff8dbb;
    z-index: 11;
    display: ${({ noEndpoints }) => (noEndpoints ? 'none' : 'block')};
  }
  &::before {
    content: '';
    background: #15df68;
    position: absolute;
    left: -0.8rem;
    border-radius: 50%;
    padding: 0.4rem;
    border: 0.4rem solid #00ff8d;
    z-index: 11;
    display: ${({ noEndpoints }) => (noEndpoints ? 'none' : 'block')};
  }
`;

export const ProgressBarHandler = styled.div`
  content: '';
  background: #00ff8d;
  position: absolute;
  right: -0.8rem;
  border-radius: 50%;
  padding: 0.4rem;
  border: 0.7rem solid #ffffff;
  box-shadow: 0 0 10px 0 #00ff8dbb;
  z-index: 11;
  cursor: pointer;
`;

export const ChartLegend = styled.div`
  width: 100%;
  & div.end_points {
    font-family: Space-r;
    font-size: 1.8rem;
    color: #373333;
    line-height: 3.6rem;
  }
  & div.end_points_org {
    font-family: Roboto-m;
    font-size: 1.4rem;
    color: #37333366;
    line-height: 2.4rem;
    margin: 4px 2px 0 2px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const ChartLegendProgress = styled.div`
  position: absolute;
  width: 10rem;
  top: 0.8rem;
  left: ${({ percentage }) => `Calc(${percentage}% - 5rem)`};
  font-family: Space-b;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #373333;
  text-align: center;
  z-index: 1111;
  & > div {
    width: 100%;
    text-align: center;
    font-family: Roboto-r;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #373333;
  }
`;
