import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'react-alert';
import moment from 'moment';
import timezones from 'timezones-list';

import CustomButton from '../button/CustomButton';
import WeekDayColumn from './WeekDayColumn';
import {
  CalendarWeekHeader,
  CalendarWeekWrapper,
  NeighbourWeekArrow,
  WeekHeaderFlexWrapper,
  WeekRange,
  WeekTitle,
  WeekContent,
} from './components';
import AreYouSureModal from '../are-you-sure-modal/AreYouSureModal';
import useUtils from '../../hooks/utils'
import useCalendar from '../../hooks/calendar';
import { CalendarType } from '../../constants/enums';


const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 3333,
  },
  content: {
    width: 'Calc(100% - 32px)',
    minWidth: '1080px',
    height: 'Calc(100vh - 96px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
    pointerEvents: 'all',
  },
};

const hasUnsavedChanges = (formikRef) => {
  if (formikRef?.current) {
    let a = { ...formikRef.current.values };
    let b = { ...formikRef.current.initialValues };

    a.blocks = a.blocks && a.blocks.length === 0 ? undefined : a.blocks;
    delete a.editingBlockIndex;
    delete b.editingBlockIndex;

    return JSON.stringify(a) !== JSON.stringify(b);
  } else {
    return false;
  }
};

const AddCalendarWeekModal = ({
  weekN,
  setWeekN,
  closeModal,
  calendarMatrix,
  programDays,
  loading,
  submitDay,
  addRestDay,
  submitMessageDay,
  handleCreateMeeting,
  isOpen,
  handleDeleteCalendar,
  coachProgramUid,
  programTemplateUid,
  refetch,
}) => {
  let formikrefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const wrapperRef = useRef();
  const [draggingBlock, setDraggingBlock] = useState(null);
  const [deleteDraggingBlock, setDeleteDraggingBlock] = useState(null);
  const [changeIndexModalOpen, setChangeIndexModalOpen] = useState(null);
  const [copiedColumn, setCopiedColumn] = useState(null);
  
  const { getWorkoutBlocksByWorkout } = useUtils();

  const alert = useAlert();
  
  const week = useMemo(() => calendarMatrix[weekN], [weekN, calendarMatrix]);

  const getDates = useCallback((weekNumber) => [
    calendarMatrix[weekNumber]?.[0]?.index || calendarMatrix[weekNumber]?.[6]?.index - 6 || 0,
    calendarMatrix[weekNumber]?.[6]?.index || calendarMatrix[weekNumber]?.[0]?.index + 6 || undefined,
  ], [calendarMatrix])

  const getExistingWorkouts = useCallback((weekStartDate, weekNumber, calendars) => {
    const result = new Array(7).fill(undefined);
    const weekCalendars = programTemplateUid
      ? programDays?.slice(weekNumber * 7 + 1, weekNumber * 7 + 8)
      : calendars;
    if (Array.isArray(weekCalendars)) {
      weekCalendars.forEach((cal, i) => {
        if (programTemplateUid ? !cal?.['WORKOUT'] : cal.type !== 'WORKOUT') {
          return 0;
        }
        if (programTemplateUid) {
          result[i] = cal?.['WORKOUT'];
        } else {
          result[cal.programDay - weekStartDate] = cal;
        }

        return 1;
      });
    }
    return result;
  }, [programTemplateUid, programDays]);

  const [startDate, endDate] = useMemo(() => getDates(weekN),
    [calendarMatrix, weekN]
  );

  const { calendars, calendarsLoading, refetchCalendars, getCalendarsRange } = useCalendar({
    coachProgramUid,
    programTemplateUid,
    startDate,
    endDate,
  });

  const existingWorkouts = useMemo(() => getExistingWorkouts(startDate, weekN, calendars),
    [calendars, startDate, programTemplateUid, programDays, weekN]
  );

  const pasteFromPreviousWeek = async () => {
    const prevWeek = calendarMatrix[weekN - 1]
    const prevWeekCalendar = await getCalendarsRange(startDate - 7, endDate - 7)
    const prevWeekExistingWorkouts = getExistingWorkouts(getDates(weekN - 1)[0], weekN - 1, prevWeekCalendar?.getCalendars)

    const prevWeekFormValues = prevWeek.map((day, weekDay) => (
      !!day ? createFormValues(programDays[day.index], prevWeekExistingWorkouts[weekDay], day.index) : null
    ));

    for(let i = 0; i < 7; i++) {
      pasteFormData(i, prevWeekFormValues[i])
    }
  }

  const pasteFormData = (i, values) => {
    if (i > -1 && formikrefs[i]?.current?.setFieldValue) {
      if (!values) {
        formikrefs[i].current.setFieldValue('blocks', undefined);
        formikrefs[i].current.setFieldValue('isRest', false);
        formikrefs[i].current.setFieldValue('beforeMessageChecked', false);
        formikrefs[i].current.setFieldValue('beforeMessage', '');
        formikrefs[i].current.setFieldValue('afterMessageChecked', false);
        formikrefs[i].current.setFieldValue('afterMessage', '');
      } else {
        formikrefs[i].current.setFieldValue('name', values?.name);
        formikrefs[i].current.setFieldValue('blocks', values?.blocks);
        formikrefs[i].current.setFieldValue('isRest', values?.isRest);
        formikrefs[i].current.setFieldValue('beforeMessageChecked', values?.beforeMessageChecked);
        formikrefs[i].current.setFieldValue('beforeMessage', values?.beforeMessage);
        formikrefs[i].current.setFieldValue('afterMessageChecked', values?.afterMessageChecked);
        formikrefs[i].current.setFieldValue('afterMessage', values?.afterMessage);
      }

      if (values?.FACE_MEETING?.date) {
        const {
          date,
          timezone,
          location,
          faceMeetingUid,
          onlineMeetingUid,
        } = values?.FACE_MEETING;
        formikrefs[i].current.setFieldValue('FACE_MEETING', { 
          date,
          timezone: timezones.find(t => t.tzCode === timezone),
          location,
          faceMeetingUid,
          onlineMeetingUid,
        });
      } else {
        formikrefs[i].current.setFieldValue('FACE_MEETING', { date: undefined });
      }

      if (values?.ONLINE_MEETING?.date) {
        const {
          date,
          timezone,
          faceMeetingUid,
          onlineMeetingUid
        } = values?.ONLINE_MEETING;
        formikrefs[i].current.setFieldValue('ONLINE_MEETING', { 
          date,
          timezone: timezones.find(t => t.tzCode === timezone),
          faceMeetingUid,
          onlineMeetingUid,
        });
      } else {
        formikrefs[i].current.setFieldValue('ONLINE_MEETING', { date: undefined });
      }

      if (values?.MESSAGE?.message || values?.MESSAGE?.customMessage) {
        const {
          customMessage,
          icon,
          message,
          messageType
        } = values?.MESSAGE;
        console.log('new', { 
          customMessage,
          icon,
          message,
          messageType
        })
        formikrefs[i].current.setFieldValue('MESSAGE', { 
          customMessage,
          icon,
          message,
          messageType: customMessage ? 1 : 0
        });
      } else {
        formikrefs[i].current.setFieldValue('MESSAGE', {});
      }
    }
  }

  const createFormValues = useCallback((dayContent, existingWorkout, index) => {
    const blocks = getWorkoutBlocksByWorkout(existingWorkout?.workout)
    return {
      name: existingWorkout ? existingWorkout.workout?.name : `Day ${index}`,
      beforeMessageChecked: !!existingWorkout?.workout?.beforeMessage,
      beforeMessage: existingWorkout ? existingWorkout.workout?.beforeMessage : '',
      afterMessageChecked: !!existingWorkout?.workout?.afterMessage,
      afterMessage: existingWorkout ? existingWorkout.workout?.afterMessage : '',
      type: CalendarType.WORKOUT,
      blocks,
      editingBlockIndex: null,
      isRest: !!dayContent?.REST,
      MESSAGE: dayContent?.MESSAGE || {},
      FACE_MEETING: {
        ...dayContent?.MEETING?.faceMeeting,
        date: dayContent?.MEETING?.faceMeeting?.date
          ? `00:${dayContent?.MEETING?.faceMeeting?.date}`
          : undefined,
        faceMeetingUid: dayContent?.MEETING?.faceMeeting?.uid,
        uid: dayContent?.MEETING?.uid
      },
      ONLINE_MEETING: {
        ...dayContent?.MEETING?.onlineMeeting,
        date: dayContent?.MEETING?.onlineMeeting?.date
          ? `00:${dayContent?.MEETING?.onlineMeeting?.date}`
          : undefined,
        onlineMeetingUid: dayContent?.MEETING?.onlineMeeting?.uid,
        uid: dayContent?.MEETING?.uid
      },
    };
  }, [getWorkoutBlocksByWorkout]);

  const handleColumnPaste = (i) => {
    if (
      i > -1 &&
      copiedColumn &&
      formikrefs?.[copiedColumn]?.current?.values?.blocks?.length &&
      formikrefs[i]?.current?.setFieldValue
    ) {
      pasteFormData(i, formikrefs?.[copiedColumn]?.current?.values)
      setCopiedColumn(null);
    }
  };

  const checkAllOnUnsavedChanges = () => {
    let unsavedChanges = false;
    const promises = formikrefs.map(async (ref) => {
      if (!unsavedChanges && ref?.current && hasUnsavedChanges(ref)) {
        unsavedChanges = true;
      }
    });
    return Promise.all(promises).then(() => {
      return unsavedChanges;
    });
  };

  const submitAll = () => {
    let invalid = false;
    const validationPromises = formikrefs.map(async (ref, i) => {
      if (ref?.current && hasUnsavedChanges(ref) && ref.current.values?.blocks?.length) {
        ref.current.setTouched({ name: true, blocks: { [1]: true } });
        const res = await ref?.current?.validateForm();
        if (!(Object.keys(res).length === 0 && res.constructor === Object)) {
          invalid = true;
        }
      }
    });
    return Promise.all(validationPromises).then(() => {
      alert.info('Checking ...', { timeout: 1000 });
      if (invalid) {
        alert.error('Make Sure To Meet All The Requirements !');
        return { error: true };
      }
      let changed = false;
      const promises = formikrefs.map(async (ref) => {
        if (ref?.current && hasUnsavedChanges(ref)) {
          try {
            await ref?.current?.submitForm();
            changed = true;
          } catch (err) {
            alert.error(err?.message);
          }
        }
      });
      return Promise.all(promises).then(() => {
        if (changed) {
          if (coachProgramUid) {
            refetchCalendars().then(() => alert.success('Changes Saved Successfully !'));
          } else {
            refetch().then(() => alert.success('Changes Saved Successfully !'));
          }
        } else {
          alert.info('No Changes To Save !');
        }
      });
    });
  };

  const onRequestClose = async () => {
    if (await checkAllOnUnsavedChanges()) {
      setChangeIndexModalOpen(3);
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="calendar week"
      ariaHideApp={false}
      contentRef={(ref) => (wrapperRef.current = ref)}
      onRequestClose={onRequestClose}
    >
      {week?.length && (
        <CalendarWeekWrapper
          onDragOver={(e) => {
            if (window.innerHeight - e.clientY < 248) {
              wrapperRef.current.scrollBy({
                top: 248 - window.innerHeight + e.clientY,
                behavior: 'smooth',
              });
            } else if (e.clientY < 248) {
              wrapperRef.current.scrollBy({
                top: e.clientY - 248,
                behavior: 'smooth',
              });
            }
          }}
        >
          <AreYouSureModal
            modalOpen={changeIndexModalOpen}
            rejectModal={() => {
              if (changeIndexModalOpen === 3) {
                closeModal();
              } else {
                setWeekN(weekN + changeIndexModalOpen);
              }
              setChangeIndexModalOpen(false);
            }}
            closeModal={() => setChangeIndexModalOpen(false)}
            submitModal={async () => {
              submitAll().then((res) => {
                if (changeIndexModalOpen === 3) {
                  closeModal();
                } else {
                  setWeekN(weekN + changeIndexModalOpen);
                }
                setChangeIndexModalOpen(false);
              });
            }}
            headingText={'Do you want save changes?'}
            contentText={"Changes will be lost if you don't save them."}
          />
          {
            <CalendarWeekHeader>
              <WeekHeaderFlexWrapper>
                {weekN > 0 && (
                  <CustomButton
                    outlined
                    backgroundColor="#fff"
                    style={{ maxWidth: 'fit-content', padding: '0 2rem', marginLeft: '2.4rem' }}
                    height="4rem"
                    onClick={async () => {
                      alert.info('Copying...');
                      pasteFromPreviousWeek().then(res => {
                        alert.success('Copied SuccessFully !');
                      }).catch((err) => {
                        console.log('Error while copying', err);
                        alert.error(`Something went wrong !`);
                      })
                    }}
                  >
                    Import previous week
                  </CustomButton>
                )}
              </WeekHeaderFlexWrapper>
              <WeekTitle>
                <NeighbourWeekArrow
                  onClick={async () => {
                    if (weekN > 0) {
                      if (await checkAllOnUnsavedChanges()) {
                        setChangeIndexModalOpen(-1);
                      } else {
                        setWeekN(weekN - 1);
                      }
                    }
                  }}
                />
                {programTemplateUid ? (
                  <WeekRange>{`Week ${weekN + 1}`}</WeekRange>
                ) : (
                  <WeekRange>{`${moment(week?.find((x) => x)?.date, 'DD-MM-YYYY').format(
                    'D MMMM',
                  )} : ${moment([...week].reverse()?.find((x) => x)?.date, 'DD-MM-YYYY').format(
                    'D MMMM',
                  )}`}</WeekRange>
                )}
                <NeighbourWeekArrow
                  onClick={async () => {
                    if (calendarMatrix[weekN + 1]) {
                      if (await checkAllOnUnsavedChanges()) {
                        setChangeIndexModalOpen(1);
                      } else {
                        setWeekN(weekN + 1);
                      }
                    }
                  }}
                />
              </WeekTitle>
              <WeekHeaderFlexWrapper>
                <CustomButton
                  outlined
                  backgroundColor="#fff"
                  height="4rem"
                  style={{ width: 'fit-content', padding: '1rem 2.4rem' }}
                  onClick={() => {
                    submitAll();
                  }}
                >
                  save
                </CustomButton>
                <CustomButton
                  green
                  height="4rem"
                  style={{ width: 'fit-content', padding: '1rem 2.4rem', marginLeft: '1.6rem' }}
                  onClick={async () => {
                    submitAll().then((res) => {
                      if (!res?.error) {
                        closeModal();
                      }
                    });
                  }}
                >
                  Save & close
                </CustomButton>
                <CustomButton
                  height="4rem"
                  backgroundColor="#fff"
                  color="#37333377"
                  fontSize="1.8rem"
                  style={{ width: 'fit-content', padding: '0.8rem', marginLeft: '1rem' }}
                  onClick={onRequestClose}
                >
                  X
                </CustomButton>
              </WeekHeaderFlexWrapper>
            </CalendarWeekHeader>
          }
          <WeekContent>
            {week.map(
              (day, weekDay) =>
                day && (
                  <WeekDayColumn
                    index={day.index}
                    key={day.index}
                    existingWorkout={existingWorkouts[weekDay]}
                    dayContent={programDays[day.index]}
                    weekDay={weekDay}
                    loading={loading || calendarsLoading}
                    submitDay={submitDay}
                    formikRef={formikrefs[weekDay]}
                    day={day}
                    draggingBlock={draggingBlock}
                    deleteDraggingBlock={deleteDraggingBlock}
                    setDeleteDraggingBlock={setDeleteDraggingBlock}
                    setDraggingBlock={setDraggingBlock}
                    addRestDay={addRestDay}
                    submitMessageDay={submitMessageDay}
                    handleCreateMeeting={handleCreateMeeting}
                    handleDeleteCalendar={handleDeleteCalendar}
                    handleColumnPaste={handleColumnPaste}
                    copiedColumn={copiedColumn}
                    setCopiedColumn={setCopiedColumn}
                    isTemplate={programTemplateUid}
                    initialValues={createFormValues(programDays[day.index], existingWorkouts[weekDay], day.index)}
                  />
                ),
            )}
          </WeekContent>
        </CalendarWeekWrapper>
      )}
    </Modal>
  );
};

export default AddCalendarWeekModal;
