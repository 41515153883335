import React from 'react';

import { FileInputInput, FileInputLabel, UploadCloud } from './components';

const CustomInputFile = ({
  onChange = () => {},
  accept,
  noHover,
  multiple,
  inputId = 'file',
  children,
  disabled,
}) => {
  return (
    <>
      <FileInputInput
        type="file"
        onChange={onChange}
        id={inputId}
        accept={accept}
        multiple={multiple}
        disabled={disabled}
      />
      <FileInputLabel
        htmlFor={inputId}
        noHover={noHover}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          onChange(e.dataTransfer.files);
        }}
      >
        <UploadCloud />
        {children}
      </FileInputLabel>
    </>
  );
};

export default CustomInputFile;
