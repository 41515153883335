import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  meQuery,
  editCoachMutation,
  uploadPortfolioMutation,
  getSpecialitiesQuery,
  getTrainingTypesQuery,
  deletePortfolioMutation,
  getOrgCoachesQuery,
  editOrganisationMutation,
} from '@witness/graphql';
import { useMemo } from 'react';
import { useAlert } from 'react-alert';

const useCoachPersonalInfo = ({
  setLoading = () => {},
  setOpenModal = () => {},
  orgCoachesFilters = {},
}) => {
  const { data: me, loading: meLoading, error: meError, refetch: meRefetch } = useQuery(meQuery);

  const alert = useAlert();

  const { data: spData } = useQuery(getSpecialitiesQuery, {
    skip: me?.getCurrentUser?.user?.role?.name !== 'admin',
  });
  const { data: ttData } = useQuery(getTrainingTypesQuery, {
    skip: me?.getCurrentUser?.user?.role?.name !== 'admin',
  });

  const specialities = useMemo(() => {
    if (me?.getCurrentUser?.user?.role?.name === 'admin') {
      if (spData && ttData) {
        return spData.getSpecialities.map((sp) => ({
          ...sp,
          trainingTypes: ttData.getTrainingTypes.filter((tt) => tt.speciality.id === sp.id),
        }));
      }

      return [];
    }

    return me?.getCurrentUser?.coach?.specialities || [];
  }, [spData, ttData, me]);

  const getInitialValuesPersonal = () => {
    if (me?.getCurrentUser?.organisation?.uid) {
      return {
        fullName: me?.getCurrentUser?.organisation?.fullName || '',
        address: me?.getCurrentUser?.organisation?.address || '',
      };
    }
    return {
      fullName: me?.getCurrentUser?.coach?.fullName || '',
      address: me?.getCurrentUser?.coach?.address || '',
      phone: me?.getCurrentUser?.coach?.phone || '',
      idNumber: me?.getCurrentUser?.coach?.idNumber || '',
      gender: me?.getCurrentUser?.coach?.gender || '',
      age: me?.getCurrentUser?.coach?.age || null,
      height: me?.getCurrentUser?.coach?.height || null,
      weight: me?.getCurrentUser?.coach?.weight || null,
      meal: me?.getCurrentUser?.coach?.meal || '',
      playlist: me?.getCurrentUser?.coach?.playlist || '',
    };
  };

  const getInitialValuesAbout = () => {
    if (me?.getCurrentUser?.organisation?.uid) {
      return {
        about: me?.getCurrentUser?.organisation?.about || ``,
      };
    }
    return {
      about: me?.getCurrentUser?.coach?.about || ``,
    };
  };

  const [editCoach] = useMutation(editCoachMutation);
  const [editOrg] = useMutation(editOrganisationMutation);

  const submitEditCoach = async (values, { setSubmitting, setErrors, blob }) => {
    setOpenModal(null);
    setLoading(true);
    try {
      if (me?.getCurrentUser?.organisation?.uid) {
        await editOrg({
          variables: {
            record: {
              name: values?.fullName || undefined,
              description: values?.about || undefined,
              image: values?.image || undefined,
              address: values?.address || undefined,
            },
          },
        });
      } else {
        await editCoach({
          variables: {
            record: {
              ...values,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            editCoach: blob
              ? {
                  ...me?.getCurrentUser?.coach,
                  __typename: 'Coach',
                  profileImage: {
                    __typename: 'File',
                    uid: 'temp',
                    id: 'temp',
                    url: blob,
                    filename: 'profile.img',
                    mimetype: '',
                    compressedUrl: blob,
                    encoding: '',
                  },
                }
              : {
                  ...me?.getCurrentUser?.coach,
                  __typename: 'Coach',
                  ...values,
                },
          },
        });
      }

      setSubmitting(false);
      await meRefetch();
      setLoading(false);
    } catch (err) {
      if (err.graphQLErrors?.find((x) => x.message === 'coach_already_exists')) {
        setErrors({ email: 'Email already exists' });
      }
    }
  };

  const [uploadPortfolio] = useMutation(uploadPortfolioMutation);

  const uploadportfolioItems = async (values) => {
    setLoading(true);
    new Promise((resolve, reject) => {
      const alreadyReadFiles = [];
      Array.from(values).map((file) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          alreadyReadFiles.push({
            url: reader.result,
            __typename: 'File',
            id: 'tempid',
            mimetype: 'text/',
            encoding: '7bit',
            filename: 'filename-temp',
          });
          if (alreadyReadFiles.length >= Array.from(values).length) {
            resolve({
              uid: 'temp-0b72-41e9-8d91-4326ba973cc6',
              __typename: 'Portfolio',
              files: alreadyReadFiles,
            });
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        } else {
        }
        return 1;
      });
    }).then(async (optimisticAddedFiles) => {
      await uploadPortfolio({
        variables: {
          record: {
            portfolio: values,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          uploadPortfolio: optimisticAddedFiles,
        },
        update: (proxy, { data: { uploadPortfolio } }) => {
          const data = proxy.readQuery({ query: meQuery });
          proxy.writeQuery({
            query: meQuery,
            data: {
              ...data,
              getCurrentUser: {
                ...data.getCurrentUser,
                coach: {
                  uid: me?.getCurrentUser?.coach?.id,
                  ...data.getCurrentUser.coach,
                  __typename: 'Coach',
                  portfolio: [...me?.getCurrentUser?.coach?.portfolio, uploadPortfolio],
                },
              },
            },
          });
        },
      });
      setLoading(false);
    });
  };

  const [deletePortfolio] = useMutation(deletePortfolioMutation);

  const handleDeletePortfolio = (uid) => {
    try {
      deletePortfolio({
        variables: {
          record: {
            uid,
          },
        },
      }).then(() => {
        meRefetch();
        alert.success('Item Has been Successfully Deleted');
      });
    } catch (err) {
      alert.error(err?.messae);
    }
  };
  const { data: orgCoaches, loading: loadingOrgCoaches } = useQuery(getOrgCoachesQuery, {
    variables: {
      record: orgCoachesFilters,
    },
  });

  return {
    getInitialValuesPersonal,
    getInitialValuesAbout,
    currentCoach: !meLoading &&
      !meError && {
        ...me?.getCurrentUser?.coach,
        email: me?.getCurrentUser?.user?.email,
        specialities,
      },
    submitEditCoach,
    uploadportfolioItems,
    isAdmin: me?.getCurrentUser?.user?.role?.name === 'admin',
    handleDeletePortfolio,
    currentOrganization: me?.getCurrentUser?.organisation,
    orgCoaches,
    loadingOrgCoaches,
  };
};

export default useCoachPersonalInfo;
