import React, {useState} from 'react';
import {
  DownIconImg,
  ExercisesFilters,
  ExercisesRow,
  ExercisesTD,
  FlexTDContent,
  Title,
  UpIconImg, VideoIconImg,
} from './components';
import Loader from "../../components/loader/Loader";
import useExercisePage from "../../hooks/exercisePage";
import CustomButton from "../../components/button/CustomButton";
import PaginatedNumbers from "./PaginatedNumbers";
import AnIcon from "../../assets/input/search-icon.png";
import CustomInput from "../../components/input/CustomInput";
import CustomInputDropdown from "../../components/input/CustomInputDropdown";
import VideoPreview from "./VideoPreview";

const iconStyles = {
  borderRight: '1px solid #050000',
  height: '100%',
  width: '4.8rem',
  margin: 0,
  backgroundSize: '48% auto',
  opacity: 0.2,
};

const availableTypes = [
  {label: 'None', value: null},
  {label: 'Upper Body', value: 'UPPERBODY'},
  {label: 'Lower Body', value: 'LOWERBODY'},
  {label: 'Core', value: 'CORE'},
  {label: 'Multi', value: 'MULTI'},
  {label: 'Stretch', value: 'STRETCH'},
  {label: 'Pilates R', value: 'PILATESR'}];

const availableCategories = [
  {label: 'None', value: null},
  {label: 'Warm up', value: 'WARMPUP'},
  {label: 'Core', value: 'CORE'},
  {label: 'Articulation', value: 'ARTICULATION'},
  {label: 'Extension', value: 'EXTENSION'},
  {label: 'Arms', value: 'ARMS'},
  {label: 'Lower body', value: 'LOWERBODY'},
  {label: 'Booty', value: 'BOOTY'},
  {label: 'Total Body', value: 'TOTALBODY'},
  {label: 'Spine Mobility', value: 'SPINEMOBILITY'},
  {label: 'Flow', value: 'FLOW'},];

const Exercises = () => {
  const [filters, setFilters] = useState({
    title: null,
    sortOrder: 'ASC',
    type: {label: 'None', value: null},
    isGlobal: true,
    currentPage: 1,
    category: {label: 'None', value: null},
    limit: 10,
  });

  const [videoOpen, setVideoOpen] = useState({open: false, youtubeVideoId: null, video: null});

  const {exercises, exercisesLoading} = useExercisePage(filters.title,
      'title',
      filters.sortOrder,
      filters.limit,
      filters.currentPage,
      filters.isGlobal,
      filters.type.value,
      filters.category.value);


  const setTitle = (title) => {
    setFilters(prv => {
      return {
        ...prv,
        title,
        currentPage: 1,
      }
    })
  }
  const setCurrentPage = (page) => {
    setFilters(prv => {
      return {
        ...prv, currentPage: page
      }
    })
  }

  return (
      <>
      <div style={{
        background: '#fff',
        padding: '12px 8px',
        margin: '20px',
        borderRadius: '8px',
        flexWrap: 'wrap',
      }}
      >
        <ExercisesFilters>
          <Title>Excerises</Title>
          <div style={{display: 'flex'}}>
            <div style={{ width: '12.4rem', marginRight: '1rem' }}>
          <CustomButton
              className="hoverable_view_button"
              outlined
              borderRadius="8px"
              hoverBackground="#00ff91"
              outlineColor="#F1F0F0"
              backgroundColor= {filters.isGlobal ? '#00ff91' : '#ffffff'}
              color="#373333"
              height="3.2rem"
              fontSize="1.4rem"
              onClick={() => setFilters({...filters, isGlobal: true})}
          >
            System Exercises
          </CustomButton>
            </div>
            <div style={{ width: '12.4rem', marginRight: '1rem' }}>
          <CustomButton
              className="hoverable_view_button"
              outlined
              margin="10px"
              borderRadius="8px"
              hoverBackground="#00ff91"
              outlineColor="#F1F0F0"
              backgroundColor={filters.isGlobal ? '#ffffff' : '#00ff91'}
              color="#373333"
              height="3.2rem"
              fontSize="1.4rem"
              onClick={() => setFilters({...filters,
                isGlobal: false,
                currentPage: 1,})}
          >
            My Exercises
          </CustomButton>
            </div>
          </div>
        </ExercisesFilters>
        <div style={{borderBottom: '1px solid #e9eaed', display: 'flex', alignItems: 'center'}}>
          <div style={{width: "26.6rem"}}>
          <CustomInput
              outlined
              height="4.4rem"
              borderRadius="8px"
              margin="1.55rem"
              placeholder="Search by title"
              icon={AnIcon}
              iconStyles={iconStyles}
              value={filters.title}
              onChange={setTitle}
          />
          </div>
          <div style={{marginLeft: '3rem', marginBottom: '1rem'}}>
            <div>
              <div style={{marginLeft: '0.5rem', fontWeight: 'bold'}}>
                Type
              </div>
            <CustomInputDropdown
                placeholder="None"
                value={filters.type.label}
                returnEntire
                onChange={(v) => {
                  setFilters(prv => {
                    return {
                      ...prv, type: v,
                      currentPage: 1,
                    }
                  })
                }}
                options={availableTypes}
                style={{
                  fontSize: '1.4rem',
                  border: '1px solid #f7f6f6`',
                  borderRadius: "8px",
                  width: '26rem',
                  height: "4.4rem",
                  flexGrow: 1,
                }}
            />
          </div>
          </div>
          <div style={{marginLeft: '2rem', marginBottom: '1rem'}}>
            <div>
              <div style={{marginLeft: '0.5rem', fontWeight: 'bold'}}>
                Category
              </div>
              <CustomInputDropdown
                  placeholder="None"
                  value={filters.category.label}
                  returnEntire
                  onChange={(v) => {
                    console.log(v);
                    setFilters(prv => {
                      return {
                        ...prv, category: v,
                        currentPage: 1,
                      }
                    })
                  }}
                  options={availableCategories}
                  style={{
                    fontSize: '1.4rem',
                    border: '1px solid #f7f6f6`',
                    borderRadius: "8px",
                    width: '26rem',
                    height: "4.4rem",
                    flexGrow: 1,
                  }}
              />
            </div>
          </div>
        </div>
        <div
            style={{
              background: '#f7f6f6',
              padding: '12px 8px',
              margin: '20px',
              borderRadius: '8px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
        >
      <table
          style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: '0 6px',
            borderRadius: '8px',
            textAlign: 'left',
          }}
      >
        <thead>
        <tr>
          <th
              style={{
                padding: '0.6rem 3.2rem',
                fontFamily: 'Roboto-m',
                fontSize: '1.4rem',
                color: '#4a4a4a',
                width: '40%',
              }}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
            Title
              <div onClick={() => {
                setFilters(prv => {
                  return {
                    ...prv,
                    sortOrder: prv.sortOrder === 'ASC' ? 'DESC' : 'ASC',
                    currentPage: 1,
                  }
                })
              }}>
                {filters.sortOrder === 'ASC' ? <DownIconImg/> : <UpIconImg/>}
              </div>
            </div>
          </th>
          <th
              style={{
                width: '13.75%',
                padding: '0.6rem 3.2rem',
                fontFamily: 'Roboto-m',
                fontSize: '1.4rem',
                color: '#4a4a4a',
              }}
          >
            Type
          </th>
          <th
              style={{
                padding: '0.6rem 3.2rem',
                fontFamily: 'Roboto-m',
                fontSize: '1.4rem',
                color: '#4a4a4a',
                width: '13.75%',
              }}
          >
            Category
          </th>
          <th
              style={{
                padding: '0.6rem 3.2rem',
                fontFamily: 'Roboto-m',
                fontSize: '1.4rem',
                color: '#4a4a4a',
                width: '13.75%',
              }}
          >
            Setting
          </th>
          <th
              style={{
                padding: '0.6rem 3.2rem',
                fontFamily: 'Roboto-m',
                fontSize: '1.4rem',
                color: '#4a4a4a',
                width: '13.75%',
              }}
          >
            Gender
          </th>
          <th
              style={{
                textAlign: 'right',
                padding: '0.6rem 3.2rem',
                fontFamily: 'Roboto-m',
                fontSize: '1.4rem',
                color: '#4a4a4a',
                width: '5%',
              }}
          >
            Video
          </th>
        </tr>
        </thead>
        <tbody style={{
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: '0 6px',
          borderRadius: '8px',
          textAlign: 'left',
        }}>
        {console.log(exercises)}
        {exercisesLoading ? <Loader /> : !!exercises?.getExercisesPaginated.exercises.length && exercises?.getExercisesPaginated.exercises.map((exercise) => {
          console.log(exercise)
          return <ExercisesRow>
            <ExercisesTD><FlexTDContent >{exercise.title}</FlexTDContent></ExercisesTD>
            <ExercisesTD><FlexTDContent >{availableTypes.find(x => x.value === exercise.type)?.label}</FlexTDContent></ExercisesTD>
            <ExercisesTD><FlexTDContent >{exercise.category}</FlexTDContent></ExercisesTD>
            <ExercisesTD><FlexTDContent >{exercise.setting}</FlexTDContent></ExercisesTD>
            <ExercisesTD><FlexTDContent >{exercise.gender}</FlexTDContent></ExercisesTD>
            <ExercisesTD><FlexTDContent flexEnd={true}>{(exercise.youtubeVideoId || exercise.video) &&<div style={{cursor: 'pointer'}} onClick={() => {
              setVideoOpen({open: true, youtubeVideoId: exercise.youtubeVideoId, video: exercise.video?.url})}
            }><VideoIconImg/></div>}</FlexTDContent></ExercisesTD>
          </ExercisesRow>
        })}
        </tbody>
      </table>
          {!exercisesLoading && <PaginatedNumbers curPage={filters.currentPage} setCurrentNumber={setCurrentPage} total={exercises?.getExercisesPaginated?.total} />}
        </div>
      </div>
        <VideoPreview videoUrl={videoOpen.video} isOpen={videoOpen.open} youtubeVideoId={videoOpen.youtubeVideoId} setIsOpen={() => setVideoOpen({open: false, youtubeVideoId: null, video: null})}/>
      </>
  );
};
export default Exercises;
