import { useContext, useMemo, useState } from 'react';
import {
  getProgramsQuery,
  changeProgramStatusMutation,
  getOrgProgramsQuery,
  cancelFreeProgramMutation
} from '@witness/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { CoachProgramStatus } from '../constants/enums';
import { useDebounce } from '../services/utils';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { OrganizationContext } from '../services/organizationContext';

const useProgramRequest = (props) => {
  const { isOrganization } = useContext(OrganizationContext);
  const alert = useAlert();
  const history = useHistory();
  const statusOpts = useMemo(
    () => [
      { label: 'In progress', value: CoachProgramStatus.ONGOING },
      { label: 'Pending', value: CoachProgramStatus.APPROVED },
      { label: 'Canceled', value: CoachProgramStatus.DECLINED },
      { label: 'Finished', value: CoachProgramStatus.ENDED },
    ],
    [],
  );

  const [traineeName, setTraineeName] = useState('');
  const delayedName = useDebounce(traineeName, 500);

  const [status, setStatus] = useState(props?.coachProgramStatus || []);

  const [changeStatus, { loading: changeStatusLoading }] = useMutation(changeProgramStatusMutation);
  const changeProgramStatus = async (values, groupUid) => {
    try {
      const acceptedProgram = await changeStatus({
        variables: {
          record: {
            ...values,
          },
        },
      });
      if (acceptedProgram?.data?.changeProgramStatus?.status === 'APPROVED') {
        const red_url = groupUid
          ? `/program/${groupUid}?group=true`
          : `/program/${acceptedProgram?.data?.changeProgramStatus?.uid}`;
        history.push(red_url);
      }
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'program_not_relative')) {
        alert.error('Request has already been cancelled');
      } else {
        alert.error('Something Went Wrong');
      }
    }
  };
  const {
    data: programRequests,
    loading: programRequestsLoading,
    refetch: programRequestsRefetch,
  } = useQuery(getProgramsQuery, {
    variables: {
      record: {
        coachProgramStatus: status?.map((a) => a.value) || undefined,
        status: props?.requestStatus,
        type: props?.programType,
        searchName: delayedName,
      },
    },
    skip: props?.skipQuery,
  });

  const [cancelFreeProgram] = useMutation(cancelFreeProgramMutation);

  const cancelProgram = async (uid) => {
    try {
      await cancelFreeProgram({
        variables: {
          record: {
            uid,
          },
        },
      });
      programRequestsRefetch();
    } catch (err) {
      console.log();
    }
  };

  return {
    changeProgramStatus,
    programRequestsLoading,
    programRequestsRefetch,
    programRequests: programRequests?.getPrograms,
    status,
    setStatus,
    statusOpts,
    traineeName,
    setTraineeName,
    changeStatusLoading,
    cancelProgram
  };
};

export default useProgramRequest;
