import React, { useContext } from 'react';
import { MobileContext } from '../../services/MobileContext';

import Header from '../header/Header';
import MobileHeader from '../header/MobileHeader';
import MobileSidebar from '../sidebar/MobileSidebar';
import Sidebar from '../sidebar/Sidebar';

import { LayoutContainer, MainArea } from './components';

const Layout = ({ innerComponent }) => {
  const { isMobile } = useContext(MobileContext);
  return (
    <>
      {isMobile ? <MobileHeader /> : <Header />}
      <LayoutContainer>
        {isMobile ? <MobileSidebar /> : <Sidebar />}
        <MainArea isMobile={isMobile}>{innerComponent()}</MainArea>
      </LayoutContainer>
    </>
  );
};

export default Layout;
