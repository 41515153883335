import React from 'react';
import Modal from 'react-modal';
import 'react-image-crop/dist/ReactCrop.css';
import STRINGS from '../../constants/strings';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalTextArea,
} from './components';
import { Formik, Field, Form } from 'formik';
import CustomTextArea from '../input/CustomTextArea';
import { EditAboutMeSchema } from '../../schemas/signup/coachProfile';
import { ModalCloseX } from '../request-modal/components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: '28%',
    maxWidth: '684px',
    minWidth: '320px',
    height: 'fit-content',
    maxHeight: 'Calc(100% - 40px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const EditAboutMeModal = ({ modalOpen, closeModal, submitModal, getInitialValues }) => {
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader noborder>
          <span>{STRINGS.edit_about_me}</span>
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <ModalMain padding={'1rem 3.2rem 3.2rem'}>
          <Formik
            initialValues={getInitialValues()}
            validationSchema={EditAboutMeSchema}
            onSubmit={submitModal}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '100%' }}>
                <FlexWrapper>
                  <Field id="about" name="about">
                    {({ field }) => (
                      <ModalTextArea>
                        <CustomTextArea
                          value={field.value}
                          onChange={(val) => setFieldValue(field.name, val)}
                          error={errors[field.name]}
                        />
                      </ModalTextArea>
                    )}
                  </Field>
                </FlexWrapper>
                <FlexWrapper>
                  <ButtonWrapper marginTop="2.4rem">
                    <CustomButton fontSize="1.4rem" onClick={closeModal} type="button">
                      {STRINGS.close}
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" green type="submit">
                      {STRINGS.save}
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default EditAboutMeModal;
