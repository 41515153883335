import React from 'react';

import { EditableInfoContainer, EditableInfoInput, EditableInfoIcon } from './components';

const CustomEditableInfo = ({ icon, value, onChange, placeholder }) => {
  return (
    <EditableInfoContainer>
      <EditableInfoIcon icon={icon} />
      <EditableInfoInput
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        disabled
      />
    </EditableInfoContainer>
  );
};

export default CustomEditableInfo;
