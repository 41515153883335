import Modal from 'react-modal';
import {
  ModalColumn, ModalMain, VideoSign, VideoWrapper
} from "../../components/add-exercise-modal.jsx/components";
import React from "react";
import {CloseIcon} from "../../components/media-modal/components";

const VideoPreview = ({ isOpen, setIsOpen, videoUrl, youtubeVideoId }) => {

  const customStyles = {
    overlay: {
      backgroundColor: '#000000bb',
      zIndex: 44444444,
    },
    content: {
      backgroundColor: 'transparent',
      zIndex: '255',
      top: '50%',
      left: '50%',
      width: '70%',
      right: 'auto',
      justifyContent: 'center',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: '#001212',
      borderRadius: '8px',
      border: 'none',
      padding: '0 ',
    },
  };

  return (
      <ModalMain>
        {console.log('url', videoUrl)}
        <Modal
            onRequestClose={setIsOpen}
            isOpen={isOpen}
            style={customStyles}
            contentLabel="Crop Image"
            ariaHideApp={false}
        >
          <CloseIcon onClick={setIsOpen} />
          <VideoWrapper black={true}>
            {youtubeVideoId ? (
                <iframe
                    title={'test'}
                    width="100%"
                    height="500px"
                    src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>) :                       <>
              <video
                  autoPlay
                 controls={true}
                  style={{
                    maxWidth: '80vw',
                    maxHeight: '90vh',
                    width: '100%',
                    height: 'auto',
                    display: 'block',
                  }}
              >
                <source src={videoUrl} />
              </video>
              {/*<VideoSign />*/}
            </>}
          </VideoWrapper>
        </Modal>
      </ModalMain>
  )
};

export default VideoPreview;
