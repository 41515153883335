import React, { useCallback, useContext, useMemo } from 'react';
import ProgramRequestRow from './ProgramRequestRow';
import { PlaceholderText, PlaceholderContainer, PlaceholderIcon } from './components';
import icons from '../../constants/icons';
import { useHistory } from 'react-router-dom';
import { OrganizationContext } from '../../services/organizationContext';

const Placeholder = ({ title, icon }) => (
  <PlaceholderContainer>
    {!!icon && <PlaceholderIcon src={icon} alt="" />}
    <PlaceholderText>{title}</PlaceholderText>
  </PlaceholderContainer>
);

const ProgramList = ({ items, status, isLoading, openRequest }) => {
  const noItemsText = useMemo(() => {
    return status
      ? `You don't have any ${status.toLowerCase()} requests`
      : "You don't have any requests yet";
  }, [status]);

  const history = useHistory();
  const { isOrganization } = useContext(OrganizationContext);

  const handleClick = useCallback(
    (item) => {
      if (item.status === 'APPROVED' && !isOrganization) {
        if (item?.type === 'GROUP') {
          history.push(`/program/${item?.coachProgram?.group?.uid}?group=true`);
        } else {
          history.push(`/program/${item.uid}`);
        }
      } else {
        openRequest(item);
      }
    },
    [openRequest, history, isOrganization],
  );

  if (isLoading) {
    return <Placeholder title="Loading" />;
  }

  if (!items?.length) {
    return <Placeholder title={noItemsText} icon={icons.EmptyIcon} />;
  }
  return items?.map((item) => (
    <ProgramRequestRow key={item.uid} request={item} openRequest={() => handleClick(item)} />
  ));
};

export default ProgramList;
