import React from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import SearchIconPng from '../../assets/input/search-icon.png';
import AttachmentIcon from '../../assets/chat/chat-attachment.svg';
import SendIcon from '../../assets/chat/chat-send-button.svg';

export const ChatContainer = styled.div`
  border: 0.5px solid rgba(5, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #fefefe;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  display: flex;
  min-height: 70rem;
  max-height: 80vh;
`;

export const ChatColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  flex: ${({ grow }) => grow};
  flex-shrink: 0;

  &:first-child {
    border-radius: border-radius: 8px 0 0 8px;
    border-right: 0.5px solid rgba(5,0,0,0.12);
  }

  &:last-child {
    border-radius: border-radius: 0 8px 8px 0;
    border-left: 0.5px solid rgba(5,0,0,0.12);
  }
`;

export const ConversationsHeader = styled.div`
  height: 6.45rem;
  display: flex;
  align-items: center;
  padding-left: 2.4rem;
  border-bottom: 1px solid rgba(5, 0, 0, 0.12);
  flex-shrink: 0;
`;

export const ConversationsHeaderText = styled.span`
  opacity: 0.7;
  color: #373333;
  font-family: 'Gotham-r';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
`;

export const ChatDetailsHeader = styled.div`
  height: 6.45rem;
  display: flex;
  align-items: center;
  padding-left: 2.4rem;
`;

export const ChatDetailsHeaderText = styled.span`
  opacity: 0.4;
  color: #050000;
  font-family: 'Gotham-r';
  font-size: 1.4rem;
  letter-spacing: 0.088rem;
  line-height: 2.4rem;
`;

export const SearchWrapper = styled.div`
  height: 6.7rem;
  display: flex;
  align-items: center;
  padding-left: 2.4rem;
  border-bottom: 1px solid rgba(5, 0, 0, 0.12);
  flex-shrink: 0;
`;

export const SearchIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${SearchIconPng});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const SearchInput = styled.input`
  opacity: 0.8;
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  border: none;
  margin: 0 2.4rem 0 2rem;
  display: flex;
  flex-grow: 1;
  background-color: transparent;
  &::placeholder {
    opacity: 0.4;
  }
  &:focus {
    outline: none;
  }
`;

export const ConversationsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: ${({ isMobile }) => isMobile && '12px 0'};
`;

export const ConversationItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.6rem 2.4rem;
  height: 6rem;
  background-color: #fcfcfc;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  border: ${({ isActive }) => (isActive ? '1px solid #00FF91' : undefined)};
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 68px;
      margin: 4px 16px;
      background-color: rgba(168, 166, 166, 0.08);
      border-radius: 8px;
    `}
`;

export const ConversationItemColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConversationItemIcon = styled.div`
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 8px;
  margin: 1.2rem;
  background-image: ${({ icon }) => (icon ? `url(${icon})` : undefined)};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ConversationItemName = styled.span`
  color: #050000;
  font-family: Roboto-m;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24rem;
`;

export const ConversationItemLastMessage = styled.span`
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24rem;
`;

export const ConversationItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ConversationItemTime = styled.span`
  opacity: 0.4;
  color: #312e34;
  font-family: Roboto-r;
  font-size: 0.9rem;
  letter-spacing: 0;
  line-height: 1.1rem;
  text-align: right;
`;

export const ConversationItemUnreadIndicator = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 0.6rem;
  border: 2px solid #ffffff;
  background-color: #f50a4f;
  margin-top: 0.3rem;
`;

export const BorderIndicator = styled.div`
  width: 3px;
  border-radius: 0 100px 100px 0;
  background-color: #00ff91;

  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
`;

export const Avatar = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 8px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: transparent;
  font-family: 'Gotham-r';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-shrink: 0;

  ${({ src }) =>
    !src &&
    css`
      background-color: #f1f0f0;
      color: #373333;
    `}
`;
console.log('navigator.userAgent', navigator.userAgent);

export const AvatarName = styled.span`
  color: #050000;
  font-family: Roboto-m;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  margin-left: 2rem;
`;

export const MessageListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 1.2rem;
  background-color: #fafafa;
  position: relative;
  max-height: calc(100vh - 140px);
  height: calc(100% - 9.65rem - 60px);

  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 3.3rem 0 2.4rem;
  margin-top: 1.2rem;
`;

export const MessageContent = styled.div`
  display: flex;
  max-width: 70%;
  align-self: ${({ rtl }) => (rtl ? 'flex-end' : 'flex-start')};
  position: relative;
  min-height: 3.6rem;
`;

export const MessageText = styled.span`
  color: #373333;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
  border-radius: 12px;
  background-color: #f1f0f0;
  padding: 0.8rem 1.2rem;
  margin-left: ${({ isOwned }) => !isOwned && '4.8rem'};
  word-wrap: break-word;
  max-width: 100%;
  border-top-left-radius: ${({ hasAvatar, isOwned }) => (hasAvatar && !isOwned ? 2 : 12)}px;
  border-bottom-right-radius: ${({ isLast, isOwned }) => (isLast && isOwned ? 2 : 12)}px;
  ${({ isOwned }) =>
    !!isOwned &&
    css`
      background-color: #00ff9166;
      color: #050000;
    `}
  ${({ flex }) =>
    !!flex &&
    css`
      display: flex;
      align-items: center;
    `}
`;

export const ChatFooter = styled.div`
  display: flex;
  border-top: 1px solid rgba(5, 0, 0, 0.12);
  min-height: ${({ isMobile }) => (isMobile ? '70px' : '9.65rem')};
  flex-shrink: 0;
  padding: ${({ isMobile }) => (isMobile ? '14px 0' : '2.4rem 0')};
`;

export const AttachmentButtonIcon = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  margin-top: auto;
  margin-bottom: 0.8rem;
  border-radius: 8px;
  background-image: url(${AttachmentIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 2.4rem;
  cursor: pointer;
`;

export const SendButton = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  background-image: url(${SendIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 3.2rem;
  margin-top: auto;
  cursor: pointer;
`;

export const MessageInputContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${({ isMobile }) => (isMobile ? '1.4rem' : '2.4rem')};
  margin-right: 2rem;
`;

export const MessageSentAt = styled.div`
  opacity: 0.4;
  color: #312e34;
  font-family: Roboto-r;
  font-size: 0.9rem;
  letter-spacing: 0;
  line-height: 1.1rem;
  padding-top: 0.4rem;
`;

export const MessageVideo = styled.video`
  max-width: 100%;
  max-height: 33rem;
  border-radius: 12px;
`;

export const MessageImage = styled.img`
  max-width: 100%;
  max-height: 33rem;
  border-radius: 12px;
`;

export const Loading = styled.div`
  display: flex;
  flex: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const LoadingText = styled.span`
  color: #373333;
  font-family: Roboto-r;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.1rem;
`;

export const SendingIndicator = styled.span`
  color: #373333;
  font-family: Roboto-r;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.1rem;
`;

export const FetchingIndicator = styled.div`
  display: flex;
  align-self: center;
  top: 8px;
  background-color: white;
  border-radius: 1.9rem;
  padding: 0.8rem 1.6rem;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.2);
  position: absolute;
  color: #373333;
  font-family: Roboto-l;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 1.1rem;
`;

export const ChatUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid rgba(5, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }
`;

export const ChatInfoRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ mt }) => mt};

  &:last-child {
    margin-bottom: 2.2rem;
  }
`;

export const ChatUserName = styled.span`
  color: #4a4a4a;
  font-family: 'Gotham-r';
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
`;

const ChatInfoMetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4.5rem;
  &:last-child {
    margin-right: 0;
  }
`;
const ChatInfoMetaRow = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 0.8rem;
  &:last-child {
    margin-bottom: 0;
  }
`;
const ChatInfoMetaIcon = styled.img`
  margin-right: 6px;
`;
const ChatInfoMetaName = styled.span`
  opacity: 0.4;
  color: #050000;
  font-family: Roboto-r;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6rem;
`;

export const ChatInfoMeta = ({ icon, name, value }) => (
  <ChatInfoMetaContainer>
    <ChatInfoMetaRow>
      <ChatInfoMetaIcon src={icon} alt="" />
      <ChatUserName>{value}</ChatUserName>
    </ChatInfoMetaRow>
    <ChatInfoMetaRow>
      <ChatInfoMetaName>{name}</ChatInfoMetaName>
    </ChatInfoMetaRow>
  </ChatInfoMetaContainer>
);

export const RequestWrapper = styled.div`
  padding: 0 24px;
  margin-top: -2.2rem;
`;

export const ParticipantContainer = styled.div`
  margin: 0 0 2.4rem 2.2rem;
  display: flex;
`;

export const PartName = styled.span`
  color: #373333;
  font-family: Roboto-r;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.1rem;
`;

export const PartNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  resize: none;
  border-radius: 1.2rem;
  padding: 1.2rem;
  outline: none;
  border: none;
  background-color: rgb(247, 246, 246);
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: Roboto-r;
`;

export const MobileList = styled.div`
  overflow-y: auto;
  height: calc(100vh - 6.7rem - 60px);
`;

export const Paper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const MessageHeaderContainer = styled.div`
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid #0000001a;
`;

export const MessageHeaderText = styled.div`
  font-family: Space-r;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: left;
  color: #050000;
`;

export const HeaderInfo = styled.div`
  display: flex;
  width: calc(100% - 56px);
`;

export const InfoCol = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: calc(100% - 56px);
  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `}
  margin-left: ${({ ml }) => ml};
`;

export const InfoTitle = styled.span`
  font-family: Roboto-b;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #050000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoSubTitle = styled.span`
  font-family: Roboto-r;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #050000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
