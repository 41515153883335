import styled from 'styled-components';

export const CallContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const CallContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const BeforeCallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReadyText = styled.div`
  line-height: 4.8rem;
  font-family: Gotham-r;
  font-size: 2rem;
  color: #373333;
  letter-spacing: 0.2rem;
  margin-bottom: 3.2rem;
`;
