export const OneSignal = window.OneSignal || [];

if (OneSignal.installServiceWorker) {
  OneSignal.installServiceWorker();
} else if (navigator.serviceWorker) {
  navigator.serviceWorker.register(
    `/OneSignalSDKWorker.js?appId=${process.env.REACT_APP_SIGNAL_KEY}`,
  );
}

const init = () => {
  try {
    OneSignal.init({
      appId: process.env.REACT_APP_SIGNAL_KEY,
      allowLocalhostAsSecureOrigin: true,
      autoResubscribe: true,
    });
  } catch (err) {
    console.log("Can't initialize one signal");
    console.log(err);
  }
};

init();
