import React from 'react';

import { WorkoutBlockType } from '../../constants/enums';
import ICONS from '../../constants/icons';
import AddBlockCommentTooltip from './AddBlockCommentTooltip';
import BlockActionsStack from './BlockActionsStack';

import {
  WorkoutBlockForm,
  WorkoutBlockHeader,
  WorkoutBlockContent,
  WorkoutBlockAdditionalAttr,
  WorkoutBlockAdditionalAttrWrapper,
  WorkoutBlockHeaderIconsWrapper,
  WorkoutBlockHeaderIcon,
  WorkoutBlockOrderArrow,
  WorkoutBlockComment,
  WeekBlockFooter,
} from './components';
import WorkoutBlockTable from './WorkoutBlockTable';

const WorkoutBlock = ({
  block,
  mobile,
  setEditting,
  deleteSelf,
  orderDown,
  orderUp,
  isFirst,
  isLast,
  immutable,
  duplicateSelf,
  week,
  nonDeletable,
}) => {
  return (
    <WorkoutBlockForm week={week} mobile={mobile}>
      <WorkoutBlockHeader week={week} mobile={mobile}>
        <WorkoutBlockHeaderIconsWrapper>
          {!immutable && !week && (
            <>
              <WorkoutBlockOrderArrow onClick={orderUp} nonClickable={isFirst} />
              <WorkoutBlockOrderArrow onClick={orderDown} down nonClickable={isLast} />
            </>
          )}
          {week && !mobile && (
            <WorkoutBlockHeaderIcon
              move
              style={{ padding: '0 1.2rem 0 0', width: '1.6rem', height: '1.6rem' }}
            />
          )}
          {`${block?.attributes?.find((x) => x.name === 'block_title')?.value || block?.name} ${
            (block?.type === WorkoutBlockType.STRENGTH &&
              !!block?.exercises?.length &&
              `- ${block?.exercises?.[0].exercise?.title}`) ||
            ''
          }`}
        </WorkoutBlockHeaderIconsWrapper>

        {!immutable && (!week || mobile) && (
          <WorkoutBlockHeaderIconsWrapper>
            {!mobile && <WorkoutBlockHeaderIcon onClick={() => duplicateSelf(block)} duplicate />}
            <WorkoutBlockHeaderIcon onClick={setEditting} edit />
            {!nonDeletable && <WorkoutBlockHeaderIcon onClick={deleteSelf} />}
          </WorkoutBlockHeaderIconsWrapper>
        )}
        {week && !mobile && (
          <WorkoutBlockHeaderIconsWrapper>
            <WorkoutBlockHeaderIcon
              onClick={setEditting}
              style={{
                backgroundImage: `url(${ICONS.EditWeekBlock})`,
                width: '2.2rem',
                height: '2.2rem',
              }}
            />
            <BlockActionsStack
              actions={[
                { title: 'Delete block', icon: ICONS.BinSimpleGrey, action: deleteSelf },
                { title: 'Duplicate block', icon: ICONS.Copy, action: () => duplicateSelf(block) },
              ]}
            />
          </WorkoutBlockHeaderIconsWrapper>
        )}
      </WorkoutBlockHeader>
      <WorkoutBlockContent style={{ paddingTop: week ? '1.2rem' : '3.2rem' }} week={week}>
        <WorkoutBlockTable block={block} week={week} />
        {!week && block?.comment && <WorkoutBlockComment>{block?.comment}</WorkoutBlockComment>}
        {(block?.videoRequested || block?.isWarmUp) && (
          <WorkoutBlockAdditionalAttrWrapper week={week}>
            <div>
              {block?.videoRequested && (
                <WorkoutBlockAdditionalAttr week={week}>Video Requested</WorkoutBlockAdditionalAttr>
              )}
              {block?.isWarmUp && (
                <WorkoutBlockAdditionalAttr week={week}>Warmup</WorkoutBlockAdditionalAttr>
              )}
            </div>
          </WorkoutBlockAdditionalAttrWrapper>
        )}
      </WorkoutBlockContent>
      {week && block?.comment && (
        <WeekBlockFooter>
          <AddBlockCommentTooltip preview initialValue={block?.comment} onSubmit={() => {}} />
        </WeekBlockFooter>
      )}
    </WorkoutBlockForm>
  );
};

export default WorkoutBlock;
