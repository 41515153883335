import React from 'react';
import CustomButton from '../button/CustomButton';
import CustomTooltip from '../tooltip/CustomTooltip';
import {
  CalendarDayPlusIcon,
  TooltipBody,
  TooltipHeader,
  TooltipHeaderIcon,
  TooltipPrimaryContent,
  TooltipSecondaryContent,
} from './components';

const SecondaryContentTooltip = ({
  primaryContent,
  secondaryContent,
  onEditClick = () => {},
  onDeleteClick = () => {},
  hideActions,
}) => (
  <CustomTooltip
    style={{
      width: '16rem',
      height: 'fit-content',
      left: '50%',
      marginLeft: `-8rem`,
      bottom: '100%',
      background: '#FAFAFA',
      boxShadow: '0px 2px 4px #d0d0c8',
      padding: '0.8rem 0.6rem 2.4rem',
    }}
  >
    {!hideActions && (
      <TooltipHeader>
        <TooltipHeaderIcon edit onClick={onEditClick} />
        <TooltipHeaderIcon onClick={onDeleteClick} />
      </TooltipHeader>
    )}
    <TooltipBody>
      <TooltipPrimaryContent>{primaryContent}</TooltipPrimaryContent>
      <TooltipSecondaryContent>{secondaryContent}</TooltipSecondaryContent>
    </TooltipBody>
  </CustomTooltip>
);

export default SecondaryContentTooltip;
