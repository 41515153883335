import * as Yup from 'yup';

const templateName = Yup.string().required();
const programType = Yup.object()
  .shape({
    value: Yup.string().required(),
  })
  .required();
const templateLength = Yup.number().required();
const speciality = Yup.object().required();
const trainingTypes = Yup.array().of(programType).min(1);

export const TemplateSchema = Yup.object().shape({
  templateName,
  speciality,
  trainingTypes,
  templateLength,
});
