import styled from 'styled-components';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  line-height: 2.4rem;
  font-family: Space-r;
  padding: 2rem 3.6rem;
  letter-spacing: 0.08rem;
`;

export const ModalMain = styled.div`
  padding: ${({ padding }) => (padding ? padding : '3.6rem')};
`;

export const ModalColumn = styled.div`
  min-width: Calc(50% - 12px);
  flex-grow: 1;
  margin-right: 12px;
  margin-left: 12px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const ModalInput = styled.div`
  width: ${({ small }) => (small ? 'Calc(50% - 8px)' : '100%')};
  height: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Roboto-m;
  font-size: 1.4rem;
  color: #37333399;
  margin-bottom: 2.4rem;
`;

export const ModalTextArea = styled.div`
  max-height: 100%;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  width: Calc(50% - 12px);
  height: 4.8rem;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '1.6rem')};
`;
