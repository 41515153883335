import {
  getProgramQuery,
  getCoachGroupQuery,
  editCoachProgramMutation,
  cancelParticipantMutation,
} from '@witness/graphql';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { useState, useEffect, useCallback, useMemo } from 'react';
import qs from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';

const useProgram = ({ programId } = {}) => {
  const location = useLocation();
  const history = useHistory();

  const { defaultTab, isGroup, urlProgramDay, urlTraineeUid } = useMemo(() => {
    const queryData = qs.parse(location.search.substr(1));
    return {
      defaultTab: queryData.tab || 'program',
      isGroup: queryData.group === 'true',
      urlProgramDay: queryData.day,
      urlTraineeUid: queryData.trainee_id,
    };
  }, [location.search]);

  const [programOrGroup, setProgramOrGroup] = useState(null);

  const {
    data: program,
    loading: programLoading,
    refetch: refetchProgram,
  } = useQuery(getProgramQuery, {
    variables: {
      record: {
        uid: programId || location.pathname.split('?')[0].split('/')[2],
      },
    },
    skip: isGroup || !(programId || location.pathname.split('?')[0].split('/')[2]),
    fetchPolicy: 'no-cache',
    onCompleted: (a) => {
      setProgramOrGroup(a.getProgram);
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: group,
    loading: groupLoading,
    refetch: refetchGroup,
  } = useQuery(getCoachGroupQuery, {
    variables: {
      record: {
        uid: location.pathname.split('?')[0].split('/')[2],
      },
    },
    skip: !isGroup,
    fetchPolicy: 'no-cache',
    onCompleted: (a) => {
      setProgramOrGroup(a.getGroup);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      program?.getProgram?.type === 'GROUP' &&
      location.pathname.startsWith('/program') &&
      location.pathname !== `/program/${program.getProgram.code.group.uid}?group=true`
    ) {
      history.push(`/program/${program.getProgram.code.group.uid}?group=true`);
    }
  }, [program, history, location]);

  useEffect(() => {
    if (isGroup && group) {
      setProgramOrGroup(group.getGroup);
    }

    if (!isGroup && program) {
      setProgramOrGroup(program.getProgram);
    }
  }, [isGroup, group, program]);

  const refetchProgramOrGroup = useCallback(() => {
    if (program) {
      refetchProgram();
    } else {
      refetchGroup();
    }
  }, [program, refetchGroup, refetchProgram]);

  const [editCoachProgram] = useMutation(editCoachProgramMutation);
  const handleCoachProgramUpdate = useCallback(
    async (date) => {
      try {
        if (
          date &&
          date !== programOrGroup?.coachProgram?.beginningDate &&
          programOrGroup?.coachProgram?.uid
        ) {
          await editCoachProgram({
            variables: {
              record: {
                uid: programOrGroup?.coachProgram?.uid,
                beginningDate: new Date(date),
              },
            },
          });
          refetchProgramOrGroup();
        }
      } catch (err) {
        console.log('did not updated', err);
      }
    },
    [editCoachProgram, programOrGroup, refetchProgramOrGroup],
  );

  const apolloClient = useApolloClient();
  const updateCalendarCache = useCallback(
    (newCalendar) => {
      if (program) {
        apolloClient.writeQuery({
          query: getProgramQuery,
          variables: {
            record: {
              uid: location.pathname.split('?')[0].split('/')[2],
            },
          },
          data: {
            ...program,
            getProgram: {
              ...program.getProgram,
              coachProgram: {
                ...program.getProgram.coachProgram,
                calendar: newCalendar,
              },
            },
          },
        });
      } else {
        apolloClient.writeQuery({
          query: getCoachGroupQuery,
          variables: {
            record: {
              uid: location.pathname.split('?')[0].split('/')[2],
            },
          },
          data: {
            ...group,
            getGroup: {
              ...group.getGroup,
              coachProgram: {
                ...group.getGroup.coachProgram,
                calendar: newCalendar,
              },
            },
          },
        });
      }
    },
    [apolloClient, program, group, location.pathname],
  );

  const [cancelParticipant] = useMutation(cancelParticipantMutation);

  const cancelParticipantFromGroup = async (traineeUid, coachProgramUid) => {
    try {
      await cancelParticipant({
        variables: {
          record: {
            traineeUid,
            coachProgramUid,
          },
        },
      });
      refetchProgramOrGroup();
    } catch (err) {
      console.log();
    }
  };

  return {
    program: programOrGroup,
    programLoading: programLoading || groupLoading,
    isGroup,
    defaultTab,
    urlProgramDay,
    urlTraineeUid,
    refetch: refetchProgramOrGroup,
    handleCoachProgramUpdate,
    updateCalendarCache,
    cancelParticipantFromGroup,
  };
};

export default useProgram;
