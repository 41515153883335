import {Field} from 'formik';
import React, {useContext} from 'react';
import {BlockExerciseUnit, BlockExerciseWeightType, WorkoutBlockType,} from '../../constants/enums';
import {MobileContext} from '../../services/MobileContext';
import CustomInputClock from '../input/CustomInputClock';
import CustomInputDropdown from '../input/CustomInputDropdown';
import CustomInputNumber from '../input/CustomInputNumber';
import {
  DeleteExerciseIcon,
  ExerciseContent,
  ExerciseIndex,
  ExerciseLabel,
  ExerciseOpenButton,
  ExerciseTitle,
  ExerciseWrapper,
  WorkoutBlockHeaderIcon,
  WorkoutBlockOrderArrow,
} from './components';
import {FieldTitle} from "../add-exercise-modal.jsx/components";
import CustomTextArea from "../input/CustomTextArea";

const Exercise = ({
  block,
  i,
  index,
  ex,
  swapExercises,
  setExerciseAdding,
  setFieldValue,
  touched,
  errors,
  remove,
  week,
  setDraggingBlock,
  draggingEx,
  setDraggingEx,
  draggingOverEx,
  setDraggingOverEx,
  move,
}) => {
  const { isMobile } = useContext(MobileContext);
  return (
      <>
    <ExerciseWrapper
      key={i}
      week={week}
      beingDragged={draggingEx === i}
      draggingOver={draggingOverEx === i}
      onDragOver={(e) => {
        if (typeof draggingEx === 'number') {
          e.preventDefault();
          setDraggingOverEx(i);
        }
      }}
      onDragLeave={() => setDraggingOverEx(null)}
      onDrop={() => {
        move(draggingEx, draggingOverEx >= draggingEx ? draggingOverEx - 1 : draggingOverEx);
        setDraggingOverEx(null);
        setDraggingEx(null);
      }}
    >
      <ExerciseLabel
        draggable={!isMobile}
        week={week}
        onDrag={() => {
          setDraggingBlock(null);
          setDraggingEx(i);
        }}
        onDragEnd={() => {
          setDraggingEx(null);
        }}
      >
        <ExerciseIndex
          transparent={block?.type === WorkoutBlockType.STRENGTH}
          hide={block?.type === WorkoutBlockType.STRENGTH && week}
        >
          {i + 1}
          {week && !isMobile && (
            <WorkoutBlockHeaderIcon move style={{ width: '1.6rem', height: '1.6rem' }} />
          )}
        </ExerciseIndex>
        {!week && (
          <>
            <WorkoutBlockOrderArrow
              onClick={() => swapExercises(index, i, i - 1)}
              small
              nonClickable={i === 0}
            />
            <WorkoutBlockOrderArrow
              down
              small
              onClick={() => swapExercises(index, i, i + 1)}
              nonClickable={i === block.exercises.length - 1}
            />
          </>
        )}
        <ExerciseTitle
          onClick={() =>
            setExerciseAdding({
              ...ex?.exercise,
              changeCallback: (v) => setFieldValue(`blocks[${index}].exercises[${i}].exercise`, v),
            })
          }
        >
          {(block?.type !== WorkoutBlockType.STRENGTH && (ex?.exercise?.title || 'undefined')) ||
            `Set ${i + 1}`}
        </ExerciseTitle>
        {!isMobile && (
          <ExerciseOpenButton
            onClick={() =>
              setExerciseAdding({
                ...ex?.exercise,
                changeCallback: (v) =>
                  setFieldValue(`blocks[${index}].exercises[${i}].exercise`, v),
              })
            }
          />
        )}
        {!isMobile && block?.type !== WorkoutBlockType.STRENGTH && week && (
          <ExerciseOpenButton onClick={() => remove(i)} bin />
        )}
      </ExerciseLabel>
      <ExerciseContent week={week}>
        {block?.type === WorkoutBlockType.CLASSICSTRENGTH && (
          <Field
            id={`blocks[${index}].exercises[${i}].numberOfSets`}
            name={`blocks[${index}].exercises[${i}].numberOfSets`}
          >
            {({ field }) => (
              <CustomInputNumber
                arrows
                upperlabel="sets"
                style={{
                  width: week ? 'Calc(40% - 0.4rem)' : '6.4rem',
                  minHeight: week ? '3.2rem' : '4rem',
                  fontSize: week ? '1.4rem' : '1.6rem',
                  background: '#fff',
                  margin: 0,
                  marginTop: '0.8rem',
                  marginBottom: week ? '0.2rem' : '0.8rem',
                  height: week ? '3.2rem' : '4rem',
                  marginRight: week ? '0' : '0.8rem',
                  pointerEvents: block?.type === WorkoutBlockType.INTERVAL ? 'none' : 'all',
                  opacity: block?.type === WorkoutBlockType.INTERVAL ? 0.4 : 1,
                }}
                arrowStyles={{
                  marginRight: '0.6rem',
                  maxWidth: '13px',
                }}
                borderRadius="8px"
                onChange={(v) => setFieldValue(field.name, Number(v))}
                value={field.value}
                onDecrement={() => setFieldValue(field.name, Number(field.value - 1))}
                onIncriment={() => setFieldValue(field.name, Number(field.value + 1))}
                error={
                  touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.quantity
                }
              />
            )}
          </Field>
        )}

        {(block?.type === WorkoutBlockType.PILATESR) && (
            <div style={{display: "flex", flexDirection: 'column', justifyContent: "space-between", width: "90%"}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                <div style={{flex: 1}}>
                <FieldTitle>Setting</FieldTitle>
                </div>
                <div style={{flex: 5}}>
               <Field
                    id={`blocks[${index}].exercises[${i}].setting`}
                    name={`blocks[${index}].exercises[${i}].setting`}
                >
                  {({ field }) => (
                      <CustomTextArea
                          rowCount={null}
                          style={{
                            padding: '4px',
                            maxHeight: '150px',
                          }}
                          wrapperStyle={{
                            width: '100%',
                            minHeight: '10px',
                            margin: '4px',
                          }}
                          onChange={(v) => setFieldValue(field.name, v)}
                          value={field.value}
                          error={ touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.setting}
                      />
                  )}
                </Field>
                </div>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                <div style={{flex: 1}}>
                  <FieldTitle>Reps</FieldTitle>
                </div>
                <div style={{flex: 5}}>
                  <Field
                      id={`blocks[${index}].exercises[${i}].reps`}
                      name={`blocks[${index}].exercises[${i}].reps`}
                  >
                    {({ field }) => (
                        <CustomTextArea
                            rowCount={0}
                            style={{
                              padding: '4px',
                              maxHeight: '150px',
                            }}
                            wrapperStyle={{
                              width: '100%',
                              margin: '4px',
                            }}
                            onChange={(v) => setFieldValue(field.name, v)}
                            value={field.value}
                            error={ touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.reps}
                        />
                    )}
                  </Field>
                </div>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                <div style={{flex: 1}}>
                  <FieldTitle>Notes</FieldTitle>
                </div>
                <div style={{flex: 5}}>
                  <Field
                      id={`blocks[${index}].exercises[${i}].notes`}
                      name={`blocks[${index}].exercises[${i}].notes`}
                  >
                    {({ field }) => (
                        <CustomTextArea
                            rowCount={0}
                            style={{
                              padding: '4px',
                              maxHeight: '150px',
                            }}
                            wrapperStyle={{
                              width: '100%',
                              margin: '4px',
                            }}
                            onChange={(v) => setFieldValue(field.name, v)}
                            value={field.value}
                            error={ touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.notes}
                        />
                    )}
                  </Field>
                </div>
              </div>

            </div>
            )}

        {block?.type !== WorkoutBlockType.PILATESR && <Field
          id={`blocks[${index}].exercises[${i}].quantity`}
          name={`blocks[${index}].exercises[${i}].quantity`}
        >
          {({ field }) => (
            <CustomInputNumber
              arrows
              upperlabel={block?.type === WorkoutBlockType.CLASSICSTRENGTH ? 'reps' : 'quantity'}
              style={{
                width: week
                  ? block?.type === WorkoutBlockType.CLASSICSTRENGTH
                    ? 'Calc(60% - 0.4rem)'
                    : 'Calc(40% - 0.4rem)'
                  : '6.4rem',
                minHeight: week ? '3.2rem' : '4rem',
                fontSize: week ? '1.4rem' : '1.6rem',
                background: '#fff',
                margin: 0,
                marginTop: '0.8rem',
                marginBottom: week ? '0.2rem' : '0.8rem',
                height: week ? '3.2rem' : '4rem',
                marginRight: week ? '0' : '0.8rem',
                pointerEvents: block?.type === WorkoutBlockType.INTERVAL ? 'none' : 'all',
                opacity: block?.type === WorkoutBlockType.INTERVAL ? 0.4 : 1,
              }}
              arrowStyles={{
                marginRight: '0.6rem',
                maxWidth: '13px',
              }}
              borderRadius="8px"
              onChange={(v) => setFieldValue(field.name, Number(v))}
              value={field.value}
              onDecrement={() => setFieldValue(field.name, Number(field.value - 1))}
              onIncriment={() => setFieldValue(field.name, Number(field.value + 1))}
              error={
                touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.quantity
              }
            />
          )}
        </Field>}
        {block?.type !== WorkoutBlockType.CLASSICSTRENGTH && block?.type !== WorkoutBlockType.PILATESR && (
          <Field
            id={`blocks[${index}].exercises[${i}].unit`}
            name={`blocks[${index}].exercises[${i}].unit`}
          >
            {({ field }) => (
              <CustomInputDropdown
                style={{
                  width: week ? 'Calc(60% - 0.4rem)' : '10.6rem',
                  minHeight: week ? '3.2rem' : '4rem',
                  fontSize: week ? '1.4rem' : '1.6rem',
                  background: '#fff',
                  margin: 0,
                  marginTop: '0.8rem',
                  marginBottom: week ? '0.2rem' : '0.8rem',
                  marginRight: week ? '0' : '0.8rem',
                  height: week ? '3.2rem' : '4rem',
                  textTransform: 'lowercase',
                  pointerEvents: block?.type === WorkoutBlockType.INTERVAL ? 'none' : 'all',
                  opacity:
                    block?.type === WorkoutBlockType.INTERVAL ||
                    (block?.type === WorkoutBlockType.CLASSICSTRENGTH && week)
                      ? 0.4
                      : 1,
                }}
                disabled={
                  block?.type === WorkoutBlockType.INTERVAL ||
                  block?.type === WorkoutBlockType.CLASSICSTRENGTH
                }
                menuStyles={{
                  maxHeight: 'fit-content',
                }}
                valueStyles={{
                  padding: '0 1rem',
                }}
                arrowStyles={{
                  right: '0.8rem',
                }}
                borderRadius="8px"
                options={[
                  ...((block.type === WorkoutBlockType.RUN && []) || [BlockExerciseUnit.REPS]),
                  BlockExerciseUnit.SECONDS,
                  BlockExerciseUnit.METERS,
                  BlockExerciseUnit.MINUTES,
                  BlockExerciseUnit.KILOMETERS,
                  BlockExerciseUnit.CALORIES,
                ]}
                onChange={(v) => setFieldValue(field.name, v)}
                value={field.value}
                placeholder="-unit-"
                error={touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.unit}
              />
            )}
          </Field>
        )}
        {block?.type !== WorkoutBlockType.PILATESR && (<Field
          id={`blocks[${index}].exercises[${i}].weight`}
          name={`blocks[${index}].exercises[${i}].weight`}
          key={i}
        >
          {({ field }) => (
            // <ExerciseWeight
            //   style={{
            //     flexGrow: 0,
            //   }}
            // >
            <CustomInputNumber
              arrows
              upperlabel={block.type === WorkoutBlockType.RUN ? 'pace' : 'weight'}
              style={{
                width: week ? 'Calc(40% - 0.4rem)' : '6.4rem',
                minHeight: week ? '3.2rem' : '4rem',
                fontSize: week ? '1.4rem' : '1.6rem',
                background: '#fff',
                margin: 0,
                marginTop: '0.8rem',
                marginBottom: week ? '0.2rem' : '0.8rem',
                marginRight: week ? '0' : '0.8rem',
                height: week ? '3.2rem' : '4rem',
              }}
              arrowStyles={{
                marginRight: '0.6rem',
                maxWidth: '13px',
              }}
              borderRadius="8px"
              onChange={(v) => setFieldValue(field.name, Number(v))}
              value={field.value}
              onDecrement={() => setFieldValue(field.name, Number(field.value - 1))}
              onIncriment={() => setFieldValue(field.name, Number(field.value + 1))}
              error={touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.weight}
            />
            // </ExerciseWeight>
          )}
        </Field>)}
        {block?.type !== WorkoutBlockType.PILATESR && (<Field
          id={`blocks[${index}].exercises[${i}].weightType`}
          name={`blocks[${index}].exercises[${i}].weightType`}
        >
          {({ field }) => (
            <CustomInputDropdown
              style={{
                width: week
                  ? 'Calc(60% - 0.4rem)'
                  : block?.type === WorkoutBlockType.CLASSICSTRENGTH
                  ? '5.2rem'
                  : '8rem',
                minHeight: week ? '3.2rem' : '4rem',
                fontSize: week ? '1.4rem' : '1.6rem',
                background: '#fff',
                margin: 0,
                marginTop: '0.8rem',
                marginBottom: week ? '0.2rem' : '0.8rem',
                height: week ? '3.2rem' : '4rem',
                textTransform: 'lowercase',
              }}
              valueStyles={{
                padding: '0 0.8rem',
              }}
              arrowStyles={{
                right: '0.6rem',
              }}
              borderRadius="8px"
              options={[
                {
                  value: BlockExerciseWeightType.KG,
                  label: block.type === WorkoutBlockType.RUN ? 'km/h' : 'kg',
                },
                {
                  value: BlockExerciseWeightType.PERCENTAGE,
                  label: block.type === WorkoutBlockType.RUN ? 'min/km' : '%',
                },
              ]}
              onChange={(v) => setFieldValue(field.name, v)}
              value={
                [
                  {
                    value: BlockExerciseWeightType.KG,
                    label: block.type === WorkoutBlockType.RUN ? 'km/h' : 'kg',
                  },
                  {
                    value: BlockExerciseWeightType.PERCENTAGE,
                    label: block.type === WorkoutBlockType.RUN ? 'min/km' : '%',
                  },
                ].find((e) => e.value === field.value).label
              }
              error={
                touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.weightType
              }
            />
          )}
        </Field>)}
        {block.type === WorkoutBlockType.CLASSICSTRENGTH && (
          <Field
            id={`blocks[${index}].exercises[${i}].restTime`}
            name={`blocks[${index}].exercises[${i}].restTime`}
          >
            {({ field }) => (
              <>
                <CustomInputClock
                  style={{
                    width: week ? '100%' : '7.2rem',
                    // minHeight: week ? '3.2rem' : '4rem',
                    background: '#fff',
                    margin: 0,
                    marginTop: '0.8rem',
                    marginBottom: week ? '0.2rem' : '0.8rem',
                    height: week ? '3.2rem' : '4rem',
                    marginRight: '0',
                    marginLeft: week ? '0' : '0.8rem',
                    padding: '0 0.6rem 0 0.9rem',
                  }}
                  upperlabel={'rest time'}
                  fontSize={'1.4rem'}
                  noArrows={!week}
                  small
                  height="4rem"
                  onChange={(v) => setFieldValue(field.name, v)}
                  value={
                    typeof field.value === 'string'
                      ? field?.value
                      : `00:${Math.floor(field.value / 60)}:${field.value % 60}`
                  }
                  error={
                    touched?.blocks?.[index] && errors?.blocks?.[index]?.attributes?.[i]?.value
                  }
                />
              </>
            )}
          </Field>
        )}
        {block?.type !== WorkoutBlockType.STRENGTH && !week && (
          <DeleteExerciseIcon onClick={() => remove(i)} />
        )}
      </ExerciseContent>
    </ExerciseWrapper>
        {block?.type === WorkoutBlockType.CLASSICSTRENGTH && <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
          <div style={{flex: 1}}>
            <FieldTitle>Notes</FieldTitle>
          </div>
          <div style={{flex: 5}}>
            <Field
                id={`blocks[${index}].exercises[${i}].notes`}
                name={`blocks[${index}].exercises[${i}].notes`}
            >
              {({ field }) => (
                  <CustomTextArea
                      rowCount={0}
                      style={{
                        padding: '4px',
                        maxHeight: '150px',
                      }}
                      wrapperStyle={{
                        width: '100%',
                        margin: '4px',
                      }}
                      onChange={(v) => setFieldValue(field.name, v)}
                      value={field.value}
                      error={ touched?.blocks?.[index] && !!errors?.blocks?.[index]?.exercises?.[i]?.notes}
                  />
              )}
            </Field>
          </div>
        </div>}
      </>
  );
};

export default Exercise;
