import React, { useMemo } from 'react';

import icons from '../../constants/icons';
import CustomButton from '../button/CustomButton';
import {
  Avatar,
  DumbellIcon,
  StatusIcon,
  TraineeInfoWrapper,
  TraineeName,
  WorkoutInfoWrapper,
  WorkoutNameWrapper,
} from './components';

const RespWorkoutInfo = ({
  data,
  status,
  name,
  time,
  avatarUrl,
  initials,
  onButtonClick,
  buttonText,
  location,
  workoutName,
}) => {
  const icon = useMemo(() => {
    if (time) {
      return location ? icons.LocationIcon : icons.VideoCamera;
    }

    return icons.dumbellSvg;
  }, [location, time]);

  return (
    <WorkoutInfoWrapper>
      <TraineeInfoWrapper>
        <Avatar src={avatarUrl} size="32px">
          {initials}
        </Avatar>

        <TraineeName>{name}</TraineeName>

        {!location ? (
          <CustomButton
            style={{ width: 81, height: 32, minHeight: 32 }}
            outlined
            backgroundColor="white"
            fontSize="14px"
            type="submit"
            outlineColor="#00FF91"
            hoverOutlineColor="#00FF91"
            borderRadius="8px"
            fontFamily="Roboto-r"
            onClick={() =>
              onButtonClick({
                day: data?.uid,
                name,
              })
            }
          >
            {buttonText}
          </CustomButton>
        ) : (
          <WorkoutNameWrapper>{location}</WorkoutNameWrapper>
        )}
      </TraineeInfoWrapper>

      <WorkoutNameWrapper>
        <DumbellIcon src={icon} alt="workout" />
        {workoutName}
      </WorkoutNameWrapper>

      <WorkoutNameWrapper>
        {time ? (
          time
        ) : (
          <>
            <StatusIcon status={status} /> {status}
          </>
        )}
      </WorkoutNameWrapper>
    </WorkoutInfoWrapper>
  );
};

export default RespWorkoutInfo;
