import styled from 'styled-components';

export const AvatarContainer = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  border: 1px solid transparent;
  border-color: ${({ isOnline }) => isOnline && '#00FF91'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarImage = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarCredentials = styled.span`
  color: #4a4a4a;
  font-family: 'Gotham-r';
  font-size: 3.2rem;
  letter-spacing: 0.2rem;
  line-height: 3.6rem;
`;

export const MobileAvatarContainer = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileAvatarImage = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #504d4d0f;
`;

export const MobileAvatarCredentials = styled.span`
  color: #4a4a4a;
  font-family: 'Gotham-r';
  font-size: 80%;
  line-height: 82%;
  letter-spacing: 2px;
`;
