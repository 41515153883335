import React, { useContext, useState } from 'react';
import { Field, FieldArray } from 'formik';

import CustomInput from '../input/CustomInput';
import CustomSearch from '../input/CustomSearch';
import CustomButton from '../button/CustomButton';
import CustomTextArea from '../input/CustomTextArea';
import CustomInputClock from '../input/CustomInputClock';
import CustomInputDropdown from '../input/CustomInputDropdown';
import AddExerciseModal from '../add-exercise-modal.jsx/AddExerciseModal';
import BlockActionsStack from './BlockActionsStack';
import {
  WorkoutBlockType,
  BlockExerciseUnit,
  BlockExerciseWeightType,
} from '../../constants/enums';

import { useExercise } from '../../hooks';

import {
  WorkoutBlockForm,
  WorkoutBlockHeader,
  WorkoutBlockContent,
  AttributeWrapper,
  AttributeBullet,
  AttributeLabel,
  WorkoutBlockFooter,
  WorkoutBlockApplyButton,
  AttrCheckBox,
  ExerciseWrapper,
  ExerciseIndex,
  ExerciseContent,
  WorkoutBlockHeaderIconsWrapper,
  WorkoutBlockHeaderIcon,
  DeleteExerciseIcon,
  FlexWrapper,
  AsNeeded,
  AsNeededCheck,
  WeekBlockFooter,
  ExercisesWrapper,
  ModalCloseX,
} from './components';
import Exercise from './Exercise';
import AddBlockCommentTooltip from './AddBlockCommentTooltip';
import ICONS from '../../constants/icons';
import { MobileContext } from '../../services/MobileContext';

const EdittingWorkoutBlock = ({
  block,
  index,
  setFieldValue,
  errors,
  touched,
  deleteSelf,
  setTouched,
  week,
  handleCancel,
  onSubmit,
  setDraggingBlock,
}) => {
  const { exercises, exercisesLoading, searchText, setSearchText, createNewExercise, loading } =
    useExercise();
  const [exerciseAdding, setExerciseAdding] = useState(null);

  const swapExercises = (blockIndex, x, y) => {
    const exercises = [...block.exercises];
    const tempExercise = exercises[x];
    exercises[x] = exercises[y];
    exercises[y] = tempExercise;
    setFieldValue(`blocks[${index}].exercises`, exercises);
  };
  const { isMobile } = useContext(MobileContext);
  const [draggingEx, setDraggingEx] = useState(null);
  const [draggingOverEx, setDraggingOverEx] = useState(null);
  return (
    <WorkoutBlockForm week={week}>
      <WorkoutBlockHeader week={week} style={isMobile ? { padding: '16px 20px 12px' } : {}}>
        <WorkoutBlockHeaderIconsWrapper>
          {week && !isMobile && (
            <WorkoutBlockHeaderIcon
              move
              style={{ padding: '0 1.2rem 0 0', width: '1.6rem', height: '1.6rem' }}
            />
          )}
          {block?.name}
        </WorkoutBlockHeaderIconsWrapper>
        <WorkoutBlockHeaderIconsWrapper>
          {isMobile ? (
            <ModalCloseX onClick={handleCancel}>X</ModalCloseX>
          ) : week ? (
            <BlockActionsStack
              actions={[
                { title: 'Delete block', icon: ICONS.BinSimpleGrey, action: deleteSelf },
                { title: 'Duplicate block', icon: ICONS.Copy, action: () => {} },
              ]}
            />
          ) : (
            <WorkoutBlockHeaderIcon onClick={deleteSelf} />
          )}
        </WorkoutBlockHeaderIconsWrapper>
      </WorkoutBlockHeader>
      <WorkoutBlockContent week={week}>
        {block?.attributes?.map((attr, i) => (
          <AttributeWrapper week={week} key={i} className={attr.name}>
            <AttributeLabel
              small={attr.name === 'rest' || attr.type === 'text'}
              style={
                week ? { minWidth: 'fit-content', paddingLeft: '0.6rem', fontFamily: '1.4rem' } : {}
              }
            >
              {!week && <AttributeBullet clock={attr?.type === 'clock'} />}
              {(week && attr?.shortLabel) || attr?.label}
            </AttributeLabel>

            <Field
              id={`blocks[${index}].attributes[${i}].value`}
              name={`blocks[${index}].attributes[${i}].value`}
            >
              {({ field }) => (
                <>
                  {attr?.type === 'clock' ? (
                    <AsNeeded>
                      <CustomInputClock
                        small={week}
                        height={week ? '3.2rem' : '4rem'}
                        fontSize="1.6rem"
                        onChange={(v) => {
                          setFieldValue(field.name, v);
                          if (block?.exercises?.length > 0 && attr.name === 'workTime') {
                            const [, workM, workS] = v?.split(':');
                            const quantity = Number(workM) * 60 + Number(workS);
                            block.exercises.map((ex, j) => {
                              setFieldValue(`blocks[${index}].exercises[${j}].quantity`, quantity);
                            });
                          }
                        }}
                        disabled={attr?.asNeeded}
                        value={attr?.asNeeded ? undefined : field.value}
                        error={
                          touched?.blocks?.[index] &&
                          errors?.blocks?.[index]?.attributes?.[i]?.value
                        }
                      />
                      {!week && typeof attr?.asNeeded === 'boolean' && (
                        <AsNeeded style={{ minWidth: 'fit-content' }}>
                          <AsNeededCheck
                            checked={attr.asNeeded}
                            onClick={() =>
                              setFieldValue(
                                `blocks[${index}].attributes[${i}].asNeeded`,
                                !attr.asNeeded,
                              )
                            }
                          />
                          As Needed
                        </AsNeeded>
                      )}
                    </AsNeeded>
                  ) : attr?.name === 'text' ? (
                    <CustomTextArea
                      rowCount={5}
                      style={{
                        padding: '8px',
                      }}
                      placeholder="Type here..."
                      wrapperStyle={{
                        maxWidth: '100%',
                      }}
                      onChange={(v) => setFieldValue(field.name, v)}
                      value={field.value}
                      errorIcon={null}
                      errorBorder
                      error={
                        !!(
                          touched?.blocks?.[index] &&
                          errors?.blocks?.[index]?.attributes?.[i]?.value
                        )
                      }
                    />
                  ) : (
                    <>
                      <CustomInput
                        type={attr?.type === 'text' ? 'text' : 'number'}
                        onChange={(v) => {
                          setFieldValue(field.name, v);
                          if (
                            block?.exercises?.length &&
                            block?.type === WorkoutBlockType.STRENGTH &&
                            attr?.name === 'sets' &&
                            v > 0
                          ) {
                            if (block?.exercises?.length > v) {
                              setFieldValue(
                                `blocks[${index}].exercises`,
                                block?.exercises?.slice(0, v),
                              );
                            } else if (block?.exercises?.length < v) {
                              const exercisesToAdd = new Array(v - block?.exercises?.length).fill({
                                ...block?.exercises?.[0],
                                quantity: 1,
                                weight: 0,
                                unit: BlockExerciseUnit.SECONDS,
                                weightType: BlockExerciseWeightType.KG,
                              });
                              setFieldValue(`blocks[${index}].exercises`, [
                                ...block?.exercises,
                                ...exercisesToAdd,
                              ]);
                            }
                          }
                        }}
                        outlined
                        height={week ? '3.2rem' : '4rem'}
                        borderRadius="8px"
                        value={field.value}
                        style={{
                          minWidth: week ? '5.6rem' : '9.4rem',
                          width: week ? '5.6rem' : '9.4rem',
                          margin: 0,
                          textAlign: attr?.type === 'text' ? 'left' : 'center',
                          outline: 'none',
                          fontFamily: 'Space-r',
                          flexGrow: attr?.type === 'text' ? '1' : '0',
                          maxHeight: week ? '100%' : '4rem',
                          minHeight: week ? '100%' : '4rem',
                        }}
                        borderColor="transparent"
                        focusBorderColor="transparent"
                        backgroundColor="#f1f0f0cc"
                        error={
                          touched?.blocks?.[index] &&
                          !!errors?.blocks?.[index]?.attributes?.[i]?.value
                        }
                        errorIcon={false}
                      />
                    </>
                  )}
                </>
              )}
            </Field>
          </AttributeWrapper>
        ))}
        {block?.type !== WorkoutBlockType.REST && block?.type !== WorkoutBlockType.CUSTOM && (
          <>
            <FieldArray
              name={`blocks[${index}].exercises`}
              render={({ push, remove, move }) => (
                <ExercisesWrapper>
                  <AddExerciseModal
                    modalOpen={!!exerciseAdding}
                    closeModal={(initialSearch) => {
                      setExerciseAdding(null);
                      setSearchText(initialSearch);
                    }}
                    submitModal={async (v, edited, editedGlobal) => {
                      const exercise = await createNewExercise(v);
                      if (edited && editedGlobal) {
                        const exerciseIndex = block.exercises.findIndex(
                          (e) => e.exercise.id === exercise.editedExercise,
                        );
                        setFieldValue(
                          `blocks[${index}].exercises[${exerciseIndex}].exercise`,
                          exercise,
                        );
                        return;
                      } else if (edited) {
                        return;
                      }
                      if (block?.type === WorkoutBlockType.STRENGTH) {
                        const setsQuantity = block?.attributes?.find(
                          (x) => (x.name = 'sets'),
                        )?.value;
                        const exercisesToAdd = new Array(Number(setsQuantity)).fill({
                          exercise: v,
                          quantity: 1,
                          weight: 0,
                          unit: BlockExerciseUnit.SECONDS,
                          weightType: BlockExerciseWeightType.KG,
                        });
                        setFieldValue(`blocks[${index}].exercises`, exercisesToAdd);
                      } else {
                        const numberOfSets =
                          block?.type === WorkoutBlockType.CLASSICSTRENGTH ? 3 : null;
                        const restTime =
                          block?.type === WorkoutBlockType.CLASSICSTRENGTH ? '00:00:30' : null;
                        push({
                          exercise: { ...v, ...exercise },
                          quantity: 1,
                          weight: 0,
                          unit: BlockExerciseUnit.SECONDS,
                          weightType: BlockExerciseWeightType.KG,
                          numberOfSets,
                          restTime,
                        });
                      }
                      setExerciseAdding(null);
                      setSearchText('');
                    }}
                    initialValues={exerciseAdding}
                    loading={loading}
                  />
                  {block?.type === WorkoutBlockType.STRENGTH && !!block?.exercises?.length && (
                    <AttributeWrapper week={week} style={{ paddingLeft: '0.8rem' }}>
                      <FlexWrapper>
                        <AttributeLabel>
                          <AttributeBullet />
                          {block?.exercises?.[0].exercise?.title || false}
                        </AttributeLabel>
                        {!isMobile && (
                          <DeleteExerciseIcon
                            nonAbsolute
                            onClick={() => setFieldValue(`blocks[${index}].exercises`, [])}
                          />
                        )}
                      </FlexWrapper>
                    </AttributeWrapper>
                  )}

                  {block?.exercises?.map((ex, i) => (
                    <Exercise
                      week={week}
                      block={block}
                      i={i}
                      key={i}
                      index={index}
                      ex={ex}
                      swapExercises={swapExercises}
                      setExerciseAdding={setExerciseAdding}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      errors={errors}
                      remove={remove}
                      setDraggingBlock={setDraggingBlock}
                      draggingEx={draggingEx}
                      setDraggingEx={setDraggingEx}
                      draggingOverEx={draggingOverEx}
                      setDraggingOverEx={setDraggingOverEx}
                      move={move}
                    />
                  ))}

                  {/* {block?.uniqueExercises &&
                    block?.exercises?.map((ex, subIndex) =>
                    ex.exercise ? (
                      <Exercise
                      block={block}
                      i={subIndex}
                      index={index}
                          ex={ex}
                          swapExercises={swapExercises}
                          setExerciseAdding={setExerciseAdding}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          remove={(v) => {
                            const { exercise, ...rest } = v;
                            setFieldValue(`blocks[${index}].exercises[${subIndex}]`, rest);
                          }}
                        />
                      ) : (
                        <ExerciseWrapper>
                          <ExerciseIndex>
                            {block?.indexPrefix} {subIndex + 1}
                          </ExerciseIndex>
                          <ExerciseContent>
                            {(block?.type !== WorkoutBlockType.STRENGTH ||
                              !block?.exercises?.length) && (
                              <indexPrefix
                                onSelect={(v) => {
                                  setFieldValue(`blocks[${index}].exercises[${subIndex}]`, {
                                    exercise: v,
                                    quantity: 1,
                                    weight: 0,
                                    unit: BlockExerciseUnit.SECONDS,
                                    weightType: BlockExerciseWeightType.KG,
                                  });
                                }}
                                createNew={(searchInput) =>
                                  setExerciseAdding({ title: searchInput, searchText })
                                }
                                viewOption={(option) =>
                                  setExerciseAdding({ ...option, searchText })
                                }
                                error={Boolean(
                                  touched?.blocks?.[index] &&
                                    errors?.blocks?.[index]?.exercises[subIndex],
                                )}
                              />
                            )}
                          </ExerciseContent>
                        </ExerciseWrapper>
                      ),
                    )} */}
                  {/* {!block?.uniqueExercises && ( */}
                  {!isMobile && (
                    <ExerciseWrapper
                      week
                      style={week ? { display: 'flex' } : {}}
                      draggingOver={draggingOverEx === -1}
                      onDragOver={(e) => {
                        if (typeof draggingEx === 'number') {
                          e.preventDefault();
                          setDraggingOverEx(-1);
                        }
                      }}
                      onDragLeave={() => setDraggingOverEx(null)}
                      onDrop={() => {
                        move(draggingEx, block?.exercises?.length);
                        setDraggingOverEx(null);
                        setDraggingEx(null);
                      }}
                    >
                      <ExerciseIndex style={week ? { padding: 0 } : {}}>
                        {block?.type !== WorkoutBlockType.STRENGTH && block?.exercises?.length + 1}
                      </ExerciseIndex>
                      {block?.type === WorkoutBlockType.RUN ? (
                        <ExerciseContent>
                          <CustomInputDropdown
                            placeholder="Choose Exercise"
                            value={''}
                            returnEntire
                            onChange={(v) => {
                              push({
                                exercise: v,
                                quantity: 1,
                                weight: 0,
                                unit: BlockExerciseUnit.SECONDS,
                                weightType: BlockExerciseWeightType.KG,
                              });
                            }}
                            options={exercises?.getExercises
                              ?.filter(
                                (x) =>
                                  !!{ run: true, walk: true, rest: true }[x.title?.toLowerCase()] &&
                                  !x.coach,
                              )
                              .map((ex) => ({
                                ...ex,
                                label: ex.title,
                                value: ex.uid,
                              }))}
                            style={{
                              margin: '0',
                              fontSize: '1.4rem',
                              border: 'none',
                              maxHeight: week ? '2.4rem' : '4rem',
                              minHeight: week ? '2.4rem' : '4rem',
                              width: 'auto',
                              flexGrow: 1,
                            }}
                            error={
                              !block?.exercises?.length &&
                              touched?.blocks?.[index] &&
                              errors?.blocks?.[index]?.exercises
                            }
                          />
                        </ExerciseContent>
                      ) : (
                        <ExerciseContent>
                          {(block?.type !== WorkoutBlockType.STRENGTH ||
                            !block?.exercises?.length) && (
                            <CustomSearch
                              noIcon={week}
                              placeholder="Search Exercise"
                              onChange={(v, { action }) => {
                                if (action === 'input-change' || action === 'set-value') {
                                  setSearchText(v);
                                }
                              }}
                              menuStyle={
                                week
                                  ? {
                                      maxWidth: 'Calc(100% + 2.4rem)',
                                      minWidth: 'Calc(100% + 2.4rem)',
                                      left: '-1.6rem',
                                    }
                                  : {}
                              }
                              onSelect={(v) => {
                                if (block?.type === WorkoutBlockType.STRENGTH) {
                                  const setsQuantity = block?.attributes?.find(
                                    (x) => x.name === 'sets',
                                  )?.value;
                                  const exercisesToAdd = new Array(Number(setsQuantity)).fill({
                                    exercise: v,
                                    quantity: 1,
                                    weight: 0,
                                    unit: BlockExerciseUnit.REPS,
                                    weightType: BlockExerciseWeightType.KG,
                                  });
                                  setFieldValue(`blocks[${index}].exercises`, exercisesToAdd);
                                } else {
                                  const [, workM, workS] =
                                    block?.type === WorkoutBlockType.INTERVAL
                                      ? block?.attributes
                                          ?.find((x) => x.name === 'workTime')
                                          ?.value?.split(':')
                                      : '';
                                  const quantity =
                                    block?.type === WorkoutBlockType.INTERVAL
                                      ? workM * 60 + Number(workS)
                                      : 1;
                                  const unit =
                                    block.type === WorkoutBlockType.RUN ||
                                    block.type === WorkoutBlockType.INTERVAL
                                      ? BlockExerciseUnit.SECONDS
                                      : BlockExerciseUnit.REPS;
                                  const numberOfSets =
                                    block?.type === WorkoutBlockType.CLASSICSTRENGTH ? 3 : null;
                                  const restTime =
                                    block?.type === WorkoutBlockType.CLASSICSTRENGTH
                                      ? '00:00:30'
                                      : null;
                                  push({
                                    exercise: v,
                                    quantity,
                                    weight: 0,
                                    unit,
                                    weightType: BlockExerciseWeightType.KG,
                                    numberOfSets,
                                    restTime,
                                  });
                                }
                              }}
                              createNew={(searchInput) =>
                                setExerciseAdding({ title: searchInput, searchText })
                              }
                              viewOption={(option) => setExerciseAdding({ ...option, searchText })}
                              options={exercises?.getExercises || []}
                              value={searchText || ''}
                              style={{
                                margin: '0',
                                fontSize: '1.4rem',
                                border: 'none',
                                maxHeight: week ? '2.4rem' : '4rem',
                                minHeight: week ? '2.4rem' : '4rem',
                              }}
                              loading={exercisesLoading}
                              error={
                                !block?.exercises?.length &&
                                touched?.blocks?.[index] &&
                                errors?.blocks?.[index]?.exercises
                              }
                            />
                          )}
                        </ExerciseContent>
                      )}
                    </ExerciseWrapper>
                  )}
                  {/* )} */}
                </ExercisesWrapper>
              )}
            ></FieldArray>
            {!week && (
              <AttributeWrapper>
                <AttributeLabel>
                  <AttributeBullet />
                  Comments & Indications
                </AttributeLabel>
                <Field id={`blocks[${index}].comment`} name={`blocks[${index}].comment`}>
                  {({ field }) => (
                    <CustomTextArea
                      rowCount={null}
                      style={{
                        height: '68px',
                        padding: '4px',
                        maxHeight: '68px',
                      }}
                      wrapperStyle={{
                        maxWidth: week ? '100%' : 'min(70%, Calc(100% - 18.6rem))',
                      }}
                      onChange={(v) => setFieldValue(field.name, v)}
                      value={field.value}
                      error={touched?.blocks?.[index] && errors?.blocks?.[index]?.comment}
                    />
                  )}
                </Field>
              </AttributeWrapper>
            )}
          </>
        )}
        <WorkoutBlockFooter>
          <div>
            {block?.type !== 'REST' && (
              <>
                {index === 0 && (
                  <Field id={`blocks[${index}].isWarmUp`} name={`blocks[${index}].isWarmUp`}>
                    {({ field }) => (
                      <AttributeLabel flexGrow week={week}>
                        <AttrCheckBox
                          style={
                            week ? { width: '1.4rem', height: '1.4rem', margin: '0 0.6rem' } : {}
                          }
                          active={field.value}
                          onClick={() => setFieldValue(field.name, !field.value)}
                        />
                        Mark this block as Warmup
                      </AttributeLabel>
                    )}
                  </Field>
                )}
                <Field
                  id={`blocks[${index}].videoRequested`}
                  name={`blocks[${index}].videoRequested`}
                >
                  {({ field }) => (
                    <AttributeLabel flexGrow week={week}>
                      <AttrCheckBox
                        style={
                          week ? { width: '1.4rem', height: '1.4rem', margin: '0 0.6rem' } : {}
                        }
                        active={field.value}
                        onClick={() => setFieldValue(field.name, !field.value)}
                      />
                      Request Video
                    </AttributeLabel>
                  )}
                </Field>
              </>
            )}
          </div>
          {!week && (
            <WorkoutBlockApplyButton>
              <CustomButton
                outlined
                outlineColor="#00FF91"
                backgroundColor="white"
                fontSize="1.4rem"
                type="button"
                onClick={async () => {
                  await setTouched({ blocks: { [index]: true } });
                  const invalid = !!errors?.blocks?.[index];
                  if (!invalid) {
                    setFieldValue('editingBlockIndex', null);
                  }
                }}
              >
                Apply
              </CustomButton>
            </WorkoutBlockApplyButton>
          )}
        </WorkoutBlockFooter>
      </WorkoutBlockContent>
      {week && (
        <WeekBlockFooter>
          <AddBlockCommentTooltip
            initialValue={block?.comment}
            onSubmit={(v) => setFieldValue(`blocks[${index}].comment`, v)}
            isMobile={isMobile}
          />
          {!isMobile && (
            <CustomButton
              green
              fontSize="1rem"
              type="button"
              style={{
                maxHeight: '2.4rem',
                minHeight: '2.4rem',
                padding: '0 1.2rem',
                width: 'fit-content',
                borderRadius: '0.6rem',
              }}
              onClick={async () => {
                await setTouched({ blocks: { [index]: true } });
                const invalid = !!errors?.blocks?.[index];
                if (!invalid) {
                  setFieldValue('editingBlockIndex', null);
                }
              }}
            >
              Done
            </CustomButton>
          )}
        </WeekBlockFooter>
      )}
      {isMobile && (
        <WeekBlockFooter style={{ marginTop: 0 }}>
          <CustomButton
            green
            fontSize="14px"
            fontFamily="Roboto-m"
            style={{
              maxHeight: '48px',
              minHeight: '48px',
              width: 'Calc(100% - 1.2rem)',
              borderRadius: '8px',
            }}
            onClick={onSubmit}
          >
            Save Changes
          </CustomButton>
        </WeekBlockFooter>
      )}
    </WorkoutBlockForm>
  );
};

export default EdittingWorkoutBlock;
