import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #05000040;
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 80px;
  height: 100%;
  border-right: 1px solid #05000040;
  background-image: url(${ICONS.Logo});
  background-size: 38%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  min-width: 80px;
`;

export const HeaderLink = styled.div`
  width: 100px;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid #05000010;
  position: relative;
  &::after {
    content: '';
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.color.primary};
    opacity: 0.5;
    bottom: -2px;
    border-radius: 2px;
  }
  background-image: ${({ icon }) => (icon ? `url(${icon})` : '')};
  background-size: 26%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const HeaderMain = styled.div`
  flex-grow: 1;
  display: flex;
  border-right: 1px solid #05000040;
  background: #fdfdfd;
`;

export const ProfilePic = styled.div`
  width: 44px;
  height: 44px;
  background-image: ${({ img }) => (img ? `url(${img})` : `url(${ICONS.ProfileImage})`)};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`;

export const SideButtonsRelativeBox = styled.div`
  position: relative;
  width: ${({ isOrganization }) => (isOrganization ? '300px' : '400px')};
`;
export const SideButtonsAbsoluteBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 102;
`;

export const MobileHeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #05000040;
  display: flex;
  justify-content: space-between;
`;

export const HeaderSection = styled.div`
  display: flex;
`;

export const HeaderIcon = styled.div`
  width: 28px;
  height: 28px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
`;

export const HeaderRight = styled.div`
  width: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #00000026;
  position: relative;
`;

export const BurgerIcon = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${ICONS.Burger});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 6px;
  background-color: #504d4d0f;
  :active {
    background-color: #504d4d4d;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ ml }) => ml}px;
  margin-right: ${({ mr }) => mr}px;
`;

export const GoBackIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${ICONS.GoBack});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 6px;
  margin: 16px;
  :active {
    background-color: #504d4d4d;
  }
`;

export const FixedHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  border-bottom: 1px solid #0000001a;
  z-index: 1;
`;

export const FIxedHeaderText = styled.div`
  font-family: Space-r;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: left;
  color: #050000;
`;

export const Dummy = styled.div`
  width: 24px;
  height: 24px;
  margin: 16px;
`;

export const Dot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: red;
  display: inline-block;
  right: ${({ right }) => right}px;
  top: ${({ top }) => top}px;
`;
