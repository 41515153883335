import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { MobileContext } from '../../services/MobileContext';
import { ItemContainer, ItemIcon, ItemText } from './components';

const Item = ({ icon, text, url, to, children }) => {
  const { toggleMenu } = useContext(MobileContext);
  const history = useHistory();

  const handleNavigate = useCallback(() => {
    toggleMenu();
    history.push(to);
  }, [to, toggleMenu, history]);

  return (
    <ItemContainer onClick={handleNavigate}>
      <ItemIcon src={icon} />
      <ItemText>{text}</ItemText>
      {children}
    </ItemContainer>
  );
};

export default Item;
