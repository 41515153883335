const { default: styled } = require('styled-components');

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-columns: 14% 16% 20% 16% 14% 20%;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 30px;
  margin-bottom: 8px;

  span {
    ${({ smallText }) =>
      smallText
        ? `
        width: 100%;
    opacity: 0.6;
    color: #020202;
    font-family: Roboto-m;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: left;
        `
        : `
    height: 21px;
    width: 59px;
    opacity: 0.9;
    color: inherit;
    font-family: Roboto-m;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    `}
  }
`;

export const ProfilePic = styled.div`
  width: 2rem;
  height: 2rem;
  background: #d8d8d8;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  letter-spacing: 0.8px;
  color: ${({ image }) => (image ? 'transparent' : '#ffffff')};
  text-transform: uppercase;
  font-size: 0.8rem;
`;

export const SmallText = styled.span``;
