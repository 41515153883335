import React from 'react';
import TraineeCard from '../../components/trainee-card/TraineeCard';

const GroupCard = ({ group, ...rest }) => {
  return (
    <>
      <TraineeCard
        {...rest}
        isGroup={true}
        program={{
          ...group,
          trainee: { fullName: group?.name, avatar: group?.groupImage },
          coach: { fullName: group?.coach?.fullName, profileImage: group?.coach?.profileImage },
        }}
      />
    </>
  );
};

export default GroupCard;
