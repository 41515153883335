import React from 'react';
import InputRange from 'react-input-range';
import { useMemo } from 'react';

import {
  OrgWholeWrapper,
  ChartLegend,
  OrgLineWrapper,
  OrgValuesWrapper,
  OrgValuesPercentage,
} from './components';

const ProgressLineOrganization = ({ total, progress, onChange }) => {
  const percentage = useMemo(() => {
    const part = Math.round((progress / total) * 100);
    if (part < 0) return 0;
    if (part > 100) return 100;
    return part;
  }, [progress, total]);

  return (
    <OrgWholeWrapper>
      <OrgLineWrapper>
        <InputRange
          maxValue={100}
          minValue={0}
          value={progress}
          onChange={(value) => onChange(value)}
        />

        <ChartLegend>
          <div className="end_points_org">0%</div>
          <div className="end_points_org">100%</div>
        </ChartLegend>
      </OrgLineWrapper>
      <OrgValuesWrapper>
        <OrgValuesPercentage>{percentage}%</OrgValuesPercentage>
      </OrgValuesWrapper>
    </OrgWholeWrapper>
  );
};

export default ProgressLineOrganization;
