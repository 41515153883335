import { Field, Form, Formik } from 'formik';
import Modal from 'react-modal';
import React from 'react';

import TemplateFilter from '../../pages/program-template/TemplateFilter';
import { PseudoTemplateCard } from '../trainee-card/components';
import EmptyTemplate from '../empty-template/EmptyTemplate';
import TemplateCard from '../trainee-card/TemplateCard';
import CustomButton from '../button/CustomButton';
import IMAGES from '../../constants/images';
import { useTemplate } from '../../hooks';
import Loader from '../loader/Loader';
import {
  WholeWrapper,
  ModalHeader,
  ModalFooter,
  ModalCloseX,
  ModalContent,
  TemplateCardsWrapper,
  TemplateCardsPart,
  TemplateCardsScrollable,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 111111111,
  },
  content: {
    width: '84%',
    maxWidth: '1400px',
    minWidth: '1140px',
    height: 'Calc(82vh + 30px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
  },
};

const ChooseTemplateModal = ({ modalOpen, closeModal, programUid, alert, refetch, programLength }) => {
  const {
    programTemplates,
    programTemplatesLoading,
    customTemplates,
    customTemplatesLoading,
    uploadTemplate,
    updateFilter,
    templateFilter,
  } = useTemplate({
    initialFilter: {
      templateLength: undefined,
      programType: { label: '- Choose -', value: undefined },
      searchBy: { label: 'Program Template', value: 'templates' },
    },
  });
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="Program Template"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          CHOOSE PROGRAM TEMPLATE
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <Formik
          initialValues={{
            selectedTemplate: null,
          }}
          onSubmit={async (v) => {
            closeModal();
            await uploadTemplate(programUid, v?.selectedTemplate, alert, programLength);
            refetch();
          }}
        >
          {({ setFieldValue }) => (
            <Form
              style={{ width: '100%', display: 'flex', flexGrow: '1', flexDirection: 'column' }}
            >
              <ModalContent>
                <TemplateFilter setFieldValue={updateFilter} modalLayout values={templateFilter} />

                <TemplateCardsPart>
                  <Field id="selectedTemplate" name="selectedTemplate">
                    {({ field }) => (
                      <TemplateCardsScrollable>
                        <TemplateCardsWrapper>
                          {templateFilter?.searchBy?.value === 'templates' ? (
                            <>
                              {programTemplatesLoading ? (
                                <Loader />
                              ) : !programTemplates?.length ? (
                                <>
                                  {
                                    <EmptyTemplate
                                      imageSize="24rem"
                                      image={IMAGES.EmptyTemplates}
                                      primaryText={`No Templates Found`}
                                    />
                                  }
                                </>
                              ) : (
                                <TemplateCardsWrapper>
                                  {programTemplates?.map((template) => (
                                    <TemplateCard
                                      key={template.uid}
                                      template={template}
                                      withoutFooterButton
                                      selected={field.value?.id === template.uid}
                                      onSelect={() =>
                                        setFieldValue(field.name, {
                                          id: template.uid,
                                          type: 'template',
                                        })
                                      }
                                      hideDuplicate
                                    />
                                  ))}

                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                </TemplateCardsWrapper>
                              )}
                            </>
                          ) : (
                            <>
                              {customTemplatesLoading ? (
                                <Loader />
                              ) : !customTemplates?.length ? (
                                <>
                                  {
                                    <EmptyTemplate
                                      imageSize="24rem"
                                      image={IMAGES.EmptyTemplates}
                                      primaryText={`No Programs Found`}
                                    />
                                  }
                                </>
                              ) : (
                                <TemplateCardsWrapper>
                                  {customTemplates?.map((program) => {
                                    return (
                                      <TemplateCard
                                        key={program.uid}
                                        template={
                                          program?.group
                                            ? {
                                              ...program?.group,
                                              name: `${program?.group?.name}'s Program`,
                                              image: program?.group?.groupImage?.url,
                                            }
                                            : {
                                              ...program?.programRequest?.[0],
                                              name: `${program?.trainee?.fullName}'s Program`,
                                              image: program?.trainee?.avatar?.url,
                                            }
                                        }
                                        withoutFooterButton
                                        selected={field.value?.id === program.uid}
                                        onSelect={() =>
                                          setFieldValue(field.name, {
                                            id: program.uid,
                                            type: 'program',
                                          })
                                        }
                                        hideDuplicate
                                      />
                                    );
                                  })}

                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                  <PseudoTemplateCard />
                                </TemplateCardsWrapper>
                              )}
                            </>
                          )}
                        </TemplateCardsWrapper>
                      </TemplateCardsScrollable>
                    )}
                  </Field>
                </TemplateCardsPart>
              </ModalContent>
              <ModalFooter>
                <CustomButton
                  type="submit"
                  green
                  height="4.8rem"
                  style={{ padding: '0 4rem', width: 'fit-content' }}
                >
                  Select Template
                </CustomButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </WholeWrapper>
    </Modal>
  );
};

export default ChooseTemplateModal;
