import { useQuery } from '@apollo/client';
import { meQuery } from '@witness/graphql';
import React, { useContext } from 'react';
import { MobileContext } from '../../services/MobileContext';
import MobileAvatar from '../avatar/MobileAvatar';
import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import {
  MobileSidebarContainer,
  MobileSidebarContent,
  SidebarRow,
  CloseIcon,
  AvatarContainer,
  CoachTitle,
  CoachName,
  NameContainer,
  ItemsContainer,
  LogoutContainer,
  LogoutText,
} from './components';
import Item from './MobileSidebarItem';
import CustomButton from '../button/CustomButton';
import { useSignin } from '../../hooks';
import { Dot } from '../header/components';
import ChatContext from '../../services/ChatContext';

const MobileSidebar = () => {
  const { toggleMenu, menuOpen } = useContext(MobileContext);
  const { totalUnreadCount } = useContext(ChatContext);

  const { data } = useQuery(meQuery);
  const { signOut } = useSignin();

  return (
    <MobileSidebarContainer open={menuOpen}>
      <MobileSidebarContent>
        <SidebarRow>
          <CloseIcon onClick={toggleMenu} />
        </SidebarRow>
        <SidebarRow>
          <AvatarContainer>
            <MobileAvatar
              src={
                data?.getCurrentUser?.coach?.profileImage?.compressedUrl ||
                data?.getCurrentUser?.coach?.profileImage?.url
              }
              name={data?.getCurrentUser?.coach?.fullName}
              size="40px"
            />
          </AvatarContainer>
          <NameContainer>
            <CoachTitle>Coach</CoachTitle>
            <CoachName>{data?.getCurrentUser?.coach?.fullName}</CoachName>
          </NameContainer>
        </SidebarRow>
        <ItemsContainer>
          <Item icon={ICONS.HomeMobile} text="Home" to={URLS.home} />
          {/* <Item icon={ICONS.MobileTraineeIcon} text="Trainees" to={URLS.trainees} /> */}
          <Item icon={ICONS.CouponSidemenuMobile} text="Coupons" to={URLS.profile + '?tab=1'} />
          <Item icon={ICONS.ChatSidemenuMobile} text="Chat" to={URLS.messages}>
            {!!totalUnreadCount && <Dot top={11} right={202} />}
          </Item>
        </ItemsContainer>

        <LogoutContainer>
          <CustomButton
            fontSize="1rem"
            type="button"
            style={{
              margin: '12px 24px 24px 24px',
              height: 40,
              width: '100%',
              borderRadius: 8,
              backgroundColor: 'white',
              border: 'solid 1px #00ff91',
            }}
            onClick={signOut}
          >
            <LogoutText>Logout</LogoutText>
          </CustomButton>
        </LogoutContainer>
      </MobileSidebarContent>
    </MobileSidebarContainer>
  );
};

export default MobileSidebar;
