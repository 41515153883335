import { CoachProgramStatus } from '../../constants/enums';

import styled from 'styled-components';

const circleBg = ({ status }) => {
  switch (status) {
    case CoachProgramStatus.APPROVED:
      return '#FFCC00';
    case CoachProgramStatus.DECLINED:
      return '#F50A4F';
    case CoachProgramStatus.ONGOING:
      return '#00FF91';
    case CoachProgramStatus.ENDED:
      return '#fff';
    default:
      return '#15b5f3';
  }
};
const outerBrdr = ({ status }) => {
  switch (status) {
    case CoachProgramStatus.APPROVED:
      return '#FFCC00';
    case CoachProgramStatus.DECLINED:
      return '#F50A4F';
    case CoachProgramStatus.ONGOING:
      return '#00FF91';
    case CoachProgramStatus.ENDED:
      return '#00FF91';
    default:
      return '#15b5f3';
  }
};
const circleBrdr = ({ status }) => {
  switch (status) {
    case CoachProgramStatus.APPROVED:
      return '#e0d08e';
    case CoachProgramStatus.DECLINED:
      return '#f089a8';
    case CoachProgramStatus.ONGOING:
      return '#8affcc';
    case CoachProgramStatus.ENDED:
      return '#8affcc';
    default:
      return '#8adeff';
  }
};

const circleBorder = ({ status }) =>
  status === CoachProgramStatus.ENDED ? '#00FF91' : 'transparent';

export const StatusCircle = styled.div`
  border: 2px solid ${circleBorder};
  background-color: ${circleBg};
  width: 1rem;
  height: 1rem;
  border-radius: 0.6rem;
  margin-left: 1.6rem;
  position: relative;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    & * {
      display: block;
    }
    & > .wit_tooltip {
      display: block;
      background: ${circleBrdr};
    }
    & * .wit_tooltip_triangle {
      border-color: ${circleBrdr} transparent transparent transparent;
    }
  }
`;

export const StatusBorder = styled.div`
  position: absolute;
  display: none;
  border: 2px solid ${outerBrdr};
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
`;
