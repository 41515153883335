import React, { useContext, useMemo } from 'react';
import Modal from 'react-modal';
import moment from 'moment';

import { NotificationContext } from '../../services/NotificationContext';
import EmptyTemplate from '../empty-template/EmptyTemplate';
import icons from '../../constants/icons';
import {
  FlexWrapper,
  NotificationDate,
  NotificationImage,
  NotificationRow,
  NotificationsSection,
  NotificationStatus,
  NotificationsWrapper,
  NotificationText,
  NotificationTextTitle,
  SectionTitle,
  MarkAsRead,
  NotificationTextSubTitle,
} from './components';
import { MobileContext } from '../../services/MobileContext';
import Fixedheader from '../header/FixedHeader';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '100',
  },
  content: {
    zIndex: '101',
    width: '400px',
    maxWidth: '400px',
    height: 'Calc(100vh - 79px)',
    top: '79px',
    bottom: 0,
    left: 'auto',
    right: 0,
    color: '#050000',
    border: 'none',
    borderRadius: 0,
    borderTop: '1px solid #05000030',
    padding: '0.4rem 0.4rem 0',
  },
};

const NotificationsModal = ({ modalOpen, closeModal = () => {} }) => {
  const { notifications, markAsRead: mrd, markAllAsRead } = useContext(NotificationContext);

  const { isMobile } = useContext(MobileContext);

  const styles = useMemo(() => {
    if (!isMobile) {
      return customStyles;
    }

    return Object.assign({}, customStyles, {
      content: {
        ...customStyles.content,
        top: '60px',
        bottom: 0,
        left: 0,
        right: 0,
        height: 'unset',
        width: 'unset',
        maxWidth: 'unset',
      },
    });
  }, [isMobile]);

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={styles}
      contentLabel="Notifications"
      ariaHideApp={false}
    >
      {notifications?.length ? (
        <NotificationsWrapper>
          <NotificationsSection>
            {isMobile ? (
              <Fixedheader title="NOTIFICATIONS" handleBack={closeModal} />
            ) : (
              <SectionTitle>
                Notifications
                <MarkAsRead onClick={markAllAsRead}>Mark all as read</MarkAsRead>
              </SectionTitle>
            )}
            {notifications?.map((notification, i) => (
              <a
                href={
                  isMobile
                    ? undefined
                    : `${notification?.redirectUrl}${
                        notification?.action ? `?${notification?.action}` : ''
                      }`
                }
                key={i}
              >
                <NotificationRow key={i} onClick={() => mrd(notification)}>
                  <FlexWrapper>
                    <NotificationImage image={notification?.imageUrl}>
                      {!notification?.imageUrl &&
                        notification?.fullName
                          ?.split(' ')
                          ?.map((x) => x.substr(0, 1))
                          ?.join('')
                          ?.substr(0, 3)}
                      {!notification.isRead && <NotificationStatus status={'RED'} />}
                    </NotificationImage>
                    <NotificationText>
                      <NotificationTextTitle>{notification?.title}</NotificationTextTitle>
                      <NotificationTextSubTitle>{notification?.subTitle}</NotificationTextSubTitle>
                      <NotificationDate>
                        {moment(notification?.createdAt).fromNow()}
                      </NotificationDate>
                    </NotificationText>
                  </FlexWrapper>
                </NotificationRow>
              </a>
            ))}
          </NotificationsSection>
        </NotificationsWrapper>
      ) : (
        <EmptyTemplate
          imageSize={'10rem'}
          image={icons.EmptyIcon}
          hideImageGrid
          secondarytext={"You don't have any notifications yet"}
        />
      )}
    </Modal>
  );
};

export default NotificationsModal;
