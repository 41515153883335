import React, { useEffect, useRef, useState } from 'react';
import ICONS from '../../constants/icons'
import { BlockActionsStackWrapper, WorkoutBlockHeaderIcon, BlockActionsStackIcon, BlockActionsStackMenu, BlockActionsStackItem } from './components';


const BlockActionsStack = ({actions}) => { 

    const [isOpen, setIsOpen] = useState(false)

    const menuRef= useRef()
    
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);
    
    return(
        <>
            <BlockActionsStackWrapper ref={menuRef}>
                <WorkoutBlockHeaderIcon onClick={()=>setIsOpen(curr => !curr)} style={{backgroundImage: `url(${isOpen ? ICONS.VerticalDotsActive : ICONS.VerticalDots})`, width: '2.2rem', height: '2.2rem'}}/>
                {isOpen &&
                    <BlockActionsStackMenu >
                        {actions?.map( ac =>
                            <BlockActionsStackItem onClick={() => {
                                ac.action(); 
                                setIsOpen(false)
                            }}>
                                <BlockActionsStackIcon icon={ac.icon} />
                                {ac.title}
                            </BlockActionsStackItem>
                        )}
                    </BlockActionsStackMenu>
                }
            </BlockActionsStackWrapper>
        </>
    )
}

export default BlockActionsStack;