import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 7.5%;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-r')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 1.8rem;
  padding: 4px 28px;
  min-height: 28px;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-grow: 1;
`;

export const ModalContentMain = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalSubHeading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 20px;
  font-family: Roboto-r;
  font-size: 1.6rem;
  line-height: 2.4rem;
  justify-content: center;
  color: #848181;
`;

export const ArrowIcon = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #d2d1d1;
  background-image: url(${ICONS.ArrowDownWhite});
  background-position: center;
  background-size: 60%;
  transform: rotate(90deg);
  background-repeat: no-repeat;
  margin: 3.5rem auto 2rem;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const ModalRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #05000033;
  flex-grow: ${({ grow }) => (grow ? '1' : 'unset')};
  height: ${({ upper }) => (upper ? '18.7%' : 'auto')};
  min-height: ${({ bottom }) => (!bottom ? '144px' : '116px')};
  max-height: 33%;
  position: relative;
  float: left;
  &:last-child {
    border-bottom: none;
  }
`;

export const RowTitle = styled.div`
  position: absolute;
  height: 3.2rem;
  z-index: 1111;
  top: -16px;
  left: Calc(50% - 88px);
  background: #ffffff;
  width: 176px;
  border: 0.5px solid #05000033;
  border-radius: 8px;
  font-family: Space-r;
  font-size: 1.4rem;
  line-height: 3.2rem;
  text-align: center;
  background: #fafafa;
`;

export const UpperRowCell = styled.div`
  flex-grow: 1;
  min-width: Calc(100% / 3 - 0.5px);
  border-right: ${({ mid }) => (mid ? '1px solid #05000033' : 'none')};
  border-left: ${({ mid }) => (mid ? '1px solid #05000033' : 'none')};
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${({ mid }) => (mid ? '1.6rem' : '0')};
`;

export const UpperRowCellLine = styled.div`
  width: 1px;
  height: 100%;
  background: #05000033;
  margin: auto;
`;

export const ProfilePic = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border: 4px solid #f1f0f0;
  background: #d8d8d8;
  border-radius: 50%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  margin: Calc(1.5vh - 4.4px) auto Calc(1.5vh - 6.4px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  letter-spacing: 0.8px;
  color: ${({ image }) => (image ? 'transparent' : '#ffffff')};
  text-transform: uppercase;
  font-size: 1.4rem;

  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
    font-size: 20px;
    margin: 0;
  }
`;

export const ProfileName = styled.div`
  width: 100%;
  font-family: Gotham-r;
  line-height: max(20px, Calc(2vh - 5.8px));
  color: #4a4a4a;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: Calc(1.5vh - 6.4px);

  @media (max-width: 600px) {
    align-items: unset;
    margin-bottom: 8px;
    justify-content: unset;
    font-size: 16px;
  }
`;

export const GenderIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-image: ${({ gender }) =>
    gender === 'MALE' ? `url(${ICONS.Male})` : `url(${ICONS.Female})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 6px;

  @media (max-width: 600px) {
    justify-content: unset;
    width: 16px;
    height: 16px;
  }
`;

export const ParameterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    justify-content: unset;
  }
`;

export const ParameterCell = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: ${({ val }) => (val ? 'Space-b' : 'Roboto-l')};
  color: ${({ val }) => (val ? '#323232' : '#464646')};
  margin: ${({ val }) => (val ? '0 8px' : '0 1.6')};

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

export const MiddleRowCell = styled.div`
  flex: 1;
  min-width: Calc(100% / 6);
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  border-right: ${({ last }) => (last ? 'none' : '1px solid #05000033')};
  height: 100%;
  padding: Calc(5vh - 8px) 0 Calc(2vh - 2.2px);
`;

export const BottomRowCell = styled.div`
  flex: 1;
  border-right: ${({ last }) => (last ? 'none' : '1px solid #05000033')};
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  height: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Roboto-l;
  min-width: Calc(100% / 6);
  padding: ${({ grow }) => (grow === '4' ? '32px 44px 12px' : 0)};
  background: ${({ grow }) => (!grow ? '#fafafa' : 'transparent')};
`;

export const RespModalFooterWrapper = styled.div`
  @media (max-width: 600px) {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export const ModalFooter = styled.div`
  text-align: center;
  padding: 1.2% 0 1.5%;
  border-top: 1px solid #05000033;

  @media (max-width: 600px) {
    text-align: left;
    padding: 0 0 2.4rem;
    border-top: none;
    background-color: #fff;
  }
`;

export const ModalButtonsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: max(40px, 5.12vh);
  margin: 1.1% 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    height: unset;
  }
`;
export const ModalButtonWrapper = styled.div`
  width: 195px;
  height: 100%;
  margin: 0 24px;

  @media (max-width: 600px) {
    height: 48px;
    flex: 1;
    margin: ${({ margin }) => margin};
  }
`;

export const TotalPriceGreen = styled.span`
  font-family: Space-b;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #00b972;
  padding: 2px 12px;

  @media (max-width: 600px) {
    padding: 0 8px;
    font-size: 18px;
  }
`;

export const TotalPriceBold = styled.span`
  font-family: Gotham-r;
  font-size: 1.7rem;
  line-height: 2.4rem;
  letter-spacing: 0.6px;
  color: #373333;
  & > span.declined {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: ${({ theme }) => theme.color.error};
  }
  & > span.approved {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: #00b972;
  }

  @media (max-width: 600px) {
    margin-right: auto;
    font-size: 13px;
  }
`;

export const MiddleRowCellIcon = styled.div`
  width: max(28px, Calc(4.3vh - 10px));
  height: max(28px, Calc(4.3vh - 10px));
  background-image: ${({ icon }) => `url(${icon})`};
  border: 0.5px solid rgba(0, 185, 114, 0.6);
  border-radius: 6px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 600px) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin: unset;
  }
`;

export const MiddleRowCellKey = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  padding: Calc(1.5vh - 4.4px) min(26px, Calc(4.5vh - 18px)) Calc(0.75vh - 2.2px);

  @media (max-width: 600px) {
    text-align: left;
    font-family: Roboto-r;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #050000;
    padding: 0 0 6px;
  }
`;

export const MiddleRowCellValue = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  font-family: Roboto-l;
  padding: 0 min(26px, Calc(4.5vh - 18px));
  line-height: max(18px, Calc(2vh - 5.8px));

  @media (max-width: 600px) {
    /* font-family: Roboto-r; */
    font-size: 14px;
    /* font-weight: 300; */
    font-stretch: normal;
    font-style: normal;
    line-height: 21px;
    letter-spacing: normal;
    text-align: left;
    color: #050000;
    padding: 0 0 6px;
  }
`;

export const ModalMain = styled.div`
  padding: ${({ padding }) => (padding ? padding : '3.6rem')};
`;
export const ModalTextArea = styled.div`
  max-height: 100%;
  width: 100%;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  width: Calc(50% - 12px);
  height: 4.8rem;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '1.6rem')};
`;

// group parts

export const ModalGroupSide = styled.div`
  width: 32%;
  background: #f1f0f0;
  height: 100%;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #05000033;
`;

export const GroupDetailsHeading = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  font-family: Space-r;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.1rem;
  padding: 1.6rem;
  text-align: center;
  border-radius: 8px;
`;

export const GroupImageWrapper = styled.div`
  width: 11.2rem;
  height: 11.2rem;
  border-radius: 50%;
  background: #ffffff;
  padding: 0.4rem;
  border: 4px solid ${({ theme }) => theme.color.primary};
  margin: 2.4rem 2.4rem 2rem;
`;

export const GroupImage = styled.div`
  width: 100%;
  height: 100%;
  background: #f1f0f0;
  background-image: url(${({ groupImage }) => groupImage});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
`;

export const GroupName = styled.div`
  font-family: Gotham-r;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #4a4a4a;
  margin-bottom: 2rem;
  letter-spacing: 0.1rem;
  min-height: 2.4rem;
`;

export const GroupParticipants = styled.div`
  font-family: Roboto-r;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #323232;
  margin-bottom: 2.8rem;
  & > span {
    line-height: 2.4rem;
    color: #464646;
    font-family: Space-b;
    font-size: 1.6rem;
    padding: 0 1.6rem;
  }
`;

export const GroupAbout = styled.div`
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #050000;
  padding: 0 2.2rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TicketIcon = styled.div`
  width: 21.3rem;
  height: 12.9rem;
  background-image: url(${ICONS.Ticket});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media (max-width: 600px) {
    width: 109px;
    height: 60px;
  }
`;

export const TicketDigits = styled.span`
  height: 2.9rem;
  width: 11%.4rem;
  color: #050000;
  font-family: Space-b;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  line-height: 2.9rem;

  @media (max-width: 600px) {
    height: unset;
    width: unset;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.95px;
    text-align: right;
    color: #000000;
  }
`;

export const TicketNote = styled.span`
  height: 2.4rem;
  width: 6.7rem;
  opacity: 0.6;
  color: #373333;
  font-family: 'Space-r';
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  text-align: center;

  @media (max-width: 600px) {
    height: unset;
    width: unset;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
`;

// mobile
export const RespContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgb(247, 246, 246);
  overflow-y: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const BackIcon = styled.img`
  object-fit: contain;
  width: 24px;
`;

export const RespModalHeader = styled.header`
  height: 60px;
  display: flex;
  align-items: center;
  padding: 16px 16px 20px;
  text-transform: uppercase;
  background-color: #fff;
`;

export const ModalTitle = styled.h2`
  margin-left: 34px;
  font-family: Space-r;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: left;
  color: #050000;
`;

export const BannerWrapper = styled.div`
  background-image: url(${ICONS.LeftCellBackground});
  background-size: cover;
  background-position: center;
  min-height: 120px;
  display: flex;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  flex: 1 0 50%;
  height: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &:nth-child(1) {
    border-left: none;
    border-top: none;
  }
  &:nth-child(2) {
    border-left: none;
    border-right: none;
    border-top: none;
  }
  &:nth-child(3) {
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  &:nth-child(4) {
    border: none;
  }
`;

export const AvatarRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -40px;
  margin-bottom: 24px;
`;

export const RespContentContainer = styled.div`
  padding: 0 32px;
`;

export const BlockLabelContainer = styled.div`
  height: 32px;
  position: relative;
  padding: 0 12px 0 25px;
  border-radius: 8px;
  border: solid 0.5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  font-family: Space-r;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #373333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 32px 0 24px;
  margin-top: ${({ mt }) => mt || 0}px;
  margin-bottom: ${({ mb }) => mb || 0}px;

  &::after {
    z-index: -1;
    content: '';
    width: 100vw;
    height: 0.5px;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: calc(50% - (0.5px / 2));
    left: -32px;
  }
`;

export const LabelIcon = styled.img`
  width: 20px;
  height: 20px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export const LabelContentContainer = styled.div`
  margin: 32px 0 40px;
`;

export const FitnessDataRow = styled.div`
  display: flex;
  margin: 0 24px 24px;
`;

export const FitnessDataWrapper = styled.div`
  margin-left: 16px;
`;

export const FitnessDataLabel = styled.div`
  font-family: Roboto-r;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #050000;
`;

export const RespNote = styled.div`
  font-family: Roboto-r;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #050000;
`;

export const PriceRow = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-left: none;
  border-right: none;
`;

export const PriceData = styled.span`
  font-family: Roboto-r;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #323232;
  display: flex;
  align-items: center;
`;
export const CoachRowName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  &:last-child {
    border: none;
  }
`;
export const CoachRowImage = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.6rem;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
`;
