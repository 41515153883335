import React, { useCallback, useContext } from 'react';
import { PseudoTraineeCard } from '../../components/trainee-card/components';
import GroupCard from './GroupCard';
import AddGroupModal from '../../components/add-group-modal.jsx/AddGroupModal';
import { useState } from 'react';
import { useCoachPersonalInfo, useGroup } from '../../hooks';
import { ProgramStatus, CoachProgramStatus } from '../../constants/enums';
import useProgramRequest from '../../hooks/programRequest';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import CustomButton from '../../components/button/CustomButton';
import Loader from '../../components/loader/Loader';
import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import IMAGES from '../../constants/images';
import { OrganizationContext } from '../../services/organizationContext';
import CancelProgramModal from '../../components/cancel-program-modal/CancelProgramModal';
import AlertModal from '../../components/alert-modal/AlertModal';

const Groups = () => {
  const [modalGroup, setmModalGroup] = useState(false);
  const [groupToBeDeleted, setGroupToBeDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState('LIST');
  const [cancelModal, setCancelModal] = useState(null);
  const {
    groups,
    searchName,
    setSearchName,
    coachProgramStatus,
    setCoachProgramStatus,
    groupsLoading,
    createGroup,
    removeGroup,
  } = useGroup({
    coachProgramStatus: [
      { label: 'Pending', value: CoachProgramStatus.APPROVED },
      { label: 'In progress', value: CoachProgramStatus.ONGOING },
    ],
  });
  const { statusOpts } = useProgramRequest({
    requestStatus: ProgramStatus.APPROVED,
    skipQuery: true,
  });
  const { currentCoach } = useCoachPersonalInfo({});

  const submitGroup = useCallback(
    (values, { setErrors }) => {
      createGroup(values, setErrors, setLoading, () => setmModalGroup(false));
    },
    [createGroup],
  );
  const { isOrganization } = useContext(OrganizationContext);
  return (
    <>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AddGroupModal
          modalOpen={!!modalGroup}
          submitModal={submitGroup}
          closeModal={() => setmModalGroup(false)}
          setLoading={setLoading}
          loading={loading}
        />
        <CancelProgramModal
          neg
          modalOpen={!!groupToBeDeleted}
          closeModal={() => setGroupToBeDeleted(false)}
          submitModal={() => groupToBeDeleted?.uid && removeGroup(groupToBeDeleted?.uid)}
          headingText={'Are You Sure?'}
          typeText={'CANCEL_GROUP'}
          contentText={
            <>
              If you cancel this group, you won't be able to restore.
            </>
          }
        />
        {/* <AreYouSureModal
          neg
          modalOpen={!!groupToBeDeleted}
          closeModal={() => setGroupToBeDeleted(false)}
          submitModal={() => groupToBeDeleted?.uid && removeGroup(groupToBeDeleted?.uid)}
          headingText={'Are You Sure?'}
          contentText={<>If you delete the group, you won't be able to restore.</>}
          choiceQuestion={'Do you want to delete the group?'}
        /> */}
        {console.log(cancelModal)}
        <AlertModal
          title="You Can’t Directly Cancel The Program"
          content="If you want to cancel the program, please, send request email to our Admin"
          modalOpen={cancelModal}
          submitButtonText="Send Request Email"
          closeModal={() => setCancelModal(null)}
          mailHref={`mailto:support@witnesstofitness.com?subject=Group Program Cancellation&body=Dear sir/madam,\n\n\nI am requesting to cancel the group program "${cancelModal?.name}".\nGroup ID: ${cancelModal?.code?.digits}\n\n\nBest Regards,\nCoach ${currentCoach?.fullName}`}
        />
        <ProgramsHeader
          layout={layout}
          setLayout={setLayout}
          statusOpts={statusOpts}
          status={coachProgramStatus}
          setStatus={setCoachProgramStatus}
          traineeName={searchName}
          setTraineeName={setSearchName}
          heading="Your Groups"
          additionalButton={
            <CustomButton
              height="4.6rem"
              green
              onClick={() => setmModalGroup(true)}
              style={{ padding: '1rem 3rem', marginLeft: '1.6rem' }}
            >
              Create New Group
            </CustomButton>
          }
        />
        <div
          style={{
            background: '#f7f6f6',
            padding: '12px 8px',
            margin: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {!groups?.length ? (
            <>
              {(groupsLoading && <Loader />) || (
                <EmptyTemplate
                  image={IMAGES.EmptyGroups}
                  buttonText="Create Group"
                  onButtonClick={() => setmModalGroup(true)}
                  primaryText={`No groups are assigned to your account ${
                    coachProgramStatus?.value
                      ? `with Status '${coachProgramStatus?.label}' ${
                          searchName ? `and Name consisting '${searchName}'` : ''
                        }`
                      : searchName
                      ? `with Name consisting '${searchName}'`
                      : 'at this time'
                  }
                  `}
                  secondarytext={
                    coachProgramStatus?.value || searchName
                      ? ''
                      : 'This page allows you to create groups, join trainees to a group training session and gain access to collective group results.'
                  }
                />
              )}
            </>
          ) : layout === 'CARDS' ? (
            <>
              {!!groups?.length &&
                groups.map((group) => (
                  <GroupCard
                    key={group?.uid}
                    group={{ ...group, beginningDate: group?.coachProgram?.beginningDate }}
                    handleCancel={() => {
                      if (group?.coachProgram?.status === 'APPROVED') {
                        setGroupToBeDeleted(group);
                      } else {
                        if (group?.price === 0) {
                          setGroupToBeDeleted(group);
                        } else {
                          setCancelModal(group);
                        }
                      }
                    }}
                  />
                ))}
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
              <PseudoTraineeCard />
            </>
          ) : (
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 6px',
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Full name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program Status
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program types
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                      textAlign: 'center',
                    }}
                  >
                    Participants max number
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program ends
                  </th>
                  {isOrganization && (
                    <th
                      style={{
                        padding: '1.6rem 3.2rem',
                        fontFamily: 'Roboto-m',
                        fontSize: '1.4rem',
                        color: '#4a4a4a',
                      }}
                    >
                      Coach
                    </th>
                  )}
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Code
                  </th>
                  {!isOrganization && (
                    <th
                      style={{
                        width: '12.4rem',
                        padding: '1.6rem 3.2rem',
                        fontFamily: 'Roboto-m',
                        fontSize: '1.4rem',
                        color: '#4a4a4a',
                      }}
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!!groups?.length &&
                  groups.map((group) => (
                    <GroupCard
                      layout="LIST"
                      key={group?.uid}
                      group={{ ...group, beginningDate: group?.coachProgram?.beginningDate }}
                      handleEdit={() => setmModalGroup(group)}
                      handleDelete={() => setGroupToBeDeleted(group)}
                      handleCancel={() => {
                        if (group?.coachProgram?.status === 'APPROVED') {
                          setGroupToBeDeleted(group);
                        } else {
                          if (group?.price === 0) {
                            setGroupToBeDeleted(group);
                          } else {
                            setCancelModal(group);
                          }
                        }
                      }}
                    />
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Groups;
