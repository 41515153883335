import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import STRINGS from '../../constants/strings';
import moment from 'moment';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalColumn,
  ModalInput,
  CloseX,
  FieldWrapper,
  DaysWrapper,
  CheckBox,
  CoachRow,
  CoachRowCheck,
  CoachRowName,
  CoachRowImage,
  CoachList,
} from './components';
import CustomInput from '../input/CustomInput';
import CustomInputNumber from '../input/CustomInputNumber';
import { Formik, Field, Form } from 'formik';
import usePricing from '../../hooks/pricing';
import ReactSelectWitness from '../react-select-witness/ReactSelectWitness';
import CustomTextArea from '../input/CustomTextArea';
import { DiscountCouponSchema, ProgramCouponSchema } from '../../schemas/coupon/coupon';
import { useCoachPersonalInfo } from '../../hooks';
import CustomCheckSingle from '../input/CustomCheckSingle';
import { DayWrapper } from '../add-group-modal.jsx/components';
import ProgressLineOrganization from '../progress-line/ProgressLineOrganization';
import { OrganizationContext } from '../../services/organizationContext';
import { useDebounce } from '../../services/utils';
import Loader from '../loader/Loader';
import LENGTH_OPTIONS from '../../constants/programLengthOptions'
import { useUtils } from '../../hooks';

const weekDays = ['MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT', 'SUN'];

const ProgramCodeModal = ({ content, closeModal }) => {
  const isDiscount = content?.type === 'discountCode';
  const isProgram = content?.type === 'programCode';
  const { isOrganization } = useContext(OrganizationContext);

  const { programLengthValueToOption } = useUtils()

  const customStyles = {
    overlay: {
      backgroundColor: '#05000066',
      zIndex: '1111',
    },
    content: {
      zIndex: '11111',
      width: 'Calc(100% - 40px)',
      maxWidth: isOrganization ? '1024px' : '520px',
      minWidth: '320px',
      height: 'fit-content',
      top: '50%',
      left: '50%',
      right: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: '#001212',
      borderRadius: '12px',
      border: 'none',
      padding: '0 ',
    },
  };

  const [ttUids, setTTUids] = useState([]);
  const [searchName, setSearchName] = useState('');
  const delayedSearchName = useDebounce(searchName, 500);

  const { currentCoach, currentOrganization, orgCoaches, loadingOrgCoaches } = useCoachPersonalInfo(
    {
      setLoading: null,
      setOpenModal: null,
      orgCoachesFilters: { ttUids, fullName: delayedSearchName },
    },
  );

  const { createAdminCode } = usePricing();
  const title = useMemo(
    () => `${content?.uid ? 'MODIFY' : 'NEW'} ${isDiscount ? 'DISCOUNT' : 'PROGRAM'} CODE `,
    [content, isDiscount],
  );

  const specList = useMemo(() => {
    const userSpecialities = isOrganization
      ? currentOrganization?.specialities
      : currentCoach?.specialities;
    if (userSpecialities) {
      const withSpecs = userSpecialities?.reduce((acc, sp) => {
        if (sp?.name === 'Strength' || sp?.name === 'Running') {
          acc.push({ value: sp?.id, label: sp?.name, trainingTypes: sp?.trainingTypes });
        }
        return acc;
      }, []);
      const specials = [].concat(
        ...userSpecialities?.map(
          (spec) =>
            (spec?.name !== 'Strength' && spec?.name !== 'Running' && spec?.trainingTypes) || [],
        ),
      );
      return [
        ...withSpecs,
        {
          value: 'non-strength-or-running-spec',
          label: 'Special',
          trainingTypes: specials,
        },
      ];
    }

    return [];
  }, [currentCoach, currentOrganization, isOrganization]);

  const sp = useMemo(() => {
    if (!content?.trainingTypes?.[0]?.speciality) {
      return;
    }

    if (
      content?.trainingTypes?.[0]?.speciality.name !== 'Strength' &&
      content?.trainingTypes?.[0]?.speciality.name !== 'Running'
    ) {
      return specList[2];
    }

    return specList.find((s) => {
      return s.value === content?.trainingTypes?.[0]?.speciality?.id;
    });
  }, [specList, content]);

  const tt = useMemo(() => {
    const res = sp?.trainingTypes
      ?.filter((tt) => content?.trainingTypes?.find((xx) => xx.id === tt.id))
      .map((tt) => ({ value: tt.id, label: tt.name }));
    return res;
  }, [sp, content]);

  useEffect(() => setTTUids(tt?.map((t) => t?.value)), [tt?.length]);

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!content}
      style={customStyles}
      contentLabel="EditPersonalInfo"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          {title}
          <CloseX onClick={closeModal}>X</CloseX>
        </ModalHeader>
        <ModalMain>
          <Formik
            initialValues={{
              // specifications: content?.specifications || [0],
              ...content,
              isOrganization,
              title: content?.title || content?.name || '',
              amount: content?.amount || 1,
              description: content?.description || '',
              available: content?.available || 10,
              validUntil: content?.validUntil
                ? moment(content?.validUntil).format('YYYY-MM-DD')
                : moment().add(1, 'year').format('YYYY-MM-DD'),
              discountType:
                content?.discountType === 'AMOUNT'
                  ? { value: 'AMOUNT', label: '₪' }
                  : { value: 'PERCENTAGE', label: '%' },
              daysOfTraining: content?.daysOfTraining || [],
              price: typeof content?.monthlySum === 'number' ? String(content?.monthlySum) : '',
              speciality: sp || '',
              trainingTypes: tt,
              coachFee: content?.uid ? content?.coachFee || 0 : currentOrganization?.coachFee || 0,
              coaches: content?.coaches?.length
                ? Object.fromEntries(content?.coaches?.map((coach) => [coach?.uid, true]))
                : {},
            }}
            onSubmit={(...args) => {
              createAdminCode(...args);
              closeModal();
            }}
            validationSchema={isDiscount ? DiscountCouponSchema : ProgramCouponSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values, validateForm }) => (
              <Form style={{ width: '100%' }}>
                <FlexWrapper>
                  <ModalColumn
                    style={{
                      padding: '3.6rem',
                      width: isOrganization ? ' Calc(50% - 12px)' : '100%',
                    }}
                  >
                    <FlexWrapper>
                      <ModalColumn>
                        <Field id="title" name="title">
                          {({ field }) => (
                            <ModalInput>
                              Name
                              <CustomInput
                                color="#373333"
                                outlined
                                type="text"
                                borderRadius="8px"
                                height="4.4rem"
                                fontSize="1.4rem"
                                value={field.value}
                                onChange={(val) => setFieldValue(field.name, val)}
                                error={touched[field.name] && !!errors[field.name]}
                                placeholder="Program Code Name"
                                errorIcon={false}
                              />
                            </ModalInput>
                          )}
                        </Field>
                        {isDiscount && (
                          <FlexWrapper>
                            <Field id="discountType" name="discountType">
                              {({ field }) => (
                                <ModalInput small>
                                  Discount Type
                                  <ReactSelectWitness
                                    options={[
                                      { value: 'PERCENTAGE', label: '%' },
                                      { value: 'AMOUNT', label: '₪' },
                                    ]}
                                    style={{ maxHeight: '3.8rem', margin: '1rem 0 2rem' }}
                                    value={field.value}
                                    onChange={(val) => setFieldValue(field.name, val)}
                                    error={touched[field.name] && !!errors[field.name]}
                                  />
                                </ModalInput>
                              )}
                            </Field>
                            <Field id="amount" name="amount">
                              {({ field }) => (
                                <ModalInput small>
                                  Amount
                                  <CustomInputNumber
                                    arrows
                                    color="#373333"
                                    outlined
                                    borderRadius="8px"
                                    style={{ minHeight: '4.4rem' }}
                                    fontSize="1.4rem"
                                    type="number"
                                    onDecrement={() => setFieldValue(field.name, field.value - 1)}
                                    onIncriment={() => setFieldValue(field.name, field.value + 1)}
                                    value={field.value}
                                    onChange={(val) => setFieldValue(field.name, val)}
                                    error={touched[field.name] && !!errors[field.name]}
                                  />
                                </ModalInput>
                              )}
                            </Field>
                          </FlexWrapper>
                        )}
                        <Field id="available" name="available">
                          {({ field }) => (
                            <ModalInput>
                              Usage Limit
                              <CustomInput
                                color="#373333"
                                outlined
                                type="number"
                                borderRadius="8px"
                                height="4.4rem"
                                fontSize="1.4rem"
                                value={field.value}
                                onChange={(val) => setFieldValue(field.name, val)}
                                error={touched[field.name] && !!errors[field.name]}
                                errorIcon={false}
                              />
                            </ModalInput>
                          )}
                        </Field>
                        {isProgram && (
                          <Field id="speciality" name="speciality">
                            {({ field }) => (
                              <ModalInput>
                                Training Type
                                <ReactSelectWitness
                                  error={touched[field.name] && !!errors[field.name]}
                                  options={specList}
                                  value={field?.value}
                                  placeholder="- Choose training type -"
                                  onChange={(v) => {
                                    if (v?.value !== field?.value?.value) {
                                      setTTUids([]);
                                      if (values?.trainingTypes?.length) {
                                        setFieldValue('coaches', {});
                                      }
                                      setFieldValue('trainingTypes', []);
                                    }
                                    setFieldValue(field?.name, v);
                                  }}
                                  backspaceRemovesValue={false}
                                />
                              </ModalInput>
                            )}
                          </Field>
                        )}
                      </ModalColumn>
                      <ModalColumn>
                        <Field id="validUntil" name="validUntil">
                          {({ field }) => (
                            <ModalInput>
                              Expiration Date
                              <CustomInput
                                color="#373333"
                                outlined
                                type="date"
                                borderRadius="8px"
                                height="4.4rem"
                                fontSize="1.4rem"
                                value={field.value}
                                onChange={(val) => setFieldValue(field.name, val)}
                                error={touched[field.name] && !!errors[field.name]}
                                placeholder={'dd-mm-yyyy'}
                                errorIcon={false}
                              />
                            </ModalInput>
                          )}
                        </Field>
                        {isProgram && (
                          <Field id="price" name="price">
                            {({ field }) => (
                              <ModalInput>
                                Price per month
                                <CustomInput
                                  color="#373333"
                                  outlined
                                  type="number"
                                  borderRadius="8px"
                                  height="4.4rem"
                                  fontSize="1.4rem"
                                  value={field.value}
                                  onChange={(val) => setFieldValue(field.name, val)}
                                  error={touched[field.name] && !!errors[field.name]}
                                  placeholder="Price per month"
                                  errorIcon={false}
                                />
                              </ModalInput>
                            )}
                          </Field>
                        )}
                        {isDiscount && (
                          <Field id="description" name="description">
                            {({ field }) => (
                              <ModalInput height="17.5rem">
                                Description
                                <CustomTextArea
                                  style={{
                                    height: '12.4rem',
                                    maxHeight: '12.4rem',
                                    minHeight: '5rem',
                                    padding: '1rem',
                                  }}
                                  wrapperStyle={{ margin: '1rem 0 2rem' }}
                                  value={field.value}
                                  error={touched[field.name] && !!errors[field.name]}
                                  onChange={(val) => setFieldValue(field.name, val)}
                                  errorBorder
                                />
                              </ModalInput>
                            )}
                          </Field>
                        )}
                        {isProgram && (
                          <Field id="trainingTypes" name="trainingTypes">
                            {({ field }) => (
                              <FieldWrapper disabled={!values?.speciality}>
                                <ModalInput>
                                  Training Subtypes
                                  <ReactSelectWitness
                                    checkBoxes
                                    error={touched[field.name] && !!errors[field.name]}
                                    options={values?.speciality?.trainingTypes?.map((a) => ({
                                      value: a?.id,
                                      label: a?.name,
                                    }))}
                                    placeholder="- Choose training types -"
                                    isMulti={values?.speciality?.label === 'Strength'}
                                    value={field?.value}
                                    onChange={(v) => {
                                      if (values?.speciality?.label !== 'Strength') {
                                        setFieldValue('coaches', {});
                                        setFieldValue(field?.name, [v]);
                                        setTTUids([v?.value]);
                                      } else if (!v || v?.length <= 3) {
                                        setFieldValue('coaches', {});
                                        setFieldValue(field?.name, v);
                                        setTTUids(v?.map((x) => x?.value));
                                      }
                                    }}
                                    onDeleteOne={(v) => {
                                      setFieldValue(
                                        field.name,
                                        field?.value?.filter((x) => x?.value !== v),
                                      );
                                    }}
                                    backspaceRemovesValue={false}
                                  />
                                </ModalInput>
                              </FieldWrapper>
                            )}
                          </Field>
                        )}
                      </ModalColumn>
                    </FlexWrapper>
                    {isProgram && (
                      <>
                        <div>
                          <Field id="programLength" name="programLength">
                            {({ field }) => (
                              <ModalInput small>
                                Program Length
                                <ReactSelectWitness
                                  error={touched[field.name] && !!errors[field.name]}
                                  options={LENGTH_OPTIONS}
                                  value={
                                    programLengthValueToOption(field?.value)
                                  }
                                  placeholder="- Choose Length -"
                                  onChange={async (v) => {
                                    await setFieldValue(field.name, v?.value);
                                    await setFieldValue('startDate', null);
                                    await setFieldValue('endDate', null);
                                    validateForm();
                                  }}
                                  dropDownMaxHeight='25vh'
                                />
                              </ModalInput>
                            )}
                          </Field>
                        </div>
                        <Field id="daysOfTraining" name="daysOfTraining">
                          {({ field }) => (
                            <ModalInput style={{ height: 'fit-content' }}>
                              Training Days
                              <DaysWrapper>
                                {weekDays.map((day) => {
                                  return (
                                    <DayWrapper
                                      key={day}
                                      checked={field.value?.indexOf(day) > -1}
                                      error={touched[field.name] && !!errors[field.name]}
                                      onClick={() => {
                                        const currentValue = [...field.value];
                                        const index = currentValue.indexOf(day);
                                        if (index > -1) {
                                          currentValue.splice(index, 1);
                                        } else {
                                          currentValue.push(day);
                                        }
                                        setFieldValue(`daysOfTraining`, currentValue);
                                      }}
                                    >
                                      {day}
                                    </DayWrapper>
                                  );
                                })}
                              </DaysWrapper>
                            </ModalInput>
                          )}
                        </Field>
                      </>
                    )}
                    {isOrganization && (
                      <Field id="coachFee" name="coachFee">
                        {({ field }) => (
                          <ModalInput style={{ height: 'fit-content' }}>
                            Amount of % Coach receive
                            <ProgressLineOrganization
                              total={100}
                              progress={field?.value}
                              onChange={(v) => setFieldValue(field.name, v)}
                            />
                          </ModalInput>
                        )}
                      </Field>
                    )}
                  </ModalColumn>
                  {isOrganization && (
                    <ModalColumn
                      primary
                      style={{
                        borderLeft: '1px solid #05000012',
                        minHeight: isDiscount ? '47.5rem' : '72.8rem',
                        maxHeight: isDiscount ? '47.5rem' : '72.8rem',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <CustomInput
                        color="#373333"
                        type="text"
                        borderRadius="8px"
                        height="4.4rem"
                        fontSize="1.4rem"
                        style={{ border: 'none', height: '5.2rem', margin: 0 }}
                        value={searchName}
                        onChange={(val) => setSearchName(val)}
                        placeholder="Search and select coaches..."
                        errorIcon={false}
                      />
                      <CoachList>
                        <Field id="selectAll" name="selectAll">
                          {({ field }) => (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 2.4rem',
                                fontSize: '1.2rem',
                                color: '#4a4646',
                              }}
                            >
                              <CheckBox
                                checked={field?.value}
                                onClick={() => {
                                  setFieldValue(field?.name, !field?.value);
                                  orgCoaches?.getOrgCoaches &&
                                    orgCoaches.getOrgCoaches.map((coach) =>
                                      setFieldValue(`coaches.${coach.uid}`, !field?.value),
                                    );
                                }}
                              />
                              Select All
                            </div>
                          )}
                        </Field>
                        {loadingOrgCoaches ? (
                          <Loader height="56rem" fontSize="1.2rem" />
                        ) : (
                          orgCoaches?.getOrgCoaches &&
                          orgCoaches.getOrgCoaches.map((coach) => (
                            <Field id={`coaches.${coach.uid}`} name={`coaches.${coach.uid}`}>
                              {({ field }) => (
                                <CoachRow onClick={() => setFieldValue(field?.name, !field?.value)}>
                                  <CoachRowCheck>
                                    <CheckBox checked={field?.value} />
                                  </CoachRowCheck>
                                  <CoachRowName>
                                    <CoachRowImage img={coach?.profileImage?.url} />
                                    {coach.fullName}
                                  </CoachRowName>
                                </CoachRow>
                              )}
                            </Field>
                          ))
                        )}
                      </CoachList>
                    </ModalColumn>
                  )}
                </FlexWrapper>
                <FlexWrapper
                  style={{
                    justifyContent: 'center',
                    borderTop: '1px solid #05000012',
                    padding: '2.4rem 2rem 2.8rem',
                  }}
                >
                  <ButtonWrapper>
                    <CustomButton
                      fontSize="1.4rem"
                      onClick={closeModal}
                      type="button"
                      style={{ margin: 0 }}
                    >
                      {STRINGS.close}
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton
                      fontSize="1.4rem"
                      green
                      type="submit"
                      disabled={isSubmitting}
                      style={{ margin: 0 }}
                    >
                      {content?.id ? 'Save Changes' : 'Create Program Code'}
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default ProgramCodeModal;
