import { useMutation, useQuery } from '@apollo/react-hooks';
import { useContext, useCallback, useState } from 'react';
import moment from 'moment';
import {
  createCoachGroupMutation,
  getCoachGroupsQuery,
  getTrainingTypesQuery,
  checkProgramCodeQuery,
  getOrgGroupsQuery,
  removeOrganisationGroupMutation,
} from '@witness/graphql';
import { useHistory } from 'react-router-dom';
import { OrganizationContext } from '../services/organizationContext';

const useGroup = (props = {}) => {
  const { groupDigits } = props;

  const [searchName, setSearchName] = useState(null);
  const [coachProgramStatus, setCoachProgramStatus] = useState(props?.coachProgramStatus || []);

  const { isOrganization } = useContext(OrganizationContext);

  const history = useHistory();

  const { data: groups, loading: groupsLoading, refetch } = useQuery(getCoachGroupsQuery, {
    variables: {
      record: {
        searchName,
        coachProgramStatus: coachProgramStatus?.map((a) => a.value) || undefined,
      },
    },
    skip: isOrganization,
  });
  const { data: orgGroups, loading: orgGroupsLoading, refetch: orgGroupsRefetch } = useQuery(
    getOrgGroupsQuery,
    {
      variables: {
        record: {
          searchName,
          coachProgramStatus: coachProgramStatus?.map((a) => a.value) || undefined,
        },
      },
      skip: !isOrganization,
    },
  );

  const { data: groupByDigits } = useQuery(checkProgramCodeQuery, {
    variables: {
      record: {
        digits: groupDigits,
      },
    },
    skip: !groupDigits,
    fetchPolicy: 'no-cache',
  });

  const [createCoachGroup] = useMutation(createCoachGroupMutation);
  const { data: trainingTypes } = useQuery(getTrainingTypesQuery);

  const createGroup = async (values, setErrors, setLaoding, closeModal) => {
    setLaoding(true);
    const {
      uid,
      name,
      groupImage,
      price,
      participants,
      programLength,
      trainingTypes,
      description,
      daysOfTraining,
      beginningDate,
      coach,
      // startDate,
      // endDate,
    } = values;
    // let calculatedLength = programLength;
    const uidSpreader = { uid };
    // if (!programLength) {
    //   calculatedLength = moment(endDate).diff(moment(startDate), 'days');
    // }
    try {
      const addedGroup = await createCoachGroup({
        variables: {
          record: {
            ...uidSpreader,
            name,
            groupImage,
            price: Number(price),
            participants: Number(participants),
            programLength: programLength?.value,
            trainingTypes: trainingTypes?.map((x) => x.value) || [],
            description,
            daysOfTraining,
            coachUid: coach?.value,
            beginningDate,
          },
        },
      });
      setLaoding(false);
      if (!isOrganization) {
        refetch().then(() => {
          if (addedGroup?.data?.createOrUpdateGroup?.uid) {
            closeModal();
            history.push(`/program/${addedGroup?.data?.createOrUpdateGroup?.uid}?group=true`);
          }
        });
      } else {
        orgGroupsRefetch();
        closeModal();
      }
    } catch (err) {
      if (err.graphQLErrors?.find((x) => x.message === 'group_name_already_exists')) {
        setErrors({ name: 'Name is already used' });
      } else {
        console.log(err.message);
        closeModal();
      }
    }
  };

  const [removeOrganisationGroup] = useMutation(removeOrganisationGroupMutation);

  const removeGroup = async (uid) => {
    try {
      await removeOrganisationGroup({
        variables: {
          record: {
            uid,
          },
        },
      });
      refetch();
      orgGroupsRefetch();
    } catch (err) {
      console.log();
    }
  };

  const refetchGroups = useCallback(() => {
    if (isOrganization) {
      orgGroupsRefetch();
    } else {
      refetch();
    }
  }, [isOrganization, refetch, orgGroupsRefetch]);

  return {
    createGroup,
    trainingTypes,
    groups: groups?.getGroups || orgGroups?.getOrgGroups,
    searchName,
    setSearchName,
    coachProgramStatus,
    setCoachProgramStatus,
    groupsLoading: isOrganization ? orgGroupsLoading : groupsLoading,
    groupByDigits,
    removeGroup,
    refetchGroups
  };
};

export default useGroup;
