import React, { useEffect, useRef, useState } from 'react';

import {
  WorkoutBlockDropBox,
  WorkoutBlockDropBoxDashed,
  WorkoutBlockDropBoxPlus,
  TooltipBlocksWrapper,
  TooltipWorkoutBlock,
  WeekDayCheckRest,
  AttrCheckBox,
} from './components';
import CustomTooltip from '../tooltip/CustomTooltip';
import BLOCKS from '../../constants/workoutBlocks';

const WorkoutDropbox = ({
  setFieldValue,
  setTouched,
  errors,
  push,
  values,
  touched,
  noText,
  draggingBlock,
  setDraggingBlock,
  pushCallback = () => {},
  week,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const dropBoxRef = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropBoxRef.current && !dropBoxRef.current.contains(event.target)) {
      setTooltipOpen(false);
    }
  };

  return (
    <WorkoutBlockDropBox isRest={values?.isRest} className="drop-box">
      <WorkoutBlockDropBoxDashed
        week={week}
        className="drop-box"
        error={!!touched.blocks && typeof errors?.blocks === 'string'}
        onDrop={async (e) => {
          if (!draggingBlock) {
            return;
          }
          e.preventDefault();
          e.target.style.background = '#ffffff';
          await setTouched({
            blocks: { [values.editingBlockIndex]: true },
          });
          const invalid = !!errors?.blocks?.[values.editingBlockIndex];
          if (!invalid) {
            let exes = [];

            if (draggingBlock.uniqueExercises) {
              exes = Array(draggingBlock.attributes[draggingBlock.valueAttribute].value)
                .fill(false)
                .map((_, index) => ({
                  quantity: 1,
                  unit: 'REPS',
                  weightType: 'KG',
                }));
            }
            const filledBlock = week ? { ...draggingBlock } : {};

            push({
              ...draggingBlock,
              exercises: exes,
              attributes: Object.keys(draggingBlock?.attributes)?.map(
                (key) => draggingBlock.attributes[key],
              ),
              ...filledBlock,
            });
            pushCallback();

            if (!week) {
              setFieldValue('editingBlockIndex', values?.blocks?.length || 0);
            }
            setDraggingBlock(null);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          if (draggingBlock && e.target.className.slice(-8) === 'drop-box') {
            e.target.style.borderColor = '#15DF68';
            e.target.style.background = 'rgba(0,255,145,0.3)';
          }
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          if (draggingBlock && e.target.className.slice(-8) === 'drop-box') {
            e.target.style.borderColor = ' #96949455';
            e.target.style.background = '#fff';
          }
        }}
        onMouseOver={(e) => {
          if (e.target.className.slice(-8) === 'drop-box') {
            e.preventDefault();
            e.target.style.borderColor = '#15DF68';
          }
        }}
        onMouseOut={(e) => {
          if (e.target.className.slice(-8) === 'drop-box') {
            e.preventDefault();
            e.target.style.borderColor = ' #96949455';
          }
        }}
        onClick={() => setTooltipOpen((curr) => !curr)}
        ref={dropBoxRef}
      >
        <WorkoutBlockDropBoxPlus>
          {tooltipOpen && (
            <CustomTooltip
              below
              onMouseOver={() => {}}
              style={{
                width: '23.6rem',
                left: 'Calc(12px - 11.8rem)',
                padding: '0.7rem 0.2rem 0.7rem 0',
                display: 'block',
                height: 'fit-content',
              }}
            >
              <TooltipBlocksWrapper>
                {Array.from(Object.keys(BLOCKS))?.map((key, i) => {
                  const block = BLOCKS[key];
                  return (
                    <TooltipWorkoutBlock
                      key={i}
                      onClick={async () => {
                        setFieldValue('isRest', false);
                        await setTouched({
                          blocks: { [values.editingBlockIndex]: true },
                        });
                        const invalid = !!errors?.blocks?.[values.editingBlockIndex];
                        if (!invalid) {
                          let exes = [];

                          if (block.uniqueExercises) {
                            exes = Array(block.attributes[block.valueAttribute].value)
                              .fill(false)
                              .map((_, index) => ({
                                quantity: 1,
                                unit: 'REPS',
                                weightType: 'KG',
                              }));
                          }

                          push({
                            ...block,
                            exercises: exes,
                            attributes: Object.keys(block?.attributes)?.map(
                              (key) => block.attributes[key],
                            ),
                          });

                          setFieldValue('editingBlockIndex', values?.blocks?.length || 0);
                        }
                      }}
                    >
                      {block.name}
                    </TooltipWorkoutBlock>
                  );
                })}
              </TooltipBlocksWrapper>
            </CustomTooltip>
          )}
        </WorkoutBlockDropBoxPlus>
        {!noText &&
          ((!!touched.blocks && typeof errors?.blocks === 'string' && errors?.blocks) ||
            'Drag and Drop a Block')}
        {week &&
          ((!values?.blocks?.length && (
            <WeekDayCheckRest>
              <AttrCheckBox
                week={week}
                active={!values?.blocks && values.isRest}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!values.isRest) {
                    setFieldValue('blocks', undefined);
                  }
                  setFieldValue('isRest', !values.isRest);
                }}
              />
              Mark as a Rest day
            </WeekDayCheckRest>
          )) || (
            <WeekDayCheckRest style={{ pointerEvents: 'none' }}>Add New Block</WeekDayCheckRest>
          ))}
      </WorkoutBlockDropBoxDashed>
    </WorkoutBlockDropBox>
  );
};

export default WorkoutDropbox;
