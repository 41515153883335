import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { CometChat } from '@cometchat-pro/chat';
import { BeforeCallContainer, CallContainer, CallContent, ReadyText } from './components';
import CustomButton from '../../components/button/CustomButton';

const VideoCall = () => {
  const match = useRouteMatch();
  const [joined, setJoined] = useState(false);

  useEffect(() => {
    if (joined) {
      let sessionID = match.params.id;
      let audioOnly = false;
      let deafaultLayout = true;

      let callSettings = new CometChat.CallSettingsBuilder()
        .enableDefaultLayout(deafaultLayout)
        .setSessionID(sessionID)
        .setIsAudioOnlyCall(audioOnly)
        .build();

      CometChat.startCall(
        callSettings,
        document.getElementById('callScreen'),
        new CometChat.OngoingCallListener({
          onCallEnded: (call) => {
            setJoined(false);
            console.log('Call ended:', call);
          },
          onError: (error) => {
            setJoined(false);
            console.log('Error :', error);
          },
        }),
      );
    }
  }, [joined, match]);

  return (
    <CallContainer>
      <CallContent id="callScreen">
        {!joined && (
          <BeforeCallContainer>
            <ReadyText>Ready to Join?</ReadyText>
            <CustomButton
              style={{ margin: '0 1.2rem', width: '22.4rem', height: '4.8rem' }}
              fontSize="1.4rem"
              type="button"
              green
              onClick={() => setJoined(true)}
            >
              Join call
            </CustomButton>
          </BeforeCallContainer>
        )}
      </CallContent>
    </CallContainer>
  );
};

export default VideoCall;
