import React, { useContext, useMemo, useState } from 'react';
import TraineeCard from '../../components/trainee-card/TraineeCard';
import { PseudoTraineeCard } from '../../components/trainee-card/components';
import useProgramRequest from '../../hooks/programRequest';
import useProgram from '../../hooks/program';
import { CoachProgramStatus, ProgramStatus, ProgramType } from '../../constants/enums';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import Loader from '../../components/loader/Loader';
import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import { useHistory } from 'react-router-dom';
import IMAGES from '../../constants/images';
import { OrganizationContext } from '../../services/organizationContext';
import AlertModal from '../../components/alert-modal/AlertModal';
import { MobileContext } from '../../services/MobileContext';
import Fixedheader from '../../components/header/FixedHeader';
import URLS from '../../constants/urls';
import ReactSelectMulti from '../../components/react-select-witness/ReactSelectMulti';
import CancelProgramModal from '../../components/cancel-program-modal/CancelProgramModal';

const sortingOrder = {
  [CoachProgramStatus.APPROVED]: 4,
  [CoachProgramStatus.ONGOING]: 3,
  [CoachProgramStatus.ENDED]: 2,
  [CoachProgramStatus.DECLINED]: 1,
  [undefined]: 0,
};

const Trainees = () => {
  const { isMobile } = useContext(MobileContext);
  const {
    programRequests,
    status,
    setStatus,
    statusOpts,
    traineeName,
    setTraineeName,
    programRequestsLoading,
    cancelProgram
  } = useProgramRequest({
    // requestStatus: ProgramStatus.APPROVED,
    programType: ProgramType.ONETOONE,
    coachProgramStatus: [
      { label: 'Pending', value: CoachProgramStatus.APPROVED },
      { label: 'In progress', value: CoachProgramStatus.ONGOING },
    ],
  });
  const { isOrganization } = useContext(OrganizationContext);
  const [layout, setLayout] = useState(isOrganization ? 'LIST' : 'CARDS');
  const [cancelModal, setCancelModal] = useState(null);

  const sortedTrainees = useMemo(
    () =>
      programRequests?.sort((a, b) => {
        return sortingOrder?.[b?.coachProgram?.status] - sortingOrder?.[a?.coachProgram?.status];
      }),
    [programRequests],
  );

  const history = useHistory();

  return (
    <>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {cancelModal?.price > 0 ? (
          <AlertModal
            title="You Can’t Directly Cancel The Program"
            content="If you want to cancel the program, please, send request email to our Admin"
            modalOpen={cancelModal}
            submitButtonText="Send Request Email"
            closeModal={() => setCancelModal(null)}
            mailHref={`mailto:support@witnesstofitness.com?subject=Trainee Program Cancellation&body=Dear sir/madam,\n\n\nI am requesting to cancel the program with trainee "${cancelModal?.trainee?.fullName}".\n\n\nBest Regards,\nCoach ${cancelModal?.coach?.fullName}`}
          />
        ) : (
          <CancelProgramModal
            neg
            modalOpen={!!cancelModal}
            closeModal={() => setCancelModal(null)}
            submitModal={() =>
              cancelModal?.uid && cancelProgram(cancelModal?.uid)
            }
            headingText={'Are You Sure?'}
            contentText={
              <>
                If you cancel the program for {cancelModal?.trainee?.fullName}, you
                won't be able to restore.
              </>
            }
          />
        )}
        {isMobile ? (
          <Fixedheader title="1-1 PROGRAMS" handleBack={() => history.push(URLS.home)} />
        ) : (
          <ProgramsHeader
            trainees
            statusOpts={statusOpts}
            status={status}
            setStatus={setStatus}
            traineeName={traineeName}
            setTraineeName={setTraineeName}
            heading="1-1 programs"
            layout={layout}
            setLayout={setLayout}
          />
        )}
        <div
          style={{
            background: '#f7f6f6',
            padding: isMobile ? '2rem 2rem' : '12px 8px',
            margin: isMobile ? 0 : '20px',
            borderRadius: isMobile ? '0' : '8px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {isMobile && (
            <ReactSelectMulti
              style={{ width: '100%', marginBottom: '2rem' }}
              borderColor="transparent"
              margin="2rem"
              checkBoxes
              options={statusOpts}
              placeholder="- Choose Status -"
              value={status}
              onChange={setStatus}
              backspaceRemovesValue={false}
              maxSelected={null}
              onDeleteOne={(a) => setStatus((curr) => curr.filter((x) => x?.value !== a))}
            />
          )}
          {!programRequests?.length ? (
            <>
              {(programRequestsLoading && <Loader />) || (
                <EmptyTemplate
                  image={IMAGES.EmptyTrainees}
                  buttonText="Dashboard"
                  onButtonClick={() => history.push('/home')}
              //     primaryText={`This page reflects the list of active trainees ${
              //       status?.value
              //         ? `with Status '${status?.label}' ${
              //             traineeName ? `and Name Consisting '${traineeName}'` : ''
              //           }`
              //         : traineeName
              //         ? `with Name consisting '${traineeName}'`
              //         : 'associated with your account'
              //     }
              // `}
              //     secondarytext={
              //       status?.value || traineeName
              //         ? ''
              //         : 'The List of Trainees wll Appears here when You will approve your first Program request'
              //     }
                  primaryText='There are no trainees for this search'
                />
              )}
            </>
          ) : layout === 'CARDS' ? (
            <>
              {programRequests?.map((program) =>
                program?.status !== 'Pending' ? (
                  <TraineeCard
                    key={program?.uid}
                    program={program}
                    handleCancel={() => {
                      setCancelModal(program);
                    }}
                  />
                ) : (
                  false
                ),
              )}
              {!isMobile && (
                <>
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                  <PseudoTraineeCard />
                </>
              )}
            </>
          ) : (
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 6px',
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Full name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program Status
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program types
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program length
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program ends
                  </th>
                  {isOrganization && <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Coach
                  </th> }
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Code
                  </th>
                  {!isOrganization && <th
                      style={{
                        width: '12.4rem',
                        padding: '1.6rem 3.2rem',
                        fontFamily: 'Roboto-m',
                        fontSize: '1.4rem',
                        color: '#4a4a4a',
                      }}
                    >
                      Actions
                  </th> }
                </tr>
              </thead>
              <tbody>
                {sortedTrainees?.map((program) =>
                  program?.status !== 'Pending' ? (
                    <TraineeCard
                      key={program?.uid}
                      program={program}
                      layout="LIST"
                      handleCancel={() => {
                        setCancelModal(program);
                      }}
                    />
                  ) : (
                    false
                  ),
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Trainees;
