import React from 'react';

import {
  NumberInputWrapper,
  NumberInputButton,
  NumberInputInput,
  InputWrapper,
  StyledInput,
  NumberInputArrowsContainer,
  NumberInputArrow,
  InputUpperLabel,
  InputUnit,
} from './components';

const CustomInputNumber = ({
  onDecrement,
  onIncriment,
  onChange,
  value,
  disabled,
  arrows,
  height,
  color,
  fontSize,
  borderRadius,
  error,
  upperlabel,
  unit,
  arrowStyles,
  ...rest
}) => {
  return (
    <>
      {arrows ? (
        <InputWrapper
          height={height}
          outlined
          fontSize={fontSize}
          borderRadius={borderRadius}
          error={error}
          {...rest}
        >
          {upperlabel && <InputUpperLabel>{upperlabel}</InputUpperLabel>}
          <StyledInput
            value={((value || value === 0) && Number(value)) || ''}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(evt) =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault()
            }
            type="number"
            color={color}
          />
          {unit && <InputUnit>{unit}</InputUnit>}
          <NumberInputArrowsContainer>
            <NumberInputArrow onClick={onIncriment} plus style={arrowStyles} />
            <NumberInputArrow
              style={arrowStyles}
              onClick={(value > 0 && onDecrement) || (() => {})}
              nonPositive={!value > 0}
            />
          </NumberInputArrowsContainer>
        </InputWrapper>
      ) : (
        <NumberInputWrapper disabled={disabled}>
          {!disabled && <NumberInputButton onClick={onDecrement} nonPositive={!value > 0} />}
          <NumberInputInput
            onChange={(e) =>
              (e.target.value || e.target.value === 0) &&
              !disabled &&
              onChange(Number(e.target.value))
            }
            value={value}
            min={0}
            disabled={disabled}
          />
          {!disabled && <NumberInputButton onClick={onIncriment} plus />}
        </NumberInputWrapper>
      )}
    </>
  );
};

export default CustomInputNumber;
