import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

const MOBILE_WIDTH = 600;

export const MobileContext = createContext({
  isMobile: window.innerWidth <= MOBILE_WIDTH,
  menuOpen: false,
  toggleMenu: () => false,
});

export const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuOpen((curr) => !curr);
  }, []);

  useEffect(() => {
    const resizeFunc = (e) => setIsMobile(e.target.innerWidth <= MOBILE_WIDTH);

    window.addEventListener('resize', resizeFunc);

    return () => window.removeEventListener('resize', resizeFunc);
  }, []);

  const contextValue = useMemo(() => ({ isMobile, menuOpen, toggleMenu }), [
    isMobile,
    menuOpen,
    toggleMenu,
  ]);

  return <MobileContext.Provider value={contextValue}>{children}</MobileContext.Provider>;
};
