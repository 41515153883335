import React, { useMemo, useState } from 'react';
import { CalendarType } from '../../constants/enums';
import { useCoachProgram, useUtils } from '../../hooks';
import WorkoutBlock from '../add-calendar-modal/WorkoutBlock';
import AreYouSureModal from '../are-you-sure-modal/AreYouSureModal';

import {
  WorkoutPageWrapper,
  WorkoutPageHeader,
  WorkoutPageBackArrow,
  WorkoutPageAvatar,
  WorkoutPageImg,
  WorkoutPageTraineeName,
  WorkoutPageName,
  WorkoutPageContent,
  WorkoutPageMessage,
  WorkoutPageMessageHeader,
  WorkoutPageMessageIcon,
  WorkoutPageMessageText,
} from './components';
import WorkoutBlockEditModal from './WorkoutBlockEditModal';

const RespWorkoutPage = ({ trainee, back, day, coachProgramUid, refetch }) => {
  const { getWorkoutBlocksByWorkout } = useUtils();
  const { saveWorkoutDay } = useCoachProgram({ coachProgramUid });
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [edittingBlock, setEdittingBlock] = useState(null);
  const iniatialBlocks = useMemo(() => getWorkoutBlocksByWorkout(day?.workout), [
    day,
    getWorkoutBlocksByWorkout,
  ]);

  const initialValues = useMemo(() => {
    return {
      name: day?.workout?.name,
      beforeMessage: day?.workout?.beforeMessage,
      afterMessage: day?.workout?.afterMessage,
      type: CalendarType.WORKOUT,
      blocks: iniatialBlocks || [],
    };
  }, [day, iniatialBlocks]);

  return (
    <WorkoutPageWrapper>
      <WorkoutPageHeader>
        <WorkoutPageBackArrow onClick={back} />
      </WorkoutPageHeader>
      <WorkoutPageAvatar>
        <WorkoutPageImg src={trainee?.avatarUrl} />
      </WorkoutPageAvatar>
      <WorkoutPageTraineeName>{trainee?.name}</WorkoutPageTraineeName>
      <WorkoutPageName>{day?.workout?.name}</WorkoutPageName>

      {edittingBlock && (
        <WorkoutBlockEditModal
          modalOpen={edittingBlock}
          closeModal={() => setEdittingBlock(null)}
          initialValues={initialValues}
          submitModal={(v) => {
            saveWorkoutDay(day?.programDay, v, day?.workout?.uid).then(refetch);
            setEdittingBlock(null);
          }}
        />
      )}
      {toBeDeleted && (
        <AreYouSureModal
          choiceQuestion={`ARE YOU SURE YOU WANT TO DELETE "${toBeDeleted?.name}" WORKOUT?`}
          rejectText="No, Cancel"
          submitText="Yes, Delete"
          modalOpen={toBeDeleted}
          neg
          paddings={['20px', '44px']}
          submitModal={() => {
            const blocks = [...initialValues?.blocks];
            blocks.splice(toBeDeleted?.index, 1);
            saveWorkoutDay(day?.programDay, { ...initialValues, blocks }, day?.workout?.uid).then(
              refetch,
            );
          }}
          closeModal={() => setToBeDeleted(null)}
        />
      )}
      <WorkoutPageContent>
        {initialValues?.beforeMessage && (
          <WorkoutPageMessage>
            <WorkoutPageMessageHeader>
              <WorkoutPageMessageIcon />
              MESSAGE BEFORE WORKOUT
            </WorkoutPageMessageHeader>
            <WorkoutPageMessageText>{initialValues?.beforeMessage}</WorkoutPageMessageText>
          </WorkoutPageMessage>
        )}
        {initialValues?.blocks?.map((block, i) => (
          <WorkoutBlock
            week
            mobile
            nonDeletable={initialValues?.blocks?.length < 2}
            block={block}
            key={i}
            deleteSelf={() => setToBeDeleted({ name: block?.type, index: i })}
            setEditting={() => setEdittingBlock(i + 1)}
          />
        ))}
        {initialValues?.afterMessage && (
          <WorkoutPageMessage>
            <WorkoutPageMessageHeader>
              <WorkoutPageMessageIcon />
              MESSAGE AFTER WORKOUT
            </WorkoutPageMessageHeader>
            <WorkoutPageMessageText>{initialValues?.afterMessage}</WorkoutPageMessageText>
          </WorkoutPageMessage>
        )}
      </WorkoutPageContent>
    </WorkoutPageWrapper>
  );
};

export default RespWorkoutPage;
