import React from 'react';

import {
  ProgramReqArrowIcon,
  ProgramReqContainer,
  ProgramReqDesc,
  ProgramReqTitle,
} from './components';
import arrowIcon from '../../assets/dashboard/simple-arrow-up.svg';
import moment from 'moment';

const ProgramRequestCard = ({ item, clickHandler = () => {} }) => (
  <ProgramReqContainer
    onClick={(e) => {
      e.stopPropagation();
      clickHandler(item);
    }}
  >
    {console.log(item)}
    <div>
      <ProgramReqTitle>
        {(item.trainingTypes?.length &&
          (item.trainingTypes?.length > 1
            ? item.trainingTypes?.[0]?.speciality?.name
            : item.trainingTypes?.[0]?.name)) ||
          item.trainingType?.name}{' '}
        Program
      </ProgramReqTitle>

      <ProgramReqDesc style={{ color: '#373333', opacity: 1 }}>
        {item.trainee?.fullName}
      </ProgramReqDesc>
      <ProgramReqDesc>
        {item.programLength / 30} month - {item?.daysOfTraining?.length} days p/week
      </ProgramReqDesc>
      {item?.approvedDate && (
        <ProgramReqDesc>{moment(item?.approvedDate).format('DD/MM/YYYY')}</ProgramReqDesc>
      )}
    </div>

    <ProgramReqArrowIcon src={arrowIcon} alt="Show program request" />
  </ProgramReqContainer>
);

export default React.memo(ProgramRequestCard);
