import * as Yup from 'yup';

const weekDays = ['MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT', 'SUN'];

const name = Yup.string().required();
const preview = Yup.string().required();
const price = Yup.number().min(0).required();
const participants = Yup.number()
  .required()
  .when('currentParticipants', (value, schema) => {
    return schema.min(value || 1, `MAX number is less than actual participants`);
  });
const programLength = Yup.object().shape({
  value: Yup.number().min(1).nullable(),
});
// const startDate = Yup.mixed().when('programLength', (value, schema) => {
//   if (value) return schema.nullable();
//   return schema.required().test('afterNow', 'past date', (value) => {
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
//     const valueToCheck = new Date(value);
//     return today.getTime() < valueToCheck.getTime();
//   });
// });
const beginningDate = Yup.date().nullable();
// .test('afterNow', 'past date', (value) => {
//   const today = new Date();
//   today.setHours(0, 0, 0, 0);
//   const valueToCheck = new Date(value);
//   return !value || today.getTime() < valueToCheck.getTime();
// });

// const endDate = Yup.mixed()
//   .when('programLength', (value, schema) => {
//     if (value) return schema.nullable();
//     return schema.required();
//   })
//   .when('startDate', (value, schema) => {
//     if (!value) return schema;
//     return schema.test('beforeStart', 'less than start', (endDate) => {
//       const startDateToCheck = new Date(value);
//       const endDateToCheck = new Date(endDate);
//       return startDateToCheck.getTime() < endDateToCheck.getTime();
//     });
//   });
const speciality = Yup.object().required();
const trainingTypes = Yup.array().min(1);
const description = Yup.string().required();
const daysOfTraining = Yup.array().min(1).of(Yup.string().oneOf(weekDays)).required();

export const GroupSchema = Yup.object().shape({
  preview,
  name,
  price,
  participants,
  programLength,
  speciality,
  trainingTypes,
  description,
  // startDate,
  // endDate,
  daysOfTraining,
  beginningDate,
});
