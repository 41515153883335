import React, { useContext, useState } from 'react';
import { useTimer } from 'react-timer-hook';

import STRINGS from '../../constants/strings';
import IMAGES from '../../constants/images';
import CustomButton from '../../components/button/CustomButton';
import {
  SignUpContainer,
  LeftPhoto,
  FormContainer,
  ResultSentence,
  WeWillContact,
  ContactUsText,
  ResultName,
  LeftPhotoBackground,
  LeftPhotoBackgroundLine,
  ContactButtonWrapper,
  Timer,
  Logo,
} from './components';
import { MobileContext } from '../../services/MobileContext';

const CoachSignUpResult = () => {
  const { isMobile } = useContext(MobileContext);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: new Date(sessionStorage.getItem('expirationTime')),
    onExpire: () => console.warn('onExpire called'),
  });
  const [clicked, setClicked] = useState(false);
  return (
    <SignUpContainer>
      {!isMobile && (
        <LeftPhotoBackground>
          <LeftPhotoBackgroundLine>
            <Logo />
          </LeftPhotoBackgroundLine>
          <LeftPhotoBackgroundLine />
          <LeftPhotoBackgroundLine />
          <LeftPhotoBackgroundLine />
          <LeftPhoto image={IMAGES.FitnessBoySignupImage} />
        </LeftPhotoBackground>
      )}
      <FormContainer style={{ padding: '58px 0', width: isMobile ? '100%' : '45%' }}>
        <ResultName>{sessionStorage.getItem('name')}</ResultName>
        {/* <ResultSentence>{STRINGS.your_request_success}</ResultSentence>
        <Timer>{`${hours}:${minutes}:${seconds}`}</Timer>
        <WeWillContact>{STRINGS.we_will_contact_in_day}</WeWillContact> */}
        <ResultSentence>{STRINGS.your_request_approved}</ResultSentence>
        <WeWillContact>{STRINGS.please_check_email}</WeWillContact>
        <ContactUsText>{STRINGS.if_question_contact_us}</ContactUsText>
        <ContactButtonWrapper>
          <a
            href={encodeURI(
              `mailto:support@witnesstofitness.com?subject=Change Professional Info`,
            )}
          >
            <CustomButton
              backgroundColor="transparent"
              fontSize="1.4rem"
              outlined
              height="40px"
              onClick={() => setClicked(true)}
            >
              {STRINGS.contact_us}
            </CustomButton>
          </a>
        </ContactButtonWrapper>
        {clicked && (
          <>
            <ContactUsText style={{ marginBottom: '1.2rem' }}>
              Nothing happened on click?
            </ContactUsText>
            <ContactUsText style={{ marginTop: '0.6rem' }}>
              This is our email address -{' '}
              <a href="mailto:support@witnesstofitness.com">support@witnesstofitness.com</a>
            </ContactUsText>
            <ContactUsText style={{ marginTop: '0.6rem' }}>
              Please contact us and we will get back to you ASAP
            </ContactUsText>
          </>
        )}
      </FormContainer>
    </SignUpContainer>
  );
};

export default CoachSignUpResult;
