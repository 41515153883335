import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import URLS from '../constants/urls';
import useGroup from './group';
import useProgramRequest from './programRequest';
import { getGroupPriceQuery } from '@witness/graphql';
import { useQuery } from '@apollo/client';

const useProgramRequestPreview = ({ content }) => {
  const [explanationModalOpen, setExplanationModalOpen] = useState(false);
  const { changeProgramStatus, changeStatusLoading } = useProgramRequest();
  const weekDays = {
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THUR: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
    SUN: 'Sunday',
  };

  const history = useHistory();
  const handleChat = useCallback(() => {
    history.push(URLS.messages, {
      trainee: content?.trainee,
    });
  }, [history, content]);

  const isGroup = useMemo(() => {
    if (content?.type === 'GROUP') return true;
    return false;
  }, [content]);

  const { data: groupPrice } = useQuery(getGroupPriceQuery, {
    variables: {
      record: { uid: content?.uid },
    },
    skip: !isGroup,
  });
  const { groupByDigits } = useGroup({ groupDigits: content?.code?.digits });
  const [totalPrice, monthlyPrice, monthsLeft, orgFee] = useMemo(() => {
    const monthsLeft = isGroup
      ? groupPrice?.getGroupPrice?.monthsLeft
      : content?.programLength / 30;
    if (!isGroup) {
      if (!content?.organisation?.uid) {
        return [
          Math.round((content?.monthlySum || 0) * monthsLeft),
          (content?.monthlySum || 0)?.toFixed(2),
          monthsLeft,
        ];
      }
      return [
        Math.round((content?.coachFee || 0) * monthsLeft),
        (content?.coachFee || 0)?.toFixed(2),
        monthsLeft,
      ];
    } else if (content?.organisation) {
      console.log('beee', groupPrice?.getGroupPrice);
      return [
        (groupPrice?.getGroupPrice?.coachFee || 0) * monthsLeft,
        groupPrice?.getGroupPrice?.coachFee || 0,
        monthsLeft,
        groupPrice?.getGroupPrice?.orgFee || 0,
      ];
    }

    const groupCoachMonthly =
      groupPrice?.getGroupPrice?.monthlyPrice -
      (content?.adminFee || 0) -
      (content?.organisation ? content?.price : 0);

    return [
      groupCoachMonthly * groupPrice?.getGroupPrice?.monthsLeft,
      groupCoachMonthly,
      monthsLeft,
    ];

    // const programStartDate = groupByDigits?.checkProgramCode?.group?.coachProgram?.beginningDate;

    // const programGeneralLength = groupByDigits?.checkProgramCode?.group?.programLength;
    // const groupMonthlyPrice = groupByDigits?.checkProgramCode?.group?.price;
    // const beginningDate = moment(programStartDate);
    // const totalMonths = programGeneralLength / 30;
    // if (programStartDate && beginningDate.isBefore()) {
    //   const requestSentDate = moment(content?.approvedDate);
    //   const endDate = beginningDate.add(programGeneralLength, 'days');
    //   const daysLeft = endDate.diff(requestSentDate, 'days');
    //   const ratio = daysLeft / programGeneralLength;
    //   const total = Math.ceil(groupMonthlyPrice * ratio * totalMonths);
    //   return [total, (total / Math.ceil(totalMonths)).toFixed(2)];
    // } else {
    //   const total = Math.round(groupMonthlyPrice * totalMonths);
    //   return [total, (total / Math.ceil(totalMonths)).toFixed(2)];
    // }
  }, [content, isGroup, groupPrice]);

  return {
    explanationModalOpen,
    setExplanationModalOpen,
    changeProgramStatus,
    isGroup,
    groupByDigits,
    weekDays,
    totalPrice,
    monthlyPrice,
    orgFee,
    handleChat,
    changeStatusLoading,
    monthsLeft,
  };
};

export default useProgramRequestPreview;
