import React from 'react';
import Modal from 'react-modal';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalHeaderLines,
  ModalPrimaryText,
  ModalMain,
  ModalSecondaryText,
  ButtonsFlexWrapper,
  ButtonWrapper,
  ModalSecondaryQuestion,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '44444445',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden auto',
  },
  content: {
    background: 'transparent',
    zIndex: '11111111',
    width: 'Calc(100% - 20px)',
    maxWidth: '588px',
    minWidth: '300px',
    height: 'fit-content',
    top: '10px',
    minHeight: 'Calc(100vh - 20px)',
    display: 'flex',
    alignItems: 'center',
    left: 'max(10px, Calc(50% - 294px))',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
    pointerEvents: 'all',
  },
};

const AreYouSureModal = ({
  modalOpen,
  closeModal,
  submitModal,
  headingText,
  contentText,
  choiceQuestion,
  rejectModal,
  rejectText,
  submitText,
  neg,
  paddings,
}) => {
  return (
    <Modal onRequestClose={closeModal} isOpen={!!modalOpen} style={customStyles}>
      <WholeWrapper>
        <ModalHeader neg={neg}>
          <ModalHeaderLines />
        </ModalHeader>
        <ModalMain style={paddings?.[0] ? { paddingTop: paddings?.[0] } : {}}>
          {headingText && <ModalPrimaryText>{headingText}</ModalPrimaryText>}
          {contentText && <ModalSecondaryText>{contentText}</ModalSecondaryText>}
          {choiceQuestion && <ModalSecondaryQuestion>{choiceQuestion}</ModalSecondaryQuestion>}
          <ButtonsFlexWrapper style={paddings?.[1] ? { marginTop: paddings?.[1] } : {}}>
            <ButtonWrapper>
              <CustomButton
                fontSize="14px"
                fontFamily={neg ? 'Roboto-b' : 'Roboto-r'}
                onClick={rejectModal || closeModal}
                borderRadius="8px"
              >
                {rejectText || 'No'}
              </CustomButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <CustomButton
                fontSize="14px"
                fontFamily={neg ? 'Roboto-b' : 'Roboto-r'}
                backgroundColor={neg ? '#f61a5a' : '#ffffff'}
                outlined={!neg}
                color={neg ? '#fff' : undefined}
                borderRadius="8px"
                onClick={() => {
                  submitModal();
                  closeModal();
                }}
              >
                {submitText || 'Yes'}
              </CustomButton>
            </ButtonWrapper>
          </ButtonsFlexWrapper>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default AreYouSureModal;
