import React, { useCallback, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { sendResetPasswordLinkMutation } from '@witness/graphql';

import {
  SignInWrapper,
  SignInColumn,
  SignInCell,
  FlexWrapper,
  NonFlexWrapper,
  WelcomeAbsolute,
  ButtonWrapper,
} from '../sign-in/components';
import IMAGES from '../../constants/images';
import { Title, SubTitle, InputWrapper } from './components';
import CustomInput from '../../components/input/CustomInput';
import ICONS from '../../constants/icons';
import CustomButton from '../../components/button/CustomButton';
import STRINGS from '../../constants/strings';
import {
  SetPaswordEmailStepSchema,
} from '../../schemas/signup/coachProfile';


const ForgotPassword = () => {
  const [apiError, setApiError] = useState('');
  const [sendResetPasswordLink, { loading }] = useMutation(sendResetPasswordLinkMutation);
  const [sent, setSent] = useState(false);

  const submit = useCallback(async ({ email }) => {
    try {
      await sendResetPasswordLink({
        variables: {
          email: email.toLowerCase().trim(),
        },
      });

      setSent(true);
    } catch (err) {
      if (err.graphQLErrors?.find((x) => x.message === 'user_not_found')) {
        setApiError('user_not_found');
      } else {
        console.log('unexpected_error', err);
        setApiError('unexpected_error');
      }
    }
  }, [sendResetPasswordLink]);

  return (
    <SignInWrapper image={IMAGES.PasswordBackground}>
      <SignInColumn minResolution="tablet">
        <SignInCell />
        <SignInCell />
        <SignInCell />
        <SignInCell bg />
        <SignInCell />
        <SignInCell bottom />
      </SignInColumn>
      <SignInColumn main>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <WelcomeAbsolute>Welcome To</WelcomeAbsolute>
        </FlexWrapper>
        <SignInCell collSpan={2}>
          <img style={{ maxWidth: 'min(60vw, 80%)' }} src={IMAGES.LogoImage} alt="Logo" />
        </SignInCell>
        <SignInCell collSpan={2} rowSpan={3} fontSize={sent ? null : '1.8rem'}>
          {!sent ? (
            <>
              Enter The Email Address <br />
                Associated With Your Account
                <Formik
                initialValues={{ email: '' }}
                validationSchema={SetPaswordEmailStepSchema}
                onSubmit={(v) => submit(v)}
              >
                {({ setFieldValue, errors, touched, isSubmitting, values }) => (
                  <Form style={{ width: '80%', padding: '4rem 0 0', maxWidth: '44rem' }}>
                    <>
                      <Field id="email" name="email">
                        {({ field }) => (
                          <>
                            {!sent ? (
                              <CustomInput
                                autocomplete={false}
                                outlined
                                height={'5.6rem'}
                                placeholder="Enter E-mail"
                                icon={ICONS.EnvelopeIcon}
                                value={field.value}
                                onChange={(val) => {
                                  if (apiError) {
                                    setApiError('')
                                  }
                                  setFieldValue(field.name, val)
                                }}
                                backgroundColor="#ffffff"
                                margin="3.2rem 0"
                                error={touched[field.name] && errors[field.name] || STRINGS[apiError]}
                              />
                            ) : (
                                <></>
                              )}
                          </>
                        )}
                      </Field>
                    </>
                    <ButtonWrapper>
                      <CustomButton type="submit" disabled={loading} green>Next</CustomButton>
                    </ButtonWrapper>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
              <InputWrapper mt="3.2rem">
                <Title>Email Sent</Title>
                <SubTitle>We've sent reset password link to your email address.</SubTitle>
                <SubTitle>
                  If it will not appear in your inbox in few minutes please check spams' folder and
                  mark it as a "not spam" email
                </SubTitle>
              </InputWrapper>
            )}
        </SignInCell>
        <FlexWrapper>
          <SignInCell bottom bg />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn minResolution="laptopS">
        <FlexWrapper>
          <SignInCell bg />
          <SignInCell />
          <SignInCell />
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell />
          <SignInCell collSpan={2} />
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <SignInCell collSpan={2} />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <SignInCell rowSpan={2} />
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell bg />
            <SignInCell />
          </NonFlexWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell bottom />
          <SignInCell bottom />
          <SignInCell bottom bg />
          <SignInCell bottom />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
    </SignInWrapper>
  );
};

export default ForgotPassword;
