import React from 'react';
import { GoBackIcon, FixedHeaderContainer, FIxedHeaderText, Dummy } from './components';

const Fixedheader = ({ title, handleBack }) => {
  return (
    <FixedHeaderContainer>
      <GoBackIcon onClick={handleBack} />
      <FIxedHeaderText>{title}</FIxedHeaderText>
      <Dummy />
    </FixedHeaderContainer>
  );
};

export default Fixedheader;
