import DollarSignSVG from '../../assets/finance/dollar.svg';

const { default: styled } = require('styled-components');

export const FinanceMinimalPreview = styled.div`
  width: 100%;
  height: 30rem;
  background-color: #fff;
  display: flex;
  & > img {
    width: 40%;
    objectfit: cover;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 95%;
    margin: 0 auto;
  }
  & > div > span:first-of-type {
    line-height: 2.2rem;
    font-family: Gotham-r;
    font-size: 1.8rem;
    color: #373333;
    -moz-letter-spacing: 0.2rem;
    -ms-letter-spacing: 0.2rem;
    letter-spacing: 0.2rem;
    text-align: center;
    margin-top: 5%;
  }
  & > div > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  & > div > div > span {
    margin-top: 3rem;
    line-height: 2.2rem;
    font-family: Space-r;
    font-size: 4rem;
    color: #373333;
    -moz-letter-spacing: 0.2rem;
    -ms-letter-spacing: 0.2rem;
    letter-spacing: 0.2rem;
    text-align: center;
  }
  & > div > span:last-of-type {
    opacity: 0.6;
    color: #020202;
  }
  border-radius: 4px;
  overflow: hidden;
`;

export const FinanceChart = styled.div`
  width: 100%;
  height: 30rem;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 1.6rem 2rem 0;
`;
export const FinanceChartContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  & * .recharts-cartesian-axis-line {
    stroke: #ffffff00;
  }
`;

export const FinanceTable = styled.div`
  width: 100%;
  height: 100%;
  grid-column: 1 / span 2;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  padding: 1% 1%;
  box-sizing: border-box;
  & > div {
    position: relative;
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #e2e2e2;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #e2e2e2;
    }
  }
`;

export const FinanceMainGrid = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 36% calc(64% - 20px);
  grid-column-gap: 20px;
  grid-template-rows: auto calc(66vh - 136px);
  grid-row-gap: 20px;
`;

export const FinanceTableHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-columns: 14% 16% 20% 16% 14% 20%;
  align-items: center;
  /* height: 40px; */
  box-sizing: border-box;
  padding: 2.2rem 3rem 1.6rem;
  background: rgb(250, 250, 250);
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const FinanceTableHeaderText = styled.span`
  opacity: 0.6;
  color: #4a4a4a;
  font-family: Roboto-b;
  font-size: 14px;
  line-height: 2.2rem;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
`;

export const DollarSign = styled.div`
  width: 56px;
  height: 56px;
  background-image: url(${DollarSignSVG});
`;

export const NotDollarSign = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #00ff91;
  border-radius: 1.2rem;
  font-size: 4rem;
  font-family: Roboto-l;
`;
