import React from 'react';

import {
  StyledTextArea,
  StyledTextAreaWrapper,
  StyledTextAreaError,
  InputErrorIcon,
} from './components';

const CustomTextArea = ({
  placeholder,
  onChange,
  value,
  error,
  rowCount = 15,
  wrapperStyle,
  errorIcon,
  errorBorder,
  borderColor,
  ...rest
}) => {
  return (
    <>
      <StyledTextAreaWrapper
        style={wrapperStyle}
        errorBorder={error && errorBorder}
        borderColor={borderColor}
      >
        <StyledTextArea
          {...rest}
          placeholder={placeholder}
          rows={rowCount}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </StyledTextAreaWrapper>
      {error && (
        <StyledTextAreaError>
          {errorIcon && <InputErrorIcon margin="0 0.8rem 0 0" />}
          {error}
        </StyledTextAreaError>
      )}
    </>
  );
};

export default CustomTextArea;
