import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import {
  HeaderContainer,
  Logo,
  HeaderLink,
  HeaderMain,
  ProfilePic,
  SideButtonsRelativeBox,
  SideButtonsAbsoluteBox,
} from './components';
import { useCoachPersonalInfo, useSignin } from '../../hooks';
import NotificationsModal from '../notifications/NotificatiosnModal';
import { useState } from 'react';
import { NotificationContext } from '../../services/NotificationContext';
import { OrganizationContext } from '../../services/organizationContext';

const Header = () => {
  const { pathname } = useLocation();
  const { currentCoach, currentOrganization } = useCoachPersonalInfo({});
  const { signOut } = useSignin();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { unreadCount } = useContext(NotificationContext);
  const { isOrganization } = useContext(OrganizationContext);

  const currentUser = useMemo(() => (isOrganization ? currentOrganization : currentCoach), [
    isOrganization,
    currentCoach,
    currentOrganization,
  ]);

  return (
    <HeaderContainer>
      {notificationsOpen && (
        <NotificationsModal
          modalOpen={notificationsOpen}
          closeModal={() => setNotificationsOpen(false)}
        />
      )}
      <Link to={URLS.home}>
        <Logo />
      </Link>
      <HeaderMain></HeaderMain>
      <SideButtonsRelativeBox isOrganization={isOrganization}>
        <SideButtonsAbsoluteBox>
          <Link to={URLS.profile}>
            <HeaderLink isActive={!notificationsOpen && pathname === URLS.profile}>
              <ProfilePic img={currentUser?.profileImage?.url} />
            </HeaderLink>
          </Link>
          {!isOrganization && (
            <div>
              <HeaderLink
                icon={unreadCount ? ICONS.NotificationsActiveIcon : ICONS.NotificationsIcon}
                isActive={notificationsOpen}
                onClick={() => setNotificationsOpen((curr) => !curr)}
              />
            </div>
          )}
          <Link to={URLS.settings}>
            <HeaderLink
              icon={ICONS.SettingsIcon}
              isActive={!notificationsOpen && pathname === URLS.settings}
            />
          </Link>
          <Link to="#" onClick={signOut}>
            <HeaderLink
              icon={ICONS.LogoutIcon}
              isActive={!notificationsOpen && pathname === URLS.logout}
            />
          </Link>
        </SideButtonsAbsoluteBox>
      </SideButtonsRelativeBox>
    </HeaderContainer>
  );
};

export default Header;
