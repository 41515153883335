import React from 'react';
import CustomTooltip from '../tooltip/CustomTooltip';
import {
  CalendarDayPlusIcon,
  DayDropdownButton,
  DayDropdownButtonIcon,
  DayDropdownContent,
  HoveredTitle,
} from './components';

const DayDropdown = ({ isOepn, items, isFirstRow, buttonSize }) =>
  items.length ? (
    <CalendarDayPlusIcon size={buttonSize} isOepn={isOepn} className="plus-icon">
      {isOepn && (
        <CustomTooltip
          below={isFirstRow}
          style={{
            width: '18rem',
            height: 'fit-content',
            left: '50%',
            marginLeft: `-9rem`,
            // marginBottom: `calc(${buttonSize} / 2 + 8px)`,
            background: '#FAFAFA',
            display: 'block',
            boxShadow: '0px 2px 4px #d0d0c8',
            padding: 0,
          }}
        >
          <DayDropdownContent>
            {items.map((item, index) =>
              item.hidden ? null : (
                <>
                  <DayDropdownButton
                    key={index}
                    style={item?.styles}
                    {...item.buttonProps}
                    hoverIcon={item.hoverIcon}
                    icon={item.icon}
                  >
                    <DayDropdownButtonIcon icon={item.icon} style={item?.iconStyles} />
                  </DayDropdownButton>
                  <HoveredTitle className="hover_title">{item?.title}</HoveredTitle>
                </>
              ),
            )}
          </DayDropdownContent>
        </CustomTooltip>
      )}
    </CalendarDayPlusIcon>
  ) : null;

export default DayDropdown;
