import React, { useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react';
import moment from 'moment';

import ChatContext from '../../services/ChatContext';
import { MobileContext } from '../../services/MobileContext';
import AttachmentButton from './AttachmentButton';
import {
  Avatar,
  AvatarName,
  ConversationsHeader,
  MessageListContainer,
  ChatFooter,
  SendButton,
  MessageInputContainer,
  Loading,
  SendingIndicator,
  FetchingIndicator,
  StyledTextarea,
} from './components';
import Message from './Message';

const MessageList = ({ conversation, messages = [], hideHeader, programRequest }) => {
  const {
    sendTextMessage,
    sendMediaMessage,
    fetchMoreMessages,
    hasMoreMessages,
    loadingMessages,
    sendingMessage,
    fetchingMoreMessages,
  } = useContext(ChatContext);

  const endedFourOrMoreDaysAgo = useMemo(() => {
    if (!programRequest) {
      return true;
    }
    if (programRequest?.coachProgram?.status !== 'ENDED') {
      return false;
    }
    const fourDaysAgo = moment().subtract(4, 'd');
    const programEnding = moment(programRequest?.coachProgram?.beginningDate, 'YYYY/MM/DD').add(
      programRequest?.programLength,
      'd',
    );
    return programEnding.isBefore(fourDaysAgo);
  }, [programRequest]);

  const [messageText, setMessageText] = useState('');

  const handleMessageSend = useCallback(() => {
    if (!sendingMessage) {
      sendTextMessage(messageText).then(() => {
        setMessageText('');
        setScrolled(false);
      });
    }
  }, [sendTextMessage, messageText, sendingMessage]);

  const messageListBottomRef = useRef();

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (conversation) {
      setScrolled(false);
    }
  }, [conversation]);

  useEffect(() => {
    if (Array.isArray(messages) && messages.length && !scrolled) {
      setTimeout(() => {
        if (messageListBottomRef?.current) {
          messageListBottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setScrolled(true);
        }
      }, [500]);
    }
  }, [conversation, sendingMessage, messages, scrolled]);

  const handleScrollToTop = useCallback(
    (event) => {
      if (conversation && !conversation.isNew && event.target.scrollTop === 0 && hasMoreMessages) {
        fetchMoreMessages();
      }
    },
    [fetchMoreMessages, hasMoreMessages, conversation],
  );

  const initials = useMemo(() => {
    const [first, last] = conversation?.user?.name?.split(' ') || ['', ''];
    return `${first ? first[0] : ''}${last ? last[0] : first[0]}`;
  }, [conversation]);

  const { isMobile } = useContext(MobileContext);

  return (
    <>
      {!hideHeader && (
        <ConversationsHeader>
          <Avatar src={conversation.user.avatar} size="4.2rem">
            {initials}
          </Avatar>
          <AvatarName>{conversation.user.name}</AvatarName>
        </ConversationsHeader>
      )}
      <MessageListContainer onScroll={handleScrollToTop}>
        {loadingMessages && <Loading>Loading...</Loading>}

        {fetchingMoreMessages && <FetchingIndicator>Loading more messages...</FetchingIndicator>}

        {messages.map((message, index) => (
          <Message
            key={message.id}
            message={message}
            showAvatar={
              index === 0 ||
              (message?.user?.id && message?.user?.id !== messages[index - 1]?.user?.id)
            }
            isLast={messages.length === index + 1}
          />
        ))}

        {sendingMessage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginTop: 'auto',
              paddingTop: '2.2rem',
              paddingRight: '1rem',
            }}
          >
            <SendingIndicator>Sending...</SendingIndicator>
          </div>
        )}

        <div className="it-scrolls-to-here">
          <div ref={messageListBottomRef} />
        </div>
      </MessageListContainer>
      {!endedFourOrMoreDaysAgo && (
        <ChatFooter isMobile={isMobile}>
          <AttachmentButton
            accept="image/*,video/*"
            onChange={(e) => sendMediaMessage(e.target.files[0])}
          />
          <MessageInputContainer isMobile={isMobile}>
            <StyledTextarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              maxRows={5}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault();
                  handleMessageSend();
                }
              }}
              placeholder={isMobile ? 'Type your message…' : ''}
            />
          </MessageInputContainer>
          <SendButton onClick={handleMessageSend} />
        </ChatFooter>
      )}
    </>
  );
};

export default MessageList;
