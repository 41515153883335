import React, { useState } from 'react';
import Modal from 'react-modal';
import 'react-image-crop/dist/ReactCrop.css';
import STRINGS from '../../constants/strings';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalHeaderLines,
  ModalPrimaryText,
  ModalMain,
  ModalSecondaryText,
  ButtonsFlexWrapper,
  ButtonWrapper,
} from './components';
import { useContactAdmin } from '../../hooks';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: 'Calc(100% - 40px)',
    maxWidth: '588px',
    minWidth: '320px',
    height: 'fit-content',
    top: '44%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const AlertModal = ({
  modalOpen,
  closeModal,
  title,
  content,
  okText = 'Ok',
  noText = 'Cancel',
  handleOk,
}) => {
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={modalOpen}
      style={customStyles}
      contentLabel="EditPersonalInfo"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          <ModalHeaderLines />
        </ModalHeader>
        <ModalMain>
          <ModalPrimaryText>{title}</ModalPrimaryText>
          <ModalSecondaryText>{content}</ModalSecondaryText>
          <ButtonsFlexWrapper>
            <ButtonWrapper>
              <CustomButton fontSize="1.4rem" fontFamily="Roboto-r" onClick={closeModal}>
                {noText}
              </CustomButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <CustomButton
                fontSize="1.4rem"
                fontFamily="Roboto-r"
                backgroundColor="#ffffff"
                outlined
                outlineColor="#00ff91"
                onClick={handleOk}
              >
                {okText}
              </CustomButton>
            </ButtonWrapper>
          </ButtonsFlexWrapper>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default AlertModal;
