import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import ICONS from '../../constants/icons';
import ChatContext from '../../services/ChatContext';
import CustomButton from "../../components/button/CustomButton"
import {
  CardContainer,
  CardHeader,
  CardHeaderIconCell,
  HeaderProfileCell,
  ProfilePic,
  MessengerIcon,
  ParticipantCardBody,
  CardName,
  CardLevel,
  ParticipantCardFooter,
  ParticipantHealthCell,
  ParticipantHealthCellValue,
  ParticipantPhysicsWrapper,
  ParticipantPhysic,
  CardFooter,
  FooterLine,
  MessengerIconImg,
  ButtonsWrapper,
  ButtonWrapper
} from './components';

const ParticipantCard = ({ participant, handleCancel, handleOpenMoreInfo }) => {
  const history = useHistory();

  const { conversations, setCurrentConversation } = useContext(ChatContext);

  const { conversation, hasUnread } = useMemo(() => {
    const groupId = participant?.user?.uid;
    const conversation = conversations[groupId];

    return {
      conversation,
      hasUnread: !!(conversation && conversation.unreadMessageCount
        ? conversation.unreadMessageCount
        : 0),
    };
  }, [conversations, participant]);

  return (
    <CardContainer
      style={{
        minWidth: '24rem',
        width: 'max(26rem, Calc(100% / 6 - 2.4rem))',
        height: 'fit-content',
      }}
    >
      <CardHeader style={{ background: '#fafafa' }}>
        <CardHeaderIconCell>
          <MessengerIcon active={'message'} onClick={handleCancel}>
            <MessengerIconImg cancelBtn active={hasUnread} />
          </MessengerIcon>
        </CardHeaderIconCell>
        <HeaderProfileCell>
          <ProfilePic profilePic={participant?.avatar?.url || ''}>
            {!participant?.avatar?.url &&
              participant?.fullName
                ?.split(' ')
                .map((x) => x.substring(0, 1))
                .join('')}
          </ProfilePic>
        </HeaderProfileCell>
        <CardHeaderIconCell>
          <MessengerIcon
            active={'message'}
            onClick={() => {
              setCurrentConversation(conversation);
              history.push('/messages', {
                trainee: participant,
              });
            }}
          >
            <MessengerIconImg active={hasUnread} />
          </MessengerIcon>
        </CardHeaderIconCell>
      </CardHeader>
      <ParticipantCardBody>
        <CardName
          style={{
            marginBottom: '4px',
            color: '#373333dd',
            height: '4rem',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {participant?.fullName}
        </CardName>
        <CardLevel style={{ marginBottom: '1.2rem' }}>
          {participant?.programs?.[0]?.fitnessLevel}
        </CardLevel>
        <ParticipantPhysicsWrapper>
          <ParticipantPhysic
            physIcon={ICONS.Weight}
          >{`${participant?.weight}kg`}</ParticipantPhysic>
          <ParticipantPhysic physIcon={ICONS.Height}>{participant?.height}</ParticipantPhysic>
          <ParticipantPhysic physIcon={ICONS.Age}>{`${participant?.age}y`}</ParticipantPhysic>
        </ParticipantPhysicsWrapper>
      </ParticipantCardBody>
      <ParticipantCardFooter>
        <ParticipantHealthCell>
          Health Status
          <ParticipantHealthCellValue>
            {(participant?.programs?.[0]?.healthCondition &&
              participant?.programs?.[0]?.healthConditionDetails) ||
              'Healthy'}
          </ParticipantHealthCellValue>
        </ParticipantHealthCell>
        <ParticipantHealthCell>
          Injuries
          <ParticipantHealthCellValue>
            {`${
              participant?.programs?.[0]?.injuryDetails ||
              participant?.programs?.[0]?.injury ||
              'None'
            }`}
          </ParticipantHealthCellValue>
        </ParticipantHealthCell>
      </ParticipantCardFooter>
      <CardFooter style={{ height: '5.8rem' }}>
        <ButtonsWrapper>
          <ButtonWrapper>
            <CustomButton
              outlined
              borderRadius="8px"
              hoverBackground="#00ff91"
              backgroundColor="#ffffff"
              style={{ minHeight: '3.6rem' }}
              fontSize="1.4rem"
              height="3.6rem"
              onClick={ handleOpenMoreInfo }
            >
              More Info
            </CustomButton>
          </ButtonWrapper>
        </ButtonsWrapper>
      </CardFooter>
      <CardFooter style={{ height: '9px', background: '#fafafa' }}>
        <FooterLine />
        <FooterLine />
      </CardFooter>
    </CardContainer>
  );
};

export default ParticipantCard;
