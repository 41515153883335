import { Field, Form, Formik } from 'formik';
import React from 'react';
import Modal from 'react-modal';
import EdittingWorkoutBlock from '../add-calendar-modal/EdittingWorkoutBlockV2';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '111111',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden auto',
  },
  content: {
    background: 'transparent',
    zIndex: '11111111',
    width: 'Calc(100% - 20px)',
    maxWidth: '588px',
    minWidth: '300px',
    height: 'fit-content',
    top: '10px',
    minHeight: 'Calc(100vh - 20px)',
    display: 'flex',
    alignItems: 'center',
    left: 'max(10px, Calc(50% - 294px))',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
    pointerEvents: 'all',
  },
};

const WorkoutBlockEditModal = ({ modalOpen, closeModal, submitModal, initialValues }) => {
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!modalOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      <Formik initialValues={initialValues}>
        {({ setFieldValue, values }) => (
          <Form style={{ width: '100%' }}>
            <Field id={`blocks[${modalOpen - 1}]`} name={`blocks[${modalOpen - 1}]`}>
              {({ field }) => (
                <EdittingWorkoutBlock
                  block={field.value}
                  index={modalOpen - 1}
                  setFieldValue={setFieldValue}
                  validateForm={() => {}}
                  deleteSelf={() => {}}
                  preventExerciseAdding
                  week
                  contentPadding="16px"
                  handleCancel={closeModal}
                  onSubmit={() => submitModal(values)}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default WorkoutBlockEditModal;
