import React, { useEffect } from 'react';
import Select, { components } from 'react-select';

import {
  MultiValueItem,
  MultiValueName,
  MultiValueCircle,
  OptionCheck,
  OptionOption,
  OptionCircle,
} from './components';
import { OptionName, OptionRow } from '../programs-header/components';
import { CoachProgramStatus } from '../../constants/enums';

const itemBg = (a) => {
  console.log(a);
  switch (a) {
    case CoachProgramStatus.APPROVED:
      return '#FFCC0014';
    case CoachProgramStatus.DECLINED:
      return '#F50A4F14';
    default:
      return '#00FF9114';
  }
};

const customStyles = ({ error, margin, width, small, borderColor }) => ({
  control: (provided) => ({
    ...provided,
    width: width,
    maxWidth: '100%',
    height: 'fit-content',
    minHeight: '3.2rem',
    borderRadius: '8px',
    boxShadow: 'none',
    marginRight: margin,
    fontSize: '1.4rem',
    alignItems: 'center',
    borderColor: error ? '#F50A4F' : borderColor || '#05000033',
    '&:hover': {
      borderColor: error ? '#F50A4F' : '#05000033',
    },
    display: 'flex',
  }),
  input: (provided) => ({
    ...provided,
    maxHeight: small ? '3rem' : '3.6rem',
    fontSize: '1.4rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.4rem',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    opacity: 0.4,
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: small ? '1.4rem' : 'inherit',
    padding: '0.4rem 0',
    background: 'white',
    color: '#050000cc',
    cursor: 'pointer',
    '&:hover': {
      background: '#E6FFF4',
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: itemBg(state.data?.value),
  }),
});

const Option = (props) => {
  return (
    <components.Option {...props}>
      <OptionRow style={{ paddingRight: '2rem' }}>
        <OptionOption>
          <OptionCheck isSelected={props.isSelected} />
          <OptionName style={{ flexGrow: 1 }}>{props.data.label}</OptionName>
        </OptionOption>
        {props.data.value && <OptionCircle className="option_icon" status={props.data.value} />}
      </OptionRow>
    </components.Option>
  );
};

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <MultiValueItem status={props.data.value}>
        {props.data.value && <MultiValueCircle className="option_icon" status={props.data.value} />}
        <MultiValueName>{props.data.label}</MultiValueName>
      </MultiValueItem>
    </components.MultiValue>
  );
};

const ReactSelectMulti = ({
  value,
  onChange,
  options,
  error,
  checkBoxes = false,
  onDeleteOne = () => {},
  placeholder,
  maxSelected = 3,
  margin = '2.4rem',
  width = '38rem',
  disabled,
  small,
  borderColor,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Select
        options={options}
        isMulti
        styles={customStyles({ error, margin, width, small, borderColor })}
        value={value}
        onChange={onChange}
        backspaceRemovesValue={false}
        placeholder={placeholder}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          ClearIndicator: false,
          IndicatorSeparator: false,
          Option: Option,
          MultiValue,
        }}
      />
    </div>
  );
};

export default ReactSelectMulti;
