const currentLanguage = process.env.lang || 'EN';

const strings = {
  EN: {
    // coach signup personal
    new_coach_registration_form: 'New Coach Registration Form',
    personal_info: 'Personal Info',
    name: 'Name',
    id: 'ID',
    phone_number: 'Phone Number',
    email: 'E-mail',
    address: 'Address',
    continue: 'Continue',
    upload_profile_image: 'Upload your profile image',
    i_agree_to: 'I Agree to Witness ',
    terms_and_conditions: 'Terms & Conditions ',
    terms_of_service:'Terms of Service ',
    privacy_policy: 'Privacy Policy',
    and: 'and ',

    // coach signup professional
    professional_info: 'Professional Info',
    training_specialities: 'TRAINING SPECIALITIES',
    please_select_all_specialities:
      'Please select all the areas you are certified and qualified to train customres',
    years_of_experience: 'YEARS OF EXPERIENCE',
    how_many_years_coaching: 'How many years are you working as a Coach?',
    diplomas_and_credentials: 'DIPLOMAS & CREDENTIALS',
    provide_all_credentials:
      'For each training speciality you chose, please provide all diplomas, certificates & courses you performed',
    favorite_playlist: 'FAVORITE PLAYLIST',
    favorite_meal: 'FAVORITE MEAL',
    share_playlist: 'Share your favorite playlist',
    share_meal: 'Share your favorite meal',
    tell_about_yourself: 'TELL ABOUT YOURSELF',
    describe_personal_story:
      'Describe your personal story, your proffessional path and anything else you want to share with potential customers to know you better.',
    type_here: 'Type Here...',
    back: 'Back',
    submit_form: 'Submit Form',

    // upload credentials
    type_name_of_spec: 'Type name of speciality',
    attach_files: 'Attach Files',
    save: 'Save',
    required: 'Required',
    coach_credentials: 'Coach Credentials',

    // coach signup result
    your_request_success: 'Your request is being processed',
    your_request_approved: 'Your request was approved',
    we_will_contact_in_day: 'We will contact you ASAP',
    please_check_email: 'Please check the email we sent you',
    if_question_contact_us: 'Feel free to contact our support team in the meantime',
    contact_us: 'Contact us',

    // program request
    new_program_request: 'NEW PROGRAM REQUEST',
    health_and_fitness: 'Health & Fitness',
    fitness_level: 'Fitness Level',
    injures: 'Injuries',
    training_environment: 'Training Environment',
    available_equipment: 'Available Equipment',
    health_status: 'Health Status',
    focus_on: 'Focus On',
    program_details: 'Program Details',
    program_length: 'Program Length',
    workout_time: 'Workout time',
    desired_agenda: 'Desired Agenda',
    training_type: 'Training Types',
    training_goals: 'Training Goals',
    notes: 'Notes',
    total_price: 'TOTAL PRICE:',
    decline_request: 'Decline Request',
    chat_with: 'Chat with ',
    approve_request: 'Approve Request',

    // modal
    close: 'Close',
    contact_admin: 'Contact Admin',

    // edit personal info
    edit_personal_info: 'EDIT YOUR PERSONAL INFO',
    e_mail: 'E-mail',
    gender: 'Gender',
    age: 'Age',
    height: 'Height (cm)',
    weight: 'Weight (kg)',
    favorite_meal_camel: 'Favorite Meal',
    favorite_playlist_camel: 'Favorite Playlist',

    // edit about me
    edit_about_me: 'EDIT "ABOUT ME"',

    // you cannot edit professional info
    cannot_change_professional_info: 'You Can’t change your Professional Info',
    if_u_want_to_change_professional_info:
      'If you want to change information in this section, please, contact our Admin',

    // you cannot edit personal info
    cannot_change_personal_info: 'You Can’t change your Personal Info',
    if_u_want_to_change_personal_info:
      'If you want to change information in this section, please, contact our Admin',
    
    user_not_found: 'User was not found',
    invalid_token: 'Token is invalid or expired',
  },
};

export default strings[currentLanguage];
