import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { setPasswordMutation, signInMutation, verifyEmailMutation } from '@witness/graphql';
import apolloClient from '../services/apollo';
import URLS from '../constants/urls';

const useSignin = () => {
  const history = useHistory();

  const [setPassword] = useMutation(setPasswordMutation);

  const submitSetPassword = async (values, { setErrors, setStep }) => {
    const { email, password } = values;
    try {
      await setPassword({
        variables: {
          record: {
            email,
            password,
          },
        },
      });
      history.push(URLS.sign_in);
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'user_not_found')) {
        setErrors({ email: 'Email does not exist' });
        setStep('email');
      }
    }
  };

  const [verifyEmail] = useMutation(verifyEmailMutation);

  const submitVerifyEmail = async (values, { setErrors, setStep }) => {
    const { email } = values;
    try {
      await verifyEmail({
        variables: {
          record: { email },
        },
      });
      setStep('password');
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'user_not_found')) {
        setErrors({ email: 'Email does not exist' });
      }
    }
  };

  const [signIn] = useMutation(signInMutation);

  const signInAndRedirect = async (values) => {
    const signedCoach = await signIn({
      variables: {
        record: values,
      },
    });
    localStorage.setItem('token', signedCoach?.data?.signIn?.token);
    history.push(
      signedCoach?.data?.signIn?.user?.role?.name === 'admin' ? URLS.templates : URLS.home,
    );
  };

  const submitSignIn = async (values, { setErrors }) => {
    try {
      await signInAndRedirect(values)
    } catch (err) {
      if (err.graphQLErrors.find((x) => x.message === 'invalid_credentials')) {
        setErrors({ credentials: 'Email or password are invalid' });
      } else {
        setErrors({ credentials: 'Please, verify your email first' });
      }
    }
  };

  const signOut = () => {
    localStorage.removeItem('token');
    apolloClient.clearStore();
    history.push(URLS.sign_in);
  };

  return { submitSetPassword, signInAndRedirect, submitSignIn, signOut, submitVerifyEmail };
};

export default useSignin;
