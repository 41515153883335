import styled, { keyframes, css } from 'styled-components';
import ICONS from '../../constants/icons';

export const ProfileContainer = styled.div`
  display: flex;
  background: #f1f0f0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(74, 74, 74, 0.12);
  min-height: Calc(100vh - 144px);
`;

export const ProfileNavigation = styled.div`
  min-width: 20%;
  max-width: 360px;
  background: #f1f0f0;
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const ProfileMain = styled.div`
  height: 100%;
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

export const ProfileMainEdgeColumn = styled.div`
  min-width: 21%;
  background: ${({ bg }) => bg || '#f9f9f9'};
  border-left: 2px solid #0500001f;
  display: flex;
  flex-direction: column;
`;

export const ProfileMainCenterColumn = styled.div`
  flex-grow: 1;
  border-left: 2px solid #0500001f;
  background: #ffffff;
`;

export const ProfileNavHeading = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #ffffff66;
  padding: 1.8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Space-b;
  font-size: 2rem;
  margin-bottom: 4rem;
`;

export const ProfileNavLink = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${({ active, theme }) => (active ? theme.color.primary : '#ffffff')};
  box-shadow: ${({ active }) => (active ? '0px 20px 20px -24px rgb(0 0 0 / 40%)' : 'none')};
  color: ${({ active }) => (active ? '#050000' : '#373333cc')};
  font-family: ${({ active }) => (active ? 'Roboto-m' : 'Roboto-r')};
  background-image: url(${({ icon, active, activeIcon }) => (active ? activeIcon : icon)});
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;
  background-position: 1.8rem center;
  background-size: 1.6rem 2rem;
  background-repeat: no-repeat;
  padding: 2rem 3rem 2rem 5rem;
  cursor: pointer;
  text-align: center;
`;

export const ProfileImageBlock = styled.div`
  width: 100%;
  padding: 3.6rem 18% 4.8rem;
`;
export const ProfileImageFrame = styled.div`
  width: 100%;
  border: 3px solid ${({ theme }) => theme.color.primary};
  padding: 5%;
  border-radius: 50%;
  position: relative;
`;
export const ProfileId = styled.div`
  width: 15rem;
  border-radius: 8.8px;
  border: 3px solid ${({ theme }) => theme.color.primary};
  position: absolute;
  z-index: 11;
  background: #ffffff;
  font-family: Space-b;
  font-size: 1.8rem;
  line-height: 4rem;
  min-height: Calc(1.8rem + 20px);
  color: #373333;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0.5rem 1rem; */
  margin: 0 Calc(50% - 8.5rem);
  bottom: -3%;
`;
export const ProfileIdInfo = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background: grey;
  margin-left: 0.8rem;
  font-family: Space-b;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #4a4a4a;
  background: #c5c4c43d;
  border: 2px solid #9694943d;
  padding: 0.4rem;
  cursor: pointer;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0')};
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.color.primary};
    border-color: #15df68;
  }
  &:hover > .wit_tooltip {
    display: block;
  }
`;
export const ProfileImage = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 50%;
  background-image: url(${({ image }) => image});
  padding: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: visible;
`;

export const CameraIcon = styled.div`
  width: 26%;
  height: 26%;
  border: 2px solid ${({ theme }) => theme.color.primary};
  position: absolute;
  z-index: 122;
  background: #ffffff;
  border-radius: 50%;
  background-image: url(${ICONS.CameraIcon});
  background-size: 53% auto;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  right: -16%;
  bottom: 12%;
  overflow: visible;
`;

export const BorderLabel = styled.div`
  padding: 0 1.2rem;
  background: inherit;
  font-family: Space-b;
  display: flex;
  align-items: center;
  color: #050000cc;
  position: absolute;
  top: -1.4rem;
  left: 26px;
`;

export const BorderLabelEditIcon = styled.div`
  padding: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${ICONS.Edit});
  margin-left: 1.2rem;
  cursor: pointer;
  &:hover {
    background-image: url(${ICONS.EditGreen});
  }
`;

export const LabeledInfoBlock = styled.div`
  width: 100%;
  background: inherit;
  border-top: 2px solid #0500001f;
  position: relative;
  flex-grow: 1;
`;

export const ProfileName = styled.div`
  margin: 3.6rem 4rem 6.4rem 4rem;
  line-height: 3.6rem;
  font-family: Gotham-R;
  font-size: 3.2rem;
  color: #050000cc;
`;

export const ChangesSavedIcon = styled.div`
  padding: 16px 7px;
  background-image: url(${ICONS.Logo});
  margin-right: 0.8rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  margin: 8px;
`;

const Pulse = keyframes`
  0%, 80%, 100% {
    padding: 0rem;
    margin: 0 0.9rem;
  }
  40% {
    padding: 0.5rem;
    margin: 0 0.4rem;
  }
`;

export const LoadingDot = styled.span`
  margin: 0 0.9rem;
  padding: 0;
  border-radius: 50%;
  background: #05000099;
  animation: ${Pulse} 1.4s infinite ease-in-out both;
  animation-delay: ${({ i }) => `${i * -0.16}s`};
`;
export const ChangesSaved = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  color: #05000099;
  width: ${({ autoSize }) => (autoSize ? 'fit-content' : '100%')};
  height: ${({ autoSize }) => (autoSize ? 'fit-content' : '13.55rem')};
  /* margin: 2.6rem 4rem 5.4rem; */
  font-size: 1.8rem;
`;

export const PhysicalInfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #0500001f;
`;

export const PhysicalInfoItem = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #0500001f;
  padding: 3.6rem 0.8rem 2.4rem;
  font-size: 2rem;
  font-family: Space-b;
  width: 25%;
  &:last-child {
    border: none;
  }
`;

export const PhysicalInfoItemLabel = styled.div`
  font-family: Roboto-l;
  font-size: 1.2rem;
  color: #4a4a4a;
  margin-top: 8px;
  text-align: center;
  height: 2.4rem;
`;

export const PersonalInfoContainer = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
`;

export const AboutContainer = styled.div`
  width: 100%;
  padding: 4.2rem 0.8rem 4.8rem;
  color: #050000cc;
  font-size: 1.4rem;
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  max-height: 24.7rem;
  height: 100%;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
  overflow-y: auto;
  padding: 0 2.8rem;
`;

export const ProfessionalInfoContainer = styled.div`
  width: 100%;
  padding: 4rem 4rem 0 4rem;
`;

export const ProfessionalInfoItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProfessionalInfoItemLabel = styled.div`
  width: 20rem;
  display: flex;
  padding: 1.2rem 2.4rem 1.2rem 0;
  align-items: center;
  font-family: Roboto-m;
  font-size: 1.4rem;
  color: #05000077;
`;

export const ProfessionalInfoGreenCircle = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 1.2rem;
  background: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
`;

export const ProfessionalInfoItemValue = styled.div`
  flex-grow: 1;
  padding: 0 0 2.4rem;
  display: flex;
  flex-wrap: wrap;
  max-width: Calc(100% - 20rem);
`;

export const ExperienceWrapper = styled.div`
  width: 112px;
`;

export const PseudoSpeciality = styled.div`
  width: 100px;
  flex-grow: 1;
`;

export const CredentialItem = styled.div`
  width: 100%;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.8rem 0.6rem 1.6rem;
  font-size: 1.4rem;
  margin-bottom: 2.4rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: ${`url(${ICONS.Download})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 4px;
`;

export const GreyButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background: #f1f0f0;
  background-image: ${({ icon }) => (icon ? `url(${icon})` : `url(${ICONS.File})`)};
  background-position: center;
  background-size: 48%;
  background-repeat: no-repeat;
  margin-right: 4px;
  background-color: #ffffff;
`;

export const PortfolioScroll = styled.div`
  height: 100%;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const PortfolioWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 4rem 0.6rem;
  flex-grow: 1;
  height: 100%;
  position: absolute;
`;

export const PortfolioItemWrapper = styled.div`
  opacity: ${({ blur }) => (blur ? '0.5' : '1')};
  width: Calc(50% - 16px);
  margin: 0 0.8rem 1.6rem;
  padding-bottom: Calc(35% - 0.8rem);
  background: ${({ addBlock }) => (addBlock ? 'transparent' : 'black')};
  flex-grow: 1;
  border-radius: 8px;
  background-image: url(${({ image }) => image});
  background-size: ${({ size }) => (size ? size : 'cover')};
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  &:hover > #scalable {
    background-size: auto 30%;
  }
`;

export const VideoSign = styled.div`
  background-image: url(${ICONS.VideoSign});
  background-size: auto 25%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 11;
`;

export const DeleteSign = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${ICONS.BinSimpleGrey});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 8px;
  right: 8px;
  position: absolute;
  z-index: 11;
  &:hover {
    transform: scale(1.2);
  }
`;

export const PseudoPortfolioItem = styled.div`
  width: Calc(50% - 16px);
  margin: 0 0.8rem 1.6rem;
`;

// pricing & coupons

export const PricingWrapper = styled.div`
  width: 100%;
  background: #ffffff;
`;

export const PricingHeader = styled.div`
  width: 100%;
  border-bottom: 2px solid #0500000b;
  height: fit-content;
  display: flex;
`;

export const PricingHeaderItem = styled.div`
  width: ${({ isMobile }) => (isMobile ? '50%' : '172px')};
  border-right: 2px solid #0500000b;
  border-left: 2px solid transparent;
  padding: 2rem 0;
  text-align: center;
  line-height: 2rem;
  cursor: pointer;
  color: #373333;
  font-family: ${({ active }) => (active ? 'Roboto-m' : 'Roboto-r')};
  font-size: ${({ active }) => (active ? '1.8rem' : '1.6rem')};
  position: relative;
  &:last-child {
    border-left: ${({ isMobile }) => (isMobile ? '1px solid #0500000b' : '1px solid transparent')};
    border-right: ${({ isMobile }) => (isMobile ? '1px solid transparent' : '1px solid #0500000b')};
  }
  &::after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    position: absolute;
    width: Calc(100% + 4px);
    height: 4px;
    background: #53ff8a;
    bottom: -2px;
    border-radius: 3px;
    left: -2px;
  }
`;

export const ProfileCode = styled.div`
  font-family: Roboto-r;
  font-size: 1.6rem;
  line-height: 4.5rem;
  color: #05000066;
  padding: 1.2rem 3.2rem;
  margin: 1.4rem 0 2.2rem;
  display: flex;
  align-items: center;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 2.8rem;
      border-radius: 8px;
      border: solid 0.5px rgba(0, 0, 0, 0.15);
      width: calc(100% - 40px);
      justify-content: center;
      height: 48px;
      font-family: Roboto-r;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.62;
      letter-spacing: 0.4px;
      text-align: left;
      color: #373333;
    `}
`;

export const ProfileCodeValue = styled.span`
  font-family: Space-b;
  font-size: 3rem;
  line-height: 4.5rem;
  color: #050000;
  padding-left: 2rem;
  padding-bottom: 0.4rem;
  ${({ isMobile }) =>
    isMobile &&
    css`
      font-family: Gotham-r;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: unset;
      letter-spacing: 1px;
      text-align: left;
      color: #4a4a4a;
      padding-bottom: 0;
    `}
`;

export const FlexWrapperSpaceBet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column-reverse;
    `}
`;

export const AddCouponButton = styled.button`
  border: none;
  outline: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.primary};
  box-shadow: 0px 2rem 2rem -2rem rgb(0 0 0 / 40%);
  color: #050000;
  font-family: Roboto-m;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 4rem;
  cursor: pointer;
  text-align: center;
  margin-right: 3.2rem;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 24px 20px 11px 20px;
      width: calc(100% - 40px);
      height: 48px;
    `}
  &:active {
    box-shadow: 0px 2rem 2rem -2.2rem rgb(0 0 0 / 40%);
    transform: scale(0.98);
  }
`;

export const AddCouponButtonMobileText = styled.span`
  font-family: Roboto-b;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #050000;
`;

export const AddCouponButtonPlus = styled.span`
  padding: 1.6rem;
  background-image: url(${ICONS.PlusIcon});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-right: 0.8rem;
  cursor: pointer;
`;

export const PricingListWrapper = styled.div`
  padding: 0 3.2rem;
`;

export const PricingListHeading = styled.div`
  font-family: Roboto-m;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #4a4a4a;
`;

export const ProgramsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1.6rem;
`;

export const SingleProgramWrapper = styled.div`
  width: Calc(33% - 3.2rem);
  min-width: 28rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #f1f0f0;
  margin: 0 1.6rem 3.2rem;
`;

export const PseudoSingleProgram = styled.div`
  width: Calc(33% - 3.2rem);
  min-width: 28rem;
  flex-grow: 1;
  display: flex;
  margin: 0 1.6rem 3.2rem;
`;

export const ProgramImage = styled.div`
  width: 100%;
  padding-bottom: 72%;
  background: #00000055;
  position: relative;
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  border: 0.5px solid #d1d1d188;
`;

export const ProgramImageFade = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffffff55;
  display: flex;
  justify-content: center;
`;

export const ProgramImageLines = styled.div`
  width: 33%;
  height: 100%;
  border-right: 1px solid #05000066;
  border-left: 1px solid #05000066;
`;

export const ProgramName = styled.div`
  position: absolute;
  text-transform: uppercase;
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Gotham-r;
  font-size: 2.1rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  color: #ffffff;
  padding: 2rem 0;
  min-height: fit-content;
  background: #4a4a4a99;
  top: max(33%, 3.25rem);
`;

export const PriceHeading = styled.div`
  font-family: Gotham-r;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #4a4a4a99;
  padding: 1.6rem 0 1.2rem;
`;

export const PriceInputWrapper = styled.div`
  width: 80%;
  height: 8rem;
  border-radius: 8px;
  margin: auto;
`;

export const AdvencedOptions = styled.div`
  margin: 1.6rem 0 3.2rem;
  font-family: Roboto-m;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #0500009a;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid #37333399;
  }
`;

// preferences & settings

export const SettingsWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  min-height: Calc(100vh - 144px);
  padding: 32px 0;
`;

export const SettingsInfoBlock = styled.div`
  width: 100%;
  padding: 5.6rem 5.6rem 6.4rem;
`;

export const SettingsInputLabel = styled.div`
  line-height: 2rem;
  font-size: 1.6rem;
  font-family: roboto-r;
  margin-bottom: 2.4rem;
  color: #05000099;
`;

export const LanguageWrapper = styled.div`
  margin-bottom: 3.2rem;
  display: flex;
  align-items: center;
`;

export const LanguageLabel = styled.div`
  padding: 0.8rem 2.6rem 0.8rem 2.4rem;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.primary};
  margin: 0 0.4rem;
  font-family: Roboto-r;
  font-size: 1.6rem;
  line-height: 2rem;
  position: relative;
  &:before {
    cursor: pointer;
    position: absolute;
    content: '';
    right: -0.6rem;
    top: -0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    background: #00000015;
    border-radius: 50%;
  }
`;

export const MultiValueDeleteX = styled.div`
  cursor: pointer;
  position: absolute;
  content: '';
  right: -0.5rem;
  top: -0.5rem;
  width: 1.6rem;
  height: 1.6rem;
  background: red;
  background-image: url(${ICONS.PlusIcon});
  background-size: 120%;
  z-index: 224554;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(45deg);
  &:hover {
    transform: scale(1.25) rotate(45deg);
  }
`;

export const TraineesWrapper = styled.div`
  width: 16.4rem;
  height: 4.8rem;
`;

export const SettingUnits = styled.div`
  display: flex;
  align-items: center;
`;

export const UnitBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.6rem;
`;

export const UnitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 3.2rem;
  border-radius: 8px;
  border: 1px solid ${({ theme, active }) => (active ? theme?.color?.primary : '#CDCCCC')};
  box-shadow: inset 0px 0px 0px 1px
    ${({ theme, active }) => (active ? theme?.color?.primary : 'transparent')};
  line-height: 2rem;
  color: ${({ active }) => (active ? '#050000' : '#05000080')};
  margin-right: 1.2rem;
  cursor: pointer;
`;

export const Pusher = styled.div`
  width: 100%;
  min-height: 12rem;
  display: flex;
  flex-grow: 1;
`;

export const TermsAndPrivacy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled.a`
  font-size: 1.8rem;
  min-height: 1.8rem;
  font-family: Roboto-m;
  cursor: pointer;
  margin-top: 1.2rem;
  &:hover {
    text-decoration: underline;
  }
`;
export const CoachesWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 3.2rem 2rem;
`;
export const CoachRowName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem 0.8rem;
  border-bottom: 1px solid #05000012;
  &:last-child {
    border: none;
  }
`;
export const CoachRowImage = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.6rem;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
`;

export const CoachFeeWrapper = styled.div`
  width: 20%;
  flex-grow: ${({ grow }) => (grow ? '1' : 'none')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Roboto-m;
  font-size: 1.4rem;
  color: #37333399;
  margin-bottom: 4.4rem;
  padding: 0 1.6rem;
  margin-left: 1.6rem;
`;

export const CoachFeeInput = styled.div`
  font-family: Roboto-m;
  font-size: 1.6rem;
  color: #37333399;
`;
