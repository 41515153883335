import React, { useContext } from 'react';
import Modal from 'react-modal';

import STRINGS from '../../constants/strings';
import ICONS from '../../constants/icons';
import { workoutToMin } from '../../services/utils';
import useProgramRequestPreview from '../../hooks/programRequestPreview';

import {
  WholeWrapper,
  ModalHeader,
  ModalRow,
  UpperRowCell,
  MiddleRowCell,
  BottomRowCell,
  RowTitle,
  UpperRowCellLine,
  MiddleRowCellIcon,
  MiddleRowCellKey,
  MiddleRowCellValue,
  ProfilePic,
  ProfileName,
  GenderIcon,
  ParameterRow,
  ParameterCell,
  ModalCloseX,
  ModalContentMain,
  ModalContent,
  TicketIcon,
  TicketDigits,
  TicketNote,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 11111111,
  },
  content: {
    width: '74%',
    maxWidth: '1320px',
    minWidth: '980px',
    height: 'Calc(82vh + 30px)',
    top: '50%',
    left: '50%',
    right: 'auto',
    justifyContent: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '8px',
    border: 'none',
    padding: '0 ',
  },
};

const TraineeInfoModal = ({ content, closeModal }) => {
  const {
    weekDays,
  } = useProgramRequestPreview({ content });

  console.log('programRequest - ', content);
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!content}
      style={customStyles}
      contentLabel="Trainee Information"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
            Trainee Information
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <ModalContent>
          <ModalContentMain>
            <ModalRow upper>
              <UpperRowCell background={ICONS.LeftCellBackground}>
                <UpperRowCellLine />
              </UpperRowCell>
              <UpperRowCell mid>
                <ProfilePic image={content?.trainee?.avatar?.url}>
                  {content?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')
                    .substring(0, 3)}
                </ProfilePic>
                <ProfileName>
                  {content?.trainee?.fullName}
                  <GenderIcon gender={content?.trainee?.gender} />
                </ProfileName>
                <ParameterRow>
                  <ParameterCell>
                    {STRINGS.weight}
                    <ParameterCell val>{`${content?.trainee?.weight}`}</ParameterCell>
                  </ParameterCell>
                  <ParameterCell>
                    {STRINGS.height}
                    <ParameterCell val>{content?.trainee?.height}</ParameterCell>
                  </ParameterCell>
                  <ParameterCell>
                    {STRINGS.age}
                    <ParameterCell val>{content?.trainee?.age}</ParameterCell>
                  </ParameterCell>
                </ParameterRow>
              </UpperRowCell>
              <UpperRowCell background={ICONS.RightCellBackground}>
                <UpperRowCellLine
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    alignSelf: 'center',
                    height: '100%',
                  }}
                />
                {!!content?.code && (
                  <TicketIcon>
                    <TicketDigits>
                      #{`000${Math.floor(content?.code?.digits / 1000)}`.slice(-3)}-
                      {`000${content?.code?.digits % 1000}`.slice(-3)}
                    </TicketDigits>
                    <TicketNote>Code Used</TicketNote>
                  </TicketIcon>
                )}
              </UpperRowCell>
            </ModalRow>
            <ModalRow grow>
              <RowTitle>{STRINGS.health_and_fitness}</RowTitle>
              <MiddleRowCell grow={'1'}>
                <MiddleRowCellIcon icon={ICONS.FitnessLevel} />
                <MiddleRowCellKey>{STRINGS.fitness_level}</MiddleRowCellKey>
                <MiddleRowCellValue>{content?.fitnessLevel}</MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell>
                <MiddleRowCellIcon icon={ICONS.Environment} />
                <MiddleRowCellKey>{STRINGS.training_environment}</MiddleRowCellKey>
                <MiddleRowCellValue>{content?.place?.join(', ')}</MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell grow="2">
                <MiddleRowCellIcon icon={ICONS.Injures} />
                <MiddleRowCellKey>{STRINGS.injures}</MiddleRowCellKey>
                <MiddleRowCellValue>{`${content?.injury || ''}${
                  (content?.injuryDetails && '. ' + content?.injuryDetails) || ''
                }`}</MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell>
                <MiddleRowCellIcon icon={ICONS.Equipment} />
                <MiddleRowCellKey>{STRINGS.available_equipment}</MiddleRowCellKey>
                <MiddleRowCellValue>
                  {content?.equipments?.map((eq) => eq.name).join(', ')}
                  {!!content?.equipments?.length && content?.otherEquipment && ', '}
                  {content?.otherEquipment}
                  {!(content?.equipments?.length || content?.otherEquipment) &&
                    "Trainee didn't mention any available equipment"}
                </MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell grow={'1'} last>
                <MiddleRowCellIcon icon={ICONS.Health} />
                <MiddleRowCellKey>{STRINGS.health_status}</MiddleRowCellKey>
                <MiddleRowCellValue>
                  {(content?.healthCondition && content?.healthConditionDetails) || 'Healthy'}
                </MiddleRowCellValue>
              </MiddleRowCell>
            </ModalRow>
            <ModalRow grow>
              <RowTitle>{STRINGS.program_details}</RowTitle>
              <MiddleRowCell>
                <MiddleRowCellIcon icon={ICONS.ProgramLength} />
                <MiddleRowCellKey>{STRINGS.program_length}</MiddleRowCellKey>
                <MiddleRowCellValue>{content?.programLength} days</MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell>
                <MiddleRowCellIcon icon={ICONS.Time} />
                <MiddleRowCellKey>{STRINGS.workout_time} </MiddleRowCellKey>
                <MiddleRowCellValue>
                  {workoutToMin(content?.workoutLength)} min
                </MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell grow="2">
                <MiddleRowCellIcon icon={ICONS.Type} />
                <MiddleRowCellKey>{STRINGS.desired_agenda}</MiddleRowCellKey>
                <MiddleRowCellValue>
                  {content?.daysOfTraining?.map((d) => weekDays[d]).join(', ')}
                </MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell>
                <MiddleRowCellIcon icon={ICONS.Goal} />
                <MiddleRowCellKey>{STRINGS.training_type}</MiddleRowCellKey>
                <MiddleRowCellValue>
                  {content?.trainingTypes?.map((ttype) => ttype?.name)?.join(', ') ||
                    content?.trainingType?.name}
                </MiddleRowCellValue>
              </MiddleRowCell>
              <MiddleRowCell last>
                <MiddleRowCellIcon icon={ICONS.Focus} />
                <MiddleRowCellKey>{STRINGS.training_goals}</MiddleRowCellKey>
                <MiddleRowCellValue>
                  {content?.goals?.map((eq) => eq.name).join(', ')}
                </MiddleRowCellValue>
              </MiddleRowCell>
            </ModalRow>
            <ModalRow bottom>
              <RowTitle>{STRINGS.notes}</RowTitle>
              <BottomRowCell />
              <BottomRowCell grow="4">
                <div>{content?.note || 'No Additional Notes'}</div>
              </BottomRowCell>
              <BottomRowCell last />
            </ModalRow>
          </ModalContentMain>
        </ModalContent>
      </WholeWrapper>
    </Modal>
  );
};

export default TraineeInfoModal;
