import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import 'react-image-crop/dist/ReactCrop.css';
import { createExtensionCouponMutation, updateExtensionCouponMutation } from '@witness/graphql';
import { useMutation } from '@apollo/react-hooks';
import { Formik, Field, Form } from 'formik';

import CustomButton from '../button/CustomButton';
import {
  WholeWrapper,
  ModalHeader,
  ModalMain,
  FlexWrapper,
  ButtonWrapper,
  ModalInput,
  ModalCloseX,
} from '../meeting-modal/components';
import CustomInput from '../input/CustomInput';
import { TemplateInputWrapper, TemplateLengthItem } from '../../pages/program-template/components';
import AlertModal from '../../components/alert-modal/AlertModalGeneral';
import { ExtensionCouponSchema } from '../../schemas/coupon/coupon';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: '504px',
    maxWidth: '100%',
    minWidth: '320px',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const ExtensionCouponModal = ({
  isOpen,
  closeModal,
  refetch,
  programRequestUid,
  monthlySum,
  traineeName,
  coupon,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [createExtensionCoupon] = useMutation(createExtensionCouponMutation);
  const [updateExtensionCoupon] = useMutation(updateExtensionCouponMutation);

  const handleCreate = useCallback(
    async (values) => {
      if (coupon) {
        await updateExtensionCoupon({
          variables: {
            record: {
              uid: coupon.uid,
              programLength: parseInt(values.programLength),
              monthlySum: parseInt(values.monthlySum),
            },
          },
        });
      } else {
        await createExtensionCoupon({
          variables: {
            record: {
              programUid: values.programRequestUid,
              programLength: parseInt(values.programLength),
              monthlySum: parseInt(values.monthlySum),
            },
          },
        });
      }
      refetch();
      closeModal();
    },
    [createExtensionCoupon, updateExtensionCoupon, closeModal, refetch, coupon],
  );

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Alert"
      ariaHideApp={false}
    >
      <WholeWrapper>
        <ModalHeader>
          EXTEND PROGRAM DURATION
          <ModalCloseX onClick={closeModal}>X</ModalCloseX>
        </ModalHeader>
        <ModalMain>
          <Formik
            onSubmit={handleCreate}
            initialValues={{
              programRequestUid,
              monthlySum: (coupon ? String(coupon.monthlySum) : monthlySum) || '',
              programLength: coupon ? coupon.programLength : 30,
            }}
            validationSchema={ExtensionCouponSchema}
          >
            {({
              setFieldValue,
              errors,
              touched,
              isSubmitting,
              values,
              handleSubmit,
              validateForm,
            }) => (
              <Form style={{ width: '100%' }}>
                <Field id="programLength" name="programLength">
                  {({ field }) => (
                    <ModalInput>
                      How long do you want to extend the program?
                      <TemplateInputWrapper justify="center">
                        <TemplateLengthItem
                          checked={values?.[field?.name] === 30}
                          onClick={() => setFieldValue(field.name, 30)}
                        >
                          1 month
                        </TemplateLengthItem>
                        <TemplateLengthItem
                          checked={values?.[field?.name] === 60}
                          onClick={() => setFieldValue(field.name, 60)}
                        >
                          2 months
                        </TemplateLengthItem>
                        <TemplateLengthItem
                          checked={values?.[field?.name] === 90}
                          onClick={() => setFieldValue(field.name, 90)}
                        >
                          3 months
                        </TemplateLengthItem>
                      </TemplateInputWrapper>
                    </ModalInput>
                  )}
                </Field>
                <Field id="monthlySum" name="monthlySum">
                  {({ field }) => (
                    <ModalInput>
                      Monthly Price
                      <CustomInput
                        color="#373333"
                        outlined
                        type="text"
                        borderRadius="8px"
                        height="4.4rem"
                        fontSize="1.4rem"
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={!!errors[field.name]}
                        placeholder="Monthly Price"
                        errorIcon={false}
                      />
                      {console.log(errors, touched, field)}
                    </ModalInput>
                  )}
                </Field>

                <FlexWrapper>
                  <ButtonWrapper>
                    <CustomButton fontSize="1.4rem" onClick={closeModal} type="button">
                      Cancel
                    </CustomButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton
                      fontSize="1.4rem"
                      green
                      type={coupon ? 'submit' : 'button'}
                      onClick={() => {
                        if (!coupon && !Object.keys(errors).length) {
                          setConfirmOpen(true);
                        }
                      }}
                    >
                      Send to trainee
                    </CustomButton>
                  </ButtonWrapper>
                </FlexWrapper>

                <AlertModal
                  title="Are you sure?"
                  content={`If you approve, ${traineeName} will receive a message with the code details. This action cannot be undone`}
                  modalOpen={confirmOpen}
                  closeModal={() => setConfirmOpen(null)}
                  okText="Yes, Send it"
                  noText="Don't send it"
                  handleOk={handleSubmit}
                />
              </Form>
            )}
          </Formik>
        </ModalMain>
      </WholeWrapper>
    </Modal>
  );
};

export default ExtensionCouponModal;
