import styled, { css } from 'styled-components';
import ICONS from '../../constants/icons';
import React from 'react';
import { WorkoutStatus } from '../../constants/enums';

const statusToBorderColor = ({ status, theme }) => {
  if (!status) return '#e5e5e5';

  switch (status) {
    case WorkoutStatus.DONE:
      return theme.color.primary;
    case WorkoutStatus.UNDONE:
      return theme.color.error;
    default:
      return '#969494';
  }
};

const statusToHeaderBackground = ({ status, theme }) => {
  if (!status) return '#F1F0F0';

  switch (status) {
    case WorkoutStatus.DONE:
      return 'rgba(0,255,145,0.25)';
    case WorkoutStatus.UNDONE:
      return 'rgba(245,10,79,0.2)';
    default:
      return 'rgba(210,209,209,0.56)';
  }
};

export const WholeWrapper = styled.div`
  flex-grow: 1;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 80%;
`;
export const CalendarContainer = styled.div`
  flex-grow: 1;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 163px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid #e9eaed;
`;

const TabText = styled.span`
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  color: #373333;
  font-family: ${({ active }) => (active ? 'Roboto-m' : 'Roboto-r')};
  font-size: 14px;
  letter-spacing: 0;
  line-height: ${({ active }) => (active ? 24 : 21)}px;
  padding: 2.4rem 0;
`;

const TabBorder = styled.div`
  height: 4px;
  width: 163px;
  border-radius: 100px 100px 0 0;
  background-color: #00ff91;
  position: absolute;
  bottom: -2px;
`;

export const Tab = ({ text, onClick, active }) => (
  <TabContainer onClick={onClick}>
    <TabText active={active}>{text}</TabText>
    {active && <TabBorder />}
  </TabContainer>
);

export const ButotnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 36px;
  height: 44px;
`;
export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9eaed;
  height: 9.4rem;
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const ButtonText = styled.span`
  margin-left: 59px;
  margin-right: 59px;
`;

export const CalendarHeading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const CalendarBeginning = styled.div`
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #050000cc;
  font-family: Space-r;
  display: flex;
  align-items: center;
`;

export const CalendarBeginningDateWrapper = styled.div`
  width: 196px;
`;

export const CalendarUploadTemplate = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #050000cc;
  font-family: Roboto-r;
  display: flex;
  align-items: center;
`;

export const CalendarUploadIcon = styled.div`
  border-radius: 8px;
  padding: 20px;
  background-image: url(${ICONS.Upload});
  cursor: pointer;
  background-position: center;
  background-size: contain;
  margin-left: 1.6rem;
`;

export const CalendarMain = styled.div`
  border-radius: 8px;
  border: 1px solid #dededecc;
  flex-grow: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.8) 80%,
      rgba(255, 255, 255, 1) 90%
    );
    width: 100%;
    height: 10rem;
    border-radius: 8px;
  }
`;

export const CalendarHeadingTableWrapper = styled.div`
  background: rgba(5, 0, 0, 0.01);
  padding-right: 8px;
  border-bottom: 1px solid #dededecc;
`;
export const CalendarHeadingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;
export const RelativeBox = styled.div`
  position: relative;
  flex-grow: 1;
  overflow: visible;
  margin-right: 4px;
  max-height: 1200px;
  height: 100%;
`;
export const CalendarTableWrapper = styled.div`
  position: absolute;
  width: Calc(100% + 2rem);
  left: -2rem;
  padding-left: 2rem;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;
export const CalendarTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  & * > tr:hover * > .copy_week_btn,
  & * > tr:hover * > .paste_week_btn {
    display: block;
  }
  & .paste_week_btn {
    width: 1.9rem;
    height: 1.9rem;
    margin-top: 28px;
  }
`;

export const CopyWeekButton = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  left: 0.2rem;
  background-image: url(${({ paste }) => (paste ? ICONS.PasteDay : ICONS.CopyDay)});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: Calc(50% - 0.8rem);
  cursor: pointer;
  display: none;
  &:hover {
    transform: scale(1.05);
    & > .wit_tooltip {
      display: block;
    }
  }
`;

export const EditWeekButton = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  right: 0.2rem;
  background-image: url(${ICONS.Edit});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: Calc(50% - 0.8rem);
  cursor: pointer;
  /* display: none; */
  &:hover {
    transform: scale(1.05);
  }
`;
export const CalendarThead = styled.thead`
  width: 100%;
  border-radius: 12px 12px 0 0;
`;
export const CalendarTbody = styled.tr`
  width: 100%;
  height: 100%;
  background: red;
`;
export const CalendarTh = styled.th`
  padding: 1.2rem;
  text-align: center;
  font-size: 1.4rem;
  color: #373333;
  font-weight: 500;
  line-height: 2rem;
  border-right: 1px solid #dededecc;
  &:last-child {
    border-right: none;
  }
`;

export const CalendarTd = styled.td`
  padding: 1.6rem;
  border-bottom: 1px solid #dededecc;
  border-right: 1px solid #dededecc;
  &:last-child {
    border-right: none;
  }
  &:first-child {
    padding-left: 2.4rem;
  }
  position: relative;
`;

// day

export const CalendarDayWrapper = styled.div`
  width: 100%;
  border: 1px solid
    ${({ status, theme }) =>
      status
        ? status === 'rest'
          ? '#969494'
          : status === 'done'
          ? theme.color.primary
          : status === 'undone'
          ? theme.color.error
          : '#FFCC00'
        : '#e5e5e5'};
  border-radius: 8px;
  cursor: pointer;
  &:hover > * .hover-button {
    display: flex;
  }
  &:hover > * .completed {
    display: none;
  }
  &:hover > * .done {
    display: none;
  }
  &:hover > * .plus-icon {
    opacity: 1;
  }
  &:hover > .background-coloring {
    background: ${({ status }) => (!status ? 'rgba(0,255,145,0.25)' : '')};
  }
  &:hover,
  :hover > .border-coloring {
    border-color: ${({ status, theme }) => (!status ? theme.color.primary : '')};
  }
`;

export const CalendarDayHeader = styled.div`
  background: ${({ status }) =>
    status
      ? status === 'rest'
        ? 'rgba(210,209,209,0.56)'
        : status === 'done'
        ? 'rgba(0,255,145,0.25)'
        : status === 'undone'
        ? 'rgba(245,10,79,0.2)'
        : 'rgba(255,204,0,0.48)'
      : '#f1f0f0'};
  border-radius: 8px 8px 0 0;
  padding: 1.2rem;
  text-align: center;
  font-family: Roboto-l;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #05000099;
  border-bottom: 1px solid
    ${({ status, theme }) =>
      status
        ? status === 'rest'
          ? '#969494'
          : status === 'done'
          ? theme.color.primary
          : status === 'undone'
          ? theme.color.error
          : '#FFCC00'
        : '#e5e5e5'};
`;

export const CalendarDayNum = styled.div`
  width: 100%;
  font-family: ${({ isToday }) => (isToday ? 'Space-b' : 'Space-r')};
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #373333;
`;

export const CalendarDayMain = styled.div`
  width: 100%;
  height: 14.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const CalendarDayPlusIcon = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-image: url(${ICONS.PlusIcon});
  background-size: contain;
  opacity: ${({ isOepn }) => (isOepn ? 1 : 0.5)};
  position: relative;
`;

export const CalendarDayTitle = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.2rem;
  line-height: 2.1rem;
  color: #4a4a4a;
  text-align: center;
  ${({ clickable }) =>
    clickable && {
      cursor: 'pointer',
    }}
`;

export const Ellipsis = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CalendarDayStatus = styled.div`
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.2rem 1.2rem;
  line-height: 2.1rem;
  font-family: Roboto-l;
  color: #05000099;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  padding: 0 1.2rem;
  display: none;
`;

export const DayButton = styled.div`
  width: Calc(50% - 0.4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f0f0;
  border-radius: 4px;
  font-size: 1.4rem;
  font-family: Roboto-l;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 0.4rem 0 0.4rem 0.2rem;
`;

export const DayViewEditButton = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 0.4rem 0 0.4rem 0.2rem;
  font-family: Roboto-l;
  cursor: pointer;
  border: 1px solid ${statusToBorderColor};
`;

export const ButtonIcon = styled.div`
  padding: 0.6rem;
  margin-left: 0.6rem;
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-size: contain;
`;

export const MessageContainer = styled.div`
  opacity: 0.6;
  color: #050000;
  font-family: Roboto-l;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
  margin: 0 0.4rem;
`;

export const MessageIcon = styled.img`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.6rem;
`;

export const DayContainer = styled.div`
  border: 1px solid ${statusToBorderColor};
  border-radius: 8px;
  :hover {
    box-shadow: 0px 2px 4px #d0d0c8;

    .plus-icon {
      opacity: 1;
    }

    .hover-hide {
      display: none;
    }

    .hover-show {
      display: flex;
    }
  }

  .day-header {
    background-color: ${statusToHeaderBackground};
  }
`;

export const DayHeader = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 6.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DayBody = styled.div`
  height: 8.65rem;
`;

export const DayFooter = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 5.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1.25rem;
`;

export const DayFooterText = styled.span`
  margin-top: auto;
  opacity: 0.6;
  color: #050000;
  font-family: Roboto-l;
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
`;

export const DayWorkout = styled.div`
  margin-top: 0.35rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const DayMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.9rem;
`;

export const CalendarDayDate = styled.span`
  opacity: 0.8;
  color: #050000;
  font-family: ${({ isToday }) => (isToday ? 'Roboto-b' : 'Roboto-l')};
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14.15rem;
  cursor: pointer;
  position: relative;
`;

export const DropdownContainerSmall = styled.span`
  cursor: pointer;
`;

export const HoverButtons = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
  display: none;
  z-index: 1;
`;

export const HoverContainer = styled.div`
  box-sizing: border-box;
  width: 14.7rem;
  min-height: 3.2rem;
  border-radius: 4px;
  cursor: ${({ draggable }) => (draggable ? 'grab' : 'default')};
  border: 1px solid transparent;
  position: relative;
  :hover {
    border-color: #00ff91;
    background-color: rgba(0, 255, 145, 0.25);

    ${HoverButtons} {
      display: block;
    }
  }
  ${({ showDroppable }) =>
    showDroppable &&
    css`
      border: 2px dotted rgba(0, 255, 145, 0.4);
      background-color: rgba(0, 255, 145, 0.06);
    `};
  ${({ showReady }) =>
    showReady &&
    css`
      border: 2px solid transparent;
      background-color: rgba(0, 255, 145, 0.4);
    `};
  ${({ onClick }) => ({ cursor: typeof onClick === 'function' ? 'pointer' : 'default' })}
`;

export const HoverButton = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin: 0.2rem;
`;

export const EmptyActions = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export const CardSecondaryContent = styled.div`
  width: 100%;
  padding: 1rem 0.8rem;
  display: flex;
  justify-content: space-between;
`;

export const CardSecondaryContentButton = styled.div`
  flex-grow: 1;
  padding: 0.8rem;
  height: 3.4rem;
  margin: 0.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: ${({ plus }) => (plus ? 'transparent' : '#f1f0f0')};
  align-items: center;
  position: relative;
  &:hover {
    & > .wit_tooltip {
      display: block;
    }
  }
`;
export const CardSecondaryContentButtonIcon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ plus }) => (plus ? '1.6rem' : 'contain')};
  border-radius: 0.4rem;
`;
export const TooltipHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const TooltipHeaderIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  margin: 0.2rem;
  background-image: url(${({ edit }) => (edit ? ICONS.EditSimpleGrey : ICONS.BinSimpleGrey)});
  background-size: auto 1.2rem;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    background-size: contain;
  }
`;
export const TooltipBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1rem 0.4rem;
`;
export const TooltipPrimaryContent = styled.div`
  font-family: Roboto-m;
  line-height: 2rem;
  color: #050000cc;
  text-align: center;
`;
export const TooltipSecondaryContent = styled.div`
  font-family: Roboto-l;
  line-height: 1.4rem;
  color: #050000cc;
  font-size: 1.2rem;
  text-align: center;
`;
export const DayDropdownContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2.4rem 1.4rem;
  position: relative;
`;
export const HoveredTitle = styled.div`
  min-width: 100%;
  text-align: center;
  position: absolute;
  content: '123';
  top: 0;
  left: 0;
  display: none;
  line-height: 3.2rem;
  font-size: 1.4rem;
  color: #05000088;
  font-family: Roboto-r;
`;

export const DayDropdownButton = styled.div`
  width: Calc(50% - 1rem);
  height: 4.8rem;
  border-radius: 8px;
  border: 1px solid #d2d1d1;
  background: white;
  margin: 0.5rem;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(.copy_paste):hover {
    background-color: #00ff91;
    border-color: #00ff91;
  }
  &.copy_paste:hover > div {
    transform: scale(1.1);
  }
  &:hover + .hover_title {
    display: block;
  }
  &:not(.copy_paste):hover > div {
    background-image: url(${({ hoverIcon, icon }) => hoverIcon || icon});
  }
`;
export const DayDropdownButtonIcon = styled.div`
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 3.4rem;
  height: 3rem;
`;

const BadgeContainer = styled.div`
  display: inline-flex;
  background-color: #f3f3f3;
  align-items: center;
  justify-content: center;
  margin: 0.2rem;
  border-radius: 0.8rem;
  padding: 0 0.8rem;
`;

const BadgeName = styled.span`
  line-height: 3.2rem;
  font-size: 1.4rem;
  color: #05000088;
  font-family: Roboto-r;
`;

const BadgeValue = styled.span`
  line-height: 3.2rem;
  font-size: 1.4rem;
  color: #05000088;
  font-family: Roboto-b;
  margin-left: 0.4rem;
`;

export const Badge = ({ name, value }) => (
  <BadgeContainer>
    <BadgeName>{name}</BadgeName>
    <BadgeValue>{value}</BadgeValue>
  </BadgeContainer>
);

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
