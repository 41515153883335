import React, { createContext, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { meQuery } from '@witness/graphql';
import ChatManager from './ChatManager';
import { useState } from 'react';
import { serializeConversations } from './chat-helpers';
import { CometChat } from '@cometchat-pro/chat';

const ChatContext = createContext(null);

export default ChatContext;

const CONVERSATION_LIMIT = 50;
const MESSAGE_LIMIT = 30;
const SORT_MESSAGES = (a, b) => a.sentAt - b.sentAt;

export const ChatProvider = ({ children }) => {
  const [loadingConversations, setLoadingCoversations] = useState(false);
  const [conversations, setConversations] = useState({});
  const [currentConversation, setCurrentConversation] = useState(null);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [fetchingMoreMessages, setFetchingMoreMessages] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  const { data } = useQuery(meQuery);
  const chatInstance = useMemo(() => ChatManager.getInstance(), []);

  const updateTotalUnread = useCallback(() => {
    chatInstance.fetchAllUnreadMessageCount().then((count) => setTotalUnreadCount(count));
  }, [chatInstance]);

  const handleConversationUpdate = useCallback(
    (conversation) => {
      updateTotalUnread();
      setConversations((current) => {
        return {
          ...current,
          [conversation.groupId]: conversation,
        };
      });
    },
    [updateTotalUnread],
  );

  const toggleCurrentConversation = useCallback((conversation) => {
    setCurrentConversation((current) =>
      current && !conversation.isNew && current.groupId === conversation.groupId
        ? null
        : conversation,
    );

    setHasMoreMessages(true);
  }, []);

  const handleMessageUpdate = useCallback(
    (message) => {
      updateTotalUnread();
      setCurrentMessages((current) => [...current, message]);
    },
    [updateTotalUnread],
  );

  const sendTextMessage = useCallback(
    (text, isGroup) => {
      if (sendingMessage) {
        return new Promise();
      }

      setSendingMessage(true);
      return chatInstance
        .sendTextMessage(
          text,
          currentConversation?.isGroup,
          currentConversation?.isNew ? currentConversation.groupId : undefined,
        )
        .finally(() => setSendingMessage(false));
    },
    [chatInstance, currentConversation, sendingMessage],
  );

  const sendMediaMessage = useCallback(
    (file) => {
      if (sendingMessage) {
        return new Promise();
      }

      setSendingMessage(true);

      const receiverType = currentConversation.isGroup
        ? CometChat.RECEIVER_TYPE.GROUP
        : CometChat.RECEIVER_TYPE.USER;

      let messageType = CometChat.MESSAGE_TYPE.FILE;

      if (file.type.includes('image')) {
        messageType = CometChat.MESSAGE_TYPE.IMAGE;
      } else if (file.type.includes('video')) {
        messageType = CometChat.MESSAGE_TYPE.VIDEO;
      }

      return chatInstance
        .sendMediaMessage({
          file,
          messageType,
          receiverType,
        })
        .finally(() => setSendingMessage(false));
    },
    [chatInstance, currentConversation, sendingMessage],
  );

  const markAsRead = useCallback(async () => {
    if (currentConversation && currentConversation.unreadMessageCount) {
      const receiverType = currentConversation.isGroup
        ? CometChat.RECEIVER_TYPE.GROUP
        : CometChat.RECEIVER_TYPE.USER;

      await chatInstance.markAsRead(
        currentConversation.lastMessage.id,
        currentConversation.groupId,
        receiverType,
          currentConversation.groupId,
      );

      updateTotalUnread();
    }
  }, [chatInstance, currentConversation, updateTotalUnread]);

  const fetchMoreMessages = useCallback(() => {
    if (hasMoreMessages && !fetchingMoreMessages) {
      setFetchingMoreMessages(true);

      chatInstance
        .fetchMessages()
        .then((moreMessages) => {
          if (moreMessages.length < MESSAGE_LIMIT) {
            setHasMoreMessages(false);
          }

          setCurrentMessages((curr) => [...curr, ...moreMessages].sort(SORT_MESSAGES));
        })
        .finally(() => setFetchingMoreMessages(false));
    }
  }, [chatInstance, hasMoreMessages, fetchingMoreMessages]);

  const logout = useCallback(() => {
    setLoadingCoversations(false);
    setConversations({});
    setCurrentConversation(null);
    setCurrentMessages([]);
    setLoadingMessages(false);
    setSendingMessage(false);
    setHasMoreMessages(true);
    setTotalUnreadCount(0);

    ChatManager.logout();
  }, []);

  useEffect(() => {
    setCurrentMessages([]);
    if (currentConversation) {
      setLoadingMessages(true);
      chatInstance
        .subscribeToGroup({
          groupId: currentConversation.groupId,
          onMessage: handleMessageUpdate,
          messageLimit: MESSAGE_LIMIT,
          isGroup: currentConversation.isGroup,
        })
        .then((messages) => setCurrentMessages(messages))
        .finally(() => setLoadingMessages(false));
    }
  }, [currentConversation, chatInstance, handleMessageUpdate]);

  useEffect(() => {
    if (currentConversation && !currentConversation.isNew) {
      markAsRead();
    }
  }, [currentConversation, markAsRead]);

  useEffect(() => {
    if (data?.getCurrentUser?.user && !chatInstance.initialized) {
      setLoadingCoversations(true);
      ChatManager.login(data.getCurrentUser.user.cometchatToken)
        .then(() =>
          chatInstance.connect({
            onConversationUpdate: handleConversationUpdate,
            onConversationAdd: handleConversationUpdate,
            conversationLimit: CONVERSATION_LIMIT,
          }),
        )
        .then((conversations) => setConversations(serializeConversations(conversations)))
        .finally(() => setLoadingCoversations(false));
    }

    if (!data?.getCurrentUser?.user && chatInstance.initialized) {
      logout();
    }
  }, [data, chatInstance, handleConversationUpdate, logout]);

  useEffect(() => {
    updateTotalUnread();
  }, [updateTotalUnread]);

  const contextValue = useMemo(
    () => ({
      conversations,
      currentConversation,
      currentMessages,
      toggleCurrentConversation,
      sendTextMessage,
      markAsRead,
      sendMediaMessage,
      fetchMoreMessages,
      hasMoreMessages,
      loadingConversations,
      loadingMessages,
      sendingMessage,
      fetchingMoreMessages,
      setCurrentConversation,
      totalUnreadCount,
    }),
    [
      conversations,
      toggleCurrentConversation,
      currentConversation,
      currentMessages,
      sendTextMessage,
      markAsRead,
      sendMediaMessage,
      fetchMoreMessages,
      hasMoreMessages,
      loadingConversations,
      loadingMessages,
      sendingMessage,
      fetchingMoreMessages,
      setCurrentConversation,
      totalUnreadCount,
    ],
  );

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};
