import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';

import { ChangesSaved, ChangesSavedIcon, LoadingDot } from '../profile/components';
import AreYouSureModal from '../../components/are-you-sure-modal/AreYouSureModal';
import ProgramCalendar from '../../components/program-calendar/ProgramCalendar';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import CustomButton from '../../components/button/CustomButton';
import Loader from '../../components/loader/Loader';
import { Container, Content } from './components';
import TemplateFilter from './TemplateFilter';
import { useCoachPersonalInfo, useTemplate } from '../../hooks';

const ProgramTemplate = () => {
  const [deleteModal, setDeleteModal] = useState();
  const {
    programTemplate,
    refetchProgramTemplate,
    loadingProgramTemplate,
    createTemplate,
    deleteTemplate,
    updateCalendarCache,
    templateChangeLoading,
    setCalendarLoading,
    readOnly,
  } = useTemplate();
  const { currentCoach, isAdmin } = useCoachPersonalInfo({ setLoading: null, setOpenModal: null });
  const specList = useMemo(() => {
    if (currentCoach?.specialities) {
      const withSpecs = currentCoach?.specialities?.reduce((acc, sp) => {
        if (sp?.name === 'Strength' || sp?.name === 'Running') {
          acc.push({ value: sp?.id, label: sp?.name, trainingTypes: sp?.trainingTypes });
        }
        return acc;
      }, []);
      const specials = [].concat(
        ...currentCoach?.specialities?.map(
          (spec) =>
            (spec?.name !== 'Strength' && spec?.name !== 'Running' && spec?.trainingTypes) || [],
        ),
      );
      return [
        ...withSpecs,
        {
          value: 'non-strength-or-running-spec',
          label: 'Special',
          trainingTypes: specials,
        },
      ];
    }

    return [];
  }, [currentCoach.specialities]);

  return (
    <>
      <Container>
        {loadingProgramTemplate && !programTemplate ? (
          <Loader />
        ) : (
          <>
            <AreYouSureModal
              modalOpen={deleteModal}
              closeModal={() => setDeleteModal(false)}
              submitModal={() => deleteTemplate(programTemplate?.uid)}
              headingText={'Are You Sure?'}
              contentText={"If you delete the template, \n you won't be able to restore it."}
              choiceQuestion={'Do You Want to Permanently Delete the Template?'}
            />
            <Formik
              initialValues={{
                uid: programTemplate?.uid,
                templateLength: programTemplate?.programLength || 60,
                templateName: programTemplate?.name || '',
                trainingTypes: programTemplate?.trainingTypes?.map((a) => ({
                  value: a?.id,
                  label: a?.name,
                })),
                speciality: specList?.find(
                  (x) =>
                    !!x?.trainingTypes?.find(
                      (y) => y?.id === programTemplate?.trainingTypes?.[0]?.id,
                    ),
                ),
                isGlobal: programTemplate?.isGlobal || false,
              }}
              enableReinitialize
              onSubmit={createTemplate}
            >
              {({ setFieldValue, values }) => (
                <Form style={{ width: '100%', height: '100%' }}>
                  <ProgramsHeader
                    statusOpts={[]}
                    setTraineeName={() => {}}
                    heading={
                      <>
                        {readOnly ? 'View' : 'Edit'}
                        <span>Program Template</span>
                      </>
                    }
                    layout={null}
                    statusFilter={false}
                    nameFilter={false}
                    additionalButton={
                      readOnly ? (
                        "You are viewing a global template. You can't change it"
                      ) : (
                        <>
                          <CustomButton
                            height="4.8rem"
                            type="button"
                            onClick={() => setDeleteModal(true)}
                            style={{ width: '24rem' }}
                          >
                            Delete Template
                          </CustomButton>

                          <CustomButton
                            height="4.8rem"
                            green={!templateChangeLoading}
                            backgroundColor={templateChangeLoading && 'transparent'}
                            type="submit"
                            style={{ marginLeft: '1.6rem', width: '24rem' }}
                            width="24rem"
                          >
                            {templateChangeLoading ? (
                              <ChangesSaved autoSize>
                                <ChangesSavedIcon />
                                <>
                                  <LoadingDot i={2} />
                                  <LoadingDot i={1} />
                                  <LoadingDot i={0} />
                                </>
                              </ChangesSaved>
                            ) : (
                              'Save Changes'
                            )}
                          </CustomButton>
                        </>
                      )
                    }
                  />
                  <TemplateFilter
                    setFieldValue={setFieldValue}
                    values={values}
                    specList={specList}
                    isAdmin={isAdmin}
                    readOnly={readOnly}
                  />
                </Form>
              )}
            </Formik>
            <Content>
              <ProgramCalendar
                programLength={programTemplate?.programLength}
                isTemplate={true}
                calendar={programTemplate?.calendar}
                programTemplateUid={programTemplate?.uid}
                refetch={refetchProgramTemplate}
                loading={loadingProgramTemplate}
                updateCalendarCache={updateCalendarCache}
                setCalendarDayLoading={setCalendarLoading}
                readOnly={readOnly}
              />
            </Content>
          </>
        )}
      </Container>
    </>
  );
};

export default ProgramTemplate;
