import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
  InputWrapper,
  StyledSelect,
  SelectContainer,
  SelectArrow,
  StyledOption,
  OptionContainer,
  LoadingContainer,
} from './components';

const CustomInputDropdown = ({
  height,
  borderRadius,
  fontSize,
  value,
  onChange,
  color,
  options,
  disabled,
  optionStyles,
  menuStyles,
  valueStyles,
  optionComponent,
  placeholder,
  arrowStyles,
  returnEntire,
  borderColor,
  loading,
  ...rest
}) => {
  const [isOpen, setisOpen] = useState(false);
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setisOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const Opt = useMemo(() => {
    return (
      optionComponent ||
      (({ opt }) => (
        <StyledOption
          style={optionStyles}
          onClick={() => onChange(!returnEntire && opt?.value ? opt.value : opt)}
        >
          {opt?.label ? opt?.label : opt.toLowerCase()}
        </StyledOption>
      ))
    );
  }, [optionComponent, onChange, optionStyles, returnEntire]);

  return (
    <InputWrapper
      height={height}
      outlined
      fontSize={fontSize}
      borderRadius={borderRadius}
      borderColor={borderColor}
      {...rest}
    >
      <SelectContainer
        onClick={() => {
          if (!disabled) {
            setisOpen((curr) => !curr);
          }
        }}
        ref={wrapperRef}
        disabled={disabled}
      >
        <StyledSelect disabled={disabled} color={color} style={valueStyles}>
          {value || placeholder}
        </StyledSelect>

        <SelectArrow disabled={disabled} style={arrowStyles} />
        <OptionContainer
          style={isOpen ? menuStyles : { maxHeight: 0, maxWidth: 0, border: 'none' }}
        >
          {loading ? (
            <LoadingContainer>Loading</LoadingContainer>
          ) : (
            options?.map((opt, index) =>
              (opt?.value ? opt.value : opt) === value ? (
                false
              ) : (
                <Opt
                  key={index}
                  opt={opt}
                  onClick={() => onChange(opt?.value ? opt?.value : opt)}
                />
              ),
            )
          )}
        </OptionContainer>
      </SelectContainer>
    </InputWrapper>
  );
};

export default CustomInputDropdown;
