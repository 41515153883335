import React, { useState, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';

import WorkoutBlockTable from '../add-calendar-modal/WorkoutBlockTable';
import { WorkoutBlockType } from '../../constants/enums';
import CustomTextArea from '../input/CustomTextArea';
import { useResults, useUtils } from '../../hooks';
import MediaModal from '../media-modal/MediaModal';
import CustomButton from '../button/CustomButton';
import ResultTable from './ResultTable';

import {
  ModalFooter,
  ModalContent,
  WorkoutBlockResult,
  BlockExercisesSide,
  BlockFeedbackSide,
  BlockExercisesHeader,
  BlockExercisesHeaderLabel,
  HeaderLabelCheckmark,
  BlockResults,
  BlockFeedback,
  BlockFeedbackContent,
  BlockExercisesRest,
  BlockFeedbackHeader,
  FeedbackHeading,
  FeedbackTextAreaWrapper,
  EntireMessageHeading,
  EntireMessageWrapper,
  BlocksWrapper,
  BLockAttachment,
  BLockAttachmentVideo,
  ParticipantContent,
  IndividualTraineeHeaderMain,
  IndividualTraineePic,
  IndicatorDone,
  IndividualTraineeHeader,
  IndividualTraineeHeaderSide,
  IndividualTraineeBack,
  IndividualTraineeBackIcon,
  WorkoutBlockComment,
  PlayPlaceholder,
  ResultsTitle,
  AttachmentsList,
  AttachmentIcon,
  ResultsContainer,
  CommentText,
} from './components';
import ICONS from '../../constants/icons';

const SingleTraineeResults = ({
  day,
  isGroup,
  trainee,
  exit,
  refetchProgram,
  setSubmitting,
  closeModal,
  alert,
  refetchCalendars,
}) => {
  const [openMedia, setOpenMedia] = useState(null);

  const { getWorkoutBlocksByWorkout } = useUtils();
  const workoutBlocks = useMemo(() => getWorkoutBlocksByWorkout(day?.workout, trainee?.uid), [
    day,
    getWorkoutBlocksByWorkout,
    trainee,
  ]);

  const status = useMemo(
    () => day?.workout?.status?.find((x) => trainee?.uid && x?.trainee?.uid === trainee?.uid),
    [day, trainee],
  );

  const initialFeedbacks = useMemo(() => {
    const toBeReturned = {};
    if (workoutBlocks?.length) {
      workoutBlocks.map((block) => {
        const currFeedback = block?.results?.[0]?.coachFeedback;
        if (currFeedback) {
          toBeReturned[block?.uid] = currFeedback;
        }
        return 1;
      });
    }
    return toBeReturned;
  }, [workoutBlocks]);

  const { submitFeedback } = useResults();

  return (
    <Formik
      initialValues={{
        feedbacks: initialFeedbacks || {},
        workoutComment: status?.workoutComment,
        traineeUid: trainee?.uid,
        workoutUid: day?.workout?.uid,
        workoutStatusUid: status?.uid,
      }}
      onSubmit={async (v) => {
        if (!isGroup) {
          alert.info(`We are sending your feedback to ${trainee?.fullName}`);
          closeModal();
        }
        setSubmitting(true);
        try {
          console.log('try');
          await submitFeedback(v);
          console.log('next');
          alert.success(`Your feedbacks were successfully sent to ${trainee?.fullName}`);
          refetchProgram();
          refetchCalendars();
          setSubmitting(false);
          exit();
        } catch (err) {
          console.log('catch', err);
          alert.error(err.message);
          setSubmitting(false);
        }
      }}
    >
      {({ setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <ModalContent style={trainee ? { padding: '0 1.6rem' } : {}}>
            <MediaModal {...openMedia} onClose={() => setOpenMedia(null)} />
            <ParticipantContent group={isGroup}>
              {isGroup && (
                <IndividualTraineeHeader>
                  <IndividualTraineeHeaderSide>
                    <IndividualTraineeBack onClick={exit}>
                      <IndividualTraineeBackIcon />
                      Back
                    </IndividualTraineeBack>
                  </IndividualTraineeHeaderSide>
                  <IndividualTraineeHeaderMain>
                    <IndividualTraineePic>
                      {trainee?.fullName
                        ?.split(' ')
                        .map((a) => a.substr(0, 1))
                        ?.join('')}
                    </IndividualTraineePic>
                    <span>{trainee?.fullName}</span>
                    <IndicatorDone style={{ width: '1.8rem' }} />
                  </IndividualTraineeHeaderMain>
                  <IndividualTraineeHeaderSide />
                </IndividualTraineeHeader>
              )}
              <BlocksWrapper>
                {workoutBlocks?.map((block, index) => (
                  <>
                    {block?.type === WorkoutBlockType.REST ? (
                      <WorkoutBlockResult>
                        <BlockExercisesSide>
                          <BlockExercisesRest>REST</BlockExercisesRest>
                        </BlockExercisesSide>
                      </WorkoutBlockResult>
                    ) : (
                      <WorkoutBlockResult>
                        <BlockExercisesSide>
                          <BlockExercisesHeader>
                            {block?.type}
                            {block?.isWarmUp && (
                              <BlockExercisesHeaderLabel>
                                <HeaderLabelCheckmark />
                                Warmup
                              </BlockExercisesHeaderLabel>
                            )}
                          </BlockExercisesHeader>
                          <BlockResults>
                            <ResultsTitle mb="1.2rem">Results</ResultsTitle>

                            <ResultsContainer>
                              <div style={{ overflowX: 'auto', width: '100%' }}>
                                <ResultTable block={block} />
                              </div>
                              {block?.results?.[0]?.resultText && (
                                <WorkoutBlockComment>
                                  <AttachmentIcon src={ICONS.CommentNewSvg} />
                                  <CommentText>{block?.results?.[0]?.resultText}</CommentText>
                                </WorkoutBlockComment>
                              )}

                              {block?.results?.[0]?.blockAttachment?.files?.length && (
                                <AttachmentsList>
                                  <AttachmentIcon />
                                  {block?.results?.[0]?.blockAttachment?.files?.map((att) => {
                                    const link = att?.compressedUrl || att?.url || '';

                                    if (
                                      link.toLowerCase().endsWith('.mov') ||
                                      link.toLowerCase().endsWith('.mp4')
                                    ) {
                                      return (
                                        <div
                                          style={{
                                            position: 'relative',
                                            marginRight: '0.8rem',
                                            height: '3.2rem',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            setOpenMedia({
                                              url: att?.url,
                                              type: att?.mimetype?.split('/')?.[0],
                                            })
                                          }
                                        >
                                          <BLockAttachmentVideo>
                                            <source src={`${link}#t=0.1`} type="video/mp4" />
                                          </BLockAttachmentVideo>
                                          <PlayPlaceholder />
                                        </div>
                                      );
                                    }

                                    return (
                                      <BLockAttachment
                                        att={att?.compressedUrl || att?.url}
                                        onClick={() =>
                                          setOpenMedia({
                                            url: att?.url,
                                            type: att?.mimetype?.split('/')?.[0],
                                          })
                                        }
                                      />
                                    );
                                  })}
                                </AttachmentsList>
                              )}
                            </ResultsContainer>

                            <ResultsTitle mb="1.2rem" mt="3rem">
                              Details
                            </ResultsTitle>

                            <WorkoutBlockTable block={block} />
                            {block?.comment && (
                              <WorkoutBlockComment>{block?.comment}</WorkoutBlockComment>
                            )}
                          </BlockResults>
                        </BlockExercisesSide>
                        <BlockFeedbackSide>
                          <BlockFeedback>
                            <BlockFeedbackHeader />
                            <BlockFeedbackContent>
                              <FeedbackHeading>Leave Feedback</FeedbackHeading>
                              <Field
                                id={`feedbacks[${block.uid}]`}
                                name={`feedbacks[${block.uid}]`}
                              >
                                {({ field }) => (
                                  <FeedbackTextAreaWrapper>
                                    <CustomTextArea
                                      rowCount={null}
                                      style={{ padding: '1.6rem' }}
                                      wrapperStyle={{ padding: '0 0.4rem' }}
                                      placeholder="Type Here message"
                                      value={field.value}
                                      onChange={(v) => setFieldValue(field.name, v)}
                                    />
                                  </FeedbackTextAreaWrapper>
                                )}
                              </Field>
                            </BlockFeedbackContent>
                          </BlockFeedback>
                        </BlockFeedbackSide>
                      </WorkoutBlockResult>
                    )}
                  </>
                ))}
              </BlocksWrapper>
              <EntireMessageHeading>Type Message about entire Workout</EntireMessageHeading>
              <EntireMessageWrapper>
                <Field id={`workoutComment`} name={`workoutComment`}>
                  {({ field }) => (
                    <CustomTextArea
                      rowCount={10}
                      placeholder="Type Here message"
                      value={field.value}
                      onChange={(v) => setFieldValue(field.name, v)}
                    />
                  )}
                </Field>
              </EntireMessageWrapper>
              <ModalFooter>
                <CustomButton
                  style={{ margin: '0 1.2rem', width: '22.4rem', height: '4.8rem' }}
                  fontSize="1.4rem"
                  type="submit"
                  green
                >
                  Send Comments
                </CustomButton>
              </ModalFooter>
            </ParticipantContent>
          </ModalContent>
        </Form>
      )}
    </Formik>
  );
};

export default SingleTraineeResults;
