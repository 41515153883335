import React, { useEffect } from 'react';
import Select, { components } from 'react-select';

import {
  ValueLabel,
  ValuesWrapper,
  AbsoluteWrapper,
  MultiValueDeleteX,
  CheckBox,
  OptionWrapper,
  OptionsHeading,
  CoachRowImage,
} from './components';
import { useState } from 'react';
import CustomButton from '../button/CustomButton';

const customStyles = ({ error, margin, width, small, borderColor, dropDownMaxHeight }) => ({
  control: (provided) => ({
    ...provided,
    width: width,
    maxWidth: '100%',
    height: small ? '3.2rem' : '4.4rem',
    minHeight: '3.2rem',
    borderRadius: '8px',
    boxShadow: 'none',
    marginRight: margin,
    fontSize: '1.4rem',
    alignItems: 'center',
    borderColor: error ? '#F50A4F' : borderColor || '#05000033',
    '&:hover': {
      borderColor: error ? '#F50A4F' : '#05000033',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: small ? '3rem' : '3.6rem',
    fontSize: '1.4rem',
    height: '3.6rem',
  }),
  input: (provided) => ({
    ...provided,
    maxHeight: small ? '3rem' : '3.6rem',
    fontSize: '1.4rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    maxHeight: small ? '3rem' : '3.6rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.4rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '1.4rem',
    display: 'flex',
    alignItems: 'center',
    minHeight: '100&',
    overflow: 'show',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    opacity: 0.4,
  }),
  multiValue: () => ({
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0.2rem',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: dropDownMaxHeight ? dropDownMaxHeight : small ? '8.8rem' : provided.maxHeight,
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: small ? '1.4rem' : 'inherit',
    padding: small ? '0.4rem 0.8rem' : provided.padding,
    background: state.isSelected ? 'rgba(0,255,145,0.4)' : 'white',
    color: '#050000cc',
    cursor: 'pointer',
    '&:hover': {
      background: state.isSelected ? 'rgba(0,255,145,0.4)' : 'rgba(0,255,145,0.1)',
    },
  }),
});

const Option = (props) => {
  return (
    <components.Option {...props} isRt1={true} className="inner">
      {props.data?.img && <CoachRowImage img={props.data?.img} />}
      {props.children}
    </components.Option>
  );
};

const CheckBoxOption = (props) => {
  return (
    <components.Option {...props} getStyles={() => ({})} styles={{}} isRt1={true}>
      <OptionWrapper>
        <CheckBox checked={props.isSelected} />
        {props.children}
      </OptionWrapper>
    </components.Option>
  );
};

const ReactSelectWitness = ({
  value,
  onChange,
  isMulti,
  options,
  error,
  checkBoxes = false,
  onDeleteOne = () => {},
  placeholder,
  maxSelected = 3,
  margin = '2.4rem',
  width = '38rem',
  disabled,
  small,
  borderColor,
  dropDownMaxHeight,
  ...rest
}) => {
  const [currentInnerValue, setCurrentInnerValue] = useState(value);
  const [menuIsOpen, setMenuIsOpen] = useState(value);
  const [warningActive, setWarningActive] = useState(false);
  let selectRef = null;

  useEffect(() => {
    if (isMulti) {
      setCurrentInnerValue(value);
    }
  }, [isMulti, value]);

  const showWarning = () => {
    setWarningActive(true);
    setTimeout(() => setWarningActive(false), 200);
  };
  return (
    <div {...rest}>
      <Select
        ref={(r) => (selectRef = r)}
        options={options}
        classPrefix="react-select"
        multiple
        onBlur={() => selectRef && selectRef.blur()}
        onMenuOpen={() => {
          setCurrentInnerValue(value);
          setMenuIsOpen(true);
        }}
        onMenuClose={() => {
          setMenuIsOpen(false);
        }}
        removeSelected={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        styles={customStyles({ error, margin, width, small, borderColor, dropDownMaxHeight })}
        value={isMulti && menuIsOpen ? currentInnerValue : value}
        onChange={(v) =>
          isMulti
            ? maxSelected && v?.length > maxSelected
              ? showWarning()
              : setCurrentInnerValue(v)
            : onChange(v)
        }
        backspaceRemovesValue={false}
        persistMultiPlaceholder={true}
        placeholder={placeholder}
        isDisabled={disabled}
        components={{
          ClearIndicator: false,
          IndicatorSeparator: false,
          Option: checkBoxes && isMulti ? CheckBoxOption : Option,
          ValueContainer: (props) => {
            const val = menuIsOpen ? currentInnerValue : value;
            return (
              <components.ValueContainer {...props}>
                {props.getValue()?.[0]?.img ? (
                  <components.SingleValue {...props}>
                    <CoachRowImage img={props.getValue()?.[0]?.img} />
                    {props.getValue()?.[0]?.label}
                  </components.SingleValue>
                ) : (
                  props.children
                )}
                {isMulti && !!val?.length && (
                  <>
                    <components.SingleValue {...props}>{`Selected ${val?.length} item${
                      val?.length > 1 ? 's' : ''
                    }`}</components.SingleValue>
                  </>
                )}
              </components.ValueContainer>
            );
          },
          Menu: (props) => {
            return (
              <components.Menu {...props}>
                {isMulti &&
                  ((maxSelected && (
                    <OptionsHeading warn={warningActive}>
                      You can select up to {maxSelected} types
                    </OptionsHeading>
                  )) || <br />)}
                {props.children}
                {isMulti && (
                  <CustomButton
                    onClick={() => {
                      onChange(currentInnerValue);
                      selectRef && selectRef.blur();
                    }}
                    green
                    type="button"
                    fontSize="1.4rem"
                    style={{
                      maxWidth: 'fit-content',
                      maxHeight: 'fit-content',
                      height: 'fit-content',
                      padding: '0.8rem 2.8rem',
                      lineHeight: '2.2rem',
                      margin: '1.2rem auto',
                    }}
                  >
                    Done
                  </CustomButton>
                )}
              </components.Menu>
            );
          },
        }}
      />

      {isMulti && (
        <ValuesWrapper>
          <AbsoluteWrapper>
            {value?.map((a) => (
              <ValueLabel key={a?.value}>
                {a?.label}
                {!disabled && <MultiValueDeleteX onClick={() => onDeleteOne(a?.value)} />}
              </ValueLabel>
            ))}
          </AbsoluteWrapper>
        </ValuesWrapper>
      )}
    </div>
  );
};

export default ReactSelectWitness;
