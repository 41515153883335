import React, { useContext } from 'react';
import {
  FiltersForm,
  FiltersFormTitlte,
  FiltersTitle,
  LayoutOption,
  TraineesFilters,
} from './components';
import SelectStatus from './SelectStatus';
import CustomInput from '../../components/input/CustomInput';
import AnIcon from '../../assets/input/search-icon.png';
import { OrganizationContext } from '../../services/organizationContext';
import ReactSelectMulti from '../react-select-witness/ReactSelectMulti';

const iconStyles = {
  borderRight: '1px solid #050000',
  height: '100%',
  width: '4.8rem',
  margin: 0,
  backgroundSize: '48% auto',
  opacity: 0.2,
};

const ProgramsHeader = ({
  statusOpts,
  status,
  setStatus,
  traineeName,
  setTraineeName,
  heading,
  additionalButton,
  layout,
  setLayout,
  statusFilter = true,
  nameFilter = true,
  trainees,
  onlyStatus,
}) => {
  const { isOrganization } = useContext(OrganizationContext);
  return (
    <>
      <TraineesFilters>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FiltersTitle>{heading}</FiltersTitle>
          {layout && !isOrganization && (
            <>
              <LayoutOption checked={layout === 'CARDS'} onClick={() => setLayout('CARDS')} grid />
              <LayoutOption checked={layout === 'LIST'} onClick={() => setLayout('LIST')} />
            </>
          )}
        </div>
        <FiltersForm>
          {statusFilter && (
            <>
              <FiltersFormTitlte style={{ marginRight: '1.56rem' }}>
                Filter by status
              </FiltersFormTitlte>
              <ReactSelectMulti
                width="26.6rem"
                margin="0 0 0 2rem"
                checkBoxes
                options={statusOpts}
                placeholder="- Choose -"
                value={status}
                onChange={setStatus}
                backspaceRemovesValue={false}
                maxSelected={null}
                onDeleteOne={(a) => setStatus((curr) => curr.filter((x) => x?.value !== a))}
              />
            </>
          )}
          {nameFilter && (
            <CustomInput
              outlined
              height="4.4rem"
              borderRadius="8px"
              margin="0 0 0 1.55rem"
              placeholder="Search by name"
              icon={AnIcon}
              iconStyles={iconStyles}
              value={traineeName}
              onChange={setTraineeName}
            />
          )}
          {additionalButton}
        </FiltersForm>
      </TraineesFilters>
    </>
  );
};

export default ProgramsHeader;
