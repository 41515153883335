import React from 'react';
import { TooltipWrapper, TooltipTriangle, TooltipTriangleBorder } from './components';

const CustomTooltip = ({ below, children, triangleLeft, ...rest }) => {
  return (
    <TooltipWrapper className="wit_tooltip" below={below} {...rest}>
      <TooltipTriangleBorder below={below} triangleLeft={triangleLeft} />
      <TooltipTriangle className="wit_tooltip_triangle" below={below} triangleLeft={triangleLeft} />
      {children}
    </TooltipWrapper>
  );
};

export default CustomTooltip;
