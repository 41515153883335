import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ICONS from '../../constants/icons';
import URLS from '../../constants/urls';
import ChatContext from '../../services/ChatContext';
import { MobileContext } from '../../services/MobileContext';
import NotificationsModal from '../notifications/NotificatiosnModal';
import {
  HeaderRight,
  HeaderIcon,
  HeaderSection,
  MobileHeaderContainer,
  BurgerIcon,
  HeaderLeft,
  Dot,
} from './components';

const MobileHeader = () => {
  const { toggleMenu } = useContext(MobileContext);
  const { totalUnreadCount } = useContext(ChatContext);

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  return (
    <MobileHeaderContainer>
      <HeaderSection>
        <HeaderLeft ml={14} mr={9}>
          <BurgerIcon onClick={toggleMenu} />
        </HeaderLeft>
        <HeaderLeft ml={9}>
          <Link to={URLS.home} style={{ display: 'inline-flex' }}>
            <HeaderIcon src={ICONS.LogoShort} />
          </Link>
        </HeaderLeft>
      </HeaderSection>
      <HeaderSection>
        <HeaderRight>
          <Link to={URLS.messages} style={{ display: 'inline-flex' }}>
            <HeaderIcon src={ICONS.ChatMobile} />
          </Link>
          {!!totalUnreadCount && <Dot top={18} right={18} />}
        </HeaderRight>
        <HeaderRight>
          <HeaderIcon src={ICONS.NotificationMobile} onClick={() => setNotificationsOpen(true)} />
        </HeaderRight>
      </HeaderSection>

      {notificationsOpen && (
        <NotificationsModal
          modalOpen={notificationsOpen}
          closeModal={() => setNotificationsOpen(false)}
        />
      )}
    </MobileHeaderContainer>
  );
};

export default MobileHeader;
