import React, { useCallback, useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { updateCalendarDayMutation, getProgramCalendarFragment } from '@witness/graphql';
import { HoverButtons, HoverContainer, HoverButton } from './components';
import deleteIcon from '../../assets/program/delete-program.svg';
import editIcon from '../../assets/program/edit-program.svg';
import CalendarDragContext from '../../services/CalendarDragContext';

const HoverActions = ({
  children,
  onDelete,
  onEdit,
  showEdit,
  showDelete,
  draggable,
  droppable,
  workoutId,
  index,
  onClick,
}) => {
  const { isDragging, setIsDragging, refetchCalendar } = useContext(CalendarDragContext);
  const [isAbove, setIsAbove] = useState(false);
  const [updateCalendarDay] = useMutation(updateCalendarDayMutation);
  const client = useApolloClient();

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();

      const data = client.readFragment({
        id: isDragging,
        fragment: getProgramCalendarFragment,
        fragmentName: 'CalendarDay',
      });

      client.writeFragment({
        id: isDragging,
        fragment: getProgramCalendarFragment,
        fragmentName: 'CalendarDay',
        data: {
          ...data,
          programDay: index,
        },
      });

      setIsAbove(false);
      setIsDragging('');

      await updateCalendarDay({
        variables: {
          criteria: {
            uid: isDragging,
          },
          record: {
            programDay: index,
          },
        },
        fetchPolicy: 'no-cache',
      });

      refetchCalendar();
    },
    [updateCalendarDay, index, setIsDragging, isDragging, refetchCalendar, client],
  );

  return (
    <HoverContainer
      draggable={draggable}
      showDroppable={droppable && isDragging}
      showReady={droppable && isAbove && isDragging}
      onDragStart={() => setIsDragging(workoutId)}
      onDragEnd={() => setIsDragging('')}
      onDragOver={(e) => {
        e.preventDefault();
        setIsAbove(true);
      }}
      onDragLeave={() => setIsAbove(false)}
      onDrop={handleDrop}
      onClick={onClick}
    >
      <HoverButtons>
        {showEdit && <HoverButton src={editIcon} alt="Edit" onClick={onEdit} />}
        {showDelete && <HoverButton src={deleteIcon} alt="Delete" onClick={onDelete} />}
      </HoverButtons>
      {children}
    </HoverContainer>
  );
};

export default HoverActions;
