import moment from 'moment';
import React from 'react';
import Modal from 'react-modal';
import {
  ModalHeader,
  HeaderTitle,
  CloseIcon,
  ModalBody,
  ModalCode,
  ItemTypes,
  TrainingType,
  Info,
} from './components';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: '1111',
  },
  content: {
    zIndex: '11111',
    width: 'Calc(100% - 40px)',
    maxWidth: '520px',
    minWidth: '320px',
    height: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: '#001212',
    borderRadius: '12px',
    border: 'none',
    padding: '0 ',
  },
};

const MobileCouponDetailsModal = ({ item, closeModal, isDetails }) => {
  if (!item) {
    return null;
  }
  console.log('item', item);

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={!!item}
      style={customStyles}
      contentLabel="CouponDetails"
      ariaHideApp={false}
    >
      <ModalHeader>
        <HeaderTitle>COUPON INFO</HeaderTitle>
        <CloseIcon onClick={closeModal} />
      </ModalHeader>
      <ModalBody>
        <ModalCode>
          #{('000' + Math.floor(item?.code?.digits / 1000)).slice(-3)}-
          {('000' + (item?.code?.digits % 1000)).slice(-3)}
        </ModalCode>
        <ItemTypes mt="16px" mb="18px">
          {Array.isArray(item.trainingTypes) ? (
            item.trainingTypes.map((tt) => <TrainingType>{tt.name}</TrainingType>)
          ) : (
            <TrainingType>{item.title}</TrainingType>
          )}
        </ItemTypes>

        <Info title="Expiration Date" value={moment(item.validUntil).format('DD / MM / YYYY')} />

        {isDetails ? (
          <>
            <Info
              title="Amount"
              value={item.monthlySum + (item.discountType === 'PERCENTAGE' ? '%' : '₪')}
            />
            <Info title="Program Length" value={item.programLength + ' days'} />
          </>
        ) : (
          <>
            <Info title="Discount type" value={item.discountType === 'PERCENTAGE' ? '%' : '₪'} />
            <Info
              title="Amount of discount"
              value={item.amount + (item.discountType === 'PERCENTAGE' ? '%' : '₪')}
            />
          </>
        )}
        <Info title="No. of Usage" value={item.usage + '/' + item.available} />
      </ModalBody>
    </Modal>
  );
};

export default MobileCouponDetailsModal;
