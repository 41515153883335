import { getCalendarsQuery, getCalendarByUidQuery } from '@witness/graphql';
import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';

const useCalendar = ({ coachProgramUid, startDate, endDate, calendarUid }) => {
  const { data: calendars, loading: calendarsLoading, refetch: refetchCalendars } = useQuery(
    getCalendarsQuery,
    {
      variables: {
        record: {
          coachProgramUid,
          startDate,
          endDate,
        },
      },
      skip: !coachProgramUid || typeof startDate !== 'number',
      fetchPolicy: 'network-only',
    },
  );

  const { data: calendar, loading: calendarLoading, refetch: refetchCalendar } = useQuery(
    getCalendarByUidQuery,
    {
      variables: {
        uid: calendarUid,
      },
      skip: coachProgramUid || !calendarUid,
      fetchPolicy: 'network-only',
    },
  );

  const { refetch: refetchCalendarsRange } = useQuery(getCalendarsQuery, {
    fetchPolicy: 'network-only',
    skip: true
  });
  
  const getCalendarsRange = async (rangeStart, rangeEnd) => {
    try {
      const { data: calendarsRange } = await refetchCalendarsRange(
        {
            record: {
              coachProgramUid,
              startDate: rangeStart,
              endDate: rangeEnd,
            },
          }
      );
      return calendarsRange
    } catch (err) {
      console.log('Error while querying calendars', err);
    }
  }

  const state = useMemo(
    () => ({
      calendars: coachProgramUid ? calendars?.getCalendars : calendar?.getCalendarByUid,
      calendarsLoading: coachProgramUid ? calendarsLoading : calendarLoading,
      refetchCalendars: coachProgramUid
        ? refetchCalendars
        : calendarUid
        ? refetchCalendar
        : () => {},
      getCalendarsRange
    }),
    [
      coachProgramUid,
      calendar,
      calendarUid,
      calendarLoading,
      calendars,
      calendarsLoading,
      refetchCalendar,
      refetchCalendars,
      getCalendarsRange
    ],
  );

  return state;
};

export default useCalendar;
