import React, { useCallback, useState, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import {
  resetPasswordMutation,
  setPasswordMutation,
  setPasswordToOrgMutation,
} from '@witness/graphql';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'querystring';
import { SetPaswordPasswordStepSchema } from '../../schemas/signup/coachProfile';

import { useSignin } from '../../hooks'

import {
  SignInWrapper,
  SignInColumn,
  SignInCell,
  FlexWrapper,
  NonFlexWrapper,
  WelcomeAbsolute,
  ButtonWrapper,
} from '../sign-in/components';
import IMAGES from '../../constants/images';
import { Title, InputWrapper } from './components';
import CustomInput from '../../components/input/CustomInput';
import ICONS from '../../constants/icons';
import CustomButton from '../../components/button/CustomButton';
import STRINGS from '../../constants/strings';
import URLS from '../../constants/urls';

const ResetPassword = () => {
  const [didReset, setDidReset] = useState(false);
  const [resetPassword] = useMutation(resetPasswordMutation);
  const [setPassword] = useMutation(setPasswordMutation);
  const [setPasswordToOrg] = useMutation(setPasswordToOrgMutation);
  const { signInAndRedirect } = useSignin();

  const history = useHistory();
  const location = useLocation();
  const token = useMemo(() => {
    return parse(location.search.substring(1)).token;
  }, [location.search]);
  const role = useMemo(() => {
    return parse(location.search.substring(1)).role;
  }, [location.search]);

  const [apiError, setApiError] = useState('');

  const resetError = useCallback(() => {
    if (apiError) {
      setApiError('');
    }
  }, [apiError]);

  const submit = useCallback(
    async ({ password }) => {
      console.log(history);
      try {
        const setPass = role === 'coach' ? setPassword : setPasswordToOrg;
        const handler = history.location.pathname === URLS.set_password ? setPass : resetPassword;
        
        const { data } = await handler({
          variables: {
            token,
            password,
          },
        });

        let email;
        if (history.location.pathname === URLS.set_password) {
          email = role === 'coach' ? data?.setPasswordToCoach?.user?.email : data?.setPasswordToOrg?.email;
        } else {
          email = data?.resetPassword?.email;
        }

        if (role === 'coach' || role === 'organisation') {
          await signInAndRedirect({
            email,
            password
          });
        } else {
          setDidReset(true);
        }
      } catch (err) {
        if (err.graphQLErrors?.find((x) => x.message === 'invalid_token')) {
          setApiError('invalid_token');
        } else {
          console.log('unexpected_error', err);
          setApiError('unexpected_error');
        }
      }
    },
    [resetPassword, token, role, history, setPassword, setPasswordToOrg],
  );

  return (
    <SignInWrapper image={IMAGES.PasswordBackground}>
      <SignInColumn minResolution="tablet">
        <SignInCell />
        <SignInCell />
        <SignInCell />
        <SignInCell bg />
        <SignInCell />
        <SignInCell bottom />
      </SignInColumn>
      <SignInColumn main>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <WelcomeAbsolute>Welcome To</WelcomeAbsolute>
        </FlexWrapper>
        <SignInCell collSpan={2}>
          <img style={{ maxWidth: 'min(60vw, 80%)' }} src={IMAGES.LogoImage} alt="Logo" />
        </SignInCell>
        <SignInCell collSpan={2} rowSpan={3}>
          {!didReset ? (
            <>
              Please create a secure password
              <Formik
                initialValues={{ password: '', repassword: '' }}
                validationSchema={SetPaswordPasswordStepSchema}
                onSubmit={(v) => submit(v)}
              >
                {({ setFieldValue, errors, touched, isSubmitting, values }) => (
                  <Form style={{ width: '80%', padding: '4rem 0 0', maxWidth: '44rem' }}>
                    <>
                      <Field id="password" name="password">
                        {({ field }) => (
                          <>
                            <CustomInput
                              autocomplete={false}
                              outlined
                              height={'5.6rem'}
                              placeholder="Enter Password"
                              icon={ICONS.PasswordIcon}
                              value={field.value}
                              onChange={(val) => {
                                resetError();
                                setFieldValue(field.name, val);
                              }}
                              type="password"
                              backgroundColor="#ffffff"
                              margin="3.2rem 0"
                              error={
                                (touched[field.name] && errors[field.name]) || STRINGS[apiError]
                              }
                            />
                          </>
                        )}
                      </Field>
                      <Field id="repassword" name="repassword">
                        {({ field }) => (
                          <>
                            <CustomInput
                              autocomplete={false}
                              outlined
                              height={'5.6rem'}
                              placeholder="Repeat Your Password"
                              icon={ICONS.PasswordIcon}
                              value={field.value}
                              onChange={(val) => {
                                resetError();
                                setFieldValue(field.name, val);
                              }}
                              type="password"
                              backgroundColor="#ffffff"
                              error={touched[field.name] && errors[field.name]}
                            />
                          </>
                        )}
                      </Field>
                    </>
                    <ButtonWrapper>
                      <CustomButton type="submit" green>
                        Next
                      </CustomButton>
                    </ButtonWrapper>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <InputWrapper mt="3.2rem">
              <Title>Password Changed Successfully</Title>
            </InputWrapper>
          )}
        </SignInCell>
        <FlexWrapper>
          <SignInCell bottom bg />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn minResolution="laptopS">
        <FlexWrapper>
          <SignInCell bg />
          <SignInCell />
          <SignInCell />
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell />
          <SignInCell collSpan={2} />
          <SignInCell />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <SignInCell collSpan={2} />
          <SignInCell />
        </FlexWrapper>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <SignInCell rowSpan={2} />
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell bg />
            <SignInCell />
          </NonFlexWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <SignInCell bottom />
          <SignInCell bottom />
          <SignInCell bottom bg />
          <SignInCell bottom />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
    </SignInWrapper>
  );
};

export default ResetPassword;
