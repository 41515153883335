import React from 'react';
import CreatableSelect from 'react-select/creatable';
import ICONS from '../../constants/icons';

import {
  InputError,
  InputErrorIcon,
  InputWrapper,
  StyledOption,
  OptionButtonsContainer,
  OptionButton,
} from './components';

const CustomSearch = ({
  backgroundColor,
  outlined,
  fontSize,
  placeholder,
  value,
  onChange,
  type,
  borderColor,
  onSelect,
  loading,
  options = [],
  error,
  errorIcon = true,
  createNew,
  viewOption,
  initialValue,
  noIcon,
  menuStyle,
  ...rest
}) => {
  return (
    <InputWrapper
      height="4rem"
      outlined={true}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius="8px"
      fontSize={fontSize}
      error={error && !value}
      {...rest}
    >
      <CreatableSelect
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
            backgroundImage: (error && !value) || noIcon ? '' : `url(${ICONS.Search})`,
            backgroundSize: 'auto 2rem',
            backgroundPosition: 'right',
            paddingRight: !noIcon ? '2.2rem' : 0,
            backgroundRepeat: 'no-repeat',
            marginRight: noIcon ? 0 : '1rem',
          }),
          control: (provided) => ({
            ...provided,
            boxShadow: 'none',
            border: 0,
            backgroundColor: 'tranparent',
          }),
          menu: (provided) => ({
            ...provided,
            display: value ? 'block' : 'none',
            ...menuStyle,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontFamily: 'Roboto-l',
            fontSize: '1.4rem',
            color: '#0500004d',
          }),
        }}
        placeholder={placeholder}
        autoFocus
        options={options.map((opt) => ({ label: opt.title, value: opt.id, ...opt }))}
        createOptionPosition={'first'}
        formatCreateLabel={(userInput) => `Create new exercise: ${userInput}`}
        onInputChange={onChange}
        defaultValue={initialValue}
        value={value}
        controlShouldRenderValue={false}
        openMenuOnFocus
        inputValue={value}
        isLoading={loading}
        components={{
          DropdownIndicator: null,
          ClearIndicator: null,
          LoadingIndicator: null,
          Option: (props) => (
            <StyledOption
              className="opt"
              isNew={props?.data?.__isNew__}
              isFocused={props?.isFocused}
              onClick={() => props?.selectOption(props?.data)}
            >
              {props.children}
              {!props?.data?.__isNew__ &&
                (props.data?.gender === 'FEMALE' ? '  (Female)' : '   (Male)')}
              {!props?.data?.__isNew__ && (
                <OptionButtonsContainer>
                  <OptionButton className="option-plus" />
                  <OptionButton
                    className="option-view"
                    isView
                    onClick={(e) => {
                      e.stopPropagation();
                      viewOption(props.data);
                    }}
                  />
                </OptionButtonsContainer>
              )}
            </StyledOption>
          ),
        }}
        // clearable={true}
        // onSelectResetsInput={true}
        captureMenuScroll={false}
        onKeyDown={(keyEvent) => {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
        }}
        onChange={(a) => {
          if (a.__isNew__) {
            createNew(a?.value);
          } else {
            onSelect(a);
          }
        }}
        on
      />
      {error ? (
        <InputError style={{ overflow: 'visible' }}>
          {error}
          {errorIcon && <InputErrorIcon />}
        </InputError>
      ) : (
        <></>
      )}
    </InputWrapper>
  );
};

export default CustomSearch;
