import styled, { keyframes } from 'styled-components';
import ICONS from '../../constants/icons';

export const WholeWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const ColumnsWrapper = styled.div`
  width: 100%;
  max-height: Calc(100% - 11.4rem);
  flex-grow: 1;
  background: #ffffff;
  display: flex;
`;

export const ModalColumn = styled.div`
  width: ${({ main }) => (main ? '50%' : '25%')};
  flex-grow: 1;
  border-right: ${({ left }) => (left ? '1px solid #05000033' : 'none')};
  border-left: ${({ right }) => (right ? '1px solid #05000033' : 'none')};
  background: ${({ left }) => (left ? '#ffffff' : '#F1F0F0')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalHeader = styled.div`
  width: 100%;
  min-height: 6rem;
  display: flex;
  align-items: center;
  line-height: 2.4rem;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'Space-b')};
  border-bottom: ${({ noborder }) => (noborder ? 'none' : '1px solid #05000033')};
  font-size: 2.2rem;
  padding: 0 2.8rem;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  background: #ffffff;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 3.2rem;
  border-top: 1px solid #05000033;
`;

export const ModalHeaderCenterLine = styled.div`
  width: 1px;
  height: 100%;
  background: #05000033;
`;

export const ModalCloseX = styled.div`
  font-size: 2rem;
  color: #4a4a4a;
  opacity: 0.6;
  font-family: Roboto-m;
  cursor: pointer;
`;

export const LeftSideBlock = styled.div`
  width: 100%;
  padding: 2rem 3.2rem 3.8rem;
  border-bottom: 1px solid #05000033;
  &:last-child {
    border-bottom: none;
  }
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const LeftSideBlockTitle = styled.div`
  font-size: 1.6rem;
  font-family: Roboto-r;
  line-height: 2.1rem;
  margin: 1.6rem 0;
  display: flex;
  align-items: center;
`;

export const LeftSideBlockOptional = styled.span`
  font-size: 1.4rem;
  font-family: Roboto-r;
  line-height: 2.1rem;
  color: #0500004d;
  margin-left: 0.8rem;
  letter-spacing: 0.08rem;
`;

export const MessageCheck = styled.div`
  color: ${({ active }) => (active ? '#050000cc' : '#05000066')};
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  margin: 22px 0 12px;
  cursor: pointer;
  & > .checkbox {
    background-image: url(${({ active }) => (active ? ICONS.TermsCheckMark : 'none')});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-color: ${({ theme, active }) => (active ? theme.color.primary : '#05000033')};
  }
  overflow: hidden;
`;

export const MessageCheckBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #05000033;
  margin-right: 16px;
`;

export const ContentContainer = styled.div`
  padding: 2rem 2.4rem;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const ContentWrapper = styled.div`
  max-height: Calc(100% - 6rem);
  height: 100%;
  width: 100%;
  padding: 1.2rem 0.8rem;
  position: relative;
`;

export const WorkoutBlockDropBox = styled.div`
  width: 100%;
  padding-bottom: 24%;
  background: #ffffff;
  min-height: 104px;
  border-radius: 12px;
  position: relative;
  display: ${({ isRest }) => (isRest ? 'none' : 'block')};
`;

export const WorkoutBlockDropBoxDashed = styled.div`
  position: absolute;
  width: ${({ week }) => (week ? '100%' : 'Calc(100% - 1.2rem)')};
  height: ${({ week }) => (week ? '100%' : 'Calc(100% - 1.2rem)')};
  top: ${({ week }) => (week ? '0' : '0.6rem')};
  left: ${({ week }) => (week ? '0' : '0.6rem')};
  border: 1px dashed #96949499;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  font-family: Space-r;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: ${({ week }) => (week ? '0.6rem 1rem' : '2rem')};
  border-color: ${({ error, theme }) => (error ? theme.color.error : '#96949455')};
  color: ${({ error, theme }) => (error ? theme.color.error : '#0500004d')};
  &:hover {
    border-color: ${({ error, theme }) => (error ? theme.color.error : '#15df68')};
  }
  cursor: pointer;
`;

export const WorkoutBlockDropBoxPlus = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${ICONS.PlusSimple});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 0.4rem;
  position: relative;
  pointer-events: none;
`;

export const TooltipWorkoutBlock = styled.div`
  opacity: 80%;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0 1.4rem;
  font-family: Roboto-r;
  overflow: hidden;
  color: #050000;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 100%;
    background: #00ff911f;
  }
  line-height: 3.6rem;
`;

export const TooltipBlocksWrapper = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
  height: 100%;
  overflow: auto;
  max-height: 21rem;
`;

export const RightColumnContainer = styled.div`
  width: 100%;
  padding: 0.8rem;
  flex-grow: 1;
  max-height: Calc(100% - 6rem);
`;

export const WorkoutBlocksBoxScrollable = styled.div`
  width: 100%;
  max-height: 100%;
  height: 100%;
  background: white;
  padding: 1.2rem 0.2rem;
  border-radius: 8px;
`;

export const WorkoutBlocksBox = styled.div`
  padding: 1.4rem;
  overflow: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
  width: 100%;
  height: 100%;
  /* height: Calc(100% - 6rem);
  min-height: Calc(100% - 6rem); */
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Gotham-r;
  line-height: 2.4rem;
  color: #4a4a4acc;
`;

export const DraggableSign = styled.div`
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  background: #f1f0f0;
  margin-bottom: 2rem;
  background-image: url(${ICONS.DragIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const WorkoutBlocksContainer = styled.div`
  width: 100%;
  margin-top: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const WorkoutBlockItem = styled.div`
  width: Calc(50% - 1.6rem);
  height: 6.4rem;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: move;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 1.4rem 1.6rem 1rem;
  text-align: center;
  border-color: ${({ active, theme }) => (active ? theme.color.primary : 'transparent')};
  background: ${({ active }) => (active ? '#ffffff' : '#f1f0f0')};
  color: ${({ active }) => (active ? '#050000' : '#373333')};
`;

export const PseudoWorkoutBlock = styled.div`
  width: Calc(50% - 1.6rem);
`;

export const WorkoutBlockForm = styled.div`
  width: 100%;
  background: white;
  border-radius: ${({ mobile }) => (mobile ? '4px' : '12px')};
  box-shadow: 0 0 3px rgba(74, 74, 74, 0.12);
  margin-bottom: ${({ week }) => (week ? '0.8rem' : '1.6rem')};
`;

export const WorkoutBlockHeader = styled.div`
  width: 100%;
  border-radius: ${({ mobile }) => (mobile ? '4px 4px 0 0' : '12px 12px 0 0')};
  padding: ${({ week, mobile }) =>
    mobile ? '12px 8px 10px 20px' : week ? '1.6rem 1.2rem 1.6rem 0.8rem' : '2.2rem 2.4rem'};
  border-bottom: 1px solid #4a4a4a22;
  font-family: Space-b;
  font-size: 1.6rem;
  line-height: ${({ week }) => (week ? '1.8rem' : '2.4rem')};
  color: #050000cc;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const WorkoutBlockHeaderIconsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const WorkoutBlockHeaderIcon = styled.div`
  width: 2rem;
  height: 2rem;
  background-image: ${({ edit, duplicate, move }) =>
    edit
      ? `url(${ICONS.EditSimpleGrey})`
      : duplicate
      ? `url(${ICONS.Copy})`
      : move
      ? `url(${ICONS.Move})`
      : `url(${ICONS.BinSimpleGrey})`};
  background-size: contain;
  background-position: center;
  margin: 0 0.4rem;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const WorkoutBlockFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1.6rem;
`;

export const AttrCheckBox = styled.div`
  width: ${({ week }) => (week ? '1.4rem' : '1.8rem')};
  height: ${({ week }) => (week ? '1.4rem' : '1.8rem')};
  border-radius: 5px;
  border: 1px solid #05000033;
  margin-right: ${({ week }) => (week ? '1rem' : '1.2rem')};
  background-color: ${({ theme, active }) => (active ? theme.color.primary : 'transparent')};
  background-image: url(${({ active }) => (active ? ICONS.TermsCheckMark : 'none')});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: ${({ theme, active }) => (active ? theme.color.primary : '#05000033')};
  cursor: pointer;
`;

export const WorkoutBlockApplyButton = styled.div`
  width: 124px;
  height: 40px;
`;

export const WeekBlockFooter = styled.div`
  width: 100%;
  padding: 0.8rem 1rem 1rem 0.8rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #05000020;
  align-items: center;
  margin-top: 0.6rem;
  position: relative;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const WeekBlockAddComment = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0.6rem;
  background: #fff;
  min-width: ${({ preview, beforeAfter }) => (preview || beforeAfter ? '100%' : '2rem')};
  border-radius: 6px;
  @media (max-width: 600px) {
    max-width: 100%;
    margin-right: 0;
    padding: 0 6px;
  }
`;
export const WeekBlockAddCommentPreview = styled.div`
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #373333;
  padding: 1rem 1rem 1.4rem;
  text-align: center;
`;
export const WeekBlockAddCommentTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #373333;
  padding: 0 0.8rem;
`;
export const WeekBlockAddCommentButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const WeekBlockAddCommentIcon = styled.div`
  min-width: 1.6rem;
  min-height: 1.6rem;
  background-image: url(${ICONS.AddComment});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const WeekBlockAddCommentText = styled.div`
  font-size: 1rem;
  color: #373333;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.2rem;
  line-height: 1.2rem;
  white-space: nowrap;
  max-width: Calc(100% - 2.2rem);
  flex-grow: 1;
  pointer-events: none;
  margin-left: 0.6rem;
`;

export const WorkoutBlockContent = styled.div`
  padding: ${({ week }) => (week ? '0.8rem 0.6rem' : '4rem 2.6rem 3.2rem')};
  width: 100%;
  @media (max-width: 600px) {
    padding: 16px;
  }
  display: flex;
  flex-direction: column;
  order: 2;
  & > * {
    order: 2;
  }
  & > *.block_title {
    order: 1;
  }
`;

export const AttributeWrapper = styled.div`
  margin-bottom: ${({ week }) => (week ? 0 : '1.6rem')};
  display: flex;
  align-items: flex-start;
  ${({ week }) =>
    week
      ? `
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  `
      : ``};
`;

export const ExercisesWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #f8f7f7cc;
  border: 1px solid #0500000d;
  margin-top: 1.6rem;
`;
export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AttributeLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ week }) => (week ? '1.1rem' : '1.4rem')};
  color: #050000cc;
  line-height: 2.1rem;
  padding: ${({ small, week }) => (week ? '0' : small ? '1rem 2rem 1rem 0' : '1rem 0')};
  flex-grow: ${({ flexGrow }) => (flexGrow ? '1' : 'none')};
  width: ${({ flexGrow, small, week }) =>
    week ? 'fit-content' : flexGrow ? '100%' : small ? '12rem' : '30%'};
  min-width: ${({ small, week }) => (week ? 'fit-content' : small ? '12rem' : '18.6rem')};
`;
export const AttributeUnit = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #050000cc;
  line-height: 4rem;
  padding-left: 1rem;
`;

export const AsNeeded = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #050000cc;
  line-height: 2.1rem;
`;

export const AsNeededCheck = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 30%;
  border: 1px solid ${({ checked, theme }) => (checked ? theme.color.primary : '#0500003e')};
  margin-right: 0.8rem;
  margin-left: 2rem;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ checked }) => checked && ICONS.TermsCheckMark});
`;

export const AttributeBullet = styled.div`
  width: ${({ clock, week }) => (clock ? '12px' : week ? '6px' : '8px')};
  height: ${({ clock, week }) => (clock ? '12px' : week ? '6px' : '8px')};
  min-width: ${({ clock, week }) => (clock ? '12px' : week ? '6px' : '8px')};
  min-height: ${({ clock, week }) => (clock ? '12px' : week ? '6px' : '8px')};
  margin: ${({ clock }) => (clock ? '0' : '2px')};
  border-radius: 50%;
  background: ${({ theme, clock }) => (clock ? 'transparent' : theme.color.primary)};
  background-image: url(${ICONS.Clock});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1.2rem;
`;

export const ExerciseWrapper = styled.div`
  width: 100%;
  display: ${({ week }) => (week ? 'block' : 'flex')};
  margin-bottom: 1.6rem;
  align-items: center;
  box-shadow: ${({ beingDragged }) => (beingDragged ? 'inset 0px 0px 6px 6px #00000004' : 'none')};
  opacity: ${({ beingDragged }) => (beingDragged ? '0.5' : '1')};
  ${({ week }) =>
    week
      ? `
      margin-bottom: 0;
      border-bottom: 0.3px solid #0000000d;
      &:last-child {
        border-bottom: none;
      };
      padding: 1.6rem 0.8rem;
      `
      : ''};
  border-top: ${({ draggingOver, theme }) =>
    draggingOver ? `2px solid ${theme.color.primary}` : 'none'};
`;

export const ExerciseLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Roboto-l;
  color: #373333cc;
  min-width: ${({ week }) => (week ? '100%' : ' 18.6rem')};
  width: 30%;
  margin-bottom: ${({ week }) => (week ? '0.8rem' : '0')};
`;

export const ExerciseIndex = styled.span`
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Space-r;
  padding-right: 1rem;
  color: ${({ transparent }) => (transparent ? '#ffffff00' : '#050000')};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
`;

export const ExerciseOpenButton = styled.span`
  min-width: 2.4rem;
  min-height: 2.4rem;
  background-image: url(${({ bin }) => (bin ? ICONS.BinSimpleGrey : ICONS.OptionView)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ bin }) => (bin ? 'auto 1.5rem' : 'contain')};
  margin-right: 0.5rem;
  cursor: pointer;
`;

export const ExerciseContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: ${({ week }) => (week ? 'wrap' : 'nowrap')};
  justify-content: space-between;
`;

export const DeleteExerciseIcon = styled.div`
  width: 1.8rem;
  height: ${({ nonAbsolute }) => (nonAbsolute ? '2rem' : '100%')};
  background-image: url(${ICONS.BinSimpleGrey});
  background-size: 92% auto;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: ${({ nonAbsolute }) => (nonAbsolute ? 'auto' : 'absolute')};
  left: Calc(100% + 0.3rem);
  &:hover {
    background-size: 100% auto;
  }
`;

export const ExerciseWeight = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WorkoutBlockTable = styled.div`
  width: 100%;
  border-radius: 4px;
  background: ${({ week }) => (week ? 'transparent' : 'white')};
  display: flex;
  flex-direction: ${({ week }) => (week ? 'column' : 'row')};
`;

export const WorkoutBlockTableColumnLeft = styled.div`
  width: ${({ week }) => (week ? '100%' : '42%')};
  border-radius: 8px 0 0 8px;
  border-right: 1px solid #0500000a;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const WorkoutBlockTableAttributeRow = styled.div`
  width: 100%;
  height: ${({ week }) => (week ? '2.4rem' : '3.6rem')};
  padding: ${({ week }) => (week ? '0 0.6rem' : '0 1.7rem')};
  display: flex;
  align-items: center;
  border-top: ${({ week }) => (week ? 'none' : '1px solid #0500000a')};
  &:first-child {
    border-top: none;
  }
`;
export const WorkoutBlockTableAttributeNum = styled.span`
  font-size: ${({ week }) => (week ? '1.4rem' : '1.6rem')};
  font-family: Space-b;
  color: #05000099;
  line-height: 2.4rem;
  padding-right: 0.8rem;
`;
export const WorkoutBlockTableAttributeLabel = styled.span`
  padding-right: 0.8rem;
  font-size: ${({ week }) => (week ? '1.2rem' : '1.4rem')};
  font-family: Space-b;
  font-family: Roboto-m;
  color: #05000099;
  line-height: 2.4rem;
`;
export const WorkoutBlockTableColumnRight = styled.div`
  flex-grow: 1;
  border-radius: 0 8px 8px 0;
  padding: ${({ week }) => (week ? '0.4rem 0.6rem' : '0.4rem 1.2rem 0.4rem 1.7rem')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WorkoutBlockWorkoutRow = styled.div`
  width: 100%;
  margin: 0.4rem 0;
  background: ${({ week }) => (week ? 'transparent' : 'white')};
  flex-wrap: ${({ week }) => (week ? 'wrap' : 'no-wrap')};
  border-radius: 8px;
  height: ${({ week }) => (week ? 'auto' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: ${({ type }) => (type ? "space-between" : "flex-start")};
  padding: ${({ week }) => (week ? '0' : '1rem 1.6rem')};
`;
export const CustomBlockDescriptionLine = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-family: Roboto-m;
  color: #05000099;
  line-height: 2.4rem;
  overflow: hidden;
  min-height: 2.4rem;
`;
export const CustomBlockDescription = styled.div`
  width: 100%;
  margin: 0.4rem 0;
  font-size: 1.4rem;
  font-family: Roboto-m;
  color: #05000099;
  line-height: 2.4rem;
  padding: 1rem 1.6rem;
  overflow: hidden;
`;
export const WorkoutBlockWorkoutIndex = styled.span`
  padding-right: 1.2rem;
  font-family: Space-r;
  font-size: 1.2rem;
`;
export const WorkoutBlockWorkoutName = styled.span`
  padding-right: 1.4rem;
  font-family: Roboto-r;
  font-size: 1.2rem;
  color: #05000099;
`;
export const WorkoutBlockWorkoutValue = styled.span`
  padding-right: 1.4rem;
  font-family: Roboto-l;
  font-size: 1.2rem;
  color: #373333cc;
`;

export const WorkoutBlockWorkoutPilatesrValue = styled.span`
  padding-right: 1.4rem;
  font-family: Roboto-l;
  font-size: 1.2rem;
  color: #373333cc;
  border-radius: 8px;
  padding: 4px;
  border: 1.5px solid #E6E5E5;
  margin: 4px;
  flex: 3;
`;

export const WorkoutBlockWorkoutPilatesrNameValue = styled.span`
  padding-right: 1.4rem;
  font-family: Roboto-l;
  font-size: 1.2rem;
  color: #373333cc;
  flex: 1;
`;

export const WorkoutBlockComment = styled.div`
  padding-left: 3.6rem;
  min-height: 2.5rem;
  font-family: Roboto-l;
  font-size: 1.4rem;
  line-height: 2.1rem;
  background-image: url(${ICONS.Comment});
  background-size: 2.5rem auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-top: 2rem;
`;
export const WorkoutBlockAdditionalAttrWrapper = styled.div`
  margin-top: ${({ week }) => (week ? '2.4rem' : '4rem')};
  display: flex;
  flex-direction: column;
  align-items: ${({ week }) => (week ? 'flex-start' : 'flex-end')};
`;

export const WorkoutBlockAdditionalAttr = styled.div`
  padding-left: ${({ week }) => (week ? '2rem' : '2.8rem')};
  font-family: Roboto-r;
  font-size: ${({ week }) => (week ? '1.2rem' : '1.4rem')};
  line-height: ${({ week }) => (week ? '1.8rem' : '2.1rem')};
  background-image: url(${ICONS.CheckMark});
  background-size: ${({ week }) => (week ? '1.2rem auto' : '1.6rem auto')};
  margin-left: ${({ week }) => (week ? '0.8rem' : '0')};
  background-position: 0 center;
  background-repeat: no-repeat;
`;

export const WorkoutBlockOrderArrow = styled.div`
  min-width: ${({ small }) => (small ? '2.4rem' : '3.2rem')};
  min-height: ${({ small }) => (small ? '2.4rem' : '3.2rem')};
  border-radius: 8px;
  border: 1px solid #f1f0f0;
  margin-right: ${({ small }) => (small ? '0.4rem' : '4px')};
  background-image: url(${ICONS.ArrowUpLight});
  transform: ${({ down }) => (down ? 'rotate(180deg)' : 'none')};
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: center;
  &:last-child {
    margin-right: 2rem;
  }
  ${({ nonClickable }) =>
    nonClickable
      ? `
      opacity: 0.6;
      pointer-events: none;
      `
      : `
  &:hover {
    background: #f1f0f0;
    background-image: url(${ICONS.ArrowUpDark});
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
  cursor: pointer;
  `}
`;

export const BeforeExitModal = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BeforeExitModalContents = styled.div`
  width: 90vw;
  min-width: 300px;
  max-width: 450px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 50% 50%;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  & > span {
    text-align: center;
    grid-column: 1 / span 2;
    margin-bottom: 10px;
  }
`;

export const ExerciseTitle = styled.span`
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: Roboto-l;
  color: #373333cc;
  margin-right: 0.5rem;
  flex-grow: 1;
`;

// calendar week

export const CalendarWeekWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CalendarWeekHeader = styled.div`
  width: 100%;
  height: 7.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #05000033;
`;

export const WeekHeaderFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 2.4rem;
  min-width: 30rem;
  flex: 1;
  &:last-child {
    justify-content: flex-end;
  }
`;

export const WeekTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  border-left: 1px solid #05000033;
  border-right: 1px solid #05000033;
  height: 100%;
`;

export const NeighbourWeekArrow = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background: #f1f0f0;
  margin: 1.2rem 2.4rem;
  cursor: pointer;
  background-image: url(${ICONS.ArrowLeft});
  background-size: 60% auto;
  background-repeat: no-repeat;
  background-position: center;
  &:last-child {
    transform: rotate(180deg);
  }
`;

export const WeekRange = styled.div`
  font-family: Space-r;
  font-size: 2.2.rem;
  line-height: 3.2rem;
  color: #373333;
  text-transform: uppercase;
`;

export const WeekContent = styled.div`
  background: #f2f2f2;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 2rem;
`;

export const WeekDayColumnWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 200px;
  min-width: ${({ collapsed }) => (collapsed ? '6.8rem' : 'Calc((100% - 8.4rem) / 7)')};
  background: #f8f8f8;
  border-radius: 8px;
  margin: 0 0.4rem;
  max-width: ${({ collapsed }) => (collapsed ? '6rem' : 'auto')};
  &:hover {
    & * .drop-box {
      display: flex;
    }
    & * .rest-boy {
      display: none;
    }
  }
  ${({ readOnly }) =>
    readOnly
      ? `
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
  `
      : ''}
`;

export const WeekDayHeader = styled.div`
  width: 100%;
  height: 12rem;
  background: #fff;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e5e5e5;
  padding: 1.6rem;
  position: relative;
  &:hover {
    & > .hide-a-day {
      display: flex;
    }
  }
`;

export const WeekDayNumbering = styled.div`
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  color: #373333;
  font-family: Space-r;
`;

export const WeekDayDay = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #373333;
`;

export const HeaderButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
`;

export const HeaderButton = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 0.8rem;
  border-radius: 0.8rem;
  border: 1px solid #f1f0f0;
  background-image: url(${({ icon }) => icon});
  background-size: auto 64%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border-color: ${({ open, theme }) => (open ? theme.color.primary : '#f1f0f0')};
  position: relative;
  &::after {
    content: '';
    display: ${({ active, open }) => (!open && active ? 'block' : 'none')};
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.primary};
    top: -0.2rem;
    right: -0.2rem;
  }
`;

export const WeekDayName = styled.div`
  width: 100%;
  padding: 0.4 0.8rem;
  border: 1px solid #e5e5e5;
  background: ${({ status }) =>
    status === 'DONE' ? '#00ff9140' : status === 'UNDONE' ? '#f50a4f33' : 'transparent'};
`;

export const BlockActionsStackWrapper = styled.div`
  position: relative;
`;
export const BlockActionsStackMenu = styled.div`
  position: absolute;
  top: Calc(100% + 0.8rem);
  background: #fff;
  border: 1px solid #96949440;
  border-radius: 0.8rem;
  padding: 0.4rem;
  z-index: 1233131322;
`;
export const BlockActionsStackItem = styled.div`
  padding: 0.6rem;
  color: #646161;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  min-width: fit-content;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #f1f0f060;
  }
`;
export const BlockActionsStackIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-image: url(${({ icon }) => icon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 0.8rem;
`;
export const WeekDayCheckRest = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #373333;
  padding: 0.8rem 0;
`;
export const MessagesRelativeBox = styled.div`
  position: relative;
  margin-bottom: 0.8rem;
`;
export const CommentsOuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ noBorder }) => (noBorder ? '1px solid transparent' : '1px solid #c3c2c264')};
  width: 100%;
  border-radius: 6px;
  padding: 0.6rem;
  &:hover {
    border-color: ${({ theme }) => theme?.color?.primary};
  }
  opacity: ${({ op }) => (op ? '0.6' : 1)};
`;
export const WeekRestBoy = styled.div`
  display: flex;
  width: 100%;
  padding: 40% 0;
  background-image: url(${ICONS.RestDayBoy});
  background-size: min(80%, 124) auto;
  background-position: center;
  background-repeat: no-repeat;
`;
export const ColumnTopButtons = styled.div`
  position: absolute;
  right: ${({ collapsed }) => (collapsed ? 'auto' : '0.8rem')};
  top: 0.8rem;
  display: none;
`;
export const ColumnTopButton = styled.div`
  border-radius: 50%;
  cursor: pointer;
  background: #f1f0f0;
  margin-left: 0.8rem;
  padding: 0.2rem;
  &:first-child {
    margin-left: 0;
  }
`;

export const ColumnTopButtonIcon = styled.div`
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 1.4rem;
  height: 1.4rem;
`;
export const DragDropWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  /* background: ${({ draggingOver }) => (draggingOver ? 'red' : '#ffd0d0')}; */
  opacity: ${({ beingDragged }) => (beingDragged ? 0.4 : 1)};
`;
const Blink = keyframes`
  0% {
    opacity: 0.6;
    width: Calc(60% - 0.8rem);
  }
  80% {
    opacity: 1;
  }
  100% {
    width: Calc(100% + 1rem);
  }
`;
export const DropIndexIndicator = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.color.primary};
  height: 0.32rem;
  border-radius: 40rem;
  width: Calc(100% + 1rem);
  animation: ${Blink} 800ms linear infinite;
  top: -0.5rem;
`;
export const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3.2rem;
`;
