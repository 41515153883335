import { getCoachEarningsQuery, getOrgEarningsQuery } from '@witness/graphql';
import { useQuery } from '@apollo/react-hooks';
import { useContext, useMemo } from 'react';
import moment from 'moment';
import { OrganizationContext } from '../services/organizationContext';

const useEarnings = () => {
  const { isOrganization } = useContext(OrganizationContext);
  const { data: coachData, loading: coachEarningsLoading } = useQuery(getCoachEarningsQuery);

  const { data: orgData, loading: orgEarningsLoading } = useQuery(getOrgEarningsQuery);

  const [data, earningsLoading] = useMemo(() => {
    if (isOrganization) {
      return [orgData?.getOrgEarnings, orgEarningsLoading];
    } else {
      return [coachData?.getCoachEarnings, coachEarningsLoading];
    }
  }, [isOrganization, orgData, coachData, orgEarningsLoading, coachEarningsLoading]);

  const today = moment();

  const earnings = useMemo(() => {
    const years = {};
    if (!data?.length) {
      return {};
    }
    data.map((earning) => {
      years[earning.year] = {
        ...years[earning.year],
        [earning.month]: {
          monthlyIncome:
            (years[earning.year]?.[earning.month]?.monthlyIncome || 0) +
            (earning.coachFee || earning.amount),
          programs: [...(years[earning.year]?.[earning.month]?.programs || []), earning],
        },
      };
      return 1;
    });
    return years;
  }, [data]);

  const chartData = useMemo(() => {
    const result = [{ name: '', income: 0 }];
    Object.keys(earnings).map((year) =>
      Object.keys(earnings[year]).map((month) => {
        return result.push({
          name: `${month}.${year.substr(2, 2)}`,
          income: earnings?.[year]?.[month]?.monthlyIncome,
        });
      }),
    );
    return result;
  }, [earnings]);

  return {
    earningsLoading,
    earnings,
    today: today.format('MMMM, YYYY'),
    currentMonthIncome:
      earnings[today?.format('YYYY')]?.[today?.format('M')]?.monthlyIncome?.toFixed(2),
    chartData,
  };
};

export default useEarnings;
