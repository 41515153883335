import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import { useRegisterCoach, useAddressAutoComplete } from '../../hooks/index';
import STRINGS from '../../constants/strings';
import ICONS from '../../constants/icons';
import CustomInput from '../../components/input/CustomInput';
import CustomButtom from '../../components/button/CustomButton';
import { SignupPersonalSchema } from '../../schemas/signup/coachProfile';
import {
  SignUpContainer,
  LeftPhoto,
  FormContainer,
  FormHeading,
  FormSubHeading,
  ProfileimageLine,
  Profileimage,
  PersonalFormWrapper,
  SubmitButtonWrapper,
  LeftPhotoBackground,
  LeftPhotoBackgroundLine,
  Logo,
  TermsCheck,
  FieldTip,
  CenteringContainer,
  ProfileImageContainer,
  TermsAndConditions,
  TermsAndConditionsBold,
  ErrorMessage,
  InputErrorIcon,
} from './components';
import CustomInputFile from '../../components/input/CustomInputFile';
import CropImageModal from '../../components/crop-image/CropImageModal';
import { MobileContext } from '../../services/MobileContext';

const CoachSignUpPersonalInfo = () => {
  const { isMobile } = useContext(MobileContext);

  const { meLoading, submitPersonalForm, getInitialValuesPersonal } = useRegisterCoach();
  const [modalImage, setModalImage] = useState(null);
  const [modalImageFile, setModalImageFile] = useState(null);
  const { autoCompleteRef, setHandleAdressChoose } = useAddressAutoComplete();

  return (
    <SignUpContainer>
      {!isMobile && (
        <LeftPhotoBackground>
          <LeftPhotoBackgroundLine>
            <Logo />
          </LeftPhotoBackgroundLine>
          <LeftPhotoBackgroundLine />
          <LeftPhotoBackgroundLine />
          <LeftPhotoBackgroundLine />
          <LeftPhoto top="0" size="auto 90%" position="bottom" height="100%" />
        </LeftPhotoBackground>
      )}
      <FormContainer style={{ width: isMobile ? '100%' : '45%' }}>
        <FormHeading>{STRINGS.new_coach_registration_form}</FormHeading>
        <FormSubHeading>{STRINGS.personal_info}</FormSubHeading>
        <PersonalFormWrapper>
          {!meLoading && (
            <Formik
              initialValues={getInitialValuesPersonal()}
              validationSchema={SignupPersonalSchema}
              onSubmit={submitPersonalForm}
            >
              {({ setFieldValue, errors, touched, isSubmitting, values }) => (
                <Form style={{ width: '100%' }}>
                  <Field id="image" name="image">
                    {({ field }) => (
                      <ProfileImageContainer style={{ marginBottom: '4vh-11px' }}>
                        <CropImageModal
                          modalImageFile={modalImageFile}
                          image={modalImage}
                          closeModal={() => setModalImage(null)}
                          onSubmit={(file, blob) => {
                            setFieldValue(field.name, file);
                            setFieldValue('preview', blob);
                          }}
                        />
                        <ProfileimageLine error={touched.preview && errors.preview}>
                          <Profileimage src={values.preview || ICONS.AvatarDefaultImage}>
                            <CustomInputFile
                              accept="image/*"
                              onChange={async (e) => {
                                setModalImageFile(e.target.files[0]);
                                var reader = new FileReader();
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onload = (ev) => {
                                  setModalImage(ev.target.result);
                                };
                              }}
                            />
                          </Profileimage>
                        </ProfileimageLine>
                        {(touched.preview && errors.preview && (
                          <ErrorMessage margin="0">
                            <InputErrorIcon />
                            {errors.preview}
                          </ErrorMessage>
                        )) || <FieldTip>{STRINGS.upload_profile_image}</FieldTip>}
                      </ProfileImageContainer>
                    )}
                  </Field>
                  <Field id="fullName" name="fullName">
                    {({ field }) => (
                      <CustomInput
                        height={'Calc(7.1vh - 11.64px)'}
                        margin={'Calc(1.2vh - 1.96px) 0 Calc(2.4vh - 3.94px) 0'}
                        placeholder={STRINGS.name}
                        icon={ICONS.UserIcon}
                        activeIcon={ICONS.UserActiveIcon}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={touched.fullName && errors.fullName}
                      />
                    )}
                  </Field>
                  <Field id="idNumber" name="idNumber">
                    {({ field }) => (
                      <CustomInput
                        height={'Calc(7.1vh - 11.64px)'}
                        margin={'Calc(1.2vh - 1.96px) 0 Calc(2.4vh - 3.94px) 0'}
                        placeholder={STRINGS.id}
                        icon={ICONS.BookIcon}
                        activeIcon={ICONS.BookActiveIcon}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={touched.idNumber && errors.idNumber}
                      />
                    )}
                  </Field>
                  <Field id="phone" name="phone">
                    {({ field }) => (
                      <CustomInput
                        height={'Calc(7.1vh - 11.64px)'}
                        margin={'Calc(1.2vh - 1.96px) 0 Calc(2.4vh - 3.94px) 0'}
                        placeholder={STRINGS.phone_number}
                        icon={ICONS.PhoneIcon}
                        activeIcon={ICONS.PhoneActiveIcon}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={touched.phone && errors.phone}
                        type="number"
                      />
                    )}
                  </Field>
                  <Field id="email" name="email">
                    {({ field }) => (
                      <CustomInput
                        height={'Calc(7.1vh - 11.64px)'}
                        margin={'Calc(1.2vh - 1.96px) 0 Calc(2.4vh - 3.94px) 0'}
                        placeholder={STRINGS.email}
                        icon={ICONS.EnvelopeIcon}
                        activeIcon={ICONS.EnvelopeActiveIcon}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        error={touched.email && errors.email}
                      />
                    )}
                  </Field>
                  <Field id="address" name="address">
                    {({ field }) => (
                      <>
                        {setHandleAdressChoose((val) => setFieldValue(field.name, val))}
                        <CustomInput
                          inputRef={autoCompleteRef}
                          height={'Calc(7.1vh - 11.64px)'}
                          margin={'Calc(1.2vh - 1.96px) 0 Calc(2.4vh - 3.94px) 0'}
                          placeholder={STRINGS.address}
                          icon={ICONS.LocationIcon}
                          activeIcon={ICONS.LocationActiveIcon}
                          value={field.value}
                          onChange={(val) => setFieldValue(field.name, val)}
                          error={touched.address && errors.address}
                        />
                      </>
                    )}
                  </Field>
                  <Field id="agreedTerms" name="agreedTerms">
                    {({ field }) => (
                      <CenteringContainer>
                        <TermsCheck
                          checked={field.value}
                          error={touched.agreedTerms && errors.agreedTerms}
                          onClick={() => setFieldValue(field.name, !field.value)}
                        />
                        <TermsAndConditions>
                          {STRINGS.i_agree_to}
                          <TermsAndConditionsBold
                            href="https://www.witnesstofitness.com/terms-of-use"
                            target="_blank"
                          >
                            {STRINGS.terms_of_service}
                          </TermsAndConditionsBold>
                          {STRINGS.and}
                          <TermsAndConditionsBold
                            href="https://www.witnesstofitness.com/privacy-policy"
                            target="_blank"
                          >
                            {STRINGS.privacy_policy}
                          </TermsAndConditionsBold>
                        </TermsAndConditions>
                      </CenteringContainer>
                    )}
                  </Field>
                  <SubmitButtonWrapper>
                    <CustomButtom
                      height={'Calc(7.1vh - 11.64px)'}
                      green={!isSubmitting}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {STRINGS.continue}
                    </CustomButtom>
                  </SubmitButtonWrapper>
                </Form>
              )}
            </Formik>
          )}
        </PersonalFormWrapper>
      </FormContainer>
    </SignUpContainer>
  );
};

export default CoachSignUpPersonalInfo;
