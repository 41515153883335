import { useMutation } from '@apollo/react-hooks';
import {
  updateOrganisationMutation
} from '@witness/graphql';

const useOrganisation = ({ setLoading }) => {
  const [updateOrganisation] = useMutation(updateOrganisationMutation);

  const setCoachFee = async (uid, coachFee) => {
    setLoading(true);
    updateOrganisation({
      variables: {
        record: {
          uid,
          coachFee,
        },
      },
    }).finally(() => {
      console.log('doneee')
      setLoading(false)
    })
  };

  return {
    setCoachFee
  };
};

export default useOrganisation;
