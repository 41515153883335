import {default as styled} from "styled-components";
import ICONS from "../../constants/icons";

export const Title = styled.span`
  color: #4a4a4a;
  font-family: 'Gotham-r';
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 1.9rem;
  & > span {
    padding: 0 0.8rem;
    font-size: 2rem;
    font-family: Space-r;
    color: #4a4a4a;
    letter-spacing: 0.1rem;
  }
`;

export const ExercisesFilters = styled.div`
  padding: 3.2rem 2.8rem;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const ExercisesRow = styled.tr`
  width: 100%;
  background: white;
  margin: 0.6rem;
  border-radius: 8px;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 4px 2px #00000012;
  }
  &:hover * .hover_indicator {
    display: block;
  }
  &:hover * .hoverable_message_button {
    border-color: #00ff91 !important;
    transform: scale(1.05);
  }
  &:hover * .hoverable_view_button {
    border-color: ${({ theme }) => theme.color.primary} !important;
    transform: scale(1.02);
  }
  &:hover * .hoverable_cancel_button {
    border-color: #f50a4faa !important;
    transform: scale(1.02);
  }
  &:hover > * {
    color: #050000;
  }
`;

export const ExercisesTD = styled.td`
  padding: 1.4rem 0 1.4rem 3.2rem;
  text-align: left;
  color: #373333e6;
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 3.2rem;
  }
`;

export const FlexTDContent = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'flex-start')};
  position: relative;
`;

export const LeftIconImg = styled.div`
  width: 34px;
  height: 34px;
  background-image: url(${ICONS.LeftArrowPage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 56%;
`;

export const UpIconImg = styled.div`
  width: 34px;
  cursor: pointer;
  height: 34px;
  &:hover {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
  }
  background-image: url(${ICONS.UpPage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 56%;
`;

export const DownIconImg = styled.div`
  width: 34px;
  cursor: pointer;
    &:hover {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
  }
  height: 34px;
  background-image: url(${ICONS.DownPage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 56%;
`;

export const RightIconImg = styled.div`
  width: 34px;
  height: 34px;
  background-image: url(${ICONS.RightArrowPage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 56%;
`;

export const VideoIconImg = styled.div`
  width: 34px;
  height: 34px;
  background-image: url(${ICONS.VideoSign});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 56%;
`;
