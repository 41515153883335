import React from 'react';
import styled from 'styled-components';
import ICONS from '../../constants/icons';

export const PricingTableWrapper = styled.div`
  padding: ${({ isMobile }) => (isMobile ? 0 : '0 3.2rem')};
  width: 100%;
`;
export const PricingTable = styled.table`
  width: 100%;
  padding: 0.4rem 3.2rem;
  background: #fafafa;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0 4px;
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

export const PricingTableHeadTR = styled.tr`
  width: 100%;
  font-family: Roboto-r;
  color: #373333;
`;
export const PricingTableTR = styled.tr`
  width: 100%;
  border-radius: 8px;
  border: 1px solid red;
  color: #373333;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 4px 0.4px rgba(0, 0, 0, 0.05);
    color: #050000;
    & .copy {
      background-image: url(${ICONS.CopyActive});
      &:active {
        background-image: url(${ICONS.Copy});
      }
    }
    & .edit {
      background-image: url(${ICONS.EditStroke});
    }
    & .del {
      background-image: url(${ICONS.DeleteStroke});
    }
  }
`;

export const PricingTableTh = styled.th`
  padding: 2rem 1.6rem;
  color: #4a4a4aaa;
  font-family: Roboto-l;
  text-align: left;
  align-items: flex-start;
  & div {
    font-size: 1.2rem;
    color: #4a4a4aaa;
    font-family: Roboto-l;
  }
`;

export const PricingTableTd = styled.td`
  padding: 2rem 1.6rem;
  min-width: ${({ width }) => (width ? width : 'fit-content')};
  background: #ffffff;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  color: inherit;
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;

export const PricingTableTdCopy = styled.span`
  padding: 1.6rem;
  background-image: url(${ICONS.Copy});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
`;

export const PricingTableTdAction = styled.span`
  padding: 1.6rem;

  &.edit {
    background-image: url(${ICONS.EditGrey});
    &:hover {
      background-image: url(${ICONS.EditFill});
    }
    &:active {
      background-image: url(${ICONS.EditStroke});
    }
  }
  &.del {
    background-image: url(${ICONS.DeleteGrey});
    &:hover {
      background-image: url(${ICONS.DeleteFill});
    }
    &:active {
      background-image: url(${ICONS.DeleteStroke});
    }
  }
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin-right: 1rem;
  &:last-child {
    margin: 0;
  }
`;

export const SortSpecification = styled.span`
  padding: 0.5rem;
  background-image: ${({ asc, desc }) =>
    asc ? `url(${ICONS.SortAsc})` : desc ? `url(${ICONS.SortDesc})` : `url(${ICONS.SortNone})`};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin: 0 0 0 1rem;
  cursor: pointer;
  display: none;
`;

export const MobileList = styled.div`
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  border-top: 1px solid #0000001a;
`;

export const ListItemContainer = styled.div`
  height: 93px;
  margin: 4px 20px;
  border-radius: 8px;
  border: solid 0.5px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  display: flex;
`;

export const ItemValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #0000001a;
  width: 30%;
`;

export const ValueText = styled.span`
  font-family: Roboto-r;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: center;
  color: #15df68;
  margin: 0 16px 0 18px;
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px 0 20px;
`;

export const ItemCode = styled.span`
  font-family: Gotham-r;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
`;

export const ItemTypes = styled.div`
  display: flex;
  margin-top: ${({ mt }) => (mt ? mt : '4px')};
  margin-bottom: ${({ mb }) => (mb ? mb : '0')};
`;

export const TrainingType = styled.div`
  padding: 0 11px;
  border-radius: 12px;
  border: solid 0.5px rgba(150, 148, 148, 0.4);
  background-color: rgba(197, 196, 196, 0.15);
  height: fit-content;
  font-family: Roboto-r;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-align: left;
  line-height: 1.71;
  color: #050000;
`;

export const ArrowRightIcon = styled.span`
  background-image: url(${ICONS.ArrowRight});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 20px;
  height: 20px;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalHeader = styled.div`
  padding: 18px 16px 17.5px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0000001a;
`;

export const HeaderTitle = styled.span`
  font-family: Space-r;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.89px;
  text-align: left;
  color: #050000;
`;

export const CloseIcon = styled.div`
  width: 28px;
  height: 28px;
  background-image: url(${ICONS.Close});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 22px 32px 22px;
`;

export const ModalCode = styled.span`
  font-family: Gotham-r;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const InfoTitle = styled.span`
  width: 50%;
  font-family: Roboto-r;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.4px;
  text-align: left;
  color: #05000099;
`;

export const InfoValue = styled.span`
  width: 50%;
  font-family: Roboto-r;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: 0.4px;
  text-align: left;
  color: #050000;
`;

export const Info = ({ title, value }) => (
  <InfoContainer>
    <InfoTitle>{title}</InfoTitle>
    <InfoValue>{value}</InfoValue>
  </InfoContainer>
);
