import React, { useMemo } from 'react';
import moment from 'moment';
import * as Yup from 'yup';

import {
  WeekDayColumnWrapper,
  WeekDayHeader,
  WeekDayNumbering,
  WeekDayDay,
  HeaderButtons,
  WeekDayName,
  ContentWrapper,
  MessagesRelativeBox,
  WeekRestBoy,
  DragDropWrapper,
  DropIndexIndicator,
  ColumnTopButtons,
  ColumnTopButton,
  ColumnTopButtonIcon,
} from './components';
import CustomInput from '../input/CustomInput';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CalendarSchema } from '../../schemas/program/coachProgram';
import { useUtils } from '../../hooks';
import ICONS from '../../constants/icons';
import EdittingWorkoutBlock from './EdittingWorkoutBlockV2';
import WorkoutBlock from './WorkoutBlock';
import WorkoutDropbox from './WorkoutDropbox';
import AddBlockCommentTooltip from './AddBlockCommentTooltip';
import { useState } from 'react';
import AddMessageTooltip from './AddMessageTooltip';
import AddMeetingTooltip from './AddMeetingTooltip';
import Loader from '../loader/Loader';

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const weekDaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

const WeekDayColumn = ({
  index,
  day,
  loading,
  existingWorkout,
  submitDay,
  weekDay,
  formikRef,
  dayContent = {},
  draggingBlock,
  setDraggingBlock,
  deleteDraggingBlock,
  setDeleteDraggingBlock,
  addRestDay,
  submitMessageDay,
  handleCreateMeeting,
  handleDeleteCalendar,
  handleColumnPaste,
  copiedColumn,
  setCopiedColumn,
  isTemplate,
  initialValues,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const [activeDragOverIndex, setActiveDragOverIndex] = useState(null);
  const hasChanged = (initial, least) => {
    return JSON.stringify(initial) !== JSON.stringify(least);
  };
  return (
    <WeekDayColumnWrapper
      collapsed={collapsed}
      readOnly={
        moment(day?.date, 'DD.M.YYYY').isBefore(new Date(), 'day') ||
        existingWorkout?.workout?.status?.find(
          (x) => (x.trainee && x.type === 'DONE') || x.type === 'UNDONE',
        )
      }
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (v) => {
          if (initialValues?.blocks?.length && !v?.blocks?.length) {
            await handleDeleteCalendar(existingWorkout?.uid);
          }
          if (!v.isRest && initialValues?.isRest) {
            await handleDeleteCalendar(dayContent?.REST?.uid);
          }
          if (v?.blocks?.length) {
            await submitDay(day?.index, v, existingWorkout?.workout?.uid, false);
          }
          if (v.isRest && !initialValues?.isRest) {
            await addRestDay(day?.index, () => {}, false);
          }
          if (v.MESSAGE && hasChanged(v.MESSAGE, initialValues.MESSAGE)) {
            if ((initialValues.MESSAGE?.message && !v.MESSAGE?.message) || (initialValues.MESSAGE?.customMessage && !v.MESSAGE?.customMessage)) {
              await handleDeleteCalendar(initialValues.MESSAGE?.uid)
            } else {
              await submitMessageDay(day?.index, v.MESSAGE);
            }
          }
          if (v.ONLINE_MEETING && hasChanged(v.ONLINE_MEETING, initialValues.ONLINE_MEETING)) {
            if (initialValues.ONLINE_MEETING?.date && !v.ONLINE_MEETING?.date) {
              await handleDeleteCalendar(initialValues.ONLINE_MEETING?.uid);
            } else {
              await handleCreateMeeting(day?.index, v.ONLINE_MEETING);
            }
          }
          if (v.FACE_MEETING && hasChanged(v.FACE_MEETING, initialValues.FACE_MEETING)) {
            if (initialValues.FACE_MEETING?.date && !v.FACE_MEETING?.date) {
              await handleDeleteCalendar(initialValues.FACE_MEETING?.uid);
            } else {
              await handleCreateMeeting(day?.index, v.FACE_MEETING);
            }
          }
        }}
        validationSchema={() =>
          Yup.lazy((values) => {
            if (!values?.blocks?.length) {
              return Yup.mixed();
            }
            return CalendarSchema;
          })
        }
      >
        {({ setFieldValue, errors, validateForm, touched, values, setTouched }) => (
          <Form
            style={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <WeekDayHeader>
              <WeekDayNumbering>
                {isTemplate ? `DAY ${day?.index}` : moment(day?.date, 'DD.M.YYYY').format('DD/MM')}
              </WeekDayNumbering>
              <WeekDayDay>{collapsed ? weekDaysShort[weekDay] : weekDays[weekDay]}</WeekDayDay>
              <HeaderButtons>
                {!collapsed && (
                  <AddMeetingTooltip
                    onSubmit={(v) => {
                      setFieldValue('ONLINE_MEETING', { ...values.ONLINE_MEETING, ...v })
                    }}
                    initialValues={{ ...values.ONLINE_MEETING, isOnline: true }}
                    onDelete={() =>
                      setFieldValue('ONLINE_MEETING', { date: undefined })
                    }
                  />
                )}
                {!collapsed && (
                  <AddMeetingTooltip
                    onSubmit={(v) => {
                      setFieldValue('FACE_MEETING', { ...values.FACE_MEETING, ...v })
                    }}
                    initialValues={values.FACE_MEETING}
                    onDelete={() =>
                      setFieldValue('FACE_MEETING', { date: undefined })
                    }
                  />
                )}
                {/* <HeaderButton icon={ICONS.LocationIcon} /> */}

                <AddMessageTooltip
                  onSubmit={(v) => {
                    setFieldValue('MESSAGE', { ...values?.MESSAGE, ...v })
                  }}
                  initialValue={values.MESSAGE}
                  onDelete={() =>
                    setFieldValue('MESSAGE', {})
                  }
                />
              </HeaderButtons>
              <ColumnTopButtons className="hide-a-day" collapsed={collapsed}>
                <ColumnTopButton onClick={() => setCollapsed((curr) => !curr)}>
                  <ColumnTopButtonIcon icon={collapsed ? ICONS.Expand : ICONS.Collapse} />
                </ColumnTopButton>
                {!collapsed && (copiedColumn || values?.blocks?.length) && (
                  <ColumnTopButton
                    onClick={() => {
                      if (!copiedColumn) {
                        if (values?.blocks?.length) {
                          setCopiedColumn(weekDay);
                        }
                      } else if (copiedColumn !== day.index) {
                        handleColumnPaste(weekDay);
                      }
                    }}
                  >
                    <ColumnTopButtonIcon
                      icon={
                        copiedColumn && copiedColumn !== day.index ? ICONS.PasteColumn : ICONS.Copy
                      }
                    />
                  </ColumnTopButton>
                )}
              </ColumnTopButtons>
            </WeekDayHeader>
            <WeekDayName
              status={
                existingWorkout?.workout?.status?.find((x) => x.trainee && x.type === 'DONE')
                  ? 'DONE'
                  : existingWorkout?.workout?.status?.find((x) => x.trainee && x.type === 'UNDONE')
                  ? 'UNDONE'
                  : ''
              }
            >
              <Field id="name" name="name">
                {({ field }) => (
                  <CustomInput
                    height="3.6rem"
                    fontSize="1.4rem"
                    style={{
                      border: 'none',
                      minHeight: '3.6rem',
                      margin: 0,
                      textAlign: 'center',
                      fontFamily: 'Space-r',
                    }}
                    onChange={(v) => setFieldValue(field.name, v)}
                    value={field.value}
                    error={touched[field.name] && errors[field.name]}
                    placeholder={values?.isRest ? 'Rest Day' : 'No title'}
                    disabled={values?.isRest}
                  />
                )}
              </Field>
            </WeekDayName>
            {loading ? (
              <Loader fontSize="1.6rem" height="auto" padding="0" />
            ) : (
              !collapsed && (
                <ContentWrapper>
                  {/* <ContentContainer> */}
                  {!values?.isRest && (
                    <MessagesRelativeBox>
                      <AddBlockCommentTooltip
                        beforeAfter
                        initialValue={{
                          afterMessageChecked: values?.afterMessageChecked,
                          beforeMessageChecked: values?.beforeMessageChecked,
                          beforeMessage: values?.beforeMessage,
                          afterMessage: values?.afterMessage,
                        }}
                        onSubmit={(v) => {
                          setFieldValue('afterMessageChecked', v?.afterMessageChecked);
                          setFieldValue('beforeMessageChecked', v?.beforeMessageChecked);
                          setFieldValue(
                            'beforeMessage',
                            v?.beforeMessageChecked ? v?.beforeMessage : '',
                          );
                          setFieldValue(
                            'afterMessage',
                            v?.afterMessageChecked ? v?.afterMessage : '',
                          );
                        }}
                      />
                    </MessagesRelativeBox>
                  )}
                  <FieldArray
                    name="blocks"
                    render={({ push, remove, insert, replace }) => (
                      <>
                        {values?.blocks?.map((block, i) => (
                          <DragDropWrapper
                            draggingOver={activeDragOverIndex === i}
                            draggable
                            beingDragged={draggingBlock === block}
                            onDragStart={() => {
                              setDraggingBlock(block);
                              setDeleteDraggingBlock(() => (index) => {
                                if (index === day?.index) {
                                  remove(i + 1);
                                } else {
                                  remove(i);
                                }
                                const beforeEditting = values.editingBlockIndex > i;
                                if (beforeEditting && values.editingBlockIndex) {
                                  setFieldValue('editingBlockIndex', values.editingBlockIndex - 1);
                                }
                              });
                            }}
                            onDragEnd={() => {
                              setDraggingBlock(null);
                              setDeleteDraggingBlock(null);
                            }}
                            onDragOver={(e) => {
                              if (
                                draggingBlock &&
                                e.clientY > 248 &&
                                e.clientY < window.innerHeight - 248
                              ) {
                                e.preventDefault();
                                setActiveDragOverIndex(i);
                              }
                            }}
                            onDragLeave={() => setActiveDragOverIndex(null)}
                            onDrop={(e) => {
                              if (!draggingBlock) {
                                return;
                              }
                              insert(i, draggingBlock);
                              setActiveDragOverIndex(null);
                              setDraggingBlock(null);
                              if (deleteDraggingBlock) {
                                deleteDraggingBlock(day?.index);
                              }
                            }}
                          >
                            {activeDragOverIndex === i && <DropIndexIndicator />}
                            {i === values?.editingBlockIndex ? (
                              <EdittingWorkoutBlock
                                week
                                block={block}
                                key={i}
                                index={i}
                                setFieldValue={setFieldValue}
                                validateForm={validateForm}
                                errors={errors}
                                touched={touched}
                                setTouched={setTouched}
                                deleteSelf={() => {
                                  remove(i);
                                  setFieldValue('editingBlockIndex', null);
                                }}
                                setDraggingBlock={setDraggingBlock}
                              />
                            ) : (
                              <WorkoutBlock
                                week
                                block={block}
                                key={i}
                                deleteSelf={() => {
                                  remove(i);
                                  const beforeEditting = values.editingBlockIndex > i;
                                  if (beforeEditting && values.editingBlockIndex) {
                                    setFieldValue(
                                      'editingBlockIndex',
                                      values.editingBlockIndex - 1,
                                    );
                                  }
                                }}
                                duplicateSelf={(v) => {
                                  insert(i + 1, v);
                                }}
                                isFirst={i === 0}
                                isLast={i === values?.blocks?.length - 1}
                                orderUp={() => {
                                  const clone = block;
                                  if (i > 0) {
                                    const previousOne = values?.blocks?.[i - 1];
                                    replace(i, previousOne);
                                    replace(i - 1, clone);
                                    if (values?.editingBlockIndex === i - 1) {
                                      setFieldValue('editingBlockIndex', i);
                                    }
                                  }
                                }}
                                orderDown={() => {
                                  const clone = block;
                                  if (i < values?.blocks?.length - 1) {
                                    const nextOne = values?.blocks?.[i + 1];
                                    replace(i, nextOne);
                                    replace(i + 1, clone);
                                    if (values?.editingBlockIndex === i + 1) {
                                      setFieldValue('editingBlockIndex', i);
                                    }
                                  }
                                }}
                                setEditting={async () => {
                                  await setTouched({
                                    blocks: { [values.editingBlockIndex]: true },
                                  });
                                  const invalid = !!errors?.blocks?.[values.editingBlockIndex];
                                  if (!invalid) {
                                    setFieldValue('editingBlockIndex', i);
                                  }
                                }}
                              />
                            )}
                          </DragDropWrapper>
                        ))}

                        <WorkoutDropbox
                          draggingBlock={draggingBlock}
                          setDraggingBlock={setDraggingBlock}
                          push={push}
                          pushCallback={() => {
                            setActiveDragOverIndex(null);
                            setDraggingBlock(null);
                            if (deleteDraggingBlock) {
                              deleteDraggingBlock();
                            }
                          }}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          setTouched={setTouched}
                          noText
                          touched={touched}
                          values={values}
                          week
                        />
                        {values?.isRest && <WeekRestBoy className="rest-boy" />}
                      </>
                    )}
                  ></FieldArray>
                  {/* </ContentContainer> */}
                </ContentWrapper>
              )
            )}
          </Form>
        )}
      </Formik>
    </WeekDayColumnWrapper>
  );
};

export default WeekDayColumn;
