import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import qs from 'querystring';

import DashboardProgramRequests from '../../components/dashboard/DashboardProgramRequests';
import DashboardWorkoutList from '../../components/dashboard/DashboardWorkoutList';
import ProgramRequest from '../../components/request-modal/ProgramRequest';
import CustomButton from '../../components/button/CustomButton';
import { useCoachPersonalInfo, useProgram } from '../../hooks';
import {
  DashboardSummaryBox,
  Dashboard,
  DashboardMainBox,
  DashboardMainItem,
  DashboardBanner,
  DashboardBannerBackLine,
  DashboardBannerContent,
  BannerAvatar,
  BannerText,
  BannerPrimaryText,
  BannerSecondaryText,
  RespContainer,
  ProfileWrapper,
  BannerImg,
  BannerWelcomeText,
  RespCard,
  TitleContainer,
  RespCardTitle,
  BadgeContainer,
  BadgeWrapper,
  ArrowIcon,
  LoaderWrapper,
  SeeAllReqText,
} from './components';
import { MobileContext } from '../../services/MobileContext';
import { useQuery } from '@apollo/client';
import { ProgramStatus } from '../../constants/enums';
import { getProgramsQuery } from '@witness/graphql';
import { LoaderSign } from '../../components/loader/components';
import ProgramRequestCard from './ProgramRequestCard';
import arrowIcon from '../../assets/dashboard/simple-arrow-up.svg';
import RespProgramRequest from '../../components/request-modal/RespProgramRequest';
import { OrganizationContext } from '../../services/organizationContext';

const Home = () => {
  const [requestModal, setRequestModal] = useState(null);
  const [isReqOpen, setIsReqOpen] = useState(false);
  const { currentCoach, currentOrganization } = useCoachPersonalInfo(() => {});
  const { isMobile } = useContext(MobileContext);
  const { isOrganization } = useContext(OrganizationContext);

  const currentUser = useMemo(
    () => (isOrganization ? currentOrganization : currentCoach),
    [isOrganization, currentCoach, currentOrganization],
  );

  const {
    data: programRequests,
    loading: programRequestsLoading,
    refetch,
  } = useQuery(getProgramsQuery, {
    variables: {
      record: {
        status: ProgramStatus.PENDING,
      },
    },
    skip: !isMobile,
    notifyOnNetworkStatusChange: true,
  });
  const history = useHistory();

  const urlRequestId = useMemo(
    () => history?.location?.search?.split('request_id=')?.[1]?.split('&')?.[0],
    [history.location.search],
  );

  const { program } = useProgram({ programId: urlRequestId });
  useEffect(() => {
    if (program?.status === 'APPROVED') {
      if (program?.type === 'GROUP' && program?.coachProgram?.group?.uid) {
        if (program?.coachProgram?.group?.uid) {
          history.push(`/program/${program?.coachProgram?.group?.uid}?group=true`);
        }
      } else {
        history.push(`/program/${program?.uid}`);
      }
    } else {
      setRequestModal(program);
    }
  }, [program, history]);

  const handleNewProgramRequestsToggle = () => {
    setIsReqOpen(!isReqOpen);
  };

  const handleSelectProgramRequest = useCallback(
    (item) => {
      if (item.status === 'APPROVED') {
        if (item?.type === 'GROUP') {
          history.push(`/program/${item?.coachProgram?.group?.uid}?group=true`);
        } else {
          history.push(`/program/${item.uid}`);
        }
      } else {
        setRequestModal(item);
      }
      console.log(item);
    },
    [setRequestModal, history],
  );

  if (isMobile) {
    return (
      <RespContainer>
        {!!requestModal && (
          <RespProgramRequest
            content={requestModal}
            refetchProgramRequests={refetch}
            closeModal={() => {
              setRequestModal(null);
              const queryObj = qs.parse(history.location.search.substring(1));
              delete queryObj.request_id;
              history.push(history?.location?.pathname + '?' + qs.stringify(queryObj));
            }}
          />
        )}
        <ProfileWrapper>
          <BannerAvatar>
            <BannerImg src={currentUser?.profileImage?.url} />
          </BannerAvatar>

          <BannerWelcomeText>Welcome Back,</BannerWelcomeText>

          {!isOrganization && (
            <BannerPrimaryText>{`Coach ${
              currentUser?.fullName?.split(' ')?.[0]
            }!`}</BannerPrimaryText>
          )}
        </ProfileWrapper>

        <RespCard onClick={handleNewProgramRequestsToggle}>
          <TitleContainer>
            <RespCardTitle>new Program Requests</RespCardTitle>

            {programRequests?.getPrograms?.length > 0 && (
              <BadgeContainer>
                <BadgeWrapper>+{programRequests?.getPrograms?.length}</BadgeWrapper>
              </BadgeContainer>
            )}

            <ArrowIcon src={arrowIcon} alt="Show Program Requests" isOpen={isReqOpen} />
          </TitleContainer>
          {isReqOpen && (
            <>
              {programRequests?.getPrograms?.length > 0 ? (
                <>
                  {programRequestsLoading ? (
                    <LoaderWrapper>
                      <LoaderSign />
                    </LoaderWrapper>
                  ) : (
                    programRequests?.getPrograms?.map?.((item) => (
                      <ProgramRequestCard
                        key={item.uid}
                        item={item}
                        clickHandler={handleSelectProgramRequest}
                      />
                    ))
                  )}
                  {/* <SeeAllReqText>See all requests</SeeAllReqText> */}
                </>
              ) : (
                <SeeAllReqText>There are no requests at the moment</SeeAllReqText>
              )}
            </>
          )}
        </RespCard>
        {!isOrganization && (
          <RespCard mt={10} mb={42}>
            <DashboardWorkoutList />
          </RespCard>
        )}
      </RespContainer>
    );
  }

  return (
    <Dashboard>
      {!!requestModal && (
        <ProgramRequest
          content={requestModal}
          closeModal={() => {
            setRequestModal(null);
            const queryObj = qs.parse(history.location.search.substring(1));
            delete queryObj.request_id;
            history.push(history?.location?.pathname + '?' + qs.stringify(queryObj));
          }}
        />
      )}
      <DashboardSummaryBox>
        <DashboardBanner>
          <DashboardBannerContent>
            <BannerAvatar pic={currentUser?.profileImage?.url} />
            <BannerText>
              <BannerPrimaryText>{`Welcome${isOrganization ? ' back' : ', coach'} ${
                (!isOrganization && currentUser?.fullName?.split(' ')?.[0]) || ''
              }!`}</BannerPrimaryText>
              <BannerSecondaryText>
                Please, feel free to edit Your Personal and Professional Details any time
              </BannerSecondaryText>
            </BannerText>
            <Link to="/profile">
              <CustomButton
                style={{ padding: '0 3.2rem', width: 'fit-content' }}
                height="4.8rem"
                borderRadius="8px"
                green
              >
                Edit Profile
              </CustomButton>
            </Link>
          </DashboardBannerContent>
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
          <DashboardBannerBackLine />
        </DashboardBanner>
        {/* <DashboardSummaryBlock
          title="Program Requests"
          valueChange="+11"
          timeChange="In last 36 hours"
          total="15"
          icon={ICONS.ProgramRequests}
        />
        <DashboardSummaryBlock />
        <DashboardSummaryBlock /> */}
      </DashboardSummaryBox>
      <DashboardMainBox>
        <DashboardMainItem grow={1}>
          <DashboardProgramRequests openRequest={setRequestModal} />
        </DashboardMainItem>
        {!isOrganization && (
          <DashboardMainItem grow={2}>
            <DashboardWorkoutList />
          </DashboardMainItem>
        )}
      </DashboardMainBox>
    </Dashboard>
  );
};
export default Home;
