import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';

import { WorkoutStatus } from '../../constants/enums';
import ProgressLine from '../progress-line/ProgressLine';
import SingleTraineeResults from './SingleTraineeResults';
import WorkoutBlock from '../add-calendar-modal/WorkoutBlock';

import { useUtils } from '../../hooks';

import {
  WholeWrapper,
  ModalHeader,
  ModalCloseX,
  WorkoutName,
  EntireGroupResults,
  EntireGroupHeader,
  EntireGroupHeading,
  EntireGroupResultsWrapper,
  EntireGroupContent,
  WorkoutBlockResult,
  BlockExercisesSide,
  BlockExercisesHeader,
  BlockFeedbackSide,
  BlockFeedback,
  BlockFeedbackHeader,
  GroupWorkoutsWrapper,
  GroupParticipantsHeader,
  GroupParticipantsContent,
  GroupParticipantRow,
  GroupParticipantImage,
  GroupParticipantName,
  GroupParticipantResultIndicator,
  IndicatorDone,
  IndicatorGeneral,
  HoverIndicator,
} from './components';
import Loader from '../loader/Loader';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useCalendar from '../../hooks/calendar';

const customStyles = {
  overlay: {
    backgroundColor: '#05000066',
    zIndex: 3333,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden auto',
  },
  content: {
    width: '74%',
    maxWidth: '1320px',
    minWidth: '1080px',
    minHeight: 'Calc(100vh - 64px)',
    height: 'fit-content',
    borderRadius: '8px',
    overflow: 'hidden',
    pointerEvents: 'all',
    background: 'white',
    margin: '4rem auto',
    position: 'static',
    padding: 0,
  },
};

const CalendarResultsModal = ({
  name,
  closeModal,
  refetchProgram,
  loading,
  alert,
  urlTraineeUid,
  coachProgramUid,
  day,
}) => {
  const { getWorkoutBlocksByWorkout, sortByWorkoutStatus } = useUtils();
  const [chosenIndividual, setChosenIndividual] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const { calendars, calendarsLoading, refetchCalendars } = useCalendar({
    coachProgramUid,
    startDate: day?.programDay,
    calendarUid: day?.uid,
  });

  const WORKOUT = useMemo(() => {
    if (Array.isArray(calendars)) {
      return calendars?.find((x) => x.type === 'WORKOUT');
    }

    return calendars;
  }, [calendars]);

  const workoutBlocks = useMemo(
    () => getWorkoutBlocksByWorkout(WORKOUT?.workout),
    [WORKOUT, getWorkoutBlocksByWorkout],
  );
  const [participants, doneBy] = useMemo(
    () => [
      WORKOUT?.workout?.status?.filter((x) => !!x.trainee)?.length,
      WORKOUT?.workout?.status?.filter((x) => !!x.trainee && x.type === WorkoutStatus.DONE)?.length,
    ],
    [WORKOUT],
  );

  useEffect(() => {
    const urlTrainee = WORKOUT?.workout?.status?.find((x) => x?.trainee?.uid === urlTraineeUid);
    setChosenIndividual((curr) => urlTrainee?.trainee || curr);
  }, [urlTraineeUid, WORKOUT]);

  const location = useLocation();

  const isGroup = useMemo(() => location?.search === '?group=true', [location]);

  return (
    <>
      <Modal
        onRequestClose={() => {
          closeModal();
          history.push(`${history?.location?.pathname}${isGroup ? '?group=true' : ''}`);
        }}
        isOpen={!!day}
        style={customStyles}
        contentLabel="Program Request"
        ariaHideApp={false}
      >
        <div>
          {loading || submitting || calendarsLoading ? (
            <Loader />
          ) : (
            <WholeWrapper>
              <ModalHeader>
                {name ? `${name} - ` : ''}
                {`Day ${day?.programDay}`}
                <ModalCloseX onClick={closeModal}>X</ModalCloseX>
              </ModalHeader>
              <WorkoutName>
                <span>Workout Name: </span> {day?.workout?.name}
              </WorkoutName>
              {isGroup && !chosenIndividual ? (
                <EntireGroupResultsWrapper>
                  <EntireGroupResults>
                    <EntireGroupHeader>
                      <EntireGroupHeading>Completed Workout</EntireGroupHeading>
                    </EntireGroupHeader>
                    <ProgressLine total={participants} progress={doneBy} />
                    <EntireGroupContent>
                      <WorkoutBlockResult>
                        <BlockExercisesSide>
                          <BlockExercisesHeader>Workouts</BlockExercisesHeader>
                          <GroupWorkoutsWrapper>
                            {console.log(workoutBlocks)}
                            {workoutBlocks?.map((block) => (
                              <WorkoutBlock key={block.uid} immutable block={block} />
                            ))}
                          </GroupWorkoutsWrapper>
                        </BlockExercisesSide>
                        <BlockFeedbackSide>
                          <BlockFeedback style={{ background: '#fafafa' }}>
                            <BlockFeedbackHeader>
                              <GroupParticipantsHeader>
                                Trainees
                                <div>{`${doneBy}/${participants}`}</div>
                              </GroupParticipantsHeader>
                            </BlockFeedbackHeader>
                            <GroupParticipantsContent>
                              {day?.workout?.status
                                ?.filter((status) => status.trainee)
                                .sort(sortByWorkoutStatus)
                                ?.map((status) => (
                                  <GroupParticipantRow
                                    key={status.uid}
                                    onClick={() => setChosenIndividual(status?.trainee)}
                                  >
                                    <HoverIndicator
                                      className="hover_indicator"
                                      width="0.3rem"
                                      height="60%"
                                    />
                                    <GroupParticipantImage>
                                      {status?.trainee?.fullName
                                        ?.split(' ')
                                        ?.map((a) => a.substr(0, 1))
                                        .join('')}
                                    </GroupParticipantImage>
                                    <GroupParticipantName>
                                      {status?.trainee?.fullName}
                                    </GroupParticipantName>
                                    <GroupParticipantResultIndicator>
                                      {status?.type === WorkoutStatus.DONE ? (
                                        <IndicatorDone />
                                      ) : (
                                        <IndicatorGeneral status={status?.type} />
                                      )}
                                    </GroupParticipantResultIndicator>
                                  </GroupParticipantRow>
                                ))}
                            </GroupParticipantsContent>
                          </BlockFeedback>
                        </BlockFeedbackSide>
                      </WorkoutBlockResult>
                    </EntireGroupContent>
                  </EntireGroupResults>
                </EntireGroupResultsWrapper>
              ) : (
                <SingleTraineeResults
                  day={WORKOUT}
                  alert={alert}
                  trainee={chosenIndividual || WORKOUT?.workout?.status?.[0]?.trainee}
                  isGroup={isGroup}
                  exit={() => setChosenIndividual(null)}
                  refetchProgram={refetchProgram}
                  setSubmitting={setSubmitting}
                  closeModal={closeModal}
                  refetchCalendars={refetchCalendars}
                />
              )}
            </WholeWrapper>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CalendarResultsModal;
