import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import URLS from '../constants/urls';
import {
  meQuery,
  getSpecialitiesQuery,
  createCoachPersonalMutation,
  createCoachProfessionalMutation,
} from '@witness/graphql';

const useRegisterCoach = () => {
  const history = useHistory();

  const backToPersonalPage = () => {
    if (history.location.pathname !== URLS.sign_up_coach_personal) {
      history.replace(URLS.sign_up_coach_personal);
    }
  };

  const { data: me, loading: meLoading, error: meError } = useQuery(meQuery);

  const getInitialValuesPersonal = () => {
    return {
      fullName: me?.getCurrentUser?.coach?.fullName || '',
      idNumber: me?.getCurrentUser?.coach?.idNumber || '',
      phone: me?.getCurrentUser?.coach?.phone || '',
      email: me?.getCurrentUser?.user?.email || '',
      address: me?.getCurrentUser?.coach?.address || '',
      image: undefined,
      preview: me?.getCurrentUser?.coach?.profileImage?.url || undefined,
      agreedTerms: false,
    };
  };
  const getInitialValuesProfessional = () => {
    return {
      specialities: me?.getCurrentUser?.coach?.specialities?.map((spec) => spec?.uid) || [],
      experience: me?.getCurrentUser?.coach?.experience || 0,
      about: me?.getCurrentUser?.coach?.about || '',
      files: [],
    };
  };

  const { data: specialities, loading: specialitiesLoading, error: specialitiesError } = useQuery(
    getSpecialitiesQuery,
  );

  const [createCoachPersonal] = useMutation(createCoachPersonalMutation);

  const submitPersonalForm = async (values, { setSubmitting, setErrors }) => {
    const { fullName, idNumber, phone, email, address, image } = values;
    try {
      const registeredCoach = await createCoachPersonal({
        variables: {
          record: {
            uid: me?.getCurrentUser?.coach?.uid,
            fullName,
            idNumber,
            phone,
            email,
            address,
          },
          profile: {
            image,
          },
        },
      });
      localStorage.setItem('token', registeredCoach?.data?.createPersonalInfo?.token);
      history.push(URLS.sign_up_coach_professional);
      setSubmitting(false);
    } catch (err) {
      if (err?.graphQLErrors?.find((x) => x.message === 'coach_already_exists')) {
        setErrors({ email: 'Email already exists' });
      }
    }
  };

  const [createCoachProfessional] = useMutation(createCoachProfessionalMutation);

  const submitProfessionalForm = async (values, { setSubmitting }) => {
    const { specialities, experience, playlist, meal, about, files } = values;
    const filePayload = files?.map((group) => {
      const ids = group?.diplomas?.map((dip) => dip.uid)?.filter((x) => !!x);
      return { name: group?.name, diplomaIds: ids };
    });
    const registeredCoach = await createCoachProfessional({
      variables: {
        record: {
          specialities,
          experience,
          playlist,
          meal,
          about,
        },
        files: filePayload,
      },
    });
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow?.getDate() + 1);
    localStorage.removeItem('token');
    sessionStorage.setItem('name', registeredCoach?.data?.createProfessionalInfo?.fullName);
    sessionStorage.setItem('expirationTime', tomorrow);
    history.push(URLS.sign_up_coach_result);
    setSubmitting(false);
  };

  return {
    me,
    meLoading,
    specialities,
    specialitiesLoading,
    specialitiesError,
    getInitialValuesPersonal,
    getInitialValuesProfessional,
    submitPersonalForm,
    submitProfessionalForm,
    backToPersonalPage,
    meError,
  };
};

export default useRegisterCoach;
