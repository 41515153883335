import {
  createCoachExerciseMutation,
  editCoachExerciseMutation,
  getExercisesQuery,
} from '@witness/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useState, useEffect } from 'react';

const useExercise = () => {
  const [searchText, setSearchText] = useState(null);

  const { data: exercises, loading: exercisesLoading, refetch: exercisesRefetch } = useQuery(
    getExercisesQuery,
    {
      variables: {
        record: {
          searchText,
          sortField:'title',
          sortOrder:'ASC'
        },
      },
    },
  );

  const [createCoachExercise, { loading: createLoading }] = useMutation(
    createCoachExerciseMutation,
  );
  const [editExercise, { loading: editLoading }] = useMutation(editCoachExerciseMutation);

  const createNewExercise = async ({ id, title, type, video, preview, youtubeVideoId, category, setting}) => {
    if (id) {
      const edited = await editExercise({
        variables: {
          record: {
            uid: id,
            title,
            category,
            setting,
            type,
            video: (preview && video) || null,
            youtubeVideoId,
          },
        },
      });
      return edited?.data?.editExercise || edited?.data?.editCoachExercise;
    } else {
      const added = await createCoachExercise({
        variables: {
          record: {
            title,
            type,
            category,
            setting,
            video,
            youtubeVideoId,
          },
        },
      });
      return added?.data?.createCoachExercise;
    }
  };

  useEffect(() => {
    exercisesRefetch();
  }, [searchText, exercisesRefetch]);

  return {
    exercises,
    searchText,
    setSearchText,
    exercisesLoading,
    createNewExercise,
    loading: createLoading || editLoading,
  };
};

export default useExercise;
