import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { sendProgramMutation } from '@witness/graphql';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import { ChangesSaved, ChangesSavedIcon, LoadingDot } from '../profile/components';
import { PseudoParticipantCard } from '../../components/trainee-card/components';
import ProgramCalendar from '../../components/program-calendar/ProgramCalendar';
import AddGroupModal from '../../components/add-group-modal.jsx/AddGroupModal';
import ParticipantCard from '../../components/trainee-card/ParticipantCard';
import ArrowIcon from '../../assets/common/arrow-main-right.svg';
import CustomButton from '../../components/button/CustomButton';
import { CoachProgramStatus, WorkoutStatus } from '../../constants/enums';
import Checkbox from '../../components/checkbox/Checkbox';
import ChatContext from '../../services/ChatContext';
import { workoutToMin } from '../../services/utils';
import Loader from '../../components/loader/Loader';
import MessageList from '../chat/MessageList';
import IMAGES from '../../constants/images';
import { useGroup, useProgram } from '../../hooks';

import {
  StatusBar,
  StatusTitle,
  StatusText,
  StatusMessage,
  StatusMessageContainer,
  IconContainer,
  Container,
  Sidemenu,
  Content,
  SideHeader,
  Avatar,
  TraineeName,
  TraineeNameTitle,
  TraineeNameSubTitle,
  SideContent,
  SideInfo,
  InfoHeader,
  InfoHeaderText,
  InfoCell,
  Row,
  Cell,
  CellTitle,
  MinimizeButton,
  Header,
  ContentHeader,
  Tabs,
  Tab,
  ButotnContainer,
  ButtonText,
  GroupIdBadge,
  InfoHeaderEditIcon,
  ParticipantSliderItem,
} from './components';
import AreYouSureModal from '../../components/are-you-sure-modal/AreYouSureModal';
import CancelProgramModal from '../../components/cancel-program-modal/CancelProgramModal';

import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import MagicScroll from '../../components/magic-scroll/MagicScroll';
import { useAlert } from 'react-alert';
import AlertModal from '../../components/alert-modal/AlertModal';
import TraineeInfoModal from '../../components/trainee-info-modal/TraineeInfoModal';


const Program = () => {
  const [edittingGroup, setEdditingGroup] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [menuIsOpen, setMenuOpen] = useState(true);
  const [sendGroupProgramModal, setSendGroupProgramModal] = useState(false);
  const [tab, setTab] = useState('program');
  const [statusLoading, setStatusLoading] = useState(false);
  const [cancelModal, setCancelModal] = useState(null);
  const [traineeInfoModalContent, setTraineeInfoModalContent] = useState(null);

  const {
    program,
    programLoading,
    isGroup,
    defaultTab,
    urlProgramDay,
    urlTraineeUid,
    refetch,
    handleCoachProgramUpdate,
    updateCalendarCache,
    cancelParticipantFromGroup,
  } = useProgram();

  const {
    createGroup,
  } = useGroup();

  useEffect(() => {
    setTab(defaultTab || 'program');
  }, [defaultTab]);

  const [sendProgram, { loading, client}] = useMutation(sendProgramMutation, {
    update() {
      client.resetStore()
    },
  });

  const handleSendProgram = useCallback(async () => {
    setStatusLoading(true);
    try {
      await sendProgram({
        variables: {
          record: {
            uid: program?.coachProgram?.uid,
            beginningDate: program?.coachProgram?.beginningDate || new Date(),
          },
        },
      });
      await refetch();
    } catch (err) {
      await refetch();
    }
    setStatusLoading(false);
  }, [sendProgram, program, refetch]);

  const diffHours = moment(program?.approvedDate).add(3, 'd').diff(moment(), 'hours');

  const message = useMemo(() => {
    if (!program?.approvedDate || program?.coachProgram?.status === CoachProgramStatus.ENDED) {
      return '';
    }

    if (diffHours < 0) {
      return `You are late by ${-diffHours} hours. Send program to ${program?.trainee?.fullName?.toLowerCase()} ASAP!`;
    }

    return `You have ${diffHours} hours left to send program to customer`;
  }, [diffHours, program]);

  const { currentConversation, currentMessages, conversations, setCurrentConversation } =
    useContext(ChatContext);

  const handleTabClick = useCallback(() => {
    const { uid, trainee } = program;
    const convId = isGroup ? uid : trainee.user?.uid;

    let convo = conversations[convId];

    if (!convo) {
      convo = {
        isNew: true,
        isGroup,
        groupId: convId,
        lastMessage: {},
        user: {
          id: convId,
          name: isGroup ? program.name : trainee.fullName,
          avatar: isGroup ? program.groupImage?.url : trainee.avatar?.url,
        },
      };
    }

    setCurrentConversation(convo);
    setTab('chat');
  }, [isGroup, conversations, program, setCurrentConversation]);

  useEffect(() => {
    if (program && defaultTab === 'chat') {
      handleTabClick();
    }
  }, [defaultTab, handleTabClick, program]);

  const daysCountUntilEnd = useMemo(() => {
    if (program?.coachProgram?.status !== 'ONGOING') {
      return 11;
    }

    const endDate = new Date(program.coachProgram.beginningDate);
    endDate.setDate(endDate.getDate() + program.programLength);
    const msLeft = endDate.getTime() - Date.now();

    return Math.ceil(msLeft / 1000 / 60 / 60 / 24);
  }, [program]);

  const alert = useAlert();
  const nonChangableDate = useMemo(() => {
    return !!program?.coachProgram?.calendar?.find((day) =>
      day?.workout?.status?.find(
        (st) => st?.type === WorkoutStatus.DONE || st?.type === WorkoutStatus.UNDONE,
      ),
    );
  }, [program]);
  console.log(nonChangableDate);
  return (
    <>
      <TraineeInfoModal
        content={traineeInfoModalContent}
        closeModal={() => setTraineeInfoModalContent(null)}
      />
      {program?.coachProgram ? (
        <>
          {!programLoading && (
            <StatusBar>
              {
                <div
                  style={{
                    marginLeft: 28,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StatusTitle>STATUS:</StatusTitle>
                  <AreYouSureModal
                    modalOpen={sendGroupProgramModal}
                    closeModal={() => setSendGroupProgramModal(false)}
                    submitModal={handleSendProgram}
                    headingText={'Are You Sure?'}
                    contentText={
                      <>
                        If you will change the status to “<span>Open</span>” the group program will
                        be available for trainees
                      </>
                    }
                    choiceQuestion={'Do you want to Change the Status?'}
                  />
                  <StatusText>
                    {program?.coachProgram?.status === CoachProgramStatus.ONGOING
                      ? 'In Progress '
                      : program?.coachProgram?.status === CoachProgramStatus.ENDED
                      ? 'Finished '
                      : program?.coachProgram?.status === CoachProgramStatus.DECLINED
                      ? 'Canceled '
                      : 'Building '}
                    Training Program
                  </StatusText>
                </div>
              }

              {!isGroup &&
                program?.coachProgram?.status !== CoachProgramStatus.ONGOING &&
                program?.coachProgram?.status !== CoachProgramStatus.ENDED &&
                program?.coachProgram?.status !== CoachProgramStatus.DECLINED && (
                  <StatusMessageContainer>
                    <IconContainer isDue={diffHours < 0}>
                      <img src={ArrowIcon} alt="" />
                    </IconContainer>
                    <StatusMessage>{message}</StatusMessage>
                  </StatusMessageContainer>
                )}
            </StatusBar>
          )}
          <Container>
            <Header>
              <SideHeader>
                <Avatar src={isGroup ? program?.groupImage?.url : program?.trainee?.avatar?.url}>
                  {program?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')}
                </Avatar>
                {isGroup ? (
                  <TraineeName>
                    <TraineeNameTitle>{program?.name}</TraineeNameTitle>
                    <GroupIdBadge>
                      #
                      {(program?.code?.digits &&
                        `${('000' + Math.floor(program?.code?.digits / 1000)).slice(-3)}-${(
                          '000' +
                          (program?.code?.digits % 1000)
                        ).slice(-3)}`) ||
                        '--- ---'}
                    </GroupIdBadge>
                  </TraineeName>
                ) : (
                  <TraineeName>
                    <TraineeNameTitle>
                      {(program?.trainee?.fullName && `${program?.trainee?.fullName}'s`) || false}
                    </TraineeNameTitle>
                    <TraineeNameSubTitle>Program</TraineeNameSubTitle>
                  </TraineeName>
                )}
              </SideHeader>

              <ContentHeader>
                <Tabs>
                  <Tab
                    text="Program Planning"
                    active={tab === 'program'}
                    onClick={() => setTab('program')}
                  />
                  <Tab text="Chat" active={tab === 'chat'} onClick={handleTabClick} />
                  {isGroup && (
                    <Tab
                      text="Participants"
                      active={tab === 'participants'}
                      onClick={() => setTab('participants')}
                    />
                  )}
                </Tabs>
                <ButotnContainer>
                  {!statusLoading && program?.coachProgram?.status === CoachProgramStatus.APPROVED && (
                    <CustomButton
                      backgroundColor="#00FF91"
                      height="48px"
                      fontSize="1.4rem"
                      fontFamily="Roboto-r"
                      onClick={isGroup ? () => setSendGroupProgramModal(true) : handleSendProgram}
                      disabled={loading}
                    >
                      <ButtonText>{isGroup ? `Activate Group` : `Send Program`}</ButtonText>
                    </CustomButton>
                  )}

                  {(loading || statusLoading) && (
                    <ChangesSaved>
                      <ChangesSavedIcon />
                      <LoadingDot i={2} />
                      <LoadingDot i={1} />
                      <LoadingDot i={0} />
                    </ChangesSaved>
                  )}
                  {program?.coachProgram?.status === CoachProgramStatus.ONGOING && (
                    <ChangesSaved>
                      <ChangesSavedIcon />
                      In progress
                    </ChangesSaved>
                  )}
                </ButotnContainer>
              </ContentHeader>
            </Header>
            <Content>
              <Sidemenu isOpen={menuIsOpen}>
                <SideContent isOpen={menuIsOpen}>
                  <MinimizeButton onClick={() => setMenuOpen((o) => !o)} isOpen={menuIsOpen} />
                  <SideInfo className="side-info">
                    {isGroup && (
                      <Row style={{ position: 'relative' }}>
                        { program?.coachProgram?.status !== CoachProgramStatus.ENDED  && <InfoHeader
                          style={{
                            border: 'none',
                            width: 'fit-content',
                            padding: '0 1rem',
                            left: 'Calc(50% - 5.3rem)',
                          }}
                        >
                          <AddGroupModal
                            modalOpen={!!edittingGroup}
                            initialValues={edittingGroup}
                            submitModal={(values, { setErrors }) => {
                              createGroup(
                                values,
                                setErrors,
                                () => {},
                                () => {},
                              ).then(refetch);
                              setEdditingGroup(false);
                              alert.success('Group has been updated.');
                            }}
                            closeModal={() => setEdditingGroup(false)}
                            nonChangableDate={nonChangableDate}
                          />
                          <InfoHeaderText>Edit Group</InfoHeaderText>
                          <InfoHeaderEditIcon onClick={() => setEdditingGroup(program)} />
                        </InfoHeader> }

                        <Cell mb style={{ padding: '2.4rem 1.2rem 2rem' }}>
                          <Row
                            style={{
                              justifyContent: 'center',
                              width: 'Calc(100% - 1.2rem)',
                              margin: '1.6rem 0 2.4rem',
                            }}
                          >
                            {isGroup && program?.coachProgram?.participants?.length && (
                              <MagicScroll
                                noArrows={program?.coachProgram?.participants?.length <= 5}
                                itemsToDisplay={5}
                                items={program?.coachProgram?.participants}
                                ItemComponent={ParticipantSliderItem}
                                onLeftClick={() => setSliderIndex((curr) => Math.max(0, curr - 1))}
                                onRightClick={() => setSliderIndex((curr) => Math.min(5, curr + 1))}
                                firstItem={sliderIndex}
                                keyExtractor={(a, b) => (typeof b === 'number' ? b : a)}
                                onTabClick={() => {}}
                              />
                            )}
                          </Row>
                        </Cell>
                      </Row>
                    )}
                    <Row style={{ position: 'relative' }}>
                      <InfoHeader>
                        <InfoHeaderText>Program Details</InfoHeaderText>
                      </InfoHeader>
                      <InfoCell
                        mr
                        mb
                        title="Program Length"
                        text={`${program?.programLength} days`}
                      />
                      <InfoCell
                        mb
                        title="Training Types"
                        text={
                          program?.trainingTypes?.map((a) => a.name)?.join(', ') ||
                          program?.trainingType?.name
                        }
                      />
                    </Row>
                    {isGroup ? (
                      <Row>
                        <InfoCell
                          mb
                          mr
                          title="Maximum number of participants"
                          text={`${program?.participants}`}
                        />
                        <InfoCell
                          mb
                          title="Price per participant per month"
                          text={`₪${program?.price}`}
                        />
                      </Row>
                    ) : (
                      <Row>
                        <InfoCell
                          mb
                          mr
                          title="Workout time"
                          text={`${workoutToMin(program?.workoutLength)} mi`}
                        />
                        <InfoCell
                          mb
                          title="Trainer Goals"
                          text={program?.goals?.map((eq) => eq.name).join(', ')}
                        />
                      </Row>
                    )}

                    <Row>
                      <Cell mb style={{ padding: '2.4rem 1.2rem 2rem' }}>
                        <CellTitle>Desired Agenda</CellTitle>

                        <Row
                          style={{
                            justifyContent: 'space-between',
                            width: 'Calc(100% - 1.2rem)',
                            margin: '1.6rem 0 2.4rem',
                          }}
                        >
                          <Checkbox
                            text="S"
                            checked={program?.daysOfTraining.indexOf('SUN') > -1}
                          />
                          <Checkbox
                            text="M"
                            checked={program?.daysOfTraining.indexOf('MON') > -1}
                          />
                          <Checkbox
                            text="T"
                            checked={program?.daysOfTraining.indexOf('TUE') > -1}
                          />
                          <Checkbox
                            text="W"
                            checked={program?.daysOfTraining.indexOf('WED') > -1}
                          />
                          <Checkbox
                            text="T"
                            checked={program?.daysOfTraining.indexOf('THUR') > -1}
                          />
                          <Checkbox
                            text="F"
                            checked={program?.daysOfTraining.indexOf('FRI') > -1}
                          />
                          <Checkbox
                            text="S"
                            checked={program?.daysOfTraining.indexOf('SAT') > -1}
                          />
                        </Row>
                      </Cell>
                    </Row>

                    <Row style={{ position: 'relative' }}>
                      <InfoHeader>
                        <InfoHeaderText>
                          {isGroup ? `Group Description` : `Program Details`}
                        </InfoHeaderText>
                      </InfoHeader>
                      {!isGroup && (
                        <>
                          <InfoCell mr mb title="Fitness Level" text={program?.fitnessLevel} />
                          <InfoCell
                            mb
                            title="Training Environment"
                            text={program?.place?.join(', ')}
                          />
                        </>
                      )}
                    </Row>

                    {isGroup ? (
                      <Row>
                        <InfoCell mr mb title="" text={program?.description} />
                      </Row>
                    ) : (
                      <>
                        <Row>
                          <InfoCell
                            mr
                            mb
                            title="Available Equipment"
                            text={
                              program?.equipments?.map((eq) => eq.name).join(', ') ||
                              "Trainee didn't mention any available equipment"
                            }
                          />
                        </Row>

                        <Row>
                          <InfoCell
                            mr
                            mb
                            title="Health Status"
                            text={
                              (program?.healthCondition && program?.healthConditionDetails) ||
                              'Healthy'
                            }
                            style={{
                              padding: '1rem'
                            }}
                          />
                          <InfoCell
                            mb
                            title="Injuries"
                            text={`${program?.injury || ''}${
                              (program?.injuryDetails && '. ' + program?.injuryDetails) || ''
                            }`}
                            style={{
                              padding: '1rem'
                            }}
                          />
                        </Row>
                        <Row style={{
                          padding: '8px 60px'
                        }}>
                          <CustomButton
                            backgroundColor="#fff"
                            height="25px"
                            fontSize="1.4rem"
                            fontFamily="Roboto-r"
                            onClick={() => setTraineeInfoModalContent(program)}
                            outlined
                            style={{
                              minHeight: 0
                            }}
                          >
                            More Info
                          </CustomButton>
                        </Row>
                      </>
                    )}
                  </SideInfo>
                </SideContent>
              </Sidemenu>
              {tab === 'program' && (
                <ProgramCalendar
                  isOngoing={program?.coachProgram?.status === CoachProgramStatus.ONGOING}
                  programLength={program?.programLength}
                  handleCoachProgramUpdate={handleCoachProgramUpdate}
                  calendar={program?.coachProgram?.calendar}
                  coachProgramUid={program?.coachProgram?.uid}
                  beginningDate={program?.coachProgram?.beginningDate}
                  updateCalendarCache={updateCalendarCache}
                  refetch={refetch}
                  loading={loading}
                  urlProgramDay={urlProgramDay}
                  urlTraineeUid={urlTraineeUid}
                  programRequestUid={program?.uid}
                  monthlySum={program?.monthlySum}
                  daysCountUntilEnd={daysCountUntilEnd}
                  extensionCoupon={program?.extensionCoupon}
                  trainee={program?.trainee}
                  isGroup={isGroup}
                  nonChangableDate={nonChangableDate}
                />
              )}
              {isGroup && tab === 'participants' && (
                <div
                  style={{
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    height: 'fit-content',
                    width: '100%',
                  }}
                >
                  {console.log(program)}
                  {program?.coachProgram?.participants?.length ? (
                    <>
                      {program?.price > 0 ? (
                        <AlertModal
                          title="You Can’t Directly Cancel The Program With Group Participant"
                          content={`If you want to cancel the group program with ${cancelModal?.fullName}, please, send request email to our Admin`}
                          modalOpen={cancelModal}
                          submitButtonText="Send Request Email"
                          closeModal={() => setCancelModal(null)}
                          mailHref={`mailto:support@witnesstofitness.com?subject=Trainee Program Cancellation From Group&body=Dear sir/madam,\n\n\nI am requesting to cancel the program with trainee "${cancelModal?.fullName}" in the group "${program?.name}".\n\n\nBest Regards,\nCoach ${cancelModal?.coach?.fullName}`}
                        />
                      ) : (
                        <CancelProgramModal
                          neg
                          modalOpen={!!cancelModal}
                          closeModal={() => setCancelModal(null)}
                          submitModal={() =>
                            cancelModal?.uid && cancelParticipantFromGroup(cancelModal?.uid, program?.coachProgram?.uid)
                          }
                          headingText={'Are You Sure?'}
                          contentText={
                            <>
                              If you cancel the program for {cancelModal?.fullName}, you
                              won't be able to restore.
                            </>
                          }
                        />
                      )}
                      {program?.coachProgram?.participants?.map((participant) => (
                        <ParticipantCard
                          handleOpenMoreInfo={() => setTraineeInfoModalContent({
                            ...program,
                            trainee: participant
                          })}
                          participant={participant}
                          handleCancel={() => {
                            setCancelModal(participant);
                          }}
                        />
                      ))}
                      <PseudoParticipantCard />
                      <PseudoParticipantCard />
                      <PseudoParticipantCard />
                      <PseudoParticipantCard />
                      <PseudoParticipantCard />
                    </>
                  ) : (
                    <EmptyTemplate
                      image={IMAGES.EmptyTrainees}
                      primaryText={`No Participants Yet`}
                    />
                  )}
                </div>
              )}
              {tab === 'chat' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    flexShrink: 0,
                    width: '80%',
                    maxWidth: 'min(80%, Calc(100% - 272px)',
                  }}
                >
                  <MessageList
                    conversation={currentConversation}
                    messages={currentMessages}
                    programRequest={program}
                    hideHeader
                  />
                </div>
              )}
            </Content>
          </Container>
        </>
      ) : !program && !programLoading ? (
        <>program not found</>
      ) : program && !program?.coachProgram ? (
        <>coach program is not created yet, you may need to reload the page</>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Program;
