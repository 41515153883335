import React from 'react';
import {
  MessengerIcon,
  MessengerIconImg,
  TraineeRow,
  TraineeRowProfilePic,
  TraineeTD,
} from '../../components/trainee-card/components';
import { ProgramStatus, ProgramType, CoachProgramStatusUI } from '../../constants/enums';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import Loader from '../../components/loader/Loader';
import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import { useHistory } from 'react-router-dom';
import IMAGES from '../../constants/images';
import { FlexTDContent, HoverIndicator } from '../../components/calendar-results-modal/components';
import CustomButton from '../../components/button/CustomButton';
import { useTrainees } from '../../hooks';
import { StatusBorder, StatusCircle } from './components';
import CustomTooltip from '../../components/tooltip/CustomTooltip';
const AllTrainees = (props) => {
  const history = useHistory();
  const { trainees, traineesLoading, traineeName, setTraineeName } = useTrainees();
  return (
    <>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ProgramsHeader
          statusFilter={false}
          traineeName={traineeName}
          setTraineeName={setTraineeName}
          heading="All Your Trainees"
        />

        <div
          style={{
            background: '#f7f6f6',
            padding: '12px 8px',
            margin: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {!trainees?.length ? (
            <>
              {(traineesLoading && <Loader />) || (
                <EmptyTemplate
                  image={IMAGES.EmptyTrainees}
                  buttonText="Dashboard"
                  onButtonClick={() => history.push('/home')}
                  // primaryText={`This page reflects the list of active trainees ${
                  //   traineeName ? `with Name Consisting '${traineeName}'` : ''
                  // } associated with your account`}
                  // secondarytext={
                  //   traineeName
                  //     ? ''
                  //     : 'The List of Trainees wll Appear here when You will approve your first Program request'
                  // }
                  primaryText="There are no trainees for this search"
                  c
                />
              )}
            </>
          ) : (
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 6px',
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Full name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Age
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Status/Program type
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Code
                  </th>
                  <th
                    style={{
                      width: '12.4rem',
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {trainees?.map((trainee) => {
                  const programs = trainee?.programs?.filter((program) => program.coachProgram);
                  const program = programs?.[programs?.length - 1];
                  const coachProgram = program?.coachProgram;

                  const couponCode = program?.code?.digits || 'N/A';

                  if (!coachProgram) {
                    return null;
                  }

                  return (
                    <TraineeRow key={trainee.uid}>
                      <TraineeTD>
                        <FlexTDContent>
                          <HoverIndicator className="hover_indicator" />
                          <TraineeRowProfilePic profilePic={trainee?.avatar?.url || ''}>
                            {!trainee?.avatar?.url &&
                              trainee?.fullName
                                ?.split(' ')
                                .map((x) => x.substring(0, 1))
                                .join('')}
                          </TraineeRowProfilePic>
                          {trainee?.fullName}
                        </FlexTDContent>
                      </TraineeTD>
                      <TraineeTD>{trainee.age}</TraineeTD>
                      <TraineeTD>
                        <FlexTDContent>
                          <StatusCircle status={coachProgram?.status}>
                            <CustomTooltip
                              style={{
                                left: '-1.6rem',
                                width: '8.8rem',
                                textAlign: 'center',
                                padding: '0.4rem 0.8rem',
                                fontSize: '1.2rem',
                                pointerEvents: 'all',
                                borderRadius: '0.8rem ',
                                bottom: 'Calc(100% + 2rem)',
                              }}
                              triangleLeft="1rem"
                            >
                              {CoachProgramStatusUI?.[coachProgram?.status] || 'n/a'}
                            </CustomTooltip>
                            <StatusBorder status={coachProgram?.status} />
                          </StatusCircle>
                          {{ ONETOONE: '1-1', GROUP: 'Group' }?.[program?.type]}
                        </FlexTDContent>
                      </TraineeTD>
                      <TraineeTD>
                        {program?.type === 'GROUP'
                          ? coachProgram?.group?.name
                          : coachProgram?.programName}
                      </TraineeTD>
                      <TraineeTD>{couponCode}</TraineeTD>
                      <TraineeTD>
                        <FlexTDContent flexEnd>
                          <MessengerIcon
                            className="hoverable_message_button"
                            style={{
                              marginRight: '1.6rem',
                              border: '1px solid #F1F0F0',
                              borderRadius: '8px',
                            }}
                            onClick={() => {
                              const targetProgram = trainee?.programs
                                ?.reverse()
                                ?.find(
                                  (x) =>
                                    x.status === ProgramStatus.APPROVED && x.type === program.type,
                                );
                              console.log(targetProgram, trainee);
                              const target_url =
                                targetProgram.type === ProgramType.GROUP
                                  ? `/program/${targetProgram?.coachProgram?.group?.uid}?group=true&tab=chat`
                                  : `/program/${targetProgram?.uid}?tab=chat`;
                              // setCurrentConversation(conversation);
                              history.push(target_url);
                            }}
                          >
                            <MessengerIconImg active={false} />
                          </MessengerIcon>
                          <div style={{ width: '12.4rem', marginRight: '1rem' }}>
                            <CustomButton
                              className="hoverable_view_button"
                              outlined
                              borderRadius="8px"
                              hoverBackground="#00ff91"
                              outlineColor="#F1F0F0"
                              backgroundColor="#ffffff"
                              color="#373333"
                              height="3.2rem"
                              fontSize="1.4rem"
                              onClick={() => {
                                const targetProgram = trainee?.programs
                                  ?.reverse()
                                  ?.find(
                                    (x) =>
                                      x.status === ProgramStatus.APPROVED &&
                                      x.type === program.type,
                                  );
                                history.push(`/program/${targetProgram?.uid}`);
                              }}
                            >
                              View Program
                            </CustomButton>
                          </div>
                        </FlexTDContent>
                      </TraineeTD>
                    </TraineeRow>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default AllTrainees;
