import React, { useState } from 'react';

import ExplainRejectionModal from './ExplainRejectionModal';
import CustomButton from '../button/CustomButton';
import STRINGS from '../../constants/strings';
import ICONS from '../../constants/icons';
import { workoutToMin } from '../../services/utils';

import {
  AvatarRow,
  BackIcon,
  BannerWrapper,
  Box,
  ModalTitle,
  ProfilePic,
  RespContainer,
  RespModalHeader,
  TicketIcon,
  TicketDigits,
  TicketNote,
  ProfileName,
  GenderIcon,
  ParameterRow,
  ParameterCell,
  RespContentContainer,
  TotalPriceBold,
  ModalButtonsRow,
  ModalButtonWrapper,
  MiddleRowCellIcon,
  MiddleRowCellKey,
  MiddleRowCellValue,
  BlockLabelContainer,
  LabelContentContainer,
  LabelIcon,
  FitnessDataRow,
  FitnessDataWrapper,
  RespNote,
  ModalFooter,
  TotalPriceGreen,
  PriceRow,
  PriceData,
  RespModalFooterWrapper,
} from './components';
import useProgramRequestPreview from '../../hooks/programRequestPreview';

function FitnessData(icon, label, desc) {
  this.icon = icon;
  this.label = label;
  this.desc = desc;
}

const generateFitnessDataArray = (content) => {
  return [
    new FitnessData(ICONS.FitnessLevel, STRINGS.fitness_level, content?.fitnessLevel),
    new FitnessData(
      ICONS.Injures,
      STRINGS.injures,
      `${content?.injury || ''}${(content?.injuryDetails && '. ' + content?.injuryDetails) || ''}`,
    ),
    new FitnessData(ICONS.Environment, STRINGS.training_environment, content?.place?.join(', ')),
    new FitnessData(
      ICONS.Equipment,
      STRINGS.available_equipment,
      `${content?.equipments?.map((eq) => eq.name).join(', ')}
    ${!!content?.equipments?.length && content?.otherEquipment && ', '}
    ${content?.otherEquipment}
    ${
      !(content?.equipments?.length || content?.otherEquipment) &&
      "Trainee didn't mention any available equipment"
    }`,
    ),
    new FitnessData(
      ICONS.Health,
      STRINGS.health_status,
      (content?.healthCondition && content?.healthConditionDetails) || 'Healthy',
    ),
    new FitnessData(
      ICONS.Focus,
      STRINGS.training_goals,
      content?.goals?.map((eq) => eq.name).join(', '),
    ),
  ].filter((i) => i.desc);
};

const generateProgramDetailsArray = (content, weekDays) =>
  [
    new FitnessData(ICONS.ProgramLength, STRINGS.program_length, `${content?.programLength} days`),
    new FitnessData(
      ICONS.Time,
      STRINGS.workout_time,
      `${workoutToMin(content?.workoutLength)} min`,
    ),
    new FitnessData(
      ICONS.Type,
      STRINGS.desired_agenda,
      content?.daysOfTraining?.map((d) => weekDays[d]).join(', '),
    ),
    new FitnessData(
      ICONS.Goal,
      STRINGS.training_type,
      `${
        content?.trainingTypes?.map((ttype) => ttype?.name)?.join(', ') ||
        content?.trainingType?.name
      }`,
    ),
    new FitnessData(
      ICONS.Focus,
      STRINGS.training_goals,
      content?.goals?.map((eq) => eq.name).join(', '),
    ),
  ].filter((i) => i?.desc);

const ProgramRequestBlock = ({ title, mt, mb, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <BlockLabelContainer mt={mt} mb={mb} onClick={() => setIsOpen(!isOpen)}>
        {title}
        <LabelIcon src={ICONS.SimpleArrowUp} isOpen={isOpen} />
      </BlockLabelContainer>
      {isOpen && <LabelContentContainer>{children}</LabelContentContainer>}
    </>
  );
};

const RespProgramRequest = ({ content, closeModal, refetchProgramRequests }) => {
  const {
    explanationModalOpen,
    setExplanationModalOpen,
    changeProgramStatus,
    isGroup,
    groupByDigits,
    weekDays,
    totalPrice,
    monthlyPrice,
    handleChat,
    changeStatusLoading,
  } = useProgramRequestPreview({ content });

  return (
    <RespContainer>
      <RespModalHeader>
        <BackIcon src={ICONS.ArrowLeft} alt="Go Back" onClick={closeModal} />
        <ModalTitle>New Program Request</ModalTitle>
      </RespModalHeader>

      <BannerWrapper>
        <Box />
        <Box />
        <Box />
        <Box />
      </BannerWrapper>

      <RespContentContainer>
        <AvatarRow>
          <ProfilePic image={content?.trainee?.avatar?.url}>
            {content?.trainee?.fullName
              ?.split(' ')
              .map((x) => x.substring(0, 1))
              .join('')
              .substring(0, 3)}
          </ProfilePic>

          {!!content?.code && (
            <TicketIcon>
              <TicketDigits>
                #{`000${Math.floor(content?.code?.digits / 1000)}`.slice(-3)}-
                {`000${content?.code?.digits % 1000}`.slice(-3)}
              </TicketDigits>
              <TicketNote>Code Used</TicketNote>
            </TicketIcon>
          )}
        </AvatarRow>

        <ProfileName>
          {content?.trainee?.fullName}
          <GenderIcon gender={content?.trainee?.gender} />
        </ProfileName>
        <ParameterRow>
          <ParameterCell>
            {STRINGS.weight}
            <ParameterCell val>{`${content?.trainee?.weight}`}</ParameterCell>
          </ParameterCell>
          <ParameterCell>
            {STRINGS.height}
            <ParameterCell val>{content?.trainee?.height}</ParameterCell>
          </ParameterCell>
          <ParameterCell>
            {STRINGS.age}
            <ParameterCell val>{content?.trainee?.age}</ParameterCell>
          </ParameterCell>
        </ParameterRow>
      </RespContentContainer>

      <ProgramRequestBlock title="Health & Fitness" mt={40} mb={8}>
        {generateFitnessDataArray(content)?.map?.((item) => {
          return (
            <FitnessDataRow key={item.label}>
              <MiddleRowCellIcon icon={item.icon} />
              <FitnessDataWrapper>
                <MiddleRowCellKey>{item.label}</MiddleRowCellKey>
                <MiddleRowCellValue>{item.desc}</MiddleRowCellValue>
              </FitnessDataWrapper>
            </FitnessDataRow>
          );
        })}
      </ProgramRequestBlock>

      <ProgramRequestBlock title="Program Details" mb={8}>
        {generateProgramDetailsArray(content, weekDays)?.map?.((item) => {
          return (
            <FitnessDataRow key={item.label}>
              <MiddleRowCellIcon icon={item.icon} />
              <FitnessDataWrapper>
                <MiddleRowCellKey>{item.label}</MiddleRowCellKey>
                <MiddleRowCellValue>{item.desc}</MiddleRowCellValue>
              </FitnessDataWrapper>
            </FitnessDataRow>
          );
        })}
      </ProgramRequestBlock>

      <ProgramRequestBlock title="Notes" mb={40}>
        <FitnessDataRow>
          <RespNote>{content?.note || 'No Additional Notes'}</RespNote>
        </FitnessDataRow>
      </ProgramRequestBlock>
      <RespModalFooterWrapper>
        <ModalFooter>
          <PriceRow>
            <TotalPriceBold>{STRINGS.total_price}</TotalPriceBold>
            <PriceData>
              <TotalPriceGreen>{`₪${totalPrice}`}</TotalPriceGreen>
              {`(₪${monthlyPrice} p/month)`}
            </PriceData>
          </PriceRow>
          {content?.status === 'PENDING' && (
            <ModalButtonsRow>
              <ModalButtonWrapper margin="0 12px 0 24px">
                <CustomButton
                  color="#F50A4F"
                  outlined
                  borderRadius="8px"
                  outlineColor="#DEDEDE"
                  backgroundColor="transparent"
                  onClick={() => {
                    setExplanationModalOpen(true);
                  }}
                >
                  {STRINGS.decline_request}
                </CustomButton>
              </ModalButtonWrapper>
              <CustomButton
                onClick={handleChat}
                borderRadius="8px"
                height="48px"
                style={{ width: '100%', order: -1, margin: '24px 24px 12px', maxHeight: 'unset' }}
              >
                {STRINGS.chat_with + (content?.trainee?.fullName || 'Trainee')}
              </CustomButton>
              <ModalButtonWrapper margin="0 24px 0 0">
                <CustomButton
                  green
                  borderRadius="8px"
                  disabled={changeStatusLoading}
                  onClick={async () => {
                    await changeProgramStatus(
                      {
                        uid: content?.uid,
                        status: 'APPROVED',
                      },
                      isGroup && groupByDigits?.checkProgramCode?.group?.uid,
                    );
                    closeModal();
                  }}
                >
                  {STRINGS.approve_request}
                </CustomButton>
              </ModalButtonWrapper>
            </ModalButtonsRow>
          )}
        </ModalFooter>
      </RespModalFooterWrapper>

      <ExplainRejectionModal
        modalOpen={explanationModalOpen}
        closeModal={() => {
          setExplanationModalOpen(false);
        }}
        submitModal={({ message }) => {
          changeProgramStatus({ uid: content?.uid, status: 'DECLINED', message }, isGroup).then(
            () => {
              refetchProgramRequests();
            },
          );
          closeModal();
        }}
      />
    </RespContainer>
  );
};

export default RespProgramRequest;
