import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';

import CalendarResultsModal from '../calendar-results-modal/CalendarResultsModal';
import WorkoutMessageModal from '../workout-message-modal/WorkoutMessageModal';
import ChooseTemplateModal from '../choose-template-modal/ChooseTemplateModal';
import { CalendarDragProvider } from '../../services/CalendarDragContext';
import AddCalendarModal from '../add-calendar-modal/AddCalendarModal';
import ConfirmModal from '../confirm-modal/ConfirmModal';
import ProgramCalendarDay from './ProgramCalendarDayV3';
import { CalendarType } from '../../constants/enums';
import CustomInput from '../input/CustomInput';
import { useCoachProgram } from '../../hooks';
import Loader from '../loader/Loader';
import {
  CalendarContainer,
  CalendarHeading,
  CalendarBeginning,
  CalendarBeginningDateWrapper,
  CalendarUploadTemplate,
  CalendarUploadIcon,
  CalendarMain,
  CalendarTable,
  CalendarThead,
  CalendarTh,
  CalendarTd,
  CalendarHeadingTable,
  RelativeBox,
  CalendarHeadingTableWrapper,
  WholeWrapper,
  CalendarTableWrapper,
  CopyWeekButton,
  EditWeekButton,
  ButtonText,
  Badge,
  FlexWrapper,
} from './components';
import MeetingModal from '../meeting-modal/MeetingModal';
import AddCalendarWeekModal from '../add-calendar-modal/AddCalendarWeekModal';
import CustomButton from '../button/CustomButton';
import ExtensionCouponModal from '../coupon-modal/ExtensionCouponModal';
import CustomTooltip from '../tooltip/CustomTooltip';
import AreYouSureModal from '../are-you-sure-modal/AreYouSureModal';

const ProgramCalendar = ({
  isTemplate,
  calendar,
  programLength = 60,
  coachProgramUid,
  programTemplateUid,
  beginningDate,
  refetch,
  loading,
  handleCoachProgramUpdate,
  updateCalendarCache,
  setCalendarDayLoading,
  urlProgramDay,
  urlTraineeUid,
  isOngoing,
  readOnly,
  programRequestUid,
  extensionCoupon,
  trainee,
  monthlySum = 0,
  daysCountUntilEnd = 11,
  isGroup,
  nonChangableDate,
} = {}) => {
  const firstDay = useMemo(() => {
    return isTemplate ? new Date('2034-01-01') : beginningDate;
  }, [beginningDate, isTemplate]);
  const {
    calendarMatrix,
    programDays,
    addRestDay,
    messageDay,
    openMessageModal,
    closeMessageModal,
    copiedWorkout,
    setCopiedWorkout,
    pasteWorkout,
    selectedProgram,
    setSelectedProgram,
    handleProgramDelete,
    selectedMessage,
    setSelectedMessage,
    saveWorkoutDay,
    setCopiedWeek,
    pasteWeek,
    copiedWeek,
    submitMessageDay,
    handleCreateMeeting,
    handleDeleteCalendar,
    pasteLoading,
  } = useCoachProgram({
    beginningDate: firstDay,
    programLength,
    coachProgramUid,
    programTemplateUid,
    calendar,
    refetch,
    updateCalendarCache,
    setCalendarDayLoading,
  });
  const [edittingDay, setEdditingDay] = useState(null);
  const [openResults, setOpenResults] = useState(null);
  const [templateOpenModal, setTemplateOpenModal] = useState(false);
  const [meetingModalContent, setMeetingModalContent] = useState(null);
  const [addCalendarModalWeek, setAddCalendarModalWeek] = useState(null);
  const alert = useAlert();
  const todayRef = useRef(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [pasteWeekArgs, setpasteWeekArgs] = useState(null);
  const [pasteWorkoutArgs, setpasteWorkoutArgs] = useState(null);
  useEffect(() => {
    if (todayRef?.current) {
      setTimeout(() => {
        if (todayRef?.current) {
          todayRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 1000);
    }
  }, [calendarMatrix, todayRef?.current]);

  useEffect(() => {
    setOpenResults(urlProgramDay);
  }, [urlProgramDay]);

  // const selectedWorkout = useMemo(() => {
  //   return calendar?.find(
  //     (day) => day.type === CalendarType.WORKOUT && day.programDay === edittingDay?.programDay,
  //   );
  // }, [calendar, edittingDay]);

  const closeWorkoutModal = useCallback(() => {
    setEdditingDay(null);
  }, []);
  const closeResultsModal = useCallback(() => {
    setOpenResults(null);
  }, []);
  const sureMessage = useMemo(() => {
    const { WORKOUT, REST, MEETING } = selectedProgram || {};

    if (WORKOUT) return 'Are you sure to delete this workout?';
    if (REST) return 'Are you sure to delete this rest day?';
    if (MEETING?.onlineMeeting) return 'Are you sure to delete this video call?';
    if (MEETING?.faceMeeting) return 'Are you sure to delete this meeting?';

    return 'Are you sure to delete this message?';
  }, [selectedProgram]);

  const dateInputValue = useMemo(() => {
    return moment(beginningDate || new Date()).format('YYYY-MM-DD');
  }, [beginningDate]);

  const [extModal, setExtModal] = useState(false);

  return (
    <WholeWrapper>
      {edittingDay && (
        <AddCalendarModal
          day={edittingDay}
          closeModal={closeWorkoutModal}
          submitModal={saveWorkoutDay}
          loading={loading}
          readOnly={readOnly}
          coachProgramUid={coachProgramUid}
        />
      )}
      <AddCalendarWeekModal
        isOpen={typeof addCalendarModalWeek === 'number'}
        weekN={addCalendarModalWeek}
        setWeekN={setAddCalendarModalWeek}
        closeModal={() => setAddCalendarModalWeek(null)}
        loading={loading}
        calendarMatrix={calendarMatrix}
        programDays={programDays}
        submitDay={saveWorkoutDay}
        // duplicateWeek={duplicateWeek}
        setCopiedWeek={setCopiedWeek}
        pasteWeek={pasteWeek}
        addRestDay={addRestDay}
        submitMessageDay={submitMessageDay}
        handleCreateMeeting={handleCreateMeeting}
        handleDeleteCalendar={handleDeleteCalendar}
        coachProgramUid={coachProgramUid}
        programTemplateUid={programTemplateUid}
        refetch={refetch}
      />
      {meetingModalContent && (
        <MeetingModal
          initialValues={meetingModalContent}
          closeModal={() => setMeetingModalContent(null)}
          coachProgramUid={coachProgramUid}
          refetch={refetch}
          programTemplate={programTemplateUid}
        />
      )}
      {extModal && (
        <ExtensionCouponModal
          isOpen={extModal}
          closeModal={() => setExtModal(null)}
          refetch={refetch}
          programRequestUid={programRequestUid}
          monthlySum={monthlySum}
          traineeName={trainee?.fullName}
          coupon={extensionCoupon}
        />
      )}
      {openResults && (
        <CalendarResultsModal
          day={programDays[openResults]?.WORKOUT}
          urlTraineeUid={urlTraineeUid}
          closeModal={closeResultsModal}
          refetchProgram={refetch}
          loading={loading}
          alert={alert}
          coachProgramUid={coachProgramUid}
        />
      )}
      {templateOpenModal && (
        <ChooseTemplateModal
          programUid={coachProgramUid}
          modalOpen={templateOpenModal}
          closeModal={() => setTemplateOpenModal(false)}
          alert={alert}
          refetch={refetch}
          programLength={programLength}
        />
      )}
      <WorkoutMessageModal
        beginningDate={beginningDate}
        day={messageDay}
        closeModal={closeMessageModal}
        existingMessage={selectedMessage}
        coachProgram={coachProgramUid}
        programTemplate={programTemplateUid}
        refetchProgram={refetch}
      />
      <ConfirmModal
        isOpen={!!selectedProgram}
        closeModal={() => setSelectedProgram(null)}
        message={sureMessage}
        handleSubmit={handleProgramDelete}
      />
      {pasteWeekArgs && (
        <AreYouSureModal
          modalOpen={pasteWeekArgs}
          neg
          headingText="If you paste you will lose the existing content of the week"
          choiceQuestion="Are you sure you want to paste?"
          rejectModal={() => setpasteWeekArgs(null)}
          closeModal={() => setpasteWeekArgs(null)}
          submitModal={async () => {
            await pasteWeek(pasteWeekArgs);
            refetch();
          }}
        />
      )}
      {pasteWorkoutArgs && (
        <AreYouSureModal
          modalOpen={pasteWorkoutArgs}
          neg
          headingText="If you paste you will lose the existing content"
          choiceQuestion="Are you sure you want to paste?"
          rejectModal={() => setpasteWorkoutArgs(null)}
          closeModal={() => setpasteWorkoutArgs(null)}
          submitModal={async () => {
            pasteWorkout(pasteWorkoutArgs, coachProgramUid, programTemplateUid);
          }}
        />
      )}
      {(
        programLength > 6 ? !!calendarMatrix?.[0]?.[6] : !!calendarMatrix?.[0]?.[programLength - 1]
      ) ? (
        <CalendarContainer>
          <CalendarHeading>
            {!isTemplate && (
              <CalendarBeginning>
                Beginning Date
                <CalendarBeginningDateWrapper>
                  <CustomInput
                    type="date"
                    outlined
                    height="4.4rem"
                    borderColor="rgba(74,74,74,0.12)"
                    margin="0 0 0 3.6rem"
                    onChange={handleCoachProgramUpdate}
                    value={dateInputValue}
                    disabled={nonChangableDate}
                  />
                </CalendarBeginningDateWrapper>
              </CalendarBeginning>
            )}
            <FlexWrapper style={isTemplate ? { width: '100%', justifyContent: 'flex-end' } : {}}>
              {!isTemplate && !isOngoing && (
                <CalendarUploadTemplate onClick={() => setTemplateOpenModal((curr) => !curr)}>
                  Upload a program template
                  <CalendarUploadIcon />
                </CalendarUploadTemplate>
              )}
              <CustomButton
                outlined
                borderRadius="8px"
                backgroundColor="#fff"
                style={{
                  width: 'fit-content',
                  padding: '0.8rem 2rem',
                  height: 'auto',
                  marginLeft: '1.6rem',
                }}
                onClick={() => {
                  setAddCalendarModalWeek(currentWeek);
                }}
              >
                Open Weekly Planner
              </CustomButton>
            </FlexWrapper>
            {!isTemplate && daysCountUntilEnd > 0 && daysCountUntilEnd < 11 && !isGroup && (
              <>
                {!!extensionCoupon && (
                  <div style={{ display: 'flex' }}>
                    <Badge name="extension code sent to trainee" />
                    <CalendarBeginningDateWrapper style={{ width: '10rem' }}>
                      <CustomButton
                        backgroundColor="transparent"
                        height="48px"
                        fontSize="1.4rem"
                        fontFamily="Roboto-r"
                        style={{ margin: '0.2rem', textDecoration: 'underline' }}
                        onClick={() => setExtModal(true)}
                        disabled={loading}
                      >
                        <ButtonText>Edit</ButtonText>
                      </CustomButton>
                    </CalendarBeginningDateWrapper>
                  </div>
                )}
                {!extensionCoupon && (
                  <CalendarBeginningDateWrapper style={{ width: 'fit-content' }}>
                    <CustomButton
                      backgroundColor="#ffcc00"
                      height="48px"
                      fontSize="1.4rem"
                      fontFamily="Roboto-r"
                      onClick={() => setExtModal(true)}
                      disabled={loading}
                    >
                      <ButtonText>Extend Program</ButtonText>
                    </CustomButton>
                  </CalendarBeginningDateWrapper>
                )}
              </>
            )}
          </CalendarHeading>

          <CalendarMain>
            <CalendarHeadingTableWrapper>
              <CalendarHeadingTable>
                <CalendarThead>
                  <tr>
                    <CalendarTh>Sunday</CalendarTh>
                    <CalendarTh>Monday</CalendarTh>
                    <CalendarTh>Tuesday</CalendarTh>
                    <CalendarTh>Wednesday</CalendarTh>
                    <CalendarTh>Thursday</CalendarTh>
                    <CalendarTh>Friday</CalendarTh>
                    <CalendarTh>Saturday</CalendarTh>
                  </tr>
                </CalendarThead>
              </CalendarHeadingTable>
            </CalendarHeadingTableWrapper>
            <RelativeBox>
              <CalendarTableWrapper>
                <CalendarDragProvider refetchCalendar={refetch}>
                  <CalendarTable>
                    <tbody>
                      {calendarMatrix.map((week, i) => (
                        <tr key={i}>
                          {week?.map((day, j) => (
                            <CalendarTd
                              key={j}
                              ref={(ref) => {
                                if (moment(day?.date, 'DD-MM-YYYY').isSame(new Date(), 'day')) {
                                  setCurrentWeek(i);
                                  return (todayRef.current = ref);
                                }
                              }}
                            >
                              {j === 0 ? (
                                // !copiedWeek &&
                                // i !== copiedWeek &&
                                // (copiedWeek === null ||

                                <>
                                  <CopyWeekButton
                                    paste={false}
                                    className="copy_week_btn"
                                    onClick={() => setCopiedWeek(day?.index)}
                                  >
                                    <CustomTooltip
                                      style={{
                                        left: '-1.6rem',
                                        width: '8.8rem',
                                        textAlign: 'center',
                                        padding: '0.4rem 0.8rem',
                                        fontSize: '1.2rem',
                                        color: '#8d8d8d',
                                        pointerEvents: 'all',
                                        borderRadius: '0.8rem ',
                                        bottom: 'Calc(100% + 1rem)',
                                      }}
                                      triangleLeft="1.6rem"
                                    >
                                      Copy Week
                                    </CustomTooltip>
                                  </CopyWeekButton>

                                  {copiedWeek &&
                                  !pasteLoading &&
                                  !readOnly &&
                                  (moment(day?.date, 'DD-MM-YYYY').isAfter(new Date(), 'day') ||
                                    moment(day?.date, 'DD-MM-YYYY').isSame(new Date(), 'day')) ? (
                                    <CopyWeekButton
                                      paste={true}
                                      className="paste_week_btn"
                                      onClick={async () => {
                                        const weekContent =
                                          programDays[day?.index] ||
                                          programDays[day?.index + 1] ||
                                          programDays[day?.index + 2] ||
                                          programDays[day?.index + 3] ||
                                          programDays[day?.index + 4] ||
                                          programDays[day?.index + 5] ||
                                          programDays[day?.index + 6];
                                        if (weekContent) {
                                          setpasteWeekArgs(day?.index);
                                        } else {
                                          await pasteWeek(day?.index);
                                          refetch();
                                        }
                                      }}
                                    >
                                      <CustomTooltip
                                        style={{
                                          left: '-1.6rem',
                                          width: '8.8rem',
                                          textAlign: 'center',
                                          padding: '0.4rem 0.8rem',
                                          fontSize: '1.2rem',
                                          color: '#8d8d8d',
                                          pointerEvents: 'all',
                                          borderRadius: '0.8rem ',
                                          bottom: 'Calc(100% + 1rem)',
                                        }}
                                        triangleLeft="1.6rem"
                                      >
                                        Paste Week
                                      </CustomTooltip>
                                    </CopyWeekButton>
                                  ) : null}
                                </>
                              ) : null}
                              {/* {!readOnly &&
                              j === 6 &&
                              moment(day?.date, 'DD-MM-YYYY').isAfter(new Date()) ? (
                                <EditWeekButton onClick={() => setAddCalendarModalWeek(i)} />
                              ) : null} */}
                              {day ? (
                                <ProgramCalendarDay
                                  day={day}
                                  isToday={moment(day?.date, 'DD-MM-YYYY').isSame(
                                    new Date(),
                                    'day',
                                  )}
                                  firstRow={i === 0}
                                  program={programDays[day.index]}
                                  openModal={
                                    (programDays[day.index]?.WORKOUT?.workout?.status?.filter(
                                      (x) => x?.type !== 'NEW',
                                    )?.length &&
                                      (() => setOpenResults(day.index))) ||
                                    setEdditingDay
                                  }
                                  addRestDay={addRestDay}
                                  openMessage={openMessageModal}
                                  copied={copiedWorkout}
                                  handleCopy={setCopiedWorkout}
                                  pasteWorkout={(a) => {
                                    const hasContent = programDays[day?.index];
                                    if (hasContent) {
                                      setpasteWorkoutArgs(a);
                                    } else {
                                      pasteWorkout(a, coachProgramUid, programTemplateUid);
                                    }
                                  }}
                                  pasteLoading={pasteLoading}
                                  setSelectedProgram={setSelectedProgram}
                                  setSelectedMessage={setSelectedMessage}
                                  openMeetingModal={(existing) => {
                                    const meeting = existing.onlineMeeting || existing.faceMeeting;
                                    setMeetingModalContent({
                                      ...day,
                                      calendarUid: existing.uid,
                                      faceMeetingUid: existing.faceMeeting?.uid,
                                      date: meeting?.date,
                                      timezone: meeting?.timezone,
                                      paid: false,
                                      location: meeting?.location,
                                    });
                                  }}
                                  openOnlineMeetingModal={(existing) => {
                                    const meeting = existing.onlineMeeting || existing.faceMeeting;
                                    setMeetingModalContent({
                                      ...day,
                                      isOnline: true,
                                      calendarUid: existing.uid,
                                      onlineMeetingUid: existing.onlineMeeting?.uid,
                                      date: meeting?.date,
                                      timezone: meeting?.timezone,
                                      paid: false,
                                    });
                                  }}
                                  readOnly={readOnly}
                                  hideDate={isTemplate}
                                />
                              ) : (
                                <></>
                              )}
                            </CalendarTd>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </CalendarTable>
                </CalendarDragProvider>
              </CalendarTableWrapper>
            </RelativeBox>
          </CalendarMain>
        </CalendarContainer>
      ) : (
        <Loader />
      )}
    </WholeWrapper>
  );
};

export default ProgramCalendar;
