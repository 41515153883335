import {
  createCalendarMutation,
  deleteCalendarMutation,
  deleteMeetingMutation,
  editCalendarMutation,
  editWorkoutMutation,
  copyWorkoutMutation,
} from '@witness/graphql';
import { useMutation } from '@apollo/react-hooks';
import { useState, useEffect, useCallback } from 'react';
import { lengthToDays } from '../services/utils';
import { CalendarType } from '../constants/enums';
import { create_UUID } from '../services/utils';
import { useAlert } from 'react-alert';
const useCoachProgram = ({
  beginningDate,
  programLength,
  calendar,
  coachProgramUid,
  programTemplateUid,
  refetch,
  updateCalendarCache,
  setCalendarDayLoading = () => {},
} = {}) => {
  const getCopiedWorkout = useCallback(() => {
    return JSON.parse(localStorage.getItem('copiedWorkout'));
  }, []);

  const [copiedWorkoutExists, setCopiedWorkoutExists] = useState(!!getCopiedWorkout());
  const setCopiedWorkout = useCallback(
    (source) => {
      localStorage.setItem(
        'copiedWorkout',
        JSON.stringify({
          source,
          sourceCoachProgramUid: coachProgramUid,
          sourceTemplateUid: programTemplateUid,
        }),
      );
      setCopiedWorkoutExists(true);
    },
    [setCopiedWorkoutExists, coachProgramUid, programTemplateUid],
  );

  const getCopiedWeek = useCallback(() => {
    return JSON.parse(localStorage.getItem('copiedWeek'));
  }, []);
  const [copiedWeekExists, setCopiedWeekExists] = useState(!!getCopiedWeek());

  const setCopiedWeek = useCallback(
    (sourceStart) => {
      if (sourceStart) {
        localStorage.setItem(
          'copiedWeek',
          JSON.stringify({
            sourceStart,
            sourceCoachProgramUid: coachProgramUid,
            sourceTemplateUid: programTemplateUid,
          }),
        );
        setCopiedWeekExists(true);
      } else {
        localStorage.removeItem('copiedWeek');
        setCopiedWeekExists(false);
      }
    },
    [coachProgramUid, programTemplateUid],
  );

  useEffect(() => {
    const handleLocalStorageChange = () => {
      const copiedWorkout = getCopiedWorkout();
      setCopiedWorkoutExists(!!copiedWorkout);
      const copiedWeek = getCopiedWeek();
      setCopiedWeekExists(!!copiedWeek);
    };
    window.addEventListener('storage', handleLocalStorageChange);
    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);
  const [programDays, setProgramDays] = useState([]);
  const [messageDay, setMessageOpen] = useState(null);
  const openMessageModal = useCallback((day) => setMessageOpen(day), []);
  const closeMessageModal = useCallback(() => setMessageOpen(null), []);
  const [calendarMatrix, setCalendarMatrix] = useState([]);

  const [createCalendar, { loading: createLoading }] = useMutation(createCalendarMutation);
  const [editWorkout, { loading: editLoading }] = useMutation(editWorkoutMutation);
  const [deleteMeeting] = useMutation(deleteMeetingMutation);
  const [handleCalendarUpdate] = useMutation(editCalendarMutation);
  const alert = useAlert();

  const createNewCalendar = useCallback(
    async (v, shouldNotify = true) => {
      try {
        await createCalendar(v);
        if (shouldNotify) {
          alert.success('Changes Saved !');
        }
        return true;
      } catch (err) {
        if (shouldNotify) {
          if (err.graphQLErrors.find((x) => x.message === 'date_not_valid')) {
            alert.error('You Cannot Add a Past Day');
          } else {
            alert.error('Changes Cannot Be Saved');
          }
          return false;
        }
      }
    },
    [createCalendar, alert],
  );
  const editExistingWorkout = useCallback(
    async (v, shouldNotify = true) => {
      try {
        await editWorkout(v);
        if (shouldNotify) {
          alert.success('Changes Saved !');
        }
      } catch (err) {
        if (shouldNotify) {
          if (err.graphQLErrors.find((x) => x.message === 'date_not_valid')) {
            alert.error('You Cannot Change a Past Day');
          } else {
            alert.error('Something Went Wrong');
          }
        }
      }
    },
    [editWorkout, alert],
  );

  const addRestDay = async (programDay, beforeFunc = () => {}, shouldNotify = true) => {
    beforeFunc();

    // updateCalendarCache([
    //   ...(calendar || []),
    //   {
    //     customMessage: null,
    //     icon: null,
    //     message: null,
    //     programDay,
    //     type: CalendarType.REST,
    //     uid: create_UUID(),
    //     workout: null,
    //     __typename: 'Calendar',
    //   },
    // ]);

    await createNewCalendar(
      {
        variables: {
          calendar: {
            coachProgram: coachProgramUid,
            programTemplate: programTemplateUid,
            type: CalendarType.REST,
            programDay,
          },
        },
      },
      shouldNotify,
    ).then(refetch);
  };

  const saveWorkoutDay = useCallback(
    async (programDay, values, uid, shouldNotify = true) => {
      const { name, type, beforeMessage, afterMessage, blocks, blocksReadyToSubmit } = values;
      const dataToSubmit = {
        name,
        type,
        beforeMessage,
        afterMessage,
        blocks:
          blocksReadyToSubmit ||
          blocks?.map((block, i) => {
            const { type, videoRequested, isWarmUp, comment, attributes, exercises } = block;
            return {
              type,
              order: i + 1,
              videoRequested,
              isWarmUp,
              comment,
              customAttributes: attributes
                ?.filter((x) => x?.value)
                ?.map((attr) => {
                  let value = attr.value;

                  let textValue = attr.textValue;
                  if (attr.type === 'clock') {
                    const [, m, s] = attr.value.split(':');
                    value = attr?.asNeeded ? 0 : Number(m) * 60 + Number(s);
                  } else if (attr.type === 'text') {
                    textValue = attr?.value;
                    value = -1;
                  } else {
                    value = Number(attr.value);
                  }

                  return {
                    name: attr.name,
                    value,
                    textValue,
                  };
                }),
              exercises: exercises?.map((ex, i) => {
                console.log(ex.restTime, 'rest time');
                return {
                  exerciseId: ex?.exercise?.id,
                  reps: ex.reps,
                  notes: ex.notes,
                  setting: ex.setting,
                  quantity: ex.quantity,
                  unit: ex.unit,
                  weight: ex.weight,
                  weightType: ex.weightType,
                  order: i + 1,
                  numberOfSets: ex.numberOfSets,
                  restTime: ex.restTime
                    ? Number(ex.restTime.split(':')?.[1]) * 60 + Number(ex.restTime.split(':')?.[2])
                    : undefined,
                };
              }),
            };
          }),
      };

      return uid
        ? await editExistingWorkout(
            {
              variables: {
                calendar: {
                  type: CalendarType.WORKOUT,
                  coachProgram: coachProgramUid,
                  programTemplate: programTemplateUid,
                  programDay: Math.floor(programDay),
                },
                workout: {
                  uid,
                  ...dataToSubmit,
                },
              },
            },
            shouldNotify,
          ).then((success) => {
            refetch();
            return success;
          })
        : await createNewCalendar(
            {
              variables: {
                calendar: {
                  type: CalendarType.WORKOUT,
                  coachProgram: coachProgramUid,
                  programTemplate: programTemplateUid,
                  programDay: Math.floor(programDay),
                },
                workout: dataToSubmit,
              },
            },
            shouldNotify,
          ).then((success) => {
            refetch();
            return success;
          });
    },
    [createNewCalendar, editExistingWorkout, programTemplateUid, coachProgramUid, refetch],
  );
  const generateCalendarMatrix = useCallback(() => {
    const currStartDate = new Date(beginningDate || new Date());
    const currEpoch = currStartDate.getTime();
    const startDateWeekDay = currStartDate.getDay();
    const result = [];
    const currprogramLength = programLength || 0;
    const weeksToShow = Math.ceil((currprogramLength + startDateWeekDay) / 7);
    for (const i of new Array(weeksToShow * 7).keys()) {
      const week = Math.floor(i / 7);
      const day = i % 7;
      if (day === 0) {
        result[week] = [];
      }
      if (i < startDateWeekDay || i > currprogramLength + startDateWeekDay - 1) {
        result[week][day] = null;
      } else {
        const currDayDate = new Date(currEpoch + (i - startDateWeekDay) * 86400000);
        const cellToBeAdded = {
          date: `${currDayDate.getDate()}.${
            currDayDate.getMonth() + 1
          }.${currDayDate.getFullYear()}`,
          index: i - startDateWeekDay + 1,
        };
        result[week][day] = cellToBeAdded;
      }
    }

    return result;
  }, [programLength, beginningDate]);

  const placeProgramDays = useCallback(() => {
    const length = lengthToDays(programLength) + 1;
    const programDaysArr = new Array(length);
    for (const day of calendar) {
      const index = day?.programDay;
      if (index > -1 && index < length) {
        if (programDaysArr[index]) {
          programDaysArr[index][day?.type] = day;
        } else {
          programDaysArr[index] = {
            [day?.type]: day,
          };
        }
      }
    }
    setProgramDays(programDaysArr);
    return programDaysArr;
  }, [calendar, programLength]);

  useEffect(() => {
    setCalendarMatrix(generateCalendarMatrix());
  }, [programLength, beginningDate]);

  useEffect(() => {
    if (calendar?.length) {
      placeProgramDays();
    } else {
      setProgramDays([]);
    }
  }, [calendar]);

  const prepareWorkoutToPaste = useCallback((copiedWorkout) => {
    const { name, type, beforeMessage, afterMessage, workoutBlocks } = copiedWorkout;
    const blocks = workoutBlocks?.map((workout) => {
      const {
        uid,
        __typename,
        coachFeedback,
        resultText,
        results,
        customAttributes,
        exercises,
        blockAttachment,
        ...rest
      } = workout;
      const preparedAttributes = customAttributes?.map((attr) => {
        const { uid, __typename, ...rest } = attr;
        return rest;
      });
      const preparedExercises = exercises?.map((attr, i) => {
        const { uid, __typename, exercise, ...rest } = attr;
        return {
          ...rest,
          exerciseId: exercise?.id,
          order: i + 1,
        };
      });
      return {
        ...rest,
        customAttributes: preparedAttributes,
        exercises: preparedExercises,
      };
    });
    return {
      name,
      type,
      beforeMessage,
      afterMessage,
      blocks,
    };
  }, []);

  const [copyWorkout, { loading: pasteLoading }] = useMutation(copyWorkoutMutation);

  const pasteWorkout = useCallback(
    async (target) => {
      const copiedWorkout = getCopiedWorkout();

      alert.info('Copying...');

      return copyWorkout({
        variables: {
          record: {
            ...(copiedWorkout || {}),
            target,
            coachProgramUid,
            templateUid: programTemplateUid,
          },
        },
      })
        .then(refetch)
        .then(() => alert.success('Copied Successfully!'));
    },
    [copyWorkout, getCopiedWorkout, refetch, alert, coachProgramUid, programTemplateUid],
  );

  const pasteWeek = useCallback(
    async (targetStart, callBack = () => {}) => {
      const copiedWeek = getCopiedWeek();

      alert.info('Copying...');
      console.log(copiedWeek, targetStart);
      return Promise.all(
        [0, 1, 2, 3, 4, 5, 6].map((a, i) => {
          console.log('---', copiedWeek.sourceStart + i, '--', targetStart + i, '---');
          if (targetStart + i > 0 && targetStart + i <= programLength) {
            try {
              copyWorkout({
                variables: {
                  record: {
                    sourceCoachProgramUid: copiedWeek?.sourceCoachProgramUid,
                    sourceTemplateUid: copiedWeek?.sourceTemplateUid,
                    source: copiedWeek.sourceStart + i,
                    target: targetStart + i,
                    coachProgramUid,
                    templateUid: programTemplateUid,
                  },
                },
              })
                .then(refetch)
                .then(callBack);
            } catch (err) {
              alert.error(`Something went wrong !`);
            }
          }
          return 1;
        }),
      )
        .then(refetch)
        .then(callBack)
        .then(() => alert.success('Copied SuccessFully !'));
    },
    [copyWorkout, refetch, alert, coachProgramUid, programTemplateUid, getCopiedWeek],
  );

  const [deleteCalendar, { loading: deleteLoading }] = useMutation(deleteCalendarMutation);
  const substractDays = useCallback(
    (days) => {
      var date = new Date(beginningDate);
      date.setDate(date.getDate() + days);
      return date;
    },
    [beginningDate],
  );
  const handleDeleteCalendar = useCallback(
    async (id) => {
      try {
        await deleteCalendar({
          variables: {
            id,
          },
        }).then(refetch);
      } catch (err) {
        console.log(err);
      }
    },
    [deleteCalendar, refetch],
  );

  const [selectedProgram, setSelectedProgram] = useState(null);
  const handleProgramDelete = useCallback(async () => {
    const { WORKOUT, MESSAGE, REST, MEETING } = selectedProgram || {};

    const calendarDay = WORKOUT || MESSAGE || REST || MEETING;

    if (calendarDay) {
      updateCalendarCache(calendar?.filter((item) => item.uid !== calendarDay.uid) || []);
      setSelectedProgram(null);
      try {
        await deleteCalendar({
          variables: {
            id: calendarDay.uid,
            beginningDate: substractDays(calendarDay.programDay),
          },
        });

        if (MEETING) {
          await deleteMeeting({
            variables: {
              record: {
                faceMeetingUid: MEETING.faceMeeting?.uid,
                onlineMeetingUid: MEETING.onlineMeeting?.uid,
              },
            },
          });
        }

        refetch();
      } catch (err) {
        const tempError = err.graphQLErrors?.find((x) => x.path[0] === 'deleteCalendar').message;
        alert.error(tempError);
      }
    }
  }, [selectedProgram, deleteCalendar, updateCalendarCache, refetch, calendar, deleteMeeting]);

  const [selectedMessage, setSelectedMessage] = useState(null);
  useEffect(
    () => setCalendarDayLoading(createLoading || editLoading || deleteLoading),
    [createLoading, editLoading, deleteLoading, setCalendarDayLoading],
  );

  // const duplicateWeek = useCallback(
  //   async (weekIndex, request = 'copy') => {
  //     const _programDays = placeProgramDays();
  //     const _calendarMatrix = generateCalendarMatrix();
  //     const daysDiff =
  //       weekIndex * 7 -
  //       _calendarMatrix[0].reduce((acc, value) => (acc += value === null ? 1 : 0), 0) +
  //       1;
  //     const copiedWeek = getCopiedWeek();
  //     if (request === 'paste') {
  //       const duplicatorFuncton = async (weekDay, i) => {
  //         let dayIndex = daysDiff + i;
  //         const { WORKOUT, MESSAGE, REST } = _programDays[dayIndex] || {};
  //         const calendarToDelete = WORKOUT || MESSAGE || REST;
  //         if (calendarToDelete) {
  //           deleteCalendar({ variables: { id: calendarToDelete?.uid } }).then(refetch);
  //         }

  //         if (weekDay?.WORKOUT?.workout) {
  //           return copyWorkout({
  //             variables: {
  //               record: {
  //                 calendarUid: weekDay?.WORKOUT?.uid,
  //                 target: dayIndex,
  //               },
  //             },
  //             skip: !weekDay?.WORKOUT?.uid,
  //           });
  //         } else if (_programDays[weekDay?.index]?.REST) {
  //           addRestDay(dayIndex);
  //           return true;
  //         }
  //         return true;
  //       };

  //       const duplicateAndGetStatuses = () =>
  //         Promise.all(copiedWeek.map((weekDay, i) => duplicatorFuncton(weekDay, i)));
  //       const successStatusOfDays = await duplicateAndGetStatuses();

  //       const hasSuccess = successStatusOfDays.indexOf(true) !== -1;
  //       const hasFailed = successStatusOfDays.indexOf(false) !== -1;
  //       if (hasSuccess && hasFailed) {
  //         alert.error('Changes Have Been Saved PARTIALLY');
  //       } else if (hasFailed) {
  //         alert.error('Changes Cannot Be Saved');
  //       } else if (hasSuccess) {
  //         alert.success('Changes Saved !');
  //       }
  //     } else if (request === 'copy') {
  //       const getDayToCopy = (weekDay) => {
  //         return _programDays[weekDay?.index];
  //       };
  //       const copyWeek = () => _calendarMatrix[weekIndex].map((weekDay) => getDayToCopy(weekDay));
  //       const weekToCopy = copyWeek();
  //       console.log('ck', weekToCopy);
  //       setCopiedWeek(weekToCopy);
  //     }
  //   },
  //   [pasteWorkout, copiedWeekExists, calendar],
  // );

  const submitMessageDay = useCallback(
    async (programDay, v) => {
      const messageDetails =
        v?.messageType === 0
          ? {
              calendarMessage: v?.message.id,
            }
          : {
              customMessage: v?.customMessage,
              icon: v?.icon?.id,
            };

      if (v?.uid) {
        await handleCalendarUpdate({
          variables: {
            record: {
              uid: v?.uid,
              ...messageDetails,
            },
          },
        });
      } else {
        await createCalendar({
          variables: {
            calendar: {
              ...messageDetails,
              type: CalendarType.MESSAGE,
              coachProgram: coachProgramUid,
              programTemplate: programTemplateUid,
              programDay,
            },
          },
        });
      }
      refetch();
    },
    [createCalendar, handleCalendarUpdate, refetch, coachProgramUid, programTemplateUid],
  );

  const handleCreateMeeting = useCallback(
    async (index, values) => {
      try {
        if (values?.uid) {
          await handleCalendarUpdate({
            variables: {
              record: {
                uid: values.uid,
              },
              meeting: {
                date: values.date?.substring(3),
                timezone: values.timezone.tzCode,
                paid: false,
                location: values.location,
                onlineMeetingUid: values?.onlineMeetingUid,
                faceMeetingUid: values?.faceMeetingUid,
              },
            },
          });
        } else {
          await createCalendar({
            variables: {
              calendar: {
                type: 'MEETING',
                programDay: index,
                coachProgram: coachProgramUid,
                programTemplate: programTemplateUid,
              },
              meeting: {
                date: values.date.substring(3),
                timezone: values.timezone.tzCode,
                paid: false,
                location: values.location,
              },
            },
          });
        }

        if (typeof refetch === 'function') {
          refetch();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [createCalendar, coachProgramUid, refetch, handleCalendarUpdate, programTemplateUid],
  );

  return {
    calendarMatrix,
    programDays,
    addRestDay,
    messageDay,
    openMessageModal,
    closeMessageModal,
    saveWorkoutDay,
    copiedWorkout: copiedWorkoutExists,
    setCopiedWorkout,
    pasteWorkout,
    selectedProgram,
    setSelectedProgram,
    handleProgramDelete,
    selectedMessage,
    setSelectedMessage,
    copiedWeek: copiedWeekExists,
    submitMessageDay,
    handleCreateMeeting,
    handleDeleteCalendar,
    pasteLoading,
    pasteWeek,
    setCopiedWeek,
  };
};

export default useCoachProgram;
