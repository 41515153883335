import {getExercisesPaginatedQuery,} from '@witness/graphql';
import {useQuery} from '@apollo/react-hooks';

const useExercisePage = (title= null,
    sortField ='title',
    sortOrder = 'ASC',
    limit =100,
    skip = 0,
    isGlobal= true,
    type=null,
    category= null) => {

  const { data: exercises, loading: exercisesLoading} = useQuery(
      getExercisesPaginatedQuery,
      {
        variables: {
          record: {
            searchText: title,
            sortField,
            sortOrder,
            type,
            limit,
            skip,
            isGlobal,
            category
          },
        },
      },
  );

  console.log("exercises", exercises)
  return {
    exercises,
    exercisesLoading,
  };
};

export default useExercisePage;
