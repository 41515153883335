import RandomIcon from '../assets/common/randomIcon.svg';
import Logo from '../assets/common/logo.svg';
// header
import NotificationsActiveIcon from '../assets/header/notificationsActive.svg';
import NotificationsIcon from '../assets/header/notifications.svg';
import SettingsIcon from '../assets/header/settings.svg';
import LogoutIcon from '../assets/header/logout.svg';
import ProfileImage from '../assets/header/profile.png';
// input
import PlusIcon from '../assets/signup/plus.svg';
import MinusIcon from '../assets/signup/minus.svg';
// sidebar
import HomeIcon from '../assets/navbar/home.svg';
import ExportIcon from '../assets/navbar/export.svg';
import StatsIcon from '../assets/navbar/stats.svg';
import ImportIcon from '../assets/navbar/import.svg';
import ImportIconGreen from '../assets/navbar/importGreen.svg';
import MediaIcon from '../assets/navbar/media.svg';
import CalendarIcon from '../assets/navbar/calendar.svg';
import UsersIcon from '../assets/navbar/users.svg';
import TraineeIcon from '../assets/navbar/trainee_tm.svg';
import MobileTraineeIcon from '../assets/navbar/mobile_trainee_tm.svg';
import ChatIcon from '../assets/navbar/chat.svg';
import SettingsIconSide from '../assets/navbar/settings.svg';
// coach signup personal
import AvatarDefaultImage from '../assets/signup/avatar.svg';
import UserIcon from '../assets/signup/user.svg';
import UserActiveIcon from '../assets/signup/userActive.svg';
import BookIcon from '../assets/signup/book.svg';
import BookActiveIcon from '../assets/signup/bookActive.svg';
import PhoneIcon from '../assets/signup/phone.svg';
import PhoneActiveIcon from '../assets/signup/phoneActive.svg';
import EnvelopeIcon from '../assets/signup/envelope.svg';
import EnvelopeActiveIcon from '../assets/signup/envelopeActive.svg';
import LocationIcon from '../assets/signup/location.svg';
import LocationActiveIcon from '../assets/signup/locationActive.svg';
import AlertIcon from '../assets/signup/alert.svg';
// coach signup professional
import DiplomaPlus from '../assets/signup/PlusBorderGreen.svg';
import TermsCheckMark from '../assets/signup/termsCheckMark.svg';
import File from '../assets/common/file.svg';
import Image from '../assets/common/image.svg';
import EditStroke from '../assets/common/editStroke.svg';
import DeleteStroke from '../assets/common/deleteStroke.svg';
import EditFill from '../assets/common/editFill.svg';
import DeleteFill from '../assets/common/deleteFill.svg';
import X from '../assets/common/X.svg';
// program request
import LeftCellBackground from '../assets/program-request/leftCellBackground.png';
import RightCellBackground from '../assets/program-request/rightCellBackground.png';
import FitnessLevel from '../assets/program-request/fitnessLevel.svg';
import Injures from '../assets/program-request/injures.svg';
import Environment from '../assets/program-request/environment.svg';
import Equipment from '../assets/program-request/equipment.svg';
import Health from '../assets/program-request/health.svg';
import Focus from '../assets/program-request/focus.svg';
import ProgramLength from '../assets/program-request/programLength.svg';
import Time from '../assets/program-request/time.svg';
import Type from '../assets/program-request/type.svg';
import Goal from '../assets/program-request/goal.svg';
import Male from '../assets/program-request/male.svg';
import Female from '../assets/program-request/female.svg';
// specialities
import Crossfit from '../assets/specialities/crossfit.svg';
import Triathlon from '../assets/specialities/triathlon.svg';
import Fbb from '../assets/specialities/fbb.svg';
import Strength from '../assets/specialities/strength.svg';
import Physio from '../assets/specialities/physio.svg';
import Yoga from '../assets/specialities/yoga.svg';
import Multi from '../assets/specialities/multi.svg';
import Marathon from '../assets/specialities/marathon.svg';
import Postpartum from '../assets/specialities/postpartum.svg';
import PrenetalPrep from '../assets/specialities/prenetalPrep.svg';
import Running from '../assets/specialities/running.svg';
// trainee card
import Message from '../assets/common/message.svg';
import MessageActive from '../assets/common/messageActive.svg';
// dashboard
import ProgramRequests from '../assets/dashboard/programRequests.svg';
import ActiveCustomers from '../assets/dashboard/activeCustomers.svg';
import MonthlyIncome from '../assets/dashboard/monthlyIncome.svg';
import ArrowUpGreen from '../assets/common/arrowUpGreen.svg';
// profile
import ProfilePersonal from '../assets/profile/personal.svg';
import ProfilePersonalActive from '../assets/profile/personalActive.svg';
import ProfilePricing from '../assets/profile/pricing.svg';
import ProfilePricingActive from '../assets/profile/pricingActive.svg';
import ProfileBilling from '../assets/profile/billing.svg';
import ProfileBillingActive from '../assets/profile/billingActive.svg';
import ProfileSettings from '../assets/profile/settings.svg';
import ProfileSettingsActive from '../assets/profile/settingsActive.svg';
import Edit from '../assets/profile/edit.svg';
import EditGreen from '../assets/profile/editGreen.svg';
import ID from '../assets/profile/ID.svg';
import Meal from '../assets/profile/meal.svg';
import Music from '../assets/profile/music.svg';
import AddPortfolioItem from '../assets/profile/addPortfolioItem.svg';
import Download from '../assets/profile/download.svg';
import InputArrowLight from '../assets/common/inputArrowLight.svg';
import InputArrowDark from '../assets/common/inputArrowDark.svg';
import AlertRed from '../assets/common/alertRed.svg';
import AlertYellow from '../assets/common/alertYellow.svg';
import Copy from '../assets/common/copy.svg';
import CopyActive from '../assets/common/copyActive.svg';
import EditGrey from '../assets/common/editGrey.svg';
import DeleteGrey from '../assets/common/deleteGrey.svg';
import SortNone from '../assets/common/sortNone.svg';
import SortDesc from '../assets/common/sortDesc.svg';
import SortAsc from '../assets/common/sortAsc.svg';
import VideoSign from '../assets/common/videoSign.svg';
import VideoSignGrey from '../assets/common/videoSignGrey.svg';
import EmptyIcon from '../assets/program-request/empty-requests.svg';
import PasswordIcon from '../assets/set-password/password-input.svg';
import BinSimpleGrey from '../assets/common/binSimpleGrey.svg';
import BinSimpleDark from '../assets/common/binSimpleDark.svg';
import EditSimpleGrey from '../assets/common/editSimpleGrey.svg';
import Comment from '../assets/common/comment.svg';
import CheckMark from '../assets/common/checkMark.svg';
import DragIcon from '../assets/common/dragIcon.svg';
import PlusSimple from '../assets/common/plusSimple.svg';
import OptionPlus from '../assets/input/optionPlus.svg';
import OptionPlusActive from '../assets/input/optionPlusActive.svg';
import OptionView from '../assets/input/optionView.svg';
import OptionViewDark from '../assets/input/optionViewDark.svg';
import OptionViewDarkCrossed from '../assets/input/optionViewDarkCrossed.svg';
import OptionViewActive from '../assets/input/optionViewActive.svg';
import Search from '../assets/common/search.svg';
import Upload from '../assets/common/upload.svg';
import Clock from '../assets/common/clock.svg';
import Weight from '../assets/program/weight.svg';
import Height from '../assets/program/height.svg';
import Age from '../assets/program/age.svg';
import Loader from '../assets/common/loader.svg';
import GridLayout from '../assets/common/gridLayout.svg';
import ListLayout from '../assets/common/listLayout.svg';
import ArrowMainRight from '../assets/common/arrow-main-right.svg';
import ArrowDownWhite from '../assets/common/arrowDownWhite.svg';
import ArrowUpDark from '../assets/common/arrowUpDark.svg';
import ArrowUpLight from '../assets/common/arrowUpLight.svg';
import TableSortSvg from '../assets/dashboard/table-sort.svg';
import StatusDone from '../assets/dashboard/status-done.svg';
import StatusMissed from '../assets/dashboard/status-missed.svg';
import StatusNew from '../assets/dashboard/status-new.svg';
import AttachmentSvg from '../assets/dashboard/attachment.svg';
import AttachmentsNewSvg from '../assets/dashboard/attachments-new.svg';
import CommentNewSvg from '../assets/dashboard/comment-new.svg';
import EmptyWorkoutSvg from '../assets/dashboard/workout-empty.svg';
import dumbellSvg from '../assets/dashboard/dumbell.svg';
import ArrowLeft from '../assets/common/arrow-left.svg';
import CameraIcon from '../assets/common/camera.svg';
import MessageBtn from '../assets/common/messageBtn.svg';
import Dumbell from '../assets/day-popup/dumbell.svg';
import DumbellActive from '../assets/day-popup/dumbellActive.svg';
import VideoCamera from '../assets/day-popup/videoCamera.svg';
import VideoCameraActive from '../assets/day-popup/videoCameraActive.svg';
import MessageDay from '../assets/day-popup/messageDay.svg';
import MessageDayActive from '../assets/day-popup/messageDayActive.svg';
import RestDayCup from '../assets/day-popup/RestDayCup.svg';
import RestDayCupActive from '../assets/day-popup/RestDayCupActive.svg';
import MeetingLocation from '../assets/day-popup/meetingLocation.svg';
import CopyDay from '../assets/day-popup/copyDay.svg';
import PasteDay from '../assets/day-popup/pasteDay.svg';
import ArrowDownIcon from '../assets/signin/arrow-down-big.svg';
import Move from '../assets/common/move.svg';
import AddComment from '../assets/common/addComment.svg';
import VerticalDots from '../assets/common/verticalDots.svg';
import VerticalDotsActive from '../assets/common/verticalDotsActive.svg';
import EditWeekBlock from '../assets/common/editWeekBlock.svg';
import RestDayBoy from '../assets/common/restDayBoy.svg';
import Ticket from '../assets/program-request/ticket.png';
import LogoShort from '../assets/common/logo-short.svg';
import ChatMobile from '../assets/common/chat-m.svg';
import NotificationMobile from '../assets/common/notif-m.svg';
import Burger from '../assets/common/burger-menu.svg';
import Close from '../assets/common/close.svg';
import CalendarMobile from '../assets/sidebar/calendar.svg';
import Cloud from '../assets/sidebar/cloud.svg';
import HomeMobile from '../assets/sidebar/home.svg';
import ImageMobile from '../assets/sidebar/image.svg';
import Pulse from '../assets/sidebar/pulse.svg';
import Save from '../assets/sidebar/save.svg';
import Settings from '../assets/sidebar/settings.svg';
import User from '../assets/sidebar/users.svg';
import GoBack from '../assets/header/go-back-mobile.svg';
import RedBin from '../assets/common/redBin.svg';
import SimpleArrowUp from '../assets/dashboard/simple-arrow-up.svg';
import ArrowRight from '../assets/common/arrow-right.svg';
import RespMessage from '../assets/common/respMessage.svg';
import ChatSidemenuMobile from '../assets/navbar/chat-mob.svg';
import CouponSidemenuMobile from '../assets/navbar/coupon-mob.svg';
import Collapse from '../assets/common/collapse.svg';
import Expand from '../assets/common/expand.svg';
import PasteColumn from '../assets/common/pasteColumn.svg';
import WhiteCheck from '../assets/common/WhiteCheck.svg';
import ListTrainees from '../assets/sidebar/list.svg';
import LeftArrowPage from '../assets/common/left-arrow-page.svg';
import RightArrowPage from '../assets/common/right-arrow-page.svg';
import UpPage from '../assets/common/up.svg';
import DownPage from '../assets/common/down.svg';

const icons = {
  Logo,
  RandomIcon,
  // header
  NotificationsActiveIcon,
  NotificationsIcon,
  SettingsIcon,
  LogoutIcon,
  ProfileImage,
  // input
  PlusIcon,
  MinusIcon,
  // sidebar
  HomeIcon,
  ExportIcon,
  StatsIcon,
  ImportIcon,
  ImportIconGreen,
  MediaIcon,
  CalendarIcon,
  UsersIcon,
  MobileTraineeIcon,
  TraineeIcon,
  ChatIcon,
  SettingsIconSide,
  ListTrainees,
  // coach signup personal
  AvatarDefaultImage,
  UserIcon,
  UserActiveIcon,
  BookIcon,
  BookActiveIcon,
  PhoneIcon,
  PhoneActiveIcon,
  EnvelopeIcon,
  EnvelopeActiveIcon,
  LocationIcon,
  LocationActiveIcon,
  AlertIcon,
  // coach signup professional
  DiplomaPlus,
  TermsCheckMark,
  File,
  Image,
  X,
  EditStroke,
  DeleteStroke,
  EditFill,
  DeleteFill,
  // program request
  LeftCellBackground,
  RightCellBackground,
  FitnessLevel,
  Injures,
  Environment,
  Equipment,
  Health,
  Focus,
  ProgramLength,
  Time,
  Type,
  Goal,
  Male,
  Female,
  // specialities
  Crossfit,
  Triathlon,
  Fbb,
  Strength,
  Physio,
  Yoga,
  Multi,
  Marathon,
  Postpartum,
  PrenetalPrep,
  Running,
  // trainee card
  Message,
  MessageActive,
  // dashboard
  ProgramRequests,
  ActiveCustomers,
  MonthlyIncome,
  ArrowUpGreen,
  //profile
  ProfilePersonal,
  ProfilePersonalActive,
  ProfilePricing,
  ProfilePricingActive,
  ProfileBilling,
  ProfileBillingActive,
  ProfileSettings,
  ProfileSettingsActive,
  Edit,
  EditGreen,
  ID,
  Meal,
  Music,
  AddPortfolioItem,
  Download,
  InputArrowLight,
  InputArrowDark,
  AlertRed,
  AlertYellow,
  Copy,
  CopyActive,
  EditGrey,
  DeleteGrey,
  SortNone,
  SortDesc,
  SortAsc,
  VideoSign,
  VideoSignGrey,
  EmptyIcon,
  PasswordIcon,
  BinSimpleGrey,
  EditSimpleGrey,
  Comment,
  CheckMark,
  DragIcon,
  PlusSimple,
  OptionPlus,
  OptionPlusActive,
  OptionView,
  OptionViewDark,
  OptionViewDarkCrossed,
  OptionViewActive,
  Search,
  Upload,
  Clock,
  Weight,
  Height,
  Age,
  Loader,
  GridLayout,
  ListLayout,
  ArrowMainRight,
  ArrowDownWhite,
  ArrowUpDark,
  ArrowUpLight,
  TableSortSvg,
  StatusDone,
  StatusMissed,
  StatusNew,
  AttachmentSvg,
  EmptyWorkoutSvg,
  ArrowLeft,
  MessageBtn,
  // calendar day actions
  CameraIcon,
  Dumbell,
  DumbellActive,
  VideoCamera,
  VideoCameraActive,
  MessageDay,
  MessageDayActive,
  MeetingLocation,
  RestDayCup,
  RestDayCupActive,
  CopyDay,
  PasteDay,
  ArrowDownIcon,
  Move,
  AddComment,
  VerticalDots,
  VerticalDotsActive,
  EditWeekBlock,
  RestDayBoy,
  Ticket,
  LogoShort,
  ChatMobile,
  NotificationMobile,
  Burger,
  Close,
  CalendarMobile,
  Cloud,
  HomeMobile,
  ImageMobile,
  Pulse,
  Save,
  Settings,
  User,
  GoBack,
  SimpleArrowUp,
  dumbellSvg,
  RedBin,
  ArrowRight,
  RespMessage,
  ChatSidemenuMobile,
  CouponSidemenuMobile,
  AttachmentsNewSvg,
  CommentNewSvg,
  Collapse,
  Expand,
  PasteColumn,
  WhiteCheck,
  BinSimpleDark,
  LeftArrowPage,
  RightArrowPage,
  UpPage,
  DownPage,
};

export default icons;
