import React, { useContext } from 'react';
import moment from 'moment';

import {
  CardContainer,
  CardHeader,
  CardHeaderIconCell,
  StatusCircle,
  HeaderProfileCell,
  ProfilePic,
  MessengerIcon,
  MessengerIconImg,
  CardBody,
  CardName,
  CardFooter,
  FooterLine,
  ButtonWrapper,
  CardInfo,
  CardInfoText,
  CardInfoRow,
  TraineeRow,
  TraineeTD,
  TraineeRowProfilePic,
  FlexTDContent,
  HoverIndicator,
  DeleteEditOrgGroup,
  ButtonsWrapper,
  ViewIcon,
  ViewIconImg,
  TrashIcon,
  TrashIconImg,
  MobileCardWrapper,
  MobileProfilePic,
  MobileCardBody,
  MobileCardInfoText,
} from './components';
import CustomButton from '../button/CustomButton';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { CoachProgramStatusUI, CoachProgramStatus } from '../../constants/enums';
import ChatContext from '../../services/ChatContext';
import { OrganizationContext } from '../../services/organizationContext';
import { MobileContext } from '../../services/MobileContext';

const TraineeCard = ({
  isGroup,
  program,
  layout = 'list',
  handleEdit = () => {},
  handleDelete = () => {},
  handleCancel = () => {},
}) => {
  const history = useHistory();
  const { isOrganization } = useContext(OrganizationContext);

  const { isMobile } = useContext(MobileContext);

  const [endDate, programMonths] = useMemo(() => {
    if (!program) return 'N/A';

    return [
      moment(program.coachProgram?.beginningDate)
        .add(program.programLength, 'days')
        .add(-1, 'days')
        .format('DD/MM/YYYY'),
      program.programLength / 30,
    ];
  }, [program]);

  const couponCode = useMemo(() => {
    if (!program?.code?.digits) return 'N/A';
    return program?.code?.digits;
  }, [program]);

  const cancelDisabled = useMemo(() => {
    return program?.coachProgram?.status === CoachProgramStatus.DECLINED || program?.coachProgram?.status === CoachProgramStatus.ENDED
  }, [program])

  const { conversations, setCurrentConversation } = useContext(ChatContext);

  const { conversation, hasUnread } = useMemo(() => {
    const groupId = isGroup ? program.uid : program.trainee?.user?.uid;
    const conversation = conversations[groupId];

    return {
      conversation,
      hasUnread: !!(conversation && conversation.unreadMessageCount
        ? conversation.unreadMessageCount
        : 0),
    };
  }, [conversations, isGroup, program]);
  return (
    <>
      {isMobile ? (
        <MobileCardWrapper>
          <MobileProfilePic profilePic={program?.trainee?.avatar?.url || ''}>
            {!program?.trainee?.avatar?.url &&
              program?.trainee?.fullName
                ?.split(' ')
                .map((x) => x.substring(0, 1))
                .join('')}
          </MobileProfilePic>
          <MobileCardBody>
            <CardName style={{ margin: '0 0 1rem', textAlign: 'left', color: '#373333ce' }}>
              {program?.trainee?.fullName}
            </CardName>
            <MobileCardInfoText>
              {program?.trainingTypes?.map((a) => a?.name)?.join(', ') ||
                program?.trainingType?.name}
            </MobileCardInfoText>
            <MobileCardInfoText>{endDate}</MobileCardInfoText>
          </MobileCardBody>
          <StatusCircle status={program?.coachProgram?.status} />
        </MobileCardWrapper>
      ) : layout === 'LIST' ? (
        <TraineeRow>
          <TraineeTD>
            <FlexTDContent>
              <HoverIndicator className="hover_indicator" />
              <TraineeRowProfilePic profilePic={program?.trainee?.avatar?.url || ''}>
                {!program?.trainee?.avatar?.url &&
                  program?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')}
              </TraineeRowProfilePic>
              {program?.trainee?.fullName}
            </FlexTDContent>
          </TraineeTD>
          <TraineeTD>
            <FlexTDContent>
              <StatusCircle
                status={program?.coachProgram?.status}
                style={{ marginRight: '0.8rem', width: '1.2rem', height: '1.2rem' }}
              />
              {CoachProgramStatusUI[program?.coachProgram?.status]}
            </FlexTDContent>
          </TraineeTD>
          <TraineeTD>
            {program?.trainingTypes?.map((a) => a?.name)?.join(', ') || program?.trainingType?.name}
          </TraineeTD>
          {isGroup && (
            <TraineeTD style={{ textAlign: 'center', padding: 0 }}>{`${
              program?.coachProgram?.participants?.length || '0'
            }/${program?.participants}`}</TraineeTD>
          )}
          {!isGroup && (
            <TraineeTD>{`${programMonths} Month${programMonths > 1 ? 's' : ''}`}</TraineeTD>
          )}
          <TraineeTD>{endDate}</TraineeTD>
          {isOrganization && (
            <TraineeTD>
              <FlexTDContent>
                <HoverIndicator />
                <TraineeRowProfilePic profilePic={program?.coach?.profileImage?.url || ''}>
                  {!program?.coach?.profileImage?.url &&
                    program?.coach?.fullName
                      ?.split(' ')
                      .map((x) => x.substring(0, 1))
                      .join('')}
                </TraineeRowProfilePic>
                {program?.coach?.fullName}
              </FlexTDContent>
            </TraineeTD>
          )}
          <TraineeTD>{couponCode}</TraineeTD>
          {(isGroup || !isOrganization) && (
            <TraineeTD>
              <FlexTDContent flexEnd>
                {isOrganization ? (
                  <>
                    {program?.coachProgram?.status === 'APPROVED' && (
                      <DeleteEditOrgGroup className="edit" onClick={handleEdit} />
                    )}
                    <DeleteEditOrgGroup className="del" onClick={handleDelete} />
                  </>
                ) : (
                  <MessengerIcon
                    className="hoverable_message_button"
                    style={{
                      marginRight: '1.6rem',
                      border: '1px solid #F1F0F0',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      setCurrentConversation(conversation);
                      history.push(
                        `/program/${program?.uid}${
                          (isGroup && '?group=true&tab=chat') || '?tab=chat'
                        }`,
                      );
                    }}
                  >
                    <MessengerIconImg active={hasUnread} />
                  </MessengerIcon>
                )}
                {!isOrganization && (
                  <>
                    {isGroup ? (
                      <div style={{ width: '12.4rem', marginRight: '1rem' }}>
                        <CustomButton
                          className="hoverable_view_button"
                          outlined
                          borderRadius="8px"
                          hoverBackground="#00ff91"
                          outlineColor="#F1F0F0"
                          backgroundColor="#ffffff"
                          color="#373333"
                          height="3.2rem"
                          fontSize="1.4rem"
                          onClick={() =>
                            history.push(
                              `/program/${program?.uid}${(isGroup && '?group=true') || ''}`,
                            )
                          }
                        >
                          View Program
                        </CustomButton>
                      </div>
                    ) : (
                      <ViewIcon
                        className="hoverable_message_button"
                        onClick={() =>
                          history.push(
                            `/program/${program?.uid}${(isGroup && '?group=true') || ''}`,
                          )
                        }
                      >
                        <ViewIconImg />
                      </ViewIcon>
                    )}
                    {isGroup ? (
                      <div style={{ width: '14.8rem' }}>
                        <CustomButton
                          className={ !cancelDisabled ? "hoverable_cancel_button" : "" }
                          outlined={ !cancelDisabled }
                          disabled={ cancelDisabled }
                          borderRadius="8px"
                          outlineColor="#F1F0F0"
                          hoverBackground={ cancelDisabled ? "#f1f0f0" : "#F50A4Faa" }
                          backgroundColor={ cancelDisabled ? "#f1f0f0" : "#ffffff" }
                          color="#373333"
                          height="3.2rem"
                          fontSize="1.4rem"
                          onClick={handleCancel}
                        >
                          {isGroup && (program?.coachProgram?.status === 'APPROVED' || program?.price === 0)
                            ? 'Cancel'
                            : 'Request to Cancel'}
                        </CustomButton>
                      </div>
                    ) : (
                      <TrashIcon className="hoverable_cancel_button" onClick={handleCancel}>
                        <TrashIconImg />
                      </TrashIcon>
                    )}
                  </>
                )}
              </FlexTDContent>
            </TraineeTD>
          )}
        </TraineeRow>
      ) : (
        <CardContainer>
          <CardHeader>
            <CardHeaderIconCell>
              <StatusCircle status={program?.coachProgram?.status} />
            </CardHeaderIconCell>
            <HeaderProfileCell>
              <ProfilePic profilePic={program?.trainee?.avatar?.url || ''}>
                {!program?.trainee?.avatar?.url &&
                  program?.trainee?.fullName
                    ?.split(' ')
                    .map((x) => x.substring(0, 1))
                    .join('')}
              </ProfilePic>
            </HeaderProfileCell>
            <CardHeaderIconCell>
              <MessengerIcon
                onClick={() => {
                  setCurrentConversation(conversation);
                  history.push(
                    `/program/${program?.uid}${(isGroup && '?group=true&tab=chat') || '?tab=chat'}`,
                  );
                }}
              >
                <MessengerIconImg active={hasUnread} />
              </MessengerIcon>
            </CardHeaderIconCell>
          </CardHeader>
          <CardBody>
            <CardName>{program?.trainee?.fullName}</CardName>
            <CardInfo>
              <CardInfoRow>
                <CardInfoText>Training Types</CardInfoText>
                <CardInfoText val>
                  {program?.trainingTypes?.map((a) => a?.name)?.join(', ') ||
                    program?.trainingType?.name}
                </CardInfoText>
              </CardInfoRow>
              <CardInfoRow>
                <CardInfoText>Program ends </CardInfoText>
                <CardInfoText val>{endDate}</CardInfoText>
              </CardInfoRow>
            </CardInfo>
          </CardBody>
          <CardFooter>
            <ButtonsWrapper>
              <ButtonWrapper>
                <CustomButton
                  outlined
                  borderRadius="8px"
                  hoverBackground="#00ff91"
                  backgroundColor="#ffffff"
                  onClick={() =>
                    history.push(`/program/${program?.uid}${(isGroup && '?group=true') || ''}`)
                  }
                  style={{ minHeight: '3.6rem' }}
                  fontSize="1.4rem"
                  height="3.6rem"
                >
                  View Program
                </CustomButton>
              </ButtonWrapper>
              <ButtonWrapper disabled={cancelDisabled}>
                <CustomButton
                  outlined={ !cancelDisabled }
                  disabled={ cancelDisabled }
                  borderRadius= "8px"
                  outlineColor="#F50A4Faa"
                  hoverBackground={ cancelDisabled ? "#f1f0f0" : "#F50A4Faa" }
                  backgroundColor={ cancelDisabled ? "#f1f0f0" : "#ffffff" }
                  onClick={handleCancel}
                  style={{ minHeight: '3.6rem' }}
                  fontSize="1.4rem"
                  height="3.6rem"
                >
                  {isGroup && (program?.coachProgram?.status === CoachProgramStatus.APPROVED || program?.price === 0)
                    ? 'Cancel'
                    : 'Request to Cancel'}
                </CustomButton>
              </ButtonWrapper>
            </ButtonsWrapper>
            <FooterLine />
            <FooterLine />
            <FooterLine />
          </CardFooter>
        </CardContainer>
      )}
    </>
  );
};

export default TraineeCard;
