import {
  getPricesQuery,
  addPricingMutation,
  createOrUpdateAdminCodeMutation,
  deleteAdminCodeMutation,
  meQuery,
  createOrUpdateProgramDetailsCouponMutation,
  getProgramDetailsCouponsQuery,
} from '@witness/graphql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useMemo } from 'react';
import useCoachPersonalInfo from './coachPersonalInfo';

const usePricing = ({ setLoading } = {}) => {
  const { data: programPrices, refetch } = useQuery(getPricesQuery);
  const { currentOrganization } = useCoachPersonalInfo({});

  const defaultPrices = useMemo(() => {
    const result = {};
    let fetchedPrices = programPrices?.getPrices;
    if (currentOrganization?.uid) {
      fetchedPrices = currentOrganization.prices;
    }
    fetchedPrices &&
      fetchedPrices.map((program) => {
        const defaultPrice =
          program.p11 === program.p12 &&
          program.p11 === program.p13 &&
          program.p11 === program.p21 &&
          program.p11 === program.p22 &&
          program.p11 === program.p23 &&
          program.p11 === program.p31 &&
          program.p11 === program.p32 &&
          program.p11 === program.p33
            ? program.p11
            : 'Custom';
        result[program?.uid] = defaultPrice;
        return 1;
      });
    return result;
  }, [programPrices, currentOrganization]);

  const [addPricing] = useMutation(addPricingMutation);

  const addProgramPricing = async (values) => {
    setLoading(true);
    await addPricing({
      variables: {
        record: {
          ...values,
        },
      },
    });
    refetch();
    setLoading(false);
  };

  const [createOrUpdateAdminCode] = useMutation(createOrUpdateAdminCodeMutation, {
    refetchQueries: [{ query: meQuery }],
  });

  const [createOrUpdateProgramDetailsCoupon] = useMutation(
    createOrUpdateProgramDetailsCouponMutation,
    {
      refetchQueries: [{ query: getProgramDetailsCouponsQuery }],
    },
  );

  const createAdminCode = (values) => {
    try {
      if (values.type === 'programCode') {
        const {
          uid,
          daysOfTraining,
          price,
          programLength,
          trainingTypes,
          title,
          validUntil,
          available,
          coachFee,
          coaches,
        } = values;

        createOrUpdateProgramDetailsCoupon({
          variables: {
            record: {
              uid,
              trainingTypes: trainingTypes.map((t) => t.value),
              programLength: parseInt(programLength),
              monthlySum: parseInt(price),
              daysOfTraining,
              name: title,
              validUntil: new Date(validUntil),
              available: parseInt(available),
              coachesUids: coaches ? Object.keys(coaches)?.filter((x) => coaches[x]) : null,
              coachFee,
            },
          },
        });
        return;
      }
      const {
        uid,
        title,
        description,
        discountType,
        amount,
        validUntil,
        available,
        coaches,
        coachFee,
      } = values;
      createOrUpdateAdminCode({
        variables: {
          record: {
            uid,
            title,
            description,
            validUntil,
            discountType: discountType?.value,
            amount: Number(amount),
            available: Number(available),
            coachesUids: coaches ? Object.keys(coaches)?.filter((x) => coaches[x]) : null,
            coachFee,
          },
        },
      });
    } catch (err) {
      alert.eror(err.message);
    }
  };

  const [deleteAdminCode] = useMutation(deleteAdminCodeMutation, {
    refetchQueries: [{ query: meQuery }],
  });
  const deleteCoupon = (uid) => {
    deleteAdminCode({
      variables: {
        record: {
          uid,
        },
      },
    });
  };

  return {
    programPrices: programPrices?.getPrices?.length
      ? programPrices?.getPrices
      : currentOrganization?.prices,
    addProgramPricing,
    defaultPrices,
    createAdminCode,
    deleteCoupon,
  };
};

export default usePricing;
