import * as Yup from 'yup';

const date = Yup.string().required();
const timezone = Yup.object()
  .shape({
    tzCode: Yup.string().required(),
  })
  .required();
const location = Yup.string().trim().required();

export const PersonalMeetingSchema = Yup.object().shape({
  date,
  timezone,
  location,
});

export const LiveMeetingSchema = Yup.object().shape({
  date,
  timezone,
});
