import styled, { css } from 'styled-components';
import ICONS from '../../constants/icons';

export const SidebarContainer = styled.div`
  min-width: 80px;
  min-height: Calc(100vh - 80px);
  border-right: 1px solid #05000040;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SidebarLink = styled.div`
  width: 42px;
  height: 42px;
  margin-bottom: 40px;
  border-radius: 4px;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  background-image: ${({ src }) => (src ? `url(${src})` : '')};
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? props.theme.color.primary : 'transparent')};
  opacity: ${(props) => (props.isActive ? '1' : '0.6')};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
    & span {
      display: block;
    }
  }
`;

export const LinkHoverTooltip = styled.span`
  position: absolute;
  display: block;
  white-space: nowrap;
  padding: 0.4rem 1.2rem;
  font-family: Roboto-r;
  color: #05000077;
  font-size: 1.4rem;
  background: #ededed;
  border-radius: 0.6rem;
  z-index: 1000;
  left: Calc(100% + 0.4rem);
  display: none;
`;

export const ChatUnreadIndicator = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.error};
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const MobileSidebarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #00000080;
  ${({ open }) =>
    open
      ? css`
          right: 0;
        `
      : css`
          width: 260px;
          left: -260px;
        `}
  transition: left 0.2s;
  z-index: 2;
`;

export const MobileSidebarContent = styled.div`
  width: 260px;
  max-width: 100%;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SidebarRow = styled.div`
  display: flex;
  border-bottom: 1px solid #0000001a;
`;

export const CloseIcon = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${ICONS.Close});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 6px;
  background-color: #504d4d0f;
  margin: 12px 14px;
  :active {
    background-color: #504d4d4d;
  }
`;

export const AvatarContainer = styled.div`
  margin: 16px 16px 16px 20px;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CoachTitle = styled.div`
  font-family: Roboto-r;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: left;
  color: #050000;
`;

export const CoachName = styled.div`
  font-family: Roboto-b;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: left;
  color: #050000;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  flex-grow: 1;
  min-height: 112px;
  overflow-y: auto;
`;

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  position: relative;
  :active {
    background-color: #504d4d4d;
  }
`;

export const ItemIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 10px;
`;

export const ItemText = styled.div`
  font-family: Roboto-r;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: left;
  color: #696666;
  display: inline-block;
  margin-left: 6px;
`;

export const LogoutContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const LogoutText = styled.span`
  font-family: Roboto-b;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: center;
  color: #050000;
`;
