import React from 'react';
import ICONS from '../../constants/icons';
import { IconContainer, IconWrapper, Icon } from './components';

const MessageIcon = ({ selected, onClick, src, size, margin, error }) => (
  <IconWrapper margin={margin} size={size} className="inner">
    <IconContainer selected={selected} onClick={onClick} className="inner" error={error}>
      <Icon src={src || ICONS.PlusSimple} alt="" className="inner" />
    </IconContainer>
  </IconWrapper>
);

export default MessageIcon;
