import React, { useContext, useState } from 'react';
import { OrganizationContext } from '../../services/organizationContext';
import { Container, ProfilePic } from './components';

const FinanceTableRow = ({ date, monthlyIncome, numOfTrainees, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isOrganization } = useContext(OrganizationContext);
  return (
    <Container
      style={{
        height: isOpen ? 'auto' : null,
        display: 'block',
        boxShadow: isOpen ? '0 1px 4px 0.4px rgba(0,0,0,0.08)' : 'none',
      }}
    >
      <Container style={{ padding: 0, marginBottom: 0, color: isOpen ? '#050000' : '#373333ee' }}>
        <span>{date}</span>
        <span>₪{monthlyIncome}</span>
        <span>{numOfTrainees}</span>
        <span></span>
        <span></span>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            style={{
              gridColumn: '6',
              fontSize: '1.4rem',
              width: '122px',
              boxSizing: 'border-box',
              height: '32px',
              border: `1px solid ${isOpen ? '#F1F0F0' : '#00FF91'}`,
              borderRadius: '6px',
              backgroundColor: `${isOpen ? '#FAFAFA' : '#FFFFFF'}`,
              marginLeft: 'auto',
              cursor: 'pointer',
              outline: 'none',
            }}
          >
            {isOpen ? 'Hide Details' : 'View Details'}
          </button>
        </span>
      </Container>
      {isOpen ? (
        <>
          <hr
            style={{
              boxSizing: 'border-box',
              height: 1,
              width: 'calc(100% + 60px)',
              backgroundColor: '#050000',
              opacity: 0.2,
              marginLeft: -30,
            }}
          />
          <div style={{ padding: '2rem 0' }}>
            {data.map((payDay) => (
              <Container
                key={payDay.uid}
                smallText
                style={{ padding: 0, marginBottom: 0, height: '40px', margin: '4px 0' }}
              >
                <span>{}</span>
                <span>+ ₪{payDay.coachFee || payDay.amount}</span>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    // alignItems: 'center',
                  }}
                >
                  <ProfilePic image={payDay.traineeAvatar}>
                    {payDay.traineeName
                      ?.split(' ')
                      .map((x) => x.substring(0, 1))
                      .join('')
                      .substring(0, 3)}
                  </ProfilePic>
                  {/* <div>
                    <img
                      style={{ width: 20, height: 20, marginRight: 5, objectFit: 'cover' }}
                      src={payDay.traineeAvatar}
                      alt=""
                    />
                  </div> */}
                  <span style={{ width: '100%' }}>{payDay.traineeName}</span>
                </div>
                {isOrganization ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      // alignItems: 'center',
                    }}
                  >
                    <ProfilePic image={payDay.coach?.profileImage?.url}>
                      {payDay.coach?.fullName
                        ?.split(' ')
                        .map((x) => x.substring(0, 1))
                        .join('')
                        .substring(0, 3)}
                    </ProfilePic>
                    {/* <div>
                    <img
                      style={{ width: 20, height: 20, marginRight: 5, objectFit: 'cover' }}
                      src={payDay.traineeAvatar}
                      alt=""
                    />
                  </div> */}
                    <span>{payDay.coach?.fullName}</span>
                  </div>
                ) : (
                  <span>{payDay.programName}</span>
                )}
                <span>{payDay.program?.coachProgram?.group?.name || ''}</span>
                <span>
                  {payDay.program?.coachProgram?.group?.code?.digits
                    ? `#${(
                        '000' + Math.floor(payDay.program?.coachProgram?.group?.code?.digits / 1000)
                      ).slice(-3)}-${(
                        '000' +
                        (payDay.program?.coachProgram?.group?.code?.digits % 1000)
                      ).slice(-3)}`
                    : ''}
                </span>
              </Container>
            ))}
          </div>
        </>
      ) : null}
    </Container>
  );
};
export default FinanceTableRow;
