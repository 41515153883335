import React, { useMemo } from 'react';
import moment from 'moment';

import {
  ProgramRequestsRowContainer,
  ProgramRequestsRowPrimaryText,
  ProgramRequestsRowSecondaryText,
} from './components';

const ProgramRequestRow = ({ request, openRequest }) => {
  const createdTime = useMemo(() => {
    if (!request) return '';
    const { approvedDate } = request;
    if (!approvedDate) return '';
    return moment(approvedDate).format('DD/MM/YYYY');
  }, [request]);
  return (
    <ProgramRequestsRowContainer status={request.status} onClick={openRequest}>
      <div>
        <ProgramRequestsRowPrimaryText>{`${
          (request.trainingTypes?.length &&
            (request.trainingTypes?.length > 1
              ? request.trainingTypes?.[0]?.speciality?.name
              : request.trainingTypes?.[0]?.name)) ||
          request.trainingType?.name
        } Program`}</ProgramRequestsRowPrimaryText>
        <br />
        {/* <ProgramRequestsRowSecondaryText>{`${request.programLength} days - ${request.daysOfTraining?.length} days p/week`}</ProgramRequestsRowSecondaryText> */}
        <ProgramRequestsRowSecondaryText>
          {request?.trainee?.fullName}
        </ProgramRequestsRowSecondaryText>
      </div>
      <div style={{ textAlign: 'right' }}>
        <ProgramRequestsRowPrimaryText>
          {request.status === 'PENDING' ? 'New' : request.status?.toLowerCase()}
        </ProgramRequestsRowPrimaryText>
        <br />
        <ProgramRequestsRowSecondaryText>{createdTime}</ProgramRequestsRowSecondaryText>
      </div>
    </ProgramRequestsRowContainer>
  );
};

export default ProgramRequestRow;
