import styled from 'styled-components';
import ICONS from '../../constants/icons';

// text input

export const InputWrapper = styled.div`
  width: 100%;
  min-height: 4rem;
  height: ${({ height }) => (height ? height : '60px')};
  border: ${({ outlined, fill }) => (outlined && !fill ? '1px solid #05000033' : 'none')};
  border-bottom: ${({ outlined, fill }) =>
    !outlined && !fill ? '1px solid #05000033' : outlined ? '1px solid #05000033' : 'none'};
  border-radius: ${({ outlined, fill, borderRadius }) =>
    outlined && borderRadius ? borderRadius : outlined || fill ? '12px' : '0'};
  border-color: ${({ isActive, error, outlined, theme, borderColor, focusBorderColor }) =>
    error
      ? theme.color.error
      : isActive && focusBorderColor
      ? focusBorderColor
      : !isActive && outlined
      ? borderColor
      : isActive && !outlined
      ? '#050000cc'
      : '#05000033'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.6rem')};
  margin: ${({ margin }) => (margin ? margin : '1rem 0 2rem 0')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  background: ${({ backgroundColor, isActive, focusBackgroundColor }) =>
    isActive && focusBackgroundColor
      ? focusBackgroundColor
      : backgroundColor
      ? backgroundColor
      : 'transparent'};
  flex-grow: ${({ grow }) => (grow ? '1' : 'none')};
  position: relative;
  text-overflow: ellipsis;
`;
export const InputWrapperClock = styled.div`
  height: ${({ small }) => (small ? '3.2rem' : '4rem')};
  opacity: ${({ disabled }) => (disabled ? '40%' : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  border: ${({ error, theme, borderColor }) =>
    error ? `1px solid ${theme.color.error}` : `1px solid ${borderColor || '#05000033'}`};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ fontSize }) => fontSize || '1.8rem'};
  width: fit-content;
  font-family: 'Space-r';
  & > * .react-time-picker__clock--open,
  .react-time-picker__button {
    display: none;
  }
  & > * .react-time-picker__wrapper,
  .react-time-picker__inputGroup__input {
    border: none;
  }
  & > * input,
  span {
    font-family: 'Space-r';
    outline: none;
    text-align: center;
    font-size: ${({ fontSize }) => fontSize || '1.8rem'};
  }
  & > * .react-time-picker__inputGroup {
    min-width: auto;
  }
  & > * .react-time-picker__inputGroup__input--hasLeadingZero {
    max-width: ${({ fontSize }) => (fontSize ? '0.8rem' : '1.1rem')};
  }
  position: relative;
`;

export const InputUpperLabel = styled.div`
  position: absolute;
  top: -0.2rem;
  left: 0.6rem;
  font-family: Roboto-m;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #37333366;
  background: white;
  padding: 0 0.25rem;
  height: 2px;
  display: flex;
  align-items: center;
`;
export const InputIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: ${({ icon }) => (icon ? `url(${icon})` : 'none')};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${({ outlined }) => (outlined ? '0 Calc(2rem - 16px) 0 2rem' : '0 0 10px 0')};
`;

export const StyledInput = styled.input`
  line-height: 40px;
  padding: 0 min(12%, 1.6rem);
  border: none;
  outline: none;
  background: none;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  font-size: 1.4rem;
  color: ${({ color }) => (color ? color : '#050000')};
  display: flex;
  align-items: center;
  &::placeholder {
    font-size: ${({ placeholderFontSize }) =>
      placeholderFontSize ? placeholderFontSize : 'inherit'};
  }
`;

export const InputError = styled.div`
  color: ${({ theme }) => theme.color.error};
  font-size: 1rem;
  font-weight: 800;
  padding: 0 12px 0 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
export const InputErrorIcon = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${ICONS.AlertIcon});
  background-position: center;
  background-size: contain;
  margin: ${({ margin }) => (margin ? margin : '0 0 0 8px')};
  background-repeat: no-repeat;
`;

// check input

export const CheckInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ little }) => (little ? 'fit-content' : '164px')};
  max-width: 164px;
  min-width: ${({ little }) => (little ? 'fit-content' : 'Calc(25% - 9px)')};
  height: ${({ little }) => (little ? '4rem' : '44px')};
  padding: 0.6rem 1.2rem 0.6rem 0.6rem;
  border-radius: 8px;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.color.primary}` : '1px solid transparent'};
  background: ${({ checked }) => (checked ? '#ffffff' : '#fafafa')};
  margin: ${({ little }) => (little ? '0 0 0.8rem 0.6rem' : '4px 6px 4px 0;')};
  cursor: pointer;
  flex-grow: 1;
`;
export const CheckInputIcon = styled.div`
  min-width: ${({ little }) => (little ? '28px' : '32px')};
  height: ${({ little }) => (little ? '28px' : '32px')};
  border-radius: 8px;
  background: ${({ checked, theme, little }) =>
    little ? 'transparent' : checked ? theme.color.primary : '#ffffff'};
  background-image: ${({ icon }) => (icon ? `url(${icon})` : '')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55%;
  margin-right: 2px;
`;
export const CheckInputTitle = styled.div`
  flex-grow: 1;
  font-size: ${({ multiWord }) => (multiWord ? '1.2rem' : '1.4rem')};
  color: #050000;
  opacity: ${({ checked }) => (checked ? 1 : 0.6)};
  text-align: center;
`;
// number input

export const NumberInputWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: ${({ disabled }) => (disabled ? 'none' : '1.2px solid #05000022')};
  background: ${({ disabled }) => (disabled ? '#fafafa' : 'none')};
  padding: 6px;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
`;

export const NumberInputButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #fafafa;
  background-position: center;
  background-size: 80%;
  cursor: ${({ nonPositive }) => (nonPositive ? 'no-drop' : 'pointer')};
  background-image: ${({ plus }) => (plus ? `url(${ICONS.PlusIcon})` : `url(${ICONS.MinusIcon})`)};
  opacity: 50%;
  &:hover {
    opacity: 100%;
  }
`;

export const NumberInputArrowsContainer = styled.div``;
export const NumberInputArrow = styled.div`
  width: ${({ small }) => (small ? '13px' : '16px')};
  height: ${({ small }) => (small ? '6px' : '9px')};
  margin: 2px 0;
  background-image: url(${ICONS.InputArrowLight});
  transform: ${({ plus }) => (plus ? `rotate(180deg)` : `none`)};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  &:hover {
    background-image: ${({ nonPositive }) =>
      nonPositive ? `url(${ICONS.InputArrowLight})` : `url(${ICONS.InputArrowDark})`};
  }
  margin-right: ${({ right, small }) => (right ? '0' : small ? '0.2rem' : '1rem')};
  margin-left: ${({ right, small }) => (right ? (small ? '0.2rem' : '1rem') : '0')};
`;

export const NumberInputInput = styled.input`
  flex-grow: 1;
  text-align: center;
  border: none;
  outline: none;
  min-width: 20px;
  width: Calc(100% - 88px);
  background: transparent;
`;

// file input
export const FileInputInput = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;
export const FileInputLabel = styled.label`
  border-radius: inherit;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: ${({ noHover }) => (noHover ? 0 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  &:hover {
    background-color: #00000011;
    /* opacity: ${({ noHover }) => (noHover ? 0 : 1)}; */
  }
`;
export const UploadCloud = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${ICONS.ImportIcon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 60%;
`;

// editable info
export const EditableInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #f1f0f0;
  background: #ffffff;
  margin: 1.2rem 0;
`;

export const EditableInfoInput = styled.input`
  flex-grow: 1;
  background: transparent;
  border: none;
  outline: none;
  color: #050000e6;
  line-height: 2.4rem;
  font-size: 1.4rem;
  padding: 1.2rem 1.2rem;
  font-family: Roboto-r;
`;

export const EditableInfoIcon = styled.div`
  padding: 2.4rem;
  border-right: 1px solid #f1f0f0;
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: min(2.4rem, 36%) auto;
  font-family: Space-b;
`;

// invisible input

export const InvisibleInputWrapper = styled.div`
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InvisibleInputSingleChoice = styled.div`
  font-size: inherit;
  font-family: inherit;
  opacity: ${({ active }) => (active ? '1' : '0.6')};
  cursor: pointer;
`;

export const InvisibleInputChoices = styled.div`
  font-size: inherit;
  font-family: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const InvisibleInput = styled.input`
  width: 100%;
  max-width: 48px;
  border: none;
  font-size: inherit;
  font-family: inherit;
  text-align: center;
  background: transparent;
  outline: none;
  padding: 4px;
  border-bottom: 1px solid transparent;
  &:focus {
    border-bottom: 1px solid #05000066;
    color: ${({ choices }) => (choices ? 'transparent' : 'inherit')};
  }
`;

// select

export const StyledSelect = styled.div`
  width: 100%;
  /* line-height: 40px; */
  display: flex;
  align-items: center;
  padding: 0 16px 0 12px;
  border: none;
  outline: none;
  background: none;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  font-size: 1.4rem;
  color: ${({ color }) => (color ? color : '#373333')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 1px;
`;

export const LoadingContainer = styled.div`
  font-size: 1.4rem;
  font-family: Space-r;
  width: 100%;
  line-height: 2rem;
  padding: 1rem;
  text-align: center;
`;
export const OptionContainer = styled.div`
  width: Calc(100% + 2px);
  max-height: 100px;
  position: absolute;
  top: Calc(100% + 2px);
  left: -1px;
  border: 1px solid #37333322;
  border-radius: 6px;
  z-index: 4444;
  overflow-y: auto;
  background: #ffffff;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const StyledOption = styled.div`
  opacity: 80%;
  cursor: pointer;
  font-size: 1.4rem;
  padding: 0.4rem 1rem 0.4rem 1.4rem;
  font-family: Roboto-r;
  color: ${({ color }) => (color ? color : '#050000')};
  width: 100%;
  opacity: ${({ isFocused }) => (isFocused ? '1' : '0.8')};
  background: ${({ isFocused }) => (isFocused ? '#00ff911a' : '#ffffff')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isFocused }) =>
    isFocused
      ? `
    & > * .option-plus {
        background-image: url(${ICONS.OptionPlusActive});
      }
    & > * .option-view {
      background-image: url(${ICONS.OptionViewActive});
    }`
      : ``}
  &:hover {
    opacity: 100%;
    background: #00ff911f;
    & > * .option-plus {
      background-image: url(${ICONS.OptionPlusActive});
    }
    & > * .option-view {
      background-image: url(${ICONS.OptionViewActive});
    }
  }
  line-height: ${({ isNew }) => (isNew ? '4.8rem' : '2.4rem')};
  border-bottom: ${({ isNew }) => (isNew ? '0.5px solid #0500001a' : 'none')};
`;

export const OptionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionButton = styled.div`
  padding: 1rem;
  margin-left: 0.6rem;
  background-image: url(${({ isView }) => (isView ? ICONS.OptionView : ICONS.OptionPlus)});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const SelectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const SelectArrow = styled.div`
  position: absolute;
  content: '';
  width: 1.6rem;
  height: 100%;
  top: 0;
  right: 16px;
  background-image: url(${ICONS.InputArrowLight});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:hover {
    background-image: url(${ICONS.InputArrowDark});
  }
`;

// text area

export const StyledTextAreaWrapper = styled.div`
  padding: 0.5rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, errorBorder, borderColor }) =>
      errorBorder ? theme.color.error : borderColor || '#d2d1d1'};
`;

export const StyledTextAreaError = styled.div`
  color: ${({ theme }) => theme.color.error};
  font-size: 1rem;
  font-weight: 800;
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

export const StyledTextArea = styled.textarea`
  border: none;
  min-width: 100%;
  flex-grow: 1;
  background: transparent;
  min-height: ${({ rows }) => (rows ? `Calc(4 * 2.4rem + 4.6rem)` : '100%')};
  max-height: ${({ rows }) => (rows ? `Calc(${rows} * 2.4rem + 4.6rem)` : '100%')};
  padding: 1.7rem 1.9rem 2.9rem 1.9rem;
  outline: none;
  max-width: 100%;
  font-family: Roboto-r;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #050000cc;
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

// multi check

export const CMultiCheckScrollable = styled.div`
  border: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  /* width */
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 2px;
  }
`;

export const CMultiCheckItem = styled.div`
  width: 100%;
  margin-bottom: Calc(1.7rem - 4px);
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #05000099;
  &:last-child {
    margin: 0;
  }
`;

export const CMultiCheckItemMark = styled.div`
  padding: 1.05rem;
  border-radius: 8px;
  border: 1px solid #05000044;
  margin-right: 1.6rem;
  border: ${({ active, theme }) =>
    active ? `1px solid ${theme.color.primary}` : '1px solid #05000044'};
  background: ${({ active, theme }) => (active ? theme.color.primary : 'transparent')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: ${({ active }) => (active ? `url(${ICONS.TermsCheckMark})` : 'none')};
  cursor: ${({ active }) => (active ? `url(${ICONS.TermsCheckMark})` : 'none')};
  cursor: pointer;
`;

export const InputUnit = styled.div`
  font-family: Roboto-l;
  font-size: 1.2rem;
  color: #969494;
  padding-right: 0.8rem;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const Suffix = styled.span`
  margin-right: 10px;
`
