import React, { useCallback, useEffect, useRef, useState } from 'react';

import CustomTooltip from '../tooltip/CustomTooltip';

import { HeaderButton } from './components';
import ICONS from '../../constants/icons';
import {
  AreaHeaderRow,
  Body,
  Footer,
  IconButton,
  OptWrapper,
} from '../workout-message-modal/components';
import RadioButton from '../radio/RadioButton';
import CustomInputDropdown from '../input/CustomInputDropdown';
import MessageIcon from '../workout-message-modal/MessageIcon';
import IconsModal from '../workout-message-modal/IconsModal';
import CustomTextArea from '../input/CustomTextArea';
import CustomButton from '../button/CustomButton';
import { useQuery } from '@apollo/react-hooks';
import { getCalendarMessagesQuery } from '@witness/graphql';

const menuStyles = {
  maxHeight: '180px',
  boxShadow: '0 4px 10px 0 rgba(74,74,74,0.12)',
  paddingTop: '1.8rem',
  paddingBottom: '1.8rem',
  width: 'fit-content',
};

const valueStyles = {
  color: '#373333',
  fontFamily: 'Roboto-r',
  fontSize: '1.2rem',
  letterSpacing: 0,
};

const optionStyles = {
  ...valueStyles,
  height: '2.4rem',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1.8rem',
};

const MessageOption = ({ opt, onClick }) => (
  <OptWrapper
    onClick={(e) => {
      // e.stopPropagation();
      onClick();
    }}
    style={{ fontSize: '1.4rem', padding: '0.4rem 0.8rem' }}
  >
    <MessageIcon margin={'0 1rem 0 0'} src={opt?.icon?.file?.url} size={20} />
    <span style={{ fontSize: '1.2rem' }}>{opt.text}</span>
  </OptWrapper>
);

const MessageValue = ({ opt }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-0.8rem', width: '100%' }}>
    <MessageIcon margin={'0 1rem 0 0'} src={opt?.icon?.file?.url} size={20} />
    <span
      style={{
        fontSize: '1.2rem',
        maxWidth: 'Calc(100% - 4.4rem)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {opt.text}
    </span>
  </div>
);

const AddMessageTooltip = ({ onSubmit, onDelete, initialValue }) => {
  const [addMessageOpen, setAddMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState(initialValue?.customMessage ? 1 : 0);
  const [message, setMessage] = useState(initialValue?.message || null);
  const [customMessage, setCustomMessage] = useState(initialValue?.customMessage || '');
  const [errors, setErrors] = useState(null);

  const { data } = useQuery(getCalendarMessagesQuery);

  const [icon, setIcon] = useState(initialValue?.icon || '');
  const [isIconsOpen, setIsIconsOpen] = useState(false);
  const toggleIconsModal = useCallback(() => {
    if (messageType === 1) {
      setIsIconsOpen((s) => !s);
    }
  }, [messageType]);

  const addCommentRef = useRef(null);

  const reinitialize = useCallback(() => {
    setMessageType(initialValue?.customMessage ? 1 : 0);
    setMessage(initialValue?.message || null);
    setCustomMessage(initialValue?.customMessage || '');
    setIcon(initialValue?.icon || '');
    setErrors(null);
  }, [setMessageType, setMessage, setCustomMessage, setIcon, initialValue]);

  const discardChanges = useCallback(() => {
    reinitialize();
    setAddMessageOpen(false);
  }, [setAddMessageOpen, reinitialize]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        addCommentRef.current &&
        !addCommentRef.current.contains(event.target) &&
        !event.target.className?.includes('inner')
      ) {
        discardChanges();
      }
    },
    [addCommentRef, discardChanges],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    reinitialize();
  }, [initialValue, reinitialize]);

  const validate = useCallback(() => {
    const currErr = {};
    if (messageType === 0) {
      if (!message) {
        currErr.message = true;
      }
      if (currErr.message) {
        setErrors(currErr);
      } else {
        setErrors(null);
      }
      return !currErr.message;
    } else if (messageType === 1) {
      if (!customMessage) {
        currErr.customMessage = true;
      }
      if (!icon) {
        currErr.icon = true;
      }
      const invalid = currErr.icon || currErr.customMessage;
      if (invalid) {
        setErrors(currErr);
      } else {
        setErrors(null);
      }
      return !invalid;
    }
  }, [setErrors, message, customMessage, icon, messageType]);

  useEffect(() => {
    if (errors) {
      validate();
    }
  }, [message, customMessage, icon, messageType]);

  return (
    <div ref={addCommentRef}>
      <CustomTooltip
        style={{
          padding: '1.6rem 0.8rem 1.2rem',
          display: addMessageOpen ? 'block' : 'none',
          pointerEvents: 'all',
          top: 'Calc(100% - 0.6rem)',
          minWidth: 'fit-content',
          left: '0.8rem',
        }}
        below
        triangleLeft="Calc(50% + 3.2rem)"
      >
        <Body style={{ padding: 0 }}>
          <RadioButton
            buttonStyle={{
              width: '1.2rem',
              height: '1.2rem',
              borderWidth: '0.3rem',
              marginRight: '0.8rem',
              marginBottom: '0',
              marginLeft: '0.6rem',
              borderColor: messageType === 0 ? '#00FF91' : 'rgba(5, 0, 0, 0.2)',
            }}
            textStyle={{ fontSize: '1.4rem' }}
            onClick={() => setMessageType(0)}
            text="Choose Message"
          />

          <CustomInputDropdown
            color="#373333"
            style={{
              minHeight: '3.2rem',
              fontSize: '1.4rem',
              marginBottom: '0',
            }}
            borderColor="#00000012"
            arrowStyles={{ width: '1.2rem' }}
            outlined
            borderRadius="8px"
            height="3.2rem"
            fontSize="1.2rem"
            options={data?.getCalendarMessages?.messages || []}
            optionComponent={MessageOption}
            value={message?.text ? <MessageValue opt={message} /> : ''}
            onChange={(val) => setMessage(val)}
            disabled={messageType !== 0}
            optionStyles={optionStyles}
            menuStyles={menuStyles}
            valueStyles={valueStyles}
            placeholder="- message text -"
            error={errors?.message}
          />

          <AreaHeaderRow style={{ marginTop: '2rem' }}>
            <RadioButton
              buttonStyle={{
                width: '1.2rem',
                height: '1.2rem',
                borderWidth: '0.3rem',
                marginRight: '0.8rem',
                marginBottom: '0',
                marginLeft: '0.6rem',
                borderColor: messageType === 1 ? '#00FF91' : 'rgba(5, 0, 0, 0.2)',
              }}
              textStyle={{ fontSize: '1.4rem' }}
              onClick={() => setMessageType(1)}
              text="Create Custom Message"
            />

            {/* {!icon?.id && (
              <IconButton onClick={toggleIconsModal} disabled={messageType !== 1}>
              Choose Icon
              </IconButton>
            )} */}

            <MessageIcon
              src={icon?.file?.url}
              onClick={toggleIconsModal}
              size={24}
              error={errors?.icon}
            />

            <IconsModal
              isOpen={isIconsOpen}
              onRequestClose={toggleIconsModal}
              apply={setIcon}
              appliedIcon={icon}
            />
          </AreaHeaderRow>

          <div style={{ marginTop: '0.8rem' }}>
            <CustomTextArea
              placeholder="Type Here"
              rowCount={null}
              value={customMessage}
              borderColor="#00000008"
              errorBorder
              wrapperStyle={{
                backgroundColor: '#f8f7f788',
              }}
              style={{
                maxHeight: '10rem',
                minHeight: '10rem',
                padding: '0.8rem 1rem',
                fontSize: '1.4rem',
              }}
              onChange={(val) => setCustomMessage(val)}
              // error={errors[field.name]}
              disabled={messageType !== 1}
              error={errors?.customMessage}
            />
          </div>
        </Body>

        <Footer style={{ padding: '0.8rem 0 0', justifyContent: 'flex-end' }}>
          <CustomButton
            type="button"
            style={{
              marginRight: '0.8rem',
              width: 'fit-content',
              height: '2.4rem',
              maxHeight: '2.4rem',
              minHeight: '2.4rem',
              padding: '0.2rem 1.2rem',
              color: 'white'
            }}
            backgroundColor="#F50A4F"
            fontSize="1.2rem"
            onClick={() => {
              onDelete();
              setAddMessageOpen(false);
            }}
            borderRadius="0.6rem"
          >
            Delete
          </CustomButton>
          <CustomButton
            type="button"
            style={{
              width: 'fit-content',
              height: '2.4rem',
              maxHeight: '2.4rem',
              minHeight: '2.4rem',
              padding: '0.2rem 1.2rem',
            }}
            backgroundColor="#F1F0F0"
            fontSize="1.2rem"
            onClick={discardChanges}
            borderRadius="0.6rem"
          >
            Discard
          </CustomButton>
          <CustomButton
            type="button"
            style={{
              marginLeft: '0.8rem',
              width: 'fit-content',
              height: '2.4rem',
              maxHeight: '2.4rem',
              minHeight: '2.4rem',
              padding: '0.2rem 1.2rem',
            }}
            backgroundColor="#00ff91"
            fontSize="1.2rem"
            borderRadius="0.6rem"
            onClick={() => {
              if (!validate()) {
                return;
              }
              onSubmit({ message, customMessage, icon, messageType });
              setAddMessageOpen(false);
            }}
          >
            Save
          </CustomButton>
        </Footer>
      </CustomTooltip>
      <HeaderButton
        icon={ICONS.Message}
        onClick={() => setAddMessageOpen((curr) => !curr)}
        open={addMessageOpen}
        active={customMessage || icon || message}
      />
    </div>
  );
};

export default AddMessageTooltip;
