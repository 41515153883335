import { useMutation } from '@apollo/react-hooks';
import { duplicateTemplateMutation } from '@witness/graphql';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IMAGES from '../../constants/images';
import AreYouSureModal from '../are-you-sure-modal/AreYouSureModal';

import CustomButton from '../button/CustomButton';
import {
  TemplateCardContainer,
  CardHeader,
  ProfilePic,
  ParticipantCardBody,
  CardName,
  ParticipantCardFooter,
  ParticipantHealthCell,
  ParticipantHealthCellValue,
  CardFooter,
  FooterLine,
  ButtonWrapper,
  HoverIndicator,
  TraineeRowProfilePic,
  TraineeTD,
  FlexTDContent,
  TraineeRow,
  MessengerIcon,
  DuplicateIconImg,
  DuplicateIconCorner,
  ButtonsWrapper,
} from './components';

const templateLengthMonth = {
  '30': '1 month',
  '60': '2 month',
  '90': '3 month',
};

const TemplateCard = ({
  template,
  withoutFooterButton,
  selected,
  onSelect,
  layout,
  refetch,
  hideDuplicate,
}) => {
  const history = useHistory();
  const [copyOpen, setCopyOpen] = useState(false);
  const [duplicateTemplate] = useMutation(duplicateTemplateMutation);
  const handleDuplicate = useCallback(async () => {
    try {
      await duplicateTemplate({
        variables: {
          uid: template?.uid,
        },
      });

      refetch();
    } catch (err) {
      console.log(err);
    }
  }, [duplicateTemplate, template, refetch]);

  const trainingTypesShortened = useMemo(() => {
    const name = template?.trainingTypes
      ?.map((a) => a?.name)
      ?.join(', ')
      .trim();
    if (!name) {
      return '';
    }

    if (name.length > 33) {
      return name.substring(0, 30) + '...';
    }

    return name;
  }, [template]);

  return (
    <>
      {layout === 'LIST' ? (
        <TraineeRow>
          <TraineeTD>
            <FlexTDContent>
              <HoverIndicator className="hover_indicator" />
              <TraineeRowProfilePic profilePic={IMAGES.TempProgram1} style={{ opacity: '0.7' }}>
                {template?.name.substring(0, 1)}
              </TraineeRowProfilePic>
              {template?.name}
            </FlexTDContent>
          </TraineeTD>
          <TraineeTD> {template?.trainingTypes?.map((a) => a?.name)?.join(', ')}</TraineeTD>
          <TraineeTD>
            {templateLengthMonth[template?.programLength] || `${template?.programLength} days`}
          </TraineeTD>
          <TraineeTD>08-10-20</TraineeTD>
          <TraineeTD>
            <div style={{ width: '16rem', display: 'flex', alignItems: 'center' }}>
              {!hideDuplicate && (
                <MessengerIcon className="hoverable_view_button">
                  <DuplicateIconImg onClick={() => setCopyOpen(true)} />
                </MessengerIcon>
              )}
              <CustomButton
                className="hoverable_view_button"
                outlined
                borderRadius="8px"
                hoverBackground="#00ff91"
                backgroundColor="#ffffff"
                height="3.2rem"
                fontSize="1.4rem"
                onClick={() => history.push(`template/${template?.uid}`)}
                style={{ marginLeft: '2rem' }}
              >
                View Template
              </CustomButton>
            </div>
          </TraineeTD>
        </TraineeRow>
      ) : (
        <TemplateCardContainer
          onClick={onSelect}
          style={{
            width: 'Calc(100% / 6 - 2.4rem)',
            height: 'fit-content',
            borderColor: selected ? '#15DF68' : 'rgba(5, 0, 0, 0.12)',
          }}
          withoutFooterButton={withoutFooterButton}
        >
          <CardHeader
            style={{
              height: '11.2rem',
              boxShadow: `0 0 0 2px ${selected ? '#15DF68' : 'transparent'}`,
            }}
            backgroundUrl={IMAGES.TempProgram1Opacity}
          >
            {!hideDuplicate && (
              <DuplicateIconCorner>
                <DuplicateIconImg onClick={() => setCopyOpen(true)} />
              </DuplicateIconCorner>
            )}
            <ProfilePic
              profilePic={template?.image}
              width="7.2rem"
              style={{
                background: '#d8d8d8',
                boxShadow: `0 0 0 2px ${selected ? '#15DF68' : '#d8d8d8'}`,
              }}
            >
              {!template?.image && template?.name?.substring(0, 1)}
            </ProfilePic>
          </CardHeader>
          <ParticipantCardBody style={{ height: '8.4rem', padding: '3.4rem 1.6rem 0' }}>
            <CardName
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: 0,
              }}
            >
              {template?.name}
            </CardName>
          </ParticipantCardBody>
          <ParticipantCardFooter>
            <ParticipantHealthCell style={{ height: '8rem', justifyContent: 'center' }}>
              <ParticipantHealthCellValue>{trainingTypesShortened}</ParticipantHealthCellValue>
            </ParticipantHealthCell>
            <ParticipantHealthCell style={{ height: '8rem', justifyContent: 'center' }}>
              <ParticipantHealthCellValue>
                {templateLengthMonth[template?.programLength] || `${template?.programLength} days`}
              </ParticipantHealthCellValue>
            </ParticipantHealthCell>
          </ParticipantCardFooter>
          <CardFooter style={withoutFooterButton ? { height: '1.2rem' } : { height: '6rem' }}>
            {!withoutFooterButton && (
              <ButtonsWrapper>
                <ButtonWrapper>
                  <CustomButton
                    outlined
                    borderRadius="8px"
                    hoverBackground="#00ff91"
                    backgroundColor="#ffffff"
                    height="3.2rem"
                    onClick={() => history.push(`template/${template?.uid}`)}
                  >
                    View Template
                  </CustomButton>
                </ButtonWrapper>
              </ButtonsWrapper>
            )}
            <FooterLine />
            <FooterLine />
            <FooterLine />
          </CardFooter>
        </TemplateCardContainer>
      )}

      <AreYouSureModal
        modalOpen={copyOpen}
        closeModal={() => setCopyOpen(false)}
        submitModal={handleDuplicate}
        headingText={'Duplicate Template'}
        contentText={'Are you sure that you want to duplicate this template?'}
      />
    </>
  );
};

export default TemplateCard;
