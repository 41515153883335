import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCoachGroupQuery, getProgramQuery } from '@witness/graphql';
import { useQuery } from '@apollo/react-hooks';

import ProgramRequestRow from '../../components/dashboard/ProgramRequestRow';
import ProgramRequest from '../../components/request-modal/ProgramRequest';
import useProgramRequest from '../../hooks/programRequest';
import ChatContext from '../../services/ChatContext';
import Avatar from '../../components/avatar/Avatar';
import { useDebounce } from '../../services/utils';
import ConversationItem from './ConversationItem';
import ConversationNew from './ConversationNew';
import ICONS from '../../constants/icons';
import MessageList from './MessageList';
import Participant from './Participant';
import {
  ChatContainer,
  ChatColumn,
  ConversationsHeader,
  ConversationsHeaderText,
  ChatDetailsHeader,
  ChatDetailsHeaderText,
  SearchWrapper,
  SearchIcon,
  SearchInput,
  ConversationsList,
  ChatUserInfo,
  ChatInfoRow,
  ChatUserName,
  ChatInfoMeta,
  RequestWrapper,
  MobileList,
  Paper,
} from './components';
import { MobileContext } from '../../services/MobileContext';
import Fixedheader from '../../components/header/FixedHeader';
import MessageHeader from './MessageHeader';

const Chat = () => {
  const {
    conversations,
    currentConversation,
    currentMessages,
    toggleCurrentConversation,
    setCurrentConversation,
  } = useContext(ChatContext);

  const [searchTerm, setSearchTerm] = useState('');
  const delayedTerm = useDebounce(searchTerm, 500);

  const convos = useMemo(() => {
    const lowered = delayedTerm.toLowerCase();
    return Object.values(conversations)
      .filter((item) => {
        if (!delayedTerm) {
          return true;
        }

        return item.user.name.toLowerCase().includes(lowered);
      })
      .sort((a, b) => b.lastMessage?.sentAt - a.lastMessage?.sentAt);
  }, [conversations, delayedTerm]);

  const history = useHistory();
  const loc = useLocation();

  const newConv = useMemo(() => {
    const trainee = loc.state?.trainee;

    if (!trainee) {
      return null;
    }

    const existing = conversations[trainee.user?.uid];

    if (existing) {
      return existing;
    }

    return {
      isNew: true,
      groupId: trainee.user?.uid,
      lastMessage: {},
      user: {
        id: trainee.user?.uid,
        name: trainee.fullName,
        avatar: trainee.avatar?.url,
      },
    };
  }, [loc.state, conversations]);

  useEffect(() => {
    if (newConv) {
      setCurrentConversation(newConv);
    }
  }, [setCurrentConversation, newConv]);

  const { programRequests } = useProgramRequest();
  console.log(programRequests?.[0]);

  const { data: groupData } = useQuery(getCoachGroupQuery, {
    skip: !currentConversation?.isGroup,
    variables: {
      record: {
        uid: currentConversation?.groupId,
      },
    },
  });

  const programRequest = useMemo(() => {
    if (!currentConversation) {
      return;
    }

    const finder = currentConversation.isGroup
      ? (item) => item?.coachProgram?.group?.uid === currentConversation.groupId
      : (item) => item?.trainee?.user?.uid === currentConversation.groupId;

    const request = programRequests?.find(finder);

    if (currentConversation.isGroup && !request && groupData?.getGroup) {
      return Object.assign({}, groupData?.getGroup, {
        status: 'PENDING',
      });
    }

    return request;
  }, [currentConversation, programRequests, groupData]);

  const [requestModal, setRequestModal] = useState(null);
  const handleClick = useCallback(
    (item) => {
      if (currentConversation?.isGroup || item.status === 'APPROVED') {
        if (currentConversation?.isGroup) {
          history.push(`/program/${currentConversation?.groupId}?group=true`);
        } else {
          history.push(`/program/${item.uid}`);
        }
      } else {
        setRequestModal(item);
      }
    },
    [setRequestModal, history, currentConversation],
  );

  const { isMobile } = useContext(MobileContext);

  return isMobile ? (
    <div style={{ backgroundColor: 'white' }}>
      {currentConversation ? (
        <Paper>
          <MessageHeader
            goBack={() => toggleCurrentConversation(currentConversation)}
            imageName={currentConversation?.user?.name}
            chatImage={currentConversation?.user?.avatar}
            title={currentConversation?.user?.name}
            subTitle={`${
              (programRequest?.trainingTypes?.length &&
                (programRequest?.trainingTypes?.length > 1
                  ? programRequest?.trainingTypes?.[0]?.speciality?.name
                  : programRequest?.trainingTypes?.[0]?.name)) ||
              programRequest?.trainingType?.name
            } Program`}
          />
          <MessageList
            programRequest={programRequest}
            conversation={currentConversation}
            messages={currentMessages}
            hideHeader
          />
        </Paper>
      ) : (
        <>
          <Fixedheader title="MESSAGES" handleBack={history.goBack} />
          <SearchWrapper>
            <SearchIcon />
            <SearchInput
              placeholder="Search Conversation or group"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchWrapper>

          <MobileList>
            <ConversationsList isMobile>
              {newConv && newConv.isNew && (
                <ConversationNew
                  trainee={loc.state?.trainee}
                  isActive={currentConversation?.isNew}
                  onClick={() => toggleCurrentConversation(newConv)}
                  isMobile
                />
              )}

              {convos.map((c) => (
                <ConversationItem
                  key={c.groupId}
                  item={c}
                  isActive={currentConversation && currentConversation.groupId === c.groupId}
                  onClick={() => toggleCurrentConversation(c)}
                  isMobile
                />
              ))}
            </ConversationsList>
          </MobileList>
        </>
      )}
    </div>
  ) : (
    <ChatContainer>
      <ChatColumn width="37.5rem">
        <ConversationsHeader>
          <ConversationsHeaderText>Your Conversations</ConversationsHeaderText>
        </ConversationsHeader>
        <SearchWrapper>
          <SearchIcon />
          <SearchInput
            placeholder="Search Conversation or group"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchWrapper>
        <ConversationsList>
          {newConv && newConv.isNew && (
            <ConversationNew
              trainee={loc.state?.trainee}
              isActive={currentConversation?.isNew}
              onClick={() => toggleCurrentConversation(newConv)}
            />
          )}
          {console.log(currentConversation, '----------', programRequest)}
          {convos.map((c) => (
            <ConversationItem
              key={c.groupId}
              item={c}
              isActive={currentConversation && currentConversation.groupId === c.groupId}
              onClick={() => toggleCurrentConversation(c)}
            />
          ))}
          {/* {console.log('convos', convos)} */}
        </ConversationsList>
      </ChatColumn>
      <ChatColumn grow={1} width="0">
        {currentConversation && (
          <MessageList
            conversation={currentConversation}
            messages={currentMessages}
            programRequest={programRequest}
          />
        )}
      </ChatColumn>
      {currentConversation && (
        <ChatColumn width="37.5rem">
          <ChatUserInfo>
            <ChatInfoRow mt="3.15rem">
              <Avatar
                size="8.6rem"
                isOnline
                src={currentConversation?.user?.avatar}
                name={currentConversation?.user?.name}
              />
            </ChatInfoRow>
            <ChatInfoRow mt="1.6rem">
              <ChatUserName>{currentConversation?.user?.name}</ChatUserName>
            </ChatInfoRow>
            {!currentConversation?.isGroup && (
              <ChatInfoRow mt="1.8rem" mb="3.3rem">
                <ChatInfoMeta
                  name="Weight"
                  value={`${programRequest?.trainee?.weight}kg`}
                  icon={ICONS.Weight}
                />
                <ChatInfoMeta
                  name="Height"
                  value={programRequest?.trainee?.height}
                  icon={ICONS.Height}
                />
                <ChatInfoMeta
                  name="Age"
                  value={`${programRequest?.trainee?.age}y`}
                  icon={ICONS.Age}
                />
              </ChatInfoRow>
            )}
          </ChatUserInfo>

          {programRequest && (
            <ChatUserInfo>
              <ChatDetailsHeader>
                <ChatDetailsHeaderText>TRAINING PROGRAM</ChatDetailsHeaderText>
              </ChatDetailsHeader>

              <RequestWrapper>
                <ProgramRequestRow
                  request={programRequest}
                  openRequest={() => handleClick(programRequest)}
                />
              </RequestWrapper>
            </ChatUserInfo>
          )}

          {currentConversation?.isGroup && programRequest?.coachProgram?.participants?.length > 0 && (
            <ChatUserInfo style={{ overflowY: 'hidden' }}>
              <ChatDetailsHeader>
                <ChatDetailsHeaderText>GROUP PARTICIPANTS</ChatDetailsHeaderText>
              </ChatDetailsHeader>

              <div style={{ overflowY: 'auto' }}>
                {programRequest?.coachProgram?.participants?.map((item) => {
                  return <Participant key={item.uid} item={item} />;
                })}
              </div>
            </ChatUserInfo>
          )}
        </ChatColumn>
      )}

      {!!requestModal && (
        <ProgramRequest content={requestModal} closeModal={() => setRequestModal(null)} />
      )}
    </ChatContainer>
  );
};

export default Chat;
