import React, { useContext, useState, useCallback } from 'react';
import moment from 'moment';
import {
  PricingTableWrapper,
  PricingTable,
  PricingTableHeadTR,
  PricingTableTR,
  PricingTableTh,
  PricingTableTd,
  PricingTableTdCopy,
  PricingTableTdAction,
  SortSpecification,
  MobileList,
} from './components';
import EmptyTemplate from '../empty-template/EmptyTemplate';
import IMAGES from '../../constants/images';
import MobileDetailCouponItem from './MobileDetailCouponItem';
import { MobileContext } from '../../services/MobileContext';
import MobileCouponDetailsModal from './MobileCouponDetailsModal';

const CouponTable = ({ setEdditingCode, coupons = [], deleteCoupon }) => {
  const copyCodeToClipboard = (id) => {
    const el = document.getElementById(id);
    el.select();
    document.execCommand('copy');
  };

  const { isMobile } = useContext(MobileContext);

  const [coupon, setCoupon] = useState(null);
  const closeCouponModal = useCallback(() => setCoupon(null), []);

  return (
    <PricingTableWrapper isMobile={isMobile}>
      {isMobile ? (
        <MobileList>
          {coupons?.map((code) => (
            <MobileDetailCouponItem item={code} key={code.uid} openCouponModal={setCoupon} />
          ))}

          <MobileCouponDetailsModal item={coupon} closeModal={closeCouponModal} />
        </MobileList>
      ) : coupons?.length ? (
        <PricingTable>
          <PricingTableHeadTR>
            <PricingTableTh>
              Coupon Code <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Name <SortSpecification asc />
            </PricingTableTh>
            <PricingTableTh>
              Expiration Date <div>(DD/MM/YYY)</div> <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Description <SortSpecification desc />
            </PricingTableTh>
            <PricingTableTh>
              Discount Type <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Amount <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>
              Usage <SortSpecification />
            </PricingTableTh>
            <PricingTableTh>Actions</PricingTableTh>
          </PricingTableHeadTR>
          {coupons?.map((code) => (
            <PricingTableTR>
              <PricingTableTd>
                {`#${code?.code?.digits}`}
                <textarea
                  style={{ maxWidth: 0, maxHeight: 0, opacity: 0 }}
                  value={code?.code?.digits}
                  id={`to_copy_${code?.code?.digits}`}
                />
                <PricingTableTdCopy
                  className="copy"
                  onClick={() => copyCodeToClipboard(`to_copy_${code?.code?.digits}`)}
                />
              </PricingTableTd>
              <PricingTableTd>{code?.title}</PricingTableTd>
              <PricingTableTd>{moment(code?.validUntil).format('DD/MM/YYYY')}</PricingTableTd>
              <PricingTableTd width="14rem">{code?.description}</PricingTableTd>
              <PricingTableTd center>{code?.discountType === 'AMOUNT' ? '₪' : '%'}</PricingTableTd>
              <PricingTableTd center> {code?.amount}</PricingTableTd>
              <PricingTableTd center> {`${code?.usage}/${code?.available}`}</PricingTableTd>
              <PricingTableTd>
                <PricingTableTdAction
                  className="edit"
                  onClick={() => setEdditingCode({ ...code, type: 'discountCode' })}
                />
                {!code?.usage && (
                  <PricingTableTdAction className="del" onClick={() => deleteCoupon(code?.uid)} />
                )}
              </PricingTableTd>
            </PricingTableTR>
          ))}
        </PricingTable>
      ) : (
        <EmptyTemplate
          image={IMAGES.EmptyTemplates}
          imageSize={'20rem'}
          primaryText="You don't Have any Coupons Yet"
        />
      )}
    </PricingTableWrapper>
  );
};

export default CouponTable;
