import React, { useCallback } from 'react';
import {
  ListItemContainer,
  ItemValue,
  ValueText,
  ItemDetails,
  ItemCode,
  ItemTypes,
  TrainingType,
  ArrowRightIcon,
  ItemHeader,
} from './components';

const MobileDetailCouponItem = ({ item, openCouponModal }) => {
  const handleOpen = useCallback(() => {
    openCouponModal(item);
  }, [item, openCouponModal]);

  return (
    <ListItemContainer onClick={handleOpen}>
      <ItemValue>
        {item.discountType ? (
          <ValueText>
            -{item.amount}
            {item.discountType === 'PERCENTAGE' ? '%' : '₪'}
          </ValueText>
        ) : (
          <ValueText>{item.monthlySum ? item.monthlySum + '₪' : '0'}</ValueText>
        )}
      </ItemValue>
      <ItemDetails>
        <ItemHeader>
          <ItemCode>
            #{('000' + Math.floor(item?.code?.digits / 1000)).slice(-3)}-
            {('000' + (item?.code?.digits % 1000)).slice(-3)}
          </ItemCode>
          <ArrowRightIcon />
        </ItemHeader>
        <ItemTypes>
          {Array.isArray(item.trainingTypes) ? (
            item.trainingTypes.map((tt) => <TrainingType>{tt.name}</TrainingType>)
          ) : (
            <TrainingType>{item.title}</TrainingType>
          )}
        </ItemTypes>
      </ItemDetails>
    </ListItemContainer>
  );
};

export default MobileDetailCouponItem;
