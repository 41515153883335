import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import timezones from 'timezones-list';

import CustomTooltip from '../tooltip/CustomTooltip';

import { HeaderButton, TooltipFooter } from './components';
import ICONS from '../../constants/icons';
import CustomButton from '../button/CustomButton';
import { ModalInput } from '../meeting-modal/components';
import ReactSelectWitness from '../react-select-witness/ReactSelectWitness';
import CustomInput from '../input/CustomInput';
import { useAddressAutoComplete } from '../../hooks';
import CustomInputClock from '../input/CustomInputClock';
import { EntireGroupResults } from '../calendar-results-modal/components';

const AddMeetingTooltip = ({ onSubmit, onDelete, initialValues }) => {
  const userTimezone = useMemo(
    () =>
      timezones.find(
        (timezone) => timezone.tzCode === Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
      ),
    [],
  );
  const [addMeetingOpen, setAddMeetingOpen] = useState(false);
  const [date, setDate] = useState(initialValues?.date);
  const [timezone, setTimezone] = useState(
    timezones.find((timezone) => timezone.tzCode === initialValues?.timezone) || userTimezone,
  );
  const [location, setLocation] = useState(initialValues?.location);

  const [errors, setErrors] = useState(null);

  const addCommentRef = useRef(null);

  const reinitialize = useCallback(() => {
    setDate(initialValues?.date);
    setTimezone(
      timezones.find((timezone) => timezone.tzCode === initialValues?.timezone) || userTimezone,
    );
    setLocation(initialValues?.location);
    setErrors(null);
  }, [setDate, setTimezone, setLocation, initialValues, userTimezone]);

  const discardChanges = useCallback(() => {
    reinitialize();
    setAddMeetingOpen(false);
  }, [reinitialize, setAddMeetingOpen]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        addCommentRef?.current &&
        !addCommentRef.current.contains(event.target) &&
        !event.target.className?.includes('inner')
      ) {
        discardChanges();
      }
    },
    [addCommentRef, discardChanges],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    reinitialize();
  }, [initialValues, reinitialize]);

  const { autoCompleteRef, setHandleAdressChoose } = useAddressAutoComplete();

  const validate = useCallback(() => {
    const currErr = {};
    if (!date) {
      currErr.date = true;
    }
    if (!timezone) {
      currErr.timezone = true;
    }
    if (!initialValues?.isOnline && !location) {
      currErr.location = true;
    }
    const invalid = currErr.date || currErr.timezone || currErr.location;
    if (invalid) {
      setErrors(currErr);
    } else {
      setErrors(null);
    }
    return !invalid;
  }, [setErrors, date, timezone, initialValues, location]);

  useEffect(() => {
    if (errors) {
      validate();
    }
  }, [date, location, timezone]);

  return (
    <div ref={addCommentRef}>
      <CustomTooltip
        style={{
          padding: '1.6rem 0.8rem 1.2rem',
          display: addMeetingOpen ? 'block' : 'none',
          pointerEvents: 'all',
          top: 'Calc(100% - 0.6rem)',
          minWidth: 'fit-content',
          left: '0.8rem',
        }}
        below
        triangleLeft={initialValues?.isOnline ? 'Calc(50% - 4.8rem)' : null}
      >
        <ModalInput style={{ height: '5.8rem', marginBottom: '1.4rem' }}>
          Time of the meeting
          <CustomInputClock
            color="#373333"
            outlined
            borderRadius="8px"
            value={date}
            onChange={(val) => setDate(val)}
            // error={touched[field.name] && errors[field.name]}
            hours
            maxTime="00:23:59"
            small
            fontSize="1.4rem"
            borderColor="rgba(0, 0, 0, 0.07)"
            error={errors?.date}
          />
        </ModalInput>
        <ModalInput style={{ height: '5.8rem', marginBottom: '1.4rem' }}>
          Time Zone
          <ReactSelectWitness
            options={timezones?.map((a) => ({ ...a, value: a.tzCode }))}
            margin="0"
            width="100%"
            value={timezone}
            onChange={(val) => setTimezone(val)}
            placeholder="Select time Zone"
            small
            borderColor="rgba(0, 0, 0, 0.07)"
            error={errors?.timezone}
          />
        </ModalInput>

        {!initialValues?.isOnline && (
          <ModalInput style={{ height: '5.8rem', marginBottom: '1.4rem' }}>
            Location
            {setHandleAdressChoose((val) => setLocation(val))}
            <CustomInput
              id="edit-meeting-address"
              inputRef={autoCompleteRef}
              color="#373333"
              outlined
              borderRadius="8px"
              height="3.2rem"
              fontSize="1.4rem"
              value={location}
              onChange={(val) => setLocation(val)}
              margin="0"
              placeholder="Type here..."
              borderColor="rgba(0, 0, 0, 0.07)"
              style={{
                maxHeight: '3.2rem',
                minHeight: '3.2rem',
              }}
              error={errors?.location}
              errorIcon={false}
            />
          </ModalInput>
        )}
        <TooltipFooter>
          <CustomButton
            type="button"
            style={{
              marginRight: '0.8rem',
              width: 'fit-content',
              height: '2.4rem',
              maxHeight: '2.4rem',
              minHeight: '2.4rem',
              padding: '0.2rem 1.2rem',
              color: 'white'
            }}
            backgroundColor="#F50A4F"
            fontSize="1.2rem"
            onClick={() => {
              onDelete();
              setAddMeetingOpen(false);
            }}
            borderRadius="0.6rem"
          >
            Delete
          </CustomButton>
          <CustomButton
            type="button"
            style={{
              width: 'fit-content',
              height: '2.4rem',
              maxHeight: '2.4rem',
              minHeight: '2.4rem',
              padding: '0.2rem 1.2rem',
            }}
            backgroundColor="#F1F0F0"
            fontSize="1.2rem"
            onClick={discardChanges}
            borderRadius="0.6rem"
          >
            Discard
          </CustomButton>
          <CustomButton
            type="button"
            style={{
              marginLeft: '0.8rem',
              width: 'fit-content',
              height: '2.4rem',
              maxHeight: '2.4rem',
              minHeight: '2.4rem',
              padding: '0.2rem 1.2rem',
            }}
            backgroundColor="#00ff91"
            fontSize="1.2rem"
            borderRadius="0.6rem"
            onClick={() => {
              const isValid = validate();
              if (!isValid) {
                return;
              }
              onSubmit({
                date,
                location,
                timezone,
                onlineMeetingUid: initialValues?.isOnline ? initialValues?.onlineMeetingUid : null,
                faceMeetingUid: !initialValues?.isOnline ? initialValues?.faceMeetingUid : null,
              });
              setAddMeetingOpen(false);
            }}
          >
            Save
          </CustomButton>
        </TooltipFooter>
      </CustomTooltip>
      <HeaderButton
        icon={initialValues?.isOnline ? ICONS.VideoCamera : ICONS.LocationIcon}
        onClick={() => setAddMeetingOpen((curr) => !curr)}
        open={addMeetingOpen}
        active={date}
      />
    </div>
  );
};

export default AddMeetingTooltip;
