import BLOCKS from '../constants/workoutBlocks';
import LENGTH_OPTIONS from '../constants/programLengthOptions'
import { WorkoutBlockType, WorkoutStatus } from '../constants/enums';

const workoutStatuses = {
  [WorkoutStatus.DONE]: 2,
  [WorkoutStatus.NEW]: 1,
  [WorkoutStatus.UNDONE]: 0,
};

const useUtils = () => {
  const sortByOrder = (a, b) => a.order - b.order;

  const sortByWorkoutStatus = (a, b) => workoutStatuses[b?.type] - workoutStatuses[a?.type];

  const getWorkoutBlocksByWorkout = (workout, traineeUid) => {
    const currentBlocks = workout?.workoutBlocks;
    return currentBlocks
      ?.map((block) => {
        const nullableAttributes = BLOCKS[block?.type]?.nullableAttributes && [
          ...BLOCKS[block?.type]?.nullableAttributes,
        ];
        const attributes =
          block?.customAttributes?.map((attr) => {
            const initialType = BLOCKS[block?.type]?.attributes?.[attr?.name]?.type;
            if (initialType === 'nullable') {
              const index = nullableAttributes.indexOf(attr?.name);
              if (index !== -1) {
                nullableAttributes.splice(index, 1);
              }
            }
            const type =
              attr?.name === 'unit'
                ? attr?.textValue === 'Time'
                  ? 'clock'
                  : 'number'
                : initialType;
            return {
              ...BLOCKS[block?.type]?.attributes?.[attr?.name],
              ...attr,
              asNeeded:
                typeof BLOCKS[block?.type]?.attributes?.[attr?.name]?.asNeeded === 'boolean'
                  ? !attr?.value
                  : undefined,
              value:
                type === 'clock'
                  ? `00:${('0' + Math.floor(attr?.value / 60)).slice(-2)}:${(
                      '0' +
                      (attr?.value % 60)
                    ).slice(-2)}`
                  : type === 'text'
                  ? attr?.textValue
                  : attr?.value,
              type,
            };
          }) || [];
        const nullValueAttributes =
          nullableAttributes?.map((x) => BLOCKS[block?.type]?.attributes?.[x]) || [];
        const exercises =
          block.type !== WorkoutBlockType.CLASSICSTRENGTH
            ? block.exercises
            : block?.exercises?.map((ex) => ({
                ...ex,
                restTime: ex.restTime
                  ? `00:${('0' + Math.floor(ex.restTime / 60)).slice(-2)}:${(
                      '0' +
                      (ex.restTime % 60)
                    ).slice(-2)}`
                  : '00:00:00',
              }));
        return {
          ...block,
          ...BLOCKS?.[block?.type],
          exercises,
          attributes: [...attributes, ...nullValueAttributes],

          results: block?.results?.filter((x) => x?.trainee?.uid === traineeUid),
        };
      })
      .sort(sortByOrder);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-IN').format(num);
  };
  
  const programLengthValueToOption = (value) => {
    const filteredOptions = LENGTH_OPTIONS.filter(op => op.value == value)
    if (filteredOptions) {
      return filteredOptions[0]
    } else {
      return null
    }
  }

  return { getWorkoutBlocksByWorkout, sortByWorkoutStatus, formatNumber, programLengthValueToOption };
};

export default useUtils;
