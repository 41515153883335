import React from 'react';
import MobileAvatar from '../../components/avatar/MobileAvatar';
import { GoBackIcon } from '../../components/header/components';
import { HeaderInfo, MessageHeaderContainer, InfoCol, InfoTitle, InfoSubTitle } from './components';

const MessageHeader = ({ goBack, chatImage, imageName, title, subTitle }) => {
  return (
    <MessageHeaderContainer>
      <GoBackIcon onClick={goBack} />
      <HeaderInfo>
        <InfoCol center>
          <MobileAvatar src={chatImage} size="28px" name={imageName} />
        </InfoCol>
        <InfoCol ml="16px">
          <InfoTitle>{title}</InfoTitle>
          <InfoSubTitle>{subTitle}</InfoSubTitle>
        </InfoCol>
      </HeaderInfo>
    </MessageHeaderContainer>
  );
};

export default MessageHeader;
