import React, { useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Loader from '../components/loader/Loader';
import URLS from '../constants/urls';
import useRegisterCoach from './registerCoach';

const useRouting = () => {
  const { me, meLoading } = useRegisterCoach();

  const RegistrationRoute = ({ component: Component, ...rest }) => {
    const { me, meLoading } = useRegisterCoach();
    const authed = !!localStorage.getItem('token');
    return (
      <Route
        {...rest}
        render={(props) =>
          meLoading ? (
            <Loader></Loader>
          ) : me?.getCurrentUser?.coach?.status === 'APPROVED' ? (
            <Redirect
              to={{
                pathname:
                  me?.getCurrentUser?.user?.role?.name === 'admin' ? URLS.templates : URLS.home,
              }}
            />
          ) : authed ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/signup/coach' }} />
          )
        }
      />
    );
  };

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const { me, meLoading } = useRegisterCoach();
    const authed = !!localStorage.getItem('token');
    return (
      <Route
        {...rest}
        render={(props) =>
          meLoading ? (
            <Loader></Loader>
          ) : !authed ||
            (me?.getCurrentUser?.coach?.status !== 'APPROVED' &&
              !me?.getCurrentUser?.organisation?.uid) ? (
            <Redirect to={{ pathname: '/signin' }} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };

  const PublicRoute = useCallback(
    ({ component: Component, ...rest }) => {
      const authed = !!localStorage.getItem('token');
      return (
        <Route
          {...rest}
          render={(props) =>
            !authed || me?.getCurrentUser?.coach?.status !== 'APPROVED' ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname:
                    me?.getCurrentUser?.user?.role?.name === 'admin' ? URLS.templates : URLS.home,
                }}
              />
            )
          }
        />
      );
    },
    [me],
  );

  const AdminRoute = useCallback(
    ({ component: Component, ...rest }) => {
      return (
        <Route
          {...rest}
          render={(props) =>
            meLoading ? (
              <Loader></Loader>
            ) : me?.getCurrentUser?.coach?.status === 'APPROVED' ||
              me?.getCurrentUser?.user?.role?.name === 'admin' ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: '/signin' }} />
            )
          }
        />
      );
    },
    [me, meLoading],
  );

  return { PrivateRoute, PublicRoute, RegistrationRoute, AdminRoute };
};

export default useRouting;
