import React, { useCallback, useContext, useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { deleteProgramDetailCoupon, getProgramDetailsCouponsQuery } from '@witness/graphql';
import {
  PricingTableWrapper,
  PricingTable,
  PricingTableHeadTR,
  PricingTableTR,
  PricingTableTh,
  PricingTableTd,
  PricingTableTdCopy,
  PricingTableTdAction,
  MobileList,
} from './components';
import EmptyTemplate from '../empty-template/EmptyTemplate';
import IMAGES from '../../constants/images';
import Loader from '../loader/Loader';
import AlertModal from '../alert-modal/AlertModalGeneral';
import { MobileContext } from '../../services/MobileContext';
import MobileDetailCouponItem from './MobileDetailCouponItem';
import MobileCouponDetailsModal from './MobileCouponDetailsModal';

const DetailCouponTable = ({ setEdditingCode, coupons = [], loading }) => {
  const [deleteUid, setDeleteUid] = useState(false);
  const copyCodeToClipboard = useCallback((id) => {
    const el = document.getElementById(id);
    el.select();
    document.execCommand('copy');
  }, []);

  const [deleteProgramDetail] = useMutation(deleteProgramDetailCoupon, {
    refetchQueries: [{ query: getProgramDetailsCouponsQuery }],
  });

  const handleDelete = useCallback(
    (uid) => {
      deleteProgramDetail({
        variables: {
          uid,
        },
      });
      setDeleteUid('');
    },
    [deleteProgramDetail],
  );

  const { isMobile } = useContext(MobileContext);
  const [coupon, setCoupon] = useState(null);
  const closeCouponModal = useCallback(() => setCoupon(null), []);

  return (
    <PricingTableWrapper isMobile={isMobile}>
      {isMobile ? (
        <MobileList>
          {coupons?.map((code) => (
            <MobileDetailCouponItem item={code} key={code.uid} openCouponModal={setCoupon} />
          ))}

          <MobileCouponDetailsModal item={coupon} closeModal={closeCouponModal} isDetails />
        </MobileList>
      ) : coupons?.length ? (
        <PricingTable>
          <PricingTableHeadTR>
            <PricingTableTh>Coupon Code</PricingTableTh>
            <PricingTableTh>Name</PricingTableTh>
            <PricingTableTh>
              Expiration Date<div>(DD/MM/YYY)</div>{' '}
            </PricingTableTh>
            <PricingTableTh>Usage</PricingTableTh>
            <PricingTableTh>Monthly Price</PricingTableTh>
            <PricingTableTh>Training Types</PricingTableTh>
            <PricingTableTh>Program Length</PricingTableTh>
            <PricingTableTh>Training Days</PricingTableTh>
            <PricingTableTh>Actions</PricingTableTh>
          </PricingTableHeadTR>
          {coupons?.map((code) => (
            <PricingTableTR>
              <PricingTableTd>
                {`#${code?.code?.digits}`}
                <textarea
                  style={{ maxWidth: 0, maxHeight: 0, opacity: 0 }}
                  value={code?.code?.digits}
                  id={`to_copy_${code?.code?.digits}`}
                />
                <PricingTableTdCopy
                  className="copy"
                  onClick={() => copyCodeToClipboard(`to_copy_${code?.code?.digits}`)}
                />
              </PricingTableTd>
              <PricingTableTd>{code?.name}</PricingTableTd>
              <PricingTableTd>
                {code?.validUntil ? moment(code?.validUntil).format('DD/MM/YYYY') : 'N/A'}
              </PricingTableTd>
              <PricingTableTd> {`${code?.usage || 0}/${code?.available || 0}`}</PricingTableTd>
              <PricingTableTd>{code?.monthlySum}</PricingTableTd>
              <PricingTableTd>
                {code?.trainingTypes?.map((item) => item.name).join(', ')}
              </PricingTableTd>
              <PricingTableTd>{code?.programLength} days</PricingTableTd>
              <PricingTableTd>{code?.daysOfTraining.join(', ')}</PricingTableTd>
              <PricingTableTd>
                <PricingTableTdAction
                  className="edit"
                  onClick={() => setEdditingCode({ ...code, type: 'programCode' })}
                />
                {!code?.usage && (
                  <PricingTableTdAction className="del" onClick={() => setDeleteUid(code?.uid)} />
                )}
              </PricingTableTd>
            </PricingTableTR>
          ))}
        </PricingTable>
      ) : loading ? (
        <div
          style={{
            maxHeight: '40rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      ) : (
        <EmptyTemplate
          image={IMAGES.EmptyTemplates}
          imageSize={'20rem'}
          primaryText="You don't Have any Coupons Yet"
        />
      )}

      <AlertModal
        title="Are you sure?"
        content={`This action cannot be undone`}
        modalOpen={deleteUid}
        closeModal={() => setDeleteUid('')}
        okText="Yes, delete it"
        noText="Don't delete it"
        handleOk={() => handleDelete(deleteUid)}
      />
    </PricingTableWrapper>
  );
};

export default DetailCouponTable;
