import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import ICONS from '../../constants/icons';
import { SignInSchema } from '../../schemas/signup/coachProfile';

import {
  SignInWrapper,
  SignInColumn,
  SignInCell,
  FlexWrapper,
  NonFlexWrapper,
  WelcomeAbsolute,
  ButtonWrapper,
  DoNotHaveAcount,
  SignUpLink,
  ErrorAuth,
  AuthErrorIcon,
  ForgotPasswordLink,
  ForgotPasswordLinkWrapper,
  ShowPasswordIcon,
} from './components';
import CustomInput from '../../components/input/CustomInput';
import CustomButton from '../../components/button/CustomButton';
import { useSignin } from '../../hooks';
import { Link } from 'react-router-dom';
import URLS from '../../constants/urls';
import images from '../../constants/images';
import { MobileContext } from '../../services/MobileContext';

const SignIn = () => {
  const { isMobile } = useContext(MobileContext);
  const { submitSignIn } = useSignin();
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordIcon = <ShowPasswordIcon
    icon={showPassword ? ICONS.OptionViewDarkCrossed : ICONS.OptionViewDark}
    onClick={() => setShowPassword(!showPassword)}
  />

  return isMobile ? (
    <SignInWrapper image={images.SigninBackground}>
      <SignInColumn>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <WelcomeAbsolute>Welcome Back!</WelcomeAbsolute>
        </FlexWrapper>
        <SignInCell collSpan={2}>
          <img style={{ width: '80%' }} src={images.LogoImage} alt="Logo" />
        </SignInCell>
        <SignInCell collSpan={2} rowSpan={3}>
          Log In
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={submitSignIn}
            validationSchema={SignInSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '80%', padding: '2rem 0 0' }}>
                <Field id="email" name="email">
                  {({ field }) => (
                    <div>
                      <CustomInput
                        autocomplete={false}
                        outlined
                        height={'5.6rem'}
                        placeholder="Type Your E-mail"
                        icon={ICONS.EnvelopeIcon}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        backgroundColor="#ffffff"
                        margin="3.2rem 0 0.4rem"
                        error={touched[field.name] && errors[field.name]}
                      />
                      {!isMobile && (
                        <ForgotPasswordLinkWrapper>
                          <Link to={URLS.forgotPassword}>
                            <ForgotPasswordLink>Forgot Password?</ForgotPasswordLink>
                          </Link>
                        </ForgotPasswordLinkWrapper>
                      )}
                    </div>
                  )}
                </Field>
                <Field id="password" name="password">
                  {({ field }) => (
                    <CustomInput
                      autocomplete={false}
                      margin="2rem 0 1.6rem"
                      outlined
                      height={'5.6rem'}
                      placeholder="Type the Password"
                      endAdornment={showPasswordIcon}
                      icon={ICONS.PasswordIcon}
                      value={field.value}
                      onChange={(val) => setFieldValue(field.name, val)}
                      type={ showPassword ? 'text' : 'password' }
                      backgroundColor="#ffffff"
                      error={touched[field.name] && errors[field.name]}
                    />
                  )}
                </Field>
                <ErrorAuth>
                  {!!errors?.credentials ? (
                    <>
                      <AuthErrorIcon />
                      {errors?.credentials || ''}
                    </>
                  ) : (
                    false
                  )}
                </ErrorAuth>
                <ButtonWrapper>
                  <CustomButton green>Log In</CustomButton>
                </ButtonWrapper>
              </Form>
            )}
          </Formik>
          {isMobile && (
            <ForgotPasswordLinkWrapper>
              <Link to={URLS.forgotPassword}>
                <ForgotPasswordLink>Forgot Password?</ForgotPasswordLink>
              </Link>
            </ForgotPasswordLinkWrapper>
          )}
          <DoNotHaveAcount>
            Don't Have any Account Yet?{' '}
            <Link to={URLS.sign_up_coach_personal}>
              <SignUpLink>Sign Up</SignUpLink>
            </Link>
          </DoNotHaveAcount>
        </SignInCell>
        <FlexWrapper>
          <SignInCell bottom />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
    </SignInWrapper>
  ) : (
    <SignInWrapper image={images.SigninBackground}>
      <SignInColumn>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bg />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell bg />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn>
        <FlexWrapper>
          <SignInCell />
          <SignInCell />
          <WelcomeAbsolute>Welcome Back!</WelcomeAbsolute>
        </FlexWrapper>
        <SignInCell collSpan={2}>
          <img style={{ width: '80%' }} src={images.LogoImage} alt="Logo" />
        </SignInCell>
        <SignInCell collSpan={2} rowSpan={3}>
          Log In
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={submitSignIn}
            validationSchema={SignInSchema}
          >
            {({ setFieldValue, errors, touched, isSubmitting, values }) => (
              <Form style={{ width: '80%', padding: '2rem 0 0' }}>
                <Field id="email" name="email">
                  {({ field }) => (
                    <div>
                      <CustomInput
                        autocomplete={false}
                        outlined
                        height={'5.6rem'}
                        placeholder="Type Your E-mail"
                        icon={ICONS.EnvelopeIcon}
                        value={field.value}
                        onChange={(val) => setFieldValue(field.name, val)}
                        backgroundColor="#ffffff"
                        margin="3.2rem 0 0.4rem"
                        error={touched[field.name] && errors[field.name]}
                      />
                      <ForgotPasswordLinkWrapper>
                        <Link to={URLS.forgotPassword}>
                          <ForgotPasswordLink>Forgot Password?</ForgotPasswordLink>
                        </Link>
                      </ForgotPasswordLinkWrapper>
                    </div>
                  )}
                </Field>
                <Field id="password" name="password">
                  {({ field }) => (
                    <CustomInput
                      autocomplete={false}
                      margin="2rem 0 1.6rem"
                      outlined
                      height={'5.6rem'}
                      placeholder="Type the Password"
                      icon={ICONS.PasswordIcon}
                      endAdornment={showPasswordIcon}
                      value={field.value}
                      onChange={(val) => setFieldValue(field.name, val)}
                      type={ showPassword ? 'text' : 'password' }
                      backgroundColor="#ffffff"
                      error={touched[field.name] && errors[field.name]}
                    />
                  )}
                </Field>
                <ErrorAuth>
                  {!!errors?.credentials ? (
                    <>
                      <AuthErrorIcon />
                      {errors?.credentials || ''}
                    </>
                  ) : (
                    false
                  )}
                </ErrorAuth>
                <ButtonWrapper>
                  <CustomButton green>Log In</CustomButton>
                </ButtonWrapper>
              </Form>
            )}
          </Formik>
          <DoNotHaveAcount>
            Don't Have any Account Yet?{' '}
            <Link to={URLS.sign_up_coach_personal}>
              <SignUpLink>Sign Up</SignUpLink>
            </Link>
          </DoNotHaveAcount>
        </SignInCell>
        <FlexWrapper>
          <SignInCell bottom />
          <SignInCell bottom />
        </FlexWrapper>
      </SignInColumn>
      <SignInColumn>
        <FlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bottom />
          </NonFlexWrapper>
          <NonFlexWrapper>
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell />
            <SignInCell bg />
            <SignInCell bottom />
          </NonFlexWrapper>
        </FlexWrapper>
      </SignInColumn>
    </SignInWrapper>
  );
};

export default SignIn;
