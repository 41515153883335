import React, { useState } from 'react';
import { BlockExerciseWeightType, WorkoutBlockType } from '../../constants/enums';
import AddExerciseModal from '../add-exercise-modal.jsx/AddExerciseModal';
import { EyeContainer } from '../calendar-results-modal/components';
import { OptionButton } from '../input/components';
import {
  AttributeBullet,
  WorkoutBlockTable as WorkoutBlockTableContainer,
  WorkoutBlockTableAttributeLabel,
  WorkoutBlockTableAttributeNum,
  WorkoutBlockTableAttributeRow,
  WorkoutBlockTableColumnLeft,
  WorkoutBlockTableColumnRight,
  WorkoutBlockWorkoutIndex,
  WorkoutBlockWorkoutName,
  WorkoutBlockWorkoutRow,
  WorkoutBlockWorkoutValue,
  CustomBlockDescription,
  CustomBlockDescriptionLine,
  WorkoutBlockWorkoutPilatesrValue, WorkoutBlockWorkoutPilatesrNameValue,
} from './components';

const WorkoutBlockTable = ({ block, week }) => {
  const [exerciseView, setExerciseView] = useState(null);

  return (
    <WorkoutBlockTableContainer week={week}>
      {block.type !== WorkoutBlockType.CUSTOM && block.type !== WorkoutBlockType.CLASSICSTRENGTH && block.type !== WorkoutBlockType.PILATESR && (
        <WorkoutBlockTableColumnLeft week={week}>
          {block.type !== WorkoutBlockType.REST && !week && <WorkoutBlockTableAttributeRow />}
          {block?.attributes?.map(
            (attr) =>
              !!attr?.value &&
              attr?.name !== 'block_title' && (
                <WorkoutBlockTableAttributeRow key={attr.uid} week={week}>
                  {!week && <AttributeBullet />}
                  {attr.type === 'clock' ? (
                    <>
                      <WorkoutBlockTableAttributeLabel>
                        {`${attr.name === 'unit' ? `Total ${attr?.textValue}` : attr.itemName}: ${
                          attr?.asNeeded ? 'As Needed' : ''
                        }`}
                      </WorkoutBlockTableAttributeLabel>

                      {block.type !== WorkoutBlockType.REST && (
                        <WorkoutBlockTableAttributeNum week={week}>
                          {attr?.asNeeded ? '' : attr?.value?.slice(-5)}
                        </WorkoutBlockTableAttributeNum>
                      )}
                    </>
                  ) : (
                    <>
                      <WorkoutBlockTableAttributeNum week={week}>
                        {attr.value}
                      </WorkoutBlockTableAttributeNum>
                      {/* {attr.name === 'pace' && (
                        <WorkoutBlockTableAttributeLabel>
                          {attr.textValue}
                        </WorkoutBlockTableAttributeLabel>
                      )} */}
                      <WorkoutBlockTableAttributeLabel>
                        {attr.name === 'unit' ? 'meters' : attr.itemName}
                      </WorkoutBlockTableAttributeLabel>
                    </>
                  )}
                </WorkoutBlockTableAttributeRow>
              ),
          )}

          {block.type !== WorkoutBlockType.REST && !week && <WorkoutBlockTableAttributeRow />}
        </WorkoutBlockTableColumnLeft>
      )}
      {/* {block.type === WorkoutBlockType.RUN ? (
        <div style={{ width: '100%', padding: '0 4rem' }}>
          <WorkoutBlockTableAttributeRow />
          {block?.attributes?.map((attr) => (
            <>
              {attr?.value && attr?.value !== -1 ? (
                <WorkoutBlockTableAttributeRow>
                  <AttributeBullet />
                  <WorkoutBlockTableAttributeLabel>
                    {(attr.name === 'unit' && `Total ${attr?.textValue}`) || attr.itemName}:
                  </WorkoutBlockTableAttributeLabel>
                  <WorkoutBlockTableAttributeNum style={{ paddingLeft: '0.4rem' }}>
                    {(attr.name === 'unit' &&
                      (attr.type === 'number'
                        ? `${formatNumber(attr?.value)} meters`
                        : `${attr?.value?.slice(-5)}`)) ||
                      `  ${attr?.value} km/h`}
                  </WorkoutBlockTableAttributeNum>
                </WorkoutBlockTableAttributeRow>
              ) : (
                <></>
              )}
            </>
          ))}
          <WorkoutBlockTableAttributeRow />
        </div>
      ) : ( */}
      <WorkoutBlockTableColumnRight week={week} >
        {block.type === WorkoutBlockType.REST && (
          <WorkoutBlockWorkoutRow>
            <WorkoutBlockWorkoutValue>
              {/* {block?.attributes?.[0]?.value?.slice(-5)} */}
            </WorkoutBlockWorkoutValue>
          </WorkoutBlockWorkoutRow>
        )}

        {block.type === WorkoutBlockType.CUSTOM &&
          block?.attributes?.map(
            (attr) =>
              attr?.name === 'text' && (
                <CustomBlockDescription>
                  {attr?.value?.split('\n').map((i) => (
                    <CustomBlockDescriptionLine>{i}</CustomBlockDescriptionLine>
                  ))}
                </CustomBlockDescription>
              ),
          )}
        {block?.exercises?.map((ex, i) => (
            <>
          <WorkoutBlockWorkoutRow key={ex.uid} week={week} type={block.type}>
            {week && <AttributeBullet week />}
            {(!week || block.type === WorkoutBlockType.STRENGTH) && (
              <WorkoutBlockWorkoutIndex>
                {block.type === WorkoutBlockType.STRENGTH ? `Set ${i + 1}:` : i + 1}
              </WorkoutBlockWorkoutIndex>
            )}

            {block.type !== WorkoutBlockType.STRENGTH && block.type !== WorkoutBlockType.PILATESR &&(
              <WorkoutBlockWorkoutName>{`${ex?.exercise?.title}:`}</WorkoutBlockWorkoutName>
            )}
            {block?.type === WorkoutBlockType.CLASSICSTRENGTH && (
              <>
                <WorkoutBlockWorkoutValue>{`${ex.numberOfSets} sets`}</WorkoutBlockWorkoutValue>
                <WorkoutBlockWorkoutValue>|</WorkoutBlockWorkoutValue>
              </>
            )}
            {block?.type !== WorkoutBlockType.PILATESR && (<WorkoutBlockWorkoutValue>{`${
              ex?.quantity
            } ${ex?.unit.toLowerCase()}`}</WorkoutBlockWorkoutValue>)}

            {!!ex?.weight && (
              <>
                <WorkoutBlockWorkoutValue>|</WorkoutBlockWorkoutValue>
                <WorkoutBlockWorkoutValue>{`${ex?.weight} ${
                  ex?.weightType
                    ? ex?.weightType === BlockExerciseWeightType.KG
                      ? (block.type === WorkoutBlockType.RUN && 'km/h') || 'kg weight'
                      : (block.type === WorkoutBlockType.RUN && 'min/km') || '% weight'
                    : ''
                }`}</WorkoutBlockWorkoutValue>
              </>
            )}
            {block?.type === WorkoutBlockType.CLASSICSTRENGTH && (
              <>
                <WorkoutBlockWorkoutValue>|</WorkoutBlockWorkoutValue>
                <WorkoutBlockWorkoutValue>{`${ex.restTime.slice(
                  -5,
                )} rest`}</WorkoutBlockWorkoutValue>
              </>
            )}
            {block?.type === WorkoutBlockType.PILATESR && (
                <>
                  <div style={{display: "flex", justifyContent: "start", flex: 9, alignItems: "center"}}>
                    <WorkoutBlockWorkoutName>{`${ex?.exercise?.title}:`}</WorkoutBlockWorkoutName>

                  <div style={{display: "flex", flexDirection: "column", flex: 2}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <WorkoutBlockWorkoutPilatesrNameValue>Setting</WorkoutBlockWorkoutPilatesrNameValue>
                      <WorkoutBlockWorkoutPilatesrValue>{`${ex.setting}`}</WorkoutBlockWorkoutPilatesrValue>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <WorkoutBlockWorkoutPilatesrNameValue>Reps</WorkoutBlockWorkoutPilatesrNameValue>
                      <WorkoutBlockWorkoutPilatesrValue>{`${ex.reps}`}</WorkoutBlockWorkoutPilatesrValue>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <WorkoutBlockWorkoutPilatesrNameValue>Notes</WorkoutBlockWorkoutPilatesrNameValue>
                      <WorkoutBlockWorkoutPilatesrValue>{`${ex.notes}`}</WorkoutBlockWorkoutPilatesrValue>
                    </div>
                  </div>
                  </div>
                </>
                )}

            <EyeContainer>
              <OptionButton
                className="option-view"
                isView
                onClick={(e) => {
                  setExerciseView(ex?.exercise);
                }}
              />
            </EyeContainer>
          </WorkoutBlockWorkoutRow>
          {block?.type === WorkoutBlockType.CLASSICSTRENGTH && <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <WorkoutBlockWorkoutPilatesrNameValue>Notes</WorkoutBlockWorkoutPilatesrNameValue>
          <WorkoutBlockWorkoutPilatesrValue>{`${ex.notes}`}</WorkoutBlockWorkoutPilatesrValue>
          </div>}
            </>
        ))}
      </WorkoutBlockTableColumnRight>
      {/* )} */}

      <AddExerciseModal
        modalOpen={!!exerciseView}
        closeModal={() => setExerciseView(null)}
        initialValues={exerciseView}
        readOnly
      />
    </WorkoutBlockTableContainer>
  );
};

export default WorkoutBlockTable;
