import React from 'react';
import { LoaderContainer, LoaderSign, LoaderText } from './components';

const Loader = ({ fontSize, height, padding }) => {
  return (
    <LoaderContainer style={{ height, padding }}>
      <LoaderSign />
      <LoaderText style={{ fontSize }}>Loading, Please, Wait</LoaderText>
    </LoaderContainer>
  );
};

export default Loader;
