import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTemplate } from '../../hooks';
import IMAGES from '../../constants/images';
import Loader from '../../components/loader/Loader';
import CustomButton from '../../components/button/CustomButton';
import TemplateCard from '../../components/trainee-card/TemplateCard';
import EmptyTemplate from '../../components/empty-template/EmptyTemplate';
import ProgramsHeader from '../../components/programs-header/ProgramsHeader';
import { PseudoTemplateCard } from '../../components/trainee-card/components';
import AddTemplateModal from '../../components/add-template-modal.jsx/AddTemplateModal';

const Templates = () => {
  const [addTemplateModal, setAddTemplateModal] = useState(false);
  const [layout, setLayout] = useState('CARDS');
  const {
    programTemplates,
    programTemplatesLoading,
    templateFilter,
    updateFilter,
    refetchProgramTemplates,
  } = useTemplate();
  return (
    <>
      <div
        style={{
          width: '100%',
          background: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AddTemplateModal
          modalOpen={addTemplateModal}
          closeModal={() => setAddTemplateModal(false)}
        />
        <ProgramsHeader
          statusOpts={[]}
          status={null}
          setStatus={null}
          traineeName={templateFilter?.templateName}
          setTraineeName={(v) => updateFilter('templateName', v)}
          heading="List of Templates"
          layout={layout}
          setLayout={setLayout}
          statusFilter={false}
          additionalButton={
            <CustomButton
              height="4.6rem"
              green
              onClick={() => setAddTemplateModal(true)}
              style={{ padding: '1rem 3rem', marginLeft: '1.6rem' }}
              fontSize="1.4rem"
            >
              Create New Template
            </CustomButton>
          }
        />
        <div
          style={{
            background: '#f7f6f6',
            padding: '12px 8px',
            margin: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          {programTemplatesLoading ? (
            <Loader />
          ) : !programTemplates?.length ? (
            <>
              {(false && <Loader />) || (
                <EmptyTemplate
                  image={IMAGES.EmptyTemplates}
                  buttonText="Create New Template"
                  onButtonClick={() => setAddTemplateModal({})}
                  primaryText={`You Haven't Created Templates Yet`}
                  secondarytext={
                    'Create Templates to easily plan your programs for groups or specific trainee'
                  }
                />
              )}
            </>
          ) : layout === 'CARDS' ? (
            <>
              {programTemplates?.map((template) => (
                <TemplateCard
                  key={template?.uid}
                  template={template}
                  refetch={refetchProgramTemplates}
                />
              ))}
              <PseudoTemplateCard />
              <PseudoTemplateCard />
              <PseudoTemplateCard />
              <PseudoTemplateCard />
              <PseudoTemplateCard />
              <PseudoTemplateCard />
              <PseudoTemplateCard />
              <PseudoTemplateCard />
            </>
          ) : (
            <table
              style={{
                width: '100%',
                borderCollapse: 'separate',
                borderSpacing: '0 6px',
                borderRadius: '8px',
                textAlign: 'left',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Template name
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Training types
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Program length
                  </th>
                  <th
                    style={{
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Created at
                  </th>
                  <th
                    style={{
                      width: '12.4rem',
                      padding: '1.6rem 3.2rem',
                      fontFamily: 'Roboto-m',
                      fontSize: '1.4rem',
                      color: '#4a4a4a',
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {programTemplates?.map((template) => (
                  <TemplateCard key={template?.uid} template={template} layout={'LIST'} />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Templates;
